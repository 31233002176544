import {
  ADD_FILTER,
  DELETE_FILTER,
  FETCH_BILLABLE_NONBILLABLE_PROJECTWISE_USERS,
  FETCH_BILLABLE_NONBILLABLE_REPORTS,
  FETCH_BILLABLE_NONBILLABLE_TYPEWISE_REPORTS,
  FETCH_FILTERS,
  FETCH_INCOMPLETE_TIME_SHEET,
  FETCH_REPORTS,
  FETCH_SCREENSHOT_REPORTS,
  FETCH_WINDOW_REPORTS,
  UPDATE_FILTER,
} from "../actionTypes";

export const fetchReports = (payload) => {
  return { type: FETCH_REPORTS, payload };
};
export const fetchWindowReports = (payload) => {
  // console.log('payload', payload)
  return { type: FETCH_WINDOW_REPORTS, payload };
};

export const fetchFilters = (payload) => {
  return { type: FETCH_FILTERS, payload };
};

export const addFilter = (payload) => {
  return { type: ADD_FILTER, payload };
};

export const updateFilter = (payload) => {
  return { type: UPDATE_FILTER, payload };
};

export const deleteFilter = (payload) => {
  return { type: DELETE_FILTER, payload };
};

export const fetchScreenshotReports = (payload) => {
  return { type: FETCH_SCREENSHOT_REPORTS, payload };
};

export const fetchIncompleteTimeSheet = (params) => {
  return { type: FETCH_INCOMPLETE_TIME_SHEET, payload: params };
};

export const fetchBillableNonBillableReports = (payload) => {
  return { type: FETCH_BILLABLE_NONBILLABLE_REPORTS, payload };
};
export const fetchBillableNonBillableTyewiseReports = (payload) => {
  return { type: FETCH_BILLABLE_NONBILLABLE_TYPEWISE_REPORTS, payload };
};
export const fetchBillableNonBillableProjectwiseUsers = (payload) => {
  return { type: FETCH_BILLABLE_NONBILLABLE_PROJECTWISE_USERS, payload };
};
