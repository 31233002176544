import { put, takeEvery } from "redux-saga/effects";
import { FETCH_USER_DATA, SET_FEEDBACK, SET_LOADING, SET_USER_DATA, UPDATE_USER_DATA, UPDATE_USER_EMAIL, UPDATE_USER_EMAIL_SUCCESS } from "../redux/actionTypes";
import { fetchLoggedInUserData, updateAccountDetails, updateUserEmail } from "../../apis/user";
import { showSuccessToast } from "../../utils/toast";
import { getLoggedInUserId } from "../../utils/auth";

function* fetchUserDetailsHandler(){
    try {
        let errPayload;
        const userId = getLoggedInUserId();
        const response = yield fetchLoggedInUserData(userId);

        if(response.data.status === 200){
            const payload = response.data.data;
            yield put({ type: SET_USER_DATA, payload });
        } else {
            errPayload = { code: response.data.status, status:"fail", 
            message: response.data.message || "Something went wrong. Try again later!" };
            yield put({ type: SET_FEEDBACK, payload: errPayload });
        }
    } catch (error) {
        yield put({ type: SET_LOADING, payload:false });
        const payload = { 
            code: error.status, 
            status:"fail", 
            message: error.message || "Something went wrong." 
        };
        yield put({ type: SET_FEEDBACK, payload });
    }
}

function* updateUserDetailsHandler(action){
    try {
        let errPayload;
        yield put({ type: SET_LOADING, payload:true });
        const response = yield updateAccountDetails(action.payload.data, action.payload.id.userId);
        yield put({ type: SET_LOADING, payload:false });
        if(response.data.status === 200){
            yield showSuccessToast(response.data.message);
            yield fetchUserDetailsHandler();
        } else {
            errPayload = { code: response.data.status, status:"fail", 
            message: response.data.message || "Something went wrong. Try again later!" };
            yield put({ type: SET_FEEDBACK, payload: errPayload });
        }
    } catch (error) {
        yield put({ type: SET_LOADING, payload:false });
        const payload = { 
            code: error.status, 
            status:"fail", 
            message: error.message || "Something went wrong." 
        };
        yield put({ type: SET_FEEDBACK, payload });
    }
}

function* updateUserEmailHandler(action){
    try {
        let errPayload;
        yield put({ type: SET_LOADING, payload:true });
        const response = yield updateUserEmail(action.payload);
        yield put({ type: SET_LOADING, payload:false });

        if(response.data.status === 200){
            showSuccessToast(response.data.message);
            // yield fetchUserDetailsHandler();
            yield put({ type: UPDATE_USER_EMAIL_SUCCESS, payload: action.payload });
        } else {
            errPayload = { code: response.data.status, status:"fail", 
            message: response.data.message || "Something went wrong. Try again later!" };
            yield put({ type: SET_FEEDBACK, payload: errPayload });
        }
    } catch (error) {
        yield put({ type: SET_LOADING, payload:false });
        const payload = { 
            code: error.status, 
            status:"fail", 
            message: error.message || "Something went wrong." 
        };
        yield put({ type: SET_FEEDBACK, payload });
    }
}

function* watchUserAction(){
    yield takeEvery(FETCH_USER_DATA, fetchUserDetailsHandler);
    yield takeEvery(UPDATE_USER_DATA, updateUserDetailsHandler);
    yield takeEvery(UPDATE_USER_EMAIL, updateUserEmailHandler)
}

export default watchUserAction;