import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import styled from '@mui/system/styled';
import Stack from '@mui/material/Stack';
import { DatePicker } from 'rsuite';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { setFeedback } from '../../store/redux/actions/feedback.action';
import { useDispatch, useSelector } from 'react-redux';
import { fetchToDosByProjectId } from '../../apis/todos';
import { fetchProjectByEmployeeId } from '../../apis/employee';
import dayjs from 'dayjs';
import { FiPlus } from 'react-icons/fi';
import { convertDateIntoYYYYMMDD, getSelectStyles } from '../../utils/helper';
import TimeLineChart from '../../components/common/TimeLineChart';
import { getTimeLineChartData } from '../../apis/timeSheet';
import AddNewToDo from './AddNewTodo';
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
}));

function isToTimeValid(fromTime, toTime) {
  const [fromHours, fromMinutes] = fromTime.split(':').map(Number);
  const [toHours, toMinutes] = toTime.split(':').map(Number);

  const fromDate = new Date(0, 0, 0, fromHours, fromMinutes);
  const toDate = new Date(0, 0, 0, toHours, toMinutes);

  return toDate > fromDate;
}

const checkCurrentDateTimeValidation = (dateValue, fromTime, toTime) => {
  if (
    convertDateIntoYYYYMMDD(dateValue) === convertDateIntoYYYYMMDD(new Date())
  ) {
    const currentHours = new Date().getHours();
    const currentMinutes = new Date().getMinutes();
    const [fromHour, fromMinute] = fromTime.split(':');
    const [toHour, toMinute] = toTime.split(':');

    if (
      parseInt(fromHour) > currentHours ||
      (parseInt(fromHour) >= currentHours &&
        parseInt(fromMinute) >= currentMinutes)
    ) {
      return false;
    } else if (
      parseInt(toHour) > currentHours ||
      (parseInt(toHour) >= currentHours && parseInt(toMinute) >= currentMinutes)
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const AddTime = ({
  addTimeModalOpen,
  addModalClose,
  addTimeSheetHandler,
  assignee,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data);

  const [totalDuration, setTotalDuration] = useState('00:00');
  const [isDisableToTime, setIsDisableToTime] = useState(true);
  const [timeLineChartData, setTimeLineChartData] = useState([]);
  const [isAbleToAddEntry, setIsAbleToAddEntry] = useState(true);

  const [todo, setTodo] = useState([]);
  const [projects, setProjects] = useState([]);
  const [formData, setFormData] = useState({
    assignee: {
      value: userData.id,
      label: `${userData.first_name} ${userData.last_name}`,
    },
    project: null,
    todo: null,
    memo: '',
    // date: dayjs(),
    date: new Date(),
    from: '',
    to: '',
    reason: null,
    other: '',
    billable: '',
  });
  const [disableAddTodo, setDisableAddTodo] = useState(true);
  // add Todos account modal
  const [addToDosModalOpen, addSetOpen] = React.useState(false);
  const addTodosModalOpen = () => addSetOpen(true);
  const addTodosModalClose = () => addSetOpen(false);

  const [error, setError] = useState({
    assignee: '',
    project: '',
    todo: '',
    memo: '',
    date: '',
    from: '',
    to: '',
    reason: '',
    other: '',
  });

  const assigneeOptions = useMemo(() => {
    return assignee?.length
      ? assignee?.map((row) => {
          return { value: row.id, label: `${row.first_name} ${row.last_name}` };
        })
      : [];
  }, [assignee]);

  const projectOptions = useMemo(() => {
    return projects.length
      ? projects.map((row) => {
          return { value: row.id, label: row.title, billable: row.billable };
        })
      : [];
  }, [projects]);

  let todoOptions = useMemo(() => {
    return todo.length
      ? todo.map((row) => {
          return { value: row.id, label: row.description };
        })
      : [];
  }, [todo]);

  const fetchProjectHandler = useCallback(async () => {
    try {
      if (formData.assignee !== null) {
        const params = { status: 'active' };
        const response = await fetchProjectByEmployeeId(
          params,
          formData.assignee.value
        );
        if (response.data.status === 200) {
          setProjects(response.data.data.projects);
        } else if (response.data.status === 404) {
          setProjects([]);
        } else {
          const errPayload = {
            code: response.data.status,
            status: 'fail',
            message: response.data.message || 'Something went wrong.',
          };
          dispatch(setFeedback(errPayload));
        }
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: 'fail',
        message: error.message || 'Something went wrong.',
      };
      dispatch(setFeedback(errPayload));
    }
  }, [dispatch, formData.assignee]);

  const fetchTodoHandler = useCallback(
    async (newAddedTodo = null) => {
      try {
        setFormData((prev) => ({ ...prev, todo: null }));
        if (formData.assignee && formData.project) setDisableAddTodo(false);
        if (formData.project !== null && formData.assignee !== null) {
          const params = {
            project: formData.project.value,
            assignee: formData.assignee.value,
            listing: true,
            status: 'pending',
          };
          const response = await fetchToDosByProjectId(params);

          if (response.data.status === 200) {
            setTodo(response.data.data.todos);
            if (newAddedTodo) {
              setFormData((prev) => ({ ...prev, todo: newAddedTodo }));
            }
          } else if (response.data.status === 404) {
            setTodo([]);
          } else {
            const errPayload = {
              code: response.data.status,
              status: 'fail',
              message: response.data.message || 'Something went wrong.',
            };
            dispatch(setFeedback(errPayload));
          }
        } else setTodo([]);
      } catch (error) {
        const errPayload = {
          code: error.status,
          status: 'fail',
          message: error.message || 'Something went wrong.',
        };
        dispatch(setFeedback(errPayload));
      }
    },
    [dispatch, formData.assignee, formData.project]
  );

  useEffect(() => {
    fetchTodoHandler();
    fetchProjectHandler();
  }, [fetchTodoHandler, fetchProjectHandler]);

  const resetForm = () => {
    setFormData({
      assignee: null,
      project: null,
      todo: null,
      memo: '',
      date: '',
      from: '',
      to: '',
      reason: null,
      other: '',
    });
    setError({
      assignee: '',
      project: '',
      todo: '',
      memo: '',
      date: '',
      from: '',
      to: '',
      reason: '',
      other: '',
    });
    addModalClose();
  };

  const changeAssigneeHandler = (selectedOption) => {
    if (selectedOption) {
      setFormData((prev) => ({
        ...prev,
        assignee: selectedOption,
        project: null,
      }));
      setError((prev) => ({ ...prev, assignee: '' }));
    }
  };
  const changeProjectHandler = (selectedOption) => {
    if (selectedOption) {
      setFormData((prev) => ({
        ...prev,
        project: selectedOption,
        billable: selectedOption.billable,
      }));
      setError((prev) => ({ ...prev, project: '' }));
    }
  };
  const changeBillableHandler = (value) => {
    setFormData((prev) => ({ ...prev, billable: value }));
  };
  const changeTodoHandler = (selectedOption) => {
    if (selectedOption) {
      setFormData((prev) => ({ ...prev, todo: selectedOption }));
      setError((prev) => ({ ...prev, todo: '' }));
    }
  };
  const changeMemoHandler = (e) => {
    setError((prev) => ({
      ...prev,
      memo: e.target.value !== '' ? '' : 'Please enter memo.',
    }));
    setFormData((prev) => ({ ...prev, memo: e.target.value }));
  };
  const changeDateHandler = (date) => {
    if (date === null) {
      setError((prev) => ({ ...prev, date: 'Please enter date.' }));
    } else {
      setError((prev) => ({ ...prev, date: '' }));
    }
    setFormData((prev) => ({ ...prev, date: date }));
  };
  const changeFromHandler = (time) => {
    if (time === null) {
      setError((prev) => ({ ...prev, from: 'Please enter FROM time.' }));
      setIsDisableToTime(true);
    } else {
      setError((prev) => ({ ...prev, from: '' }));
      setIsDisableToTime(false);
    }
    setFormData((prev) => ({ ...prev, from: time }));

    if (formData.to) {
      if (time?.$H || time?.$m) {
        let fromMinutes = time.$H * 60 + time.$m;
        let toMinutes = formData.to.$H * 60 + formData.to.$m;
        const totalMinutes = toMinutes - fromMinutes;

        const hours = totalMinutes / 60;
        const rHour = Math.floor(hours);
        const minutes = (hours - rHour) * 60;
        const rMinutes = Math.round(minutes);

        setTotalDuration(
          `${rHour.toString().padStart(2, '0')}:${rMinutes
            .toString()
            .padStart(2, '0')}`
        );
      } else {
        setTotalDuration('00:00');
      }
    }
  };
  const changeToHandler = (time) => {
    if (time === null) {
      setError((prev) => ({ ...prev, to: 'Please enter TO time.' }));
    } else if (
      !isToTimeValid(
        dayjs(formData.from).format('HH:mm'),
        dayjs(time).format('HH:mm')
      )
    ) {
      setError((prev) => ({ ...prev, to: 'TO time is not valid.' }));
    } else {
      setError((prev) => ({ ...prev, to: '' }));
    }
    setFormData((prev) => ({ ...prev, to: time }));

    if (formData.from) {
      if (time?.$H || time?.$m) {
        let fromMinutes = formData.from.$H * 60 + formData.from.$m;
        let toMinutes = time.$H * 60 + time.$m;
        const totalMinutes = toMinutes - fromMinutes;

        const hours = totalMinutes / 60;
        const rHour = Math.floor(hours);
        const minutes = (hours - rHour) * 60;
        const rMinutes = Math.round(minutes);

        setTotalDuration(
          `${rHour.toString().padStart(2, '0')}:${rMinutes
            .toString()
            .padStart(2, '0')}`
        );
      } else {
        setTotalDuration('00:00');
      }
    } else {
      setError((prev) => ({ ...prev, from: 'Please enter FROM time first.' }));
      setFormData((prev) => ({ ...prev, to: null }));
      return;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    try {
      setError({
        assignee: '',
        project: '',
        todo: '',
        date: '',
        from: '',
        to: '',
        reason: '',
        other: '',
      });
      if (formData.assignee === null) {
        setError((prev) => ({ ...prev, assignee: 'Please select assignee.' }));
        return;
      } else if (formData.project === null) {
        setError((prev) => ({ ...prev, project: 'Please select project.' }));
        return;
      } else if (formData.todo === null) {
        setError((prev) => ({ ...prev, todo: 'Please select todo.' }));
        return;
      } else if (formData.memo.trim() === '') {
        setError((prev) => ({ ...prev, memo: 'Please enter memo.' }));
        return;
      } else if (formData.date === '') {
        setError((prev) => ({ ...prev, date: 'Please enter date.' }));
        return;
      } else if (!formData.from) {
        setError((prev) => ({ ...prev, from: 'Please enter FROM time.' }));
        return;
      } else if (!formData.to) {
        setError((prev) => ({ ...prev, to: 'Please enter TO time.' }));
        return;
      } else if (
        !isToTimeValid(
          dayjs(formData.from).format('HH:mm'),
          dayjs(formData.to).format('HH:mm')
        )
      ) {
        setError((prev) => ({ ...prev, to: 'TO time is not valid.' }));
        return;
      }
      const formattedDate = convertDateIntoYYYYMMDD(formData.date);
      if (formattedDate === 'Invalid Date') {
        setError((prev) => ({ ...prev, date: 'Please enter valid date.' }));
        return;
      }
      const fromTime = dayjs(formData.from).format('HH:mm');
      const toTime = dayjs(formData.to).format('HH:mm');
      const [H, M] = toTime.split(':');

      const minute = parseInt(M) === 0 ? 59 : parseInt(M) - 1;
      const hour = parseInt(minute) === 59 ? parseInt(H) - 1 : parseInt(H);
      const finalToTime = `${hour <= 9 ? '0' + hour : hour}:${
        minute <= 9 ? '0' + minute : minute
      }:59`;

      const result = checkCurrentDateTimeValidation(
        formData.date,
        fromTime,
        toTime
      );
      if (!result) {
        const errPayload = {
          code: 400,
          status: 'fail',
          message: 'can not add time beyond current time',
        };
        dispatch(setFeedback(errPayload));
        return;
      }

      const payload = {
        user_id: formData.assignee.value,
        project_id: formData.project.value,
        todoId: formData.todo.value,
        memo: formData.memo,
        // reason: formData.reason?.value === 0 ? formData.other : formData.reason?.value,
        startDate: `${formattedDate} ${fromTime}:00`,
        endDate: `${formattedDate} ${finalToTime}`,
        billable: formData.billable,
      };
      addTimeSheetHandler(payload);
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: 'fail',
        message: error.message || 'Something went wrong.',
      };
      dispatch(setFeedback(errPayload));
    }
  };

  const fetchTimeLineChartData = useCallback(async () => {
    try {
      const params = {
        date: convertDateIntoYYYYMMDD(formData.date),
        manual_entry: true,
      };
      const response = await getTimeLineChartData(
        params,
        formData.assignee.value
      );
      if (response.data.status === 200) {
        setTimeLineChartData(response.data.data);
        setIsAbleToAddEntry(true);
      } else if (response.data.status === 404) {
        setTimeLineChartData([]);
        setIsAbleToAddEntry(true);
      } else {
        const errPayload = {
          code: response.data.status,
          status: 'fail',
          message: response.data.message || 'Something went wrong.',
        };
        dispatch(setFeedback(errPayload));
        if (response.data.status === 422) {
          setTimeLineChartData([]);
          setIsAbleToAddEntry(false);
        }
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: 'fail',
        message: error.message || 'Something went wrong.',
      };
      dispatch(setFeedback(errPayload));
    }
  }, [formData.assignee, formData.date, dispatch]);

  useEffect(() => {
    fetchTimeLineChartData();
  }, [fetchTimeLineChartData]);

  // Usage example:
  const selectAssigneeStyles = getSelectStyles(error.assignee);
  const selectProjectStyles = getSelectStyles(error.project);
  const selectTodoStyles = getSelectStyles(error.todo);
  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={addTimeModalOpen}
        onClose={resetForm}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={addTimeModalOpen}>
          <Box sx={styled} className='modal-wrapper modal-bg add-time'>
            <Typography
              variant='h6'
              component='h6'
              className='text-black modal-title'
            >
              Add Time
            </Typography>
            <Box className='modal-close' onClick={resetForm}>
              <FiPlus />
            </Box>
            <Box className='modal-body'>
              <FormGroup className='form-field'>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <FormControl variant='standard' className='form-control'>
                      <Typography
                        variant='body2'
                        component='span'
                        className='text-black input-label'
                      >
                        Select Member
                        <Typography
                          variant='body2'
                          component='span'
                          className='asterisk'
                        >
                          *
                        </Typography>
                      </Typography>
                      <Select
                        className={'select-filter'}
                        placeholder='Select Member'
                        value={formData.assignee}
                        onChange={changeAssigneeHandler}
                        options={assigneeOptions}
                        styles={selectAssigneeStyles.styles}
                        theme={selectAssigneeStyles.theme}
                      />
                      {error.assignee && (
                        <span className='error-text'>{error.assignee}</span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl variant='standard' className='form-control'>
                      <Typography
                        variant='body2'
                        component='span'
                        className='text-black input-label'
                      >
                        Select Project
                        <Typography
                          variant='body2'
                          component='span'
                          className='asterisk'
                        >
                          *
                        </Typography>
                      </Typography>
                      <Select
                        className='select-date'
                        placeholder='Select Project'
                        value={formData.project}
                        onChange={changeProjectHandler}
                        options={projectOptions}
                        styles={selectProjectStyles.styles}
                        theme={selectProjectStyles.theme}
                      />
                      {error.project && (
                        <span className='error-text'>{error.project}</span>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl variant='standard' className='form-control'>
                      <Stack className='label-btn-wrapper' direction='row'>
                        <Typography
                          variant='body2'
                          component='span'
                          className='text-black input-label'
                        >
                          Select To-Do
                          <Typography
                            variant='body2'
                            component='span'
                            className='asterisk'
                          >
                            *
                          </Typography>
                        </Typography>
                        <Button
                          className='add-new-todo-modal'
                          onClick={addTodosModalOpen}
                          disabled={
                            formData.assignee && formData.project ? false : true
                          }
                        >
                          Add New To-Do
                        </Button>
                      </Stack>
                      <Select
                        className='select-date'
                        placeholder='Select To-dos'
                        value={formData.todo}
                        onChange={changeTodoHandler}
                        options={todoOptions}
                        styles={selectTodoStyles.styles}
                        theme={selectTodoStyles.theme}
                      />
                      {error.todo && (
                        <span className='error-text'>{error.todo}</span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant='standard' className='form-control'>
                      <Typography
                        variant='body2'
                        component='span'
                        className='text-black input-label'
                      >
                        Memo
                        <Typography
                          variant='body2'
                          component='span'
                          className='asterisk'
                        >
                          *
                        </Typography>
                      </Typography>
                      <BootstrapInput
                        multiline
                        rows={1}
                        placeholder='Enter memo'
                        className={!error.memo ? 'input-field' : 'border-error'}
                        value={formData.memo}
                        onChange={changeMemoHandler}
                      />
                      {error.memo && (
                        <span className='error-text'>{error.memo}</span>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item md={4} sm={4} xs={12}>
                    <FormControl variant='standard' className='form-control'>
                      <Typography
                        variant='body2'
                        component='span'
                        className='text-black input-label'
                      >
                        Date
                        <Typography
                          variant='body2'
                          component='span'
                          className='asterisk'
                        >
                          *
                        </Typography>
                      </Typography>
                      {/* <Box className='calendar-ui' style={{ position: 'relative', zIndex: '100' }}> */}
                      <DatePicker
                        placement='autoVerticalEnd'
                        oneTap
                        appearance='subtle'
                        format='dd-MM-yyyy'
                        value={formData.date}
                        className={`calendar-ui ${
                          error.date
                            ? 'error-datepicker'
                            : 'add-timesheet-datepicker'
                        }`}
                        onChange={changeDateHandler}
                        // disabledDate={date => isAfter(date, new Date())}
                        shouldDisableDate={(date) => {
                          const today = new Date();
                          today.setHours(0, 0, 0, 0);
                          date.setHours(0, 0, 0, 0);
                          return date > today;
                        }}
                      />
                      {/* </Box> */}
                      {error.date && (
                        <span className='error-text'>{error.date}</span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={4} sm={4} xs={12}>
                    <FormControl variant='standard' className='form-control'>
                      <Typography
                        variant='body2'
                        component='span'
                        className='text-black input-label'
                      >
                        From
                        <Typography
                          variant='body2'
                          component='span'
                          className='asterisk'
                        >
                          *
                        </Typography>
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          className={
                            !error.from ? 'time-picker' : 'error-time-picker'
                          }
                          value={formData.from}
                          onChange={changeFromHandler}
                          ampm={false}
                          minutesStep={1}
                          timeSteps={{ hours: 1, minutes: 1 }}
                          // minTime={dayjs().set('hour', 11)}
                          maxTime={dayjs().set('hour', 23)}
                        />
                      </LocalizationProvider>
                      {error.from && (
                        <span className='error-text'>{error.from}</span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={4} sm={4} xs={12}>
                    <FormControl variant='standard' className='form-control'>
                      <Typography
                        variant='body2'
                        component='span'
                        className='text-black input-label'
                      >
                        To
                        <Typography
                          variant='body2'
                          component='span'
                          className='asterisk'
                        >
                          *
                        </Typography>
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          disabled={isDisableToTime}
                          className={
                            !error.to ? 'time-picker' : 'error-time-picker'
                          }
                          value={formData.to}
                          onChange={changeToHandler}
                          ampm={false}
                          minutesStep={1}
                          timeSteps={{ hours: 1, minutes: 1 }}
                        />
                      </LocalizationProvider>
                      {error.to && (
                        <span className='error-text'>{error.to}</span>
                      )}
                    </FormControl>
                  </Grid>
                  {formData?.project !== null && (
                    <Grid item xs={12}>
                      <Stack
                        className='billable-dureation-wrapper'
                        direction='row'
                      >
                        <Box>
                          <FormControl
                            variant='standard'
                            className='form-control'
                          >
                            <Typography
                              variant='body2'
                              component='span'
                              className='text-black input-label'
                              style={{ margin: '0px' }}
                            >
                              &nbsp;Is it billable
                              <Typography
                                variant='body2'
                                component='span'
                                className='asterisk'
                              >
                                *
                              </Typography>
                            </Typography>
                            <Box className='mask-box-container'>
                              <Box className='mask-box'>
                                <Box
                                  className='mask mask-2'
                                  style={{
                                    transform: `translateX(${
                                      formData.billable === 'yes' ? 0 : '100px'
                                    })`,
                                  }}
                                />
                                <Button
                                  className='mask-btn mask-btn-2'
                                  disableRipple
                                  variant='text'
                                  onClick={(e) =>
                                    formData?.project?.billable === 'yes' &&
                                    changeBillableHandler('yes')
                                  }
                                >
                                  Billable
                                </Button>
                                <Button
                                  className='mask-btn mask-btn-2'
                                  disableRipple
                                  variant='text'
                                  onClick={(e) =>
                                    formData?.project?.billable === 'yes' &&
                                    changeBillableHandler('no')
                                  }
                                >
                                  Non-Billable
                                </Button>
                              </Box>
                            </Box>
                          </FormControl>
                        </Box>
                        <Box>
                          {!isDisableToTime && (
                            <FormControl
                              variant='standard'
                              className='form-control'
                            >
                              <Stack
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'flex-end'}
                                flexDirection={'column'}
                                marginRight={'5px'}
                              >
                                <Typography
                                  variant='body2'
                                  component='span'
                                  className='text-black input-label'
                                  style={{ margin: '0px' }}
                                >
                                  &nbsp;Total Duration
                                  <Typography
                                    variant='body2'
                                    component='span'
                                    className='asterisk'
                                  >
                                    *
                                  </Typography>
                                </Typography>
                                <Box className='total-duration-box-container'>
                                  <Typography className='text-white'>
                                    {totalDuration}
                                  </Typography>
                                </Box>
                              </Stack>
                            </FormControl>
                          )}
                        </Box>
                      </Stack>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TimeLineChart
                      timeLineChartData={timeLineChartData}
                      selectedDate={formData.date}
                    />
                  </Grid>
                  {!isAbleToAddEntry && (
                    <Typography className='error-text' marginLeft={2}>
                      You can not add time while tracker is running
                    </Typography>
                  )}
                </Grid>
              </FormGroup>
            </Box>
            <Box className='modal-footer' style={{ marginTop: '30px' }}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <Button
                    className='btn btn-primary'
                    onClick={submitHandler}
                    disabled={!isAbleToAddEntry}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item md={6} xs={6}>
                  <Button className='btn btn-secondary' onClick={resetForm}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {addToDosModalOpen && (
        <AddNewToDo
          addToDosModalOpen={addToDosModalOpen}
          addTodosModalClose={addTodosModalClose}
          member={formData.assignee}
          project={formData.project}
          fetchTodoHandler={fetchTodoHandler}
        />
      )}
    </>
  );
};

export default AddTime;
