import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Select from 'react-select';
import Slider from "react-slick";
import { DatePicker } from 'rsuite';
import { FiActivity, FiClock, FiChevronRight, FiChevronLeft } from "react-icons/fi";
import ActivityCard from '../../components/common/ActivityCard';
import ScreenCastCard from '../../components/common/ScreenCastCard'; 
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setFeedback } from '../../store/redux/actions/feedback.action';
import { fetchProjects } from '../../store/redux/actions/project.action';
import { fetchEmployee } from '../../apis/employee';
import { fetchScreenshotReports } from '../../store/redux/actions/reports.action';
import { ROLES, storageName } from '../../lib/constants';
import { convertDateIntoYYYYMMDD, convertSecondsIntoTime, getDotColorStyle, getFilterStorage, getPermissionAccess, handleLabel, setFilterStorage } from '../../utils/helper';
import ImageModal from '../../components/common/ImageModal';

const Screenshots = () => {
  let tempIndex = -1;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projects = useSelector(state => state.project.projects_list);
  const reportsData = useSelector(state => state.reports.screenShotReport);
  const filterData = getFilterStorage(storageName.screenShot);

  const userDetails = useSelector(state => state.user.data);
  const permissions = userDetails.role_permissions.permissions;

  const permissionsAccess = getPermissionAccess(permissions, "Reports");
  if(!permissionsAccess.view && !permissionsAccess.add && !permissionsAccess.edit && !permissionsAccess.delete){
    navigate('/', { replace: true });
  }

  let projectOptions = [{ value: '', label: 'All' }];
  projectOptions.push(...projects?.map(proj => (
    { value: proj.id, label: proj.title }
  )));

  const [isScrollable, setIsScrollable] = useState(true);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 11,
    slidesToScroll: 1,

    draggable: isScrollable,
    swipeToSlide: isScrollable,
    touchMove: isScrollable,

    nextArrow: <FiChevronRight />,
    prevArrow: <FiChevronLeft/>,
    responsive: [
      {
        breakpoint: 1930,
        settings: {
          slidesToShow: 8,
        }
      },
      {
        breakpoint: 1570,
        settings: {
          slidesToShow: 7,
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 412,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const ActivityCardArray = [
    {
      ActivityTitle: 'Today’s Activity',
      ActivityNumber: isNaN(reportsData.activity) ? "0%" : Math.round(reportsData.activity) + "%",
      ActivityIcon: <FiActivity/>
    },
    {
      ActivityTitle: 'Worked Today',
      ActivityNumber: reportsData.workedTime ? convertSecondsIntoTime(reportsData.workedTime) : convertSecondsIntoTime(0), 
      ActivityIcon: <FiClock/>
    },
  ]
 
  const [selectedDate, setSelectedDate] = useState(filterData?.dateValue ? new Date(filterData?.dateValue) : new Date());
  const [assignee, setAssignee] = useState([]);
  const [selectedProject, setSelectedProject] = useState(filterData?.project_id ? { value: filterData.project_id, label: filterData.project_name } 
    : { value: '', label: 'All' });
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const openImageModalHandler = (index) => {
    setImageIndex(index);
    setImageModalOpen(true);
  }

  const closeImageModalHandler = () => {
    setImageIndex(0);
    setImageModalOpen(false);
  }

  const assigneeOption = useMemo(() => {
    let options = [];
    if(userDetails?.user_role?.id === ROLES.admin){
      assignee.forEach((row) => {
        if(row.status === "active"){
          options.push(handleLabel(true, row.id, `${row.first_name} ${row.last_name}`))
        } else {
          options.push(handleLabel(false, row.id, `${row.first_name} ${row.last_name}`))
        }
      })
    } else if (userDetails?.user_role?.id === ROLES.manager || userDetails?.user_role?.id === ROLES.viewer) {
      assignee.forEach((row) => {
        let flag = 0;
        if(row.status !== "active"){
          flag = 1;
        } else {
          row.project_assignments.some((row2) => {
            if(row2.deletedAt === null){
              flag = 0;
              return true;
            } else {
              flag = 1;
              return false;
            }
          })
        }

        if(flag === 0){
          options.push(handleLabel(true, row.id, `${row.first_name} ${row.last_name}`))
        } else {
          options.push(handleLabel(false, row.id, `${row.first_name} ${row.last_name}`))
        }
      })
    } else {
      options = [];
    }
    return options;
  }, [assignee, userDetails?.user_role?.id]);
   
  const [selectedAssignee, setSelectedAssignee] = useState(
    filterData?.user_id ? { value: filterData?.user_id, label: filterData?.user_name, dot_color: filterData.dot_color, text_color:'#777777' }
    : { value: userDetails.id, label: `${userDetails.first_name} ${userDetails.last_name}`, dot_color: "#8CC714", text_color:'#777777'}
  );

  const handleDateChange = (date) => {
    if (date !== null) {
      setSelectedDate(date);
    } else {
      setSelectedDate(new Date());
    }
  };

  const handleSelectedProject = (options) => {
    if(options){
      setSelectedProject(options)
    }
    
  }

  const fetchAssignee = useCallback(async () => {
    try {
      const params = { timesheet: 1 };
      const response = await fetchEmployee(params);
      if(response.data.status === 200 ){
        setAssignee(response.data.data);
      } else {
        setAssignee([]);
      }
    } catch (error) {
      const errPayload = { 
        code: error.status, 
        status: 'fail', 
        message:error.message || "Something went wrong." 
      };
      dispatch(setFeedback(errPayload));  
    }
  }, [dispatch]);

  const fetchProjectsData = useCallback( async () => {
    try { 
      const params= { listing: true, isListing: true };
      dispatch(fetchProjects(params));
    } catch (error) {
      const errPayload = { 
        code: error.status, 
        status: 'fail', 
        message:error.message || "Something went wrong." 
      };
      dispatch(setFeedback(errPayload));  
    }
  },[dispatch]);

  useEffect(()=>{
    if(userDetails?.user_role?.id !== ROLES.employee){
      fetchAssignee();
    }
    fetchProjectsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchScreenShotReportsFilters = useCallback(async ()=>{
    try {
      let storageValue;
      if(selectedAssignee && selectedAssignee.value !== ""){
        storageValue = { 
          dateValue: selectedDate, 
          user_id: selectedAssignee?.value,
          user_name: selectedAssignee?.label,
          dot_color: selectedAssignee?.dot_color
        };
      }
      if(storageValue && selectedProject !== ""){
        storageValue.project_id = selectedProject.value;
        storageValue.project_name = selectedProject.label;
      }

      if(storageValue){
        setFilterStorage(storageName.screenShot, storageValue);
        const payload = {
          date: convertDateIntoYYYYMMDD(storageValue.dateValue),
          user: storageValue.user_id,
        }
        if(storageValue.project_id){
          payload.project = storageValue.project_id
        }
        dispatch(fetchScreenshotReports(payload));
      }
    } catch (error) {
      const payload = { 
        code: error.status, 
        status:"fail", 
        message: error.message || "Something went wrong. Try again later!" 
      };
      dispatch(setFeedback(payload));
    }
  },[dispatch, selectedAssignee, selectedDate, selectedProject])

  useEffect(() => {      
    fetchScreenShotReportsFilters()
  }, [fetchScreenShotReportsFilters])

  function imageModalData(data) {
    let result = [];
  
    function recursiveModalData(items) {
      for (const item of items) {
        if (item.data && Array.isArray(item.data)) {
          recursiveModalData(item.data);
        } else {
          result.push(item);
        }
      }
    }  
    recursiveModalData(data);
    return result;
  }
  const finalModalArray = imageModalData([reportsData]);

  return (
    <>
    <Box className="content-header report-top-bar seceenshot-report-module-wrapper">
      <Box className="report-top-filter">
          <KeyboardBackspaceIcon style={{ fontSize : '36px', cursor:'pointer', fill: "#777" }} onClick={() => navigate("/reports")} className='back-btn' />
          <Box className='calendar-ui btn-while-back-icon'>
            <DatePicker 
              oneTap 
              format='dd-MM-yyyy' 
              value={selectedDate} 
              onChange={handleDateChange} 
              // disabledDate={date => isAfter(date, new Date())}
              shouldDisableDate={(date) => {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                date.setHours(0, 0, 0, 0);
                return date > today;
              }}
              />
          </Box>
        </Box>
        <Box item className="content-header-right report-btn-icon mb-0">
          { userDetails?.user_role?.id !== ROLES.employee &&
              <Select className='report-select-options select-dot-option'
                placeholder='Select Member'
                value={selectedAssignee}
                onChange={setSelectedAssignee}
                options={assigneeOption}
                classNamePrefix="select"
                styles={getDotColorStyle("SINGLE")}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                    ...theme.colors,
                    primary25: 'rgba(140, 199, 20, 0.4);',
                    primary: '#8CC714',
                    },
                  })
                }
              />
            }
            <Select className='report-select-options'
              placeholder='Select Project'
              value={selectedProject}
              onChange={handleSelectedProject}
              options={projectOptions}
              classNamePrefix="select"
              theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                  ...theme.colors,
                  primary25: 'rgba(140, 199, 20, 0.4);',
                  primary: '#8CC714',
                  },
                })
              }
            />
        </Box>
    </Box>

      {/* activity card */}
      <Box className='activity-card-wrapper mb-24'>
        <Grid container spacing={3}>
          {ActivityCardArray?.map((ActivityCardList,index)=>
            <ActivityCard
              key={index + "_screenshot_activity"}
              ActivityTitle={ActivityCardList.ActivityTitle}
              ActivityNumber={ActivityCardList.ActivityNumber}
              ActivityIcon={ActivityCardList.ActivityIcon}
            />
          )}
        </Grid>
      </Box>

      {/* Screenshots card wrapper  */}
      <Box className='card screenshot-wrapper mb-0'>
        {
          !reportsData?.data?.length ?
            <Box sx={{textAlign: 'center'}}>
              <Typography className='text-grey'>No Records Found</Typography>
            </Box>
          :
          reportsData?.data?.map((row, i) => {
            return (
              <>
                <Box className='screencast-slider'>
                  <Box className='slider-title'>
                    <Typography variant="h6" component="h6" className='text-white card-title'>
                      {row.time}   Total time worked: {convertSecondsIntoTime(row.workedTime)} 
                    </Typography>
                  </Box>
                
                  <Slider {...settings} className='custom-slider'>
                    {row?.data?.map((ScreenCastCardList,index)=>
                      {
                        //if (i === 0 && index === 0){
                        //tempIndex = 0 ;
                        //} else {
                          let currentCard = [];
                          if (ScreenCastCardList?.drive_primary || ScreenCastCardList?.screenshot_primary){
                            tempIndex = tempIndex + 1;
                            currentCard.push(tempIndex);
                          } if (ScreenCastCardList?.drive_secondary || ScreenCastCardList?.screenshot_secondary) {
                            tempIndex = tempIndex + 1;
                            currentCard.push(tempIndex);
                          }
                        //}
                        //if (ScreenCastCardList?.primaryScreenshot || ScreenCastCardList?.secondaryScreenshot) {
                            return <ScreenCastCard
                                key={index + "_screenshot_report"}
                                flag={0}
                                // ScreenCastImage={ScreenCastCardList?.screenshot_primary} 
                                ScreenCastImage={[
                                    { screen:"Screen 1", image: ScreenCastCardList?.drive_primary || ScreenCastCardList?.screenshot_primary, 
                                    isDriveImage: ScreenCastCardList?.drive_primary ? true : false },
                                    { screen:"Screen 2", image: ScreenCastCardList?.drive_secondary || ScreenCastCardList?.screenshot_secondary, isDriveImage: ScreenCastCardList?.drive_secondary ? true : false }
                                ]}
                                ProjectTitle={ScreenCastCardList?.projectTitle}
                                // ScreenCastDate={ScreenCastCardList?.end_date}
                                setIsScrollable={setIsScrollable}
                                blockTime = {ScreenCastCardList?.blocktime}
                                activity = {ScreenCastCardList?.overall_activity}
                                openImageModalHandler = {openImageModalHandler}
                                tempIndex={currentCard[0]}
                                isManual={ScreenCastCardList?.manualEntry}
                            />
                        }
                      //}
                      )
                    }
                  </Slider> 
                </Box>
              </>
            )
        })
        }
      </Box>

      {/* screenshot Image modal */}
      {
        imageModalOpen && 
        <ImageModal 
          imageModalOpen={imageModalOpen} 
          closeImageModalHandler={closeImageModalHandler} 
          finalModalArray={finalModalArray}
          imageIndex={imageIndex}
          userName={selectedAssignee.label}
        />
      }  
    </>
  );
}

export default Screenshots;
