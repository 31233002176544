import { Box, Grid } from "@mui/material";
import { AppSettingCard } from "./AppSettings";
// import { useState } from "react";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
// import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import { FitScreenOutlined } from '@mui/icons-material';
// import SettingModal from "./SettingsModal";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();
  // const [openModal, setOpenModal] = useState(false);
  // const [selectedCard, setSelectedCard] = useState(null);

  // const handleOpenModal = (cardTitle) => {
  //   setSelectedCard(cardTitle);
  //   setOpenModal(true);
  // };
  // const handleCloseModal = () => {
  //   setOpenModal(false);
  // };

  return (
    <>
      <Box className="settings-card-wrapper mb-24">
        <Grid container spacing={2}>
          <AppSettingCard
            onClick={() => navigate("/settings/time-activity")}
            icon={<ManageHistoryIcon />}
            title="Time & Activity"
          />
          <AppSettingCard
            onClick={() => navigate("/settings/project-tasks")}
            icon={<TaskAltIcon />}
            title="Project & Tasks"
          />
          <AppSettingCard
            onClick={() => navigate("/settings/screenshots")}
            icon={<FitScreenOutlined />}
            title="Screenshots"
          />
        </Grid>

        {/* {openModal && (
          <SettingModal
            openModal={openModal}
            closeModal={handleCloseModal}
            cardTitle={selectedCard}
          />
        )} */}
      </Box>
    </>
  );
};

export default Settings;
