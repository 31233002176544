import React, { useState } from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import { FiChevronDown, FiChevronRight, FiChevronUp, FiPlus } from "react-icons/fi";
import PropTypes from 'prop-types';
import { convertDateIntoDDMMYYYY, formatPrintMemo } from "../../utils/helper";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

function formatTime(time) {
    const date = new Date(time);
    const options = { 
        hour: 'numeric', 
        minute: 'numeric', 
        hour12: true 
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

const TimeLineToDoDrawer = ({ timeLineState, handleTimeLineDrawerClose, timelineData }) => {

    const timeLineData = timelineData.timeline;

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [expandedTimelineIndex, setExpandedTimelineIndex] = useState(-1);
    const handleExpandClick = (index) => {
        setExpandedTimelineIndex(index === expandedTimelineIndex ? -1 : index);
    };

    return(
        <>
            <Drawer
                anchor='right'
                open={timeLineState}
                onClose={handleTimeLineDrawerClose}            
                className='timeline-drawer'
            >
                <Box className='close-timeline' onClick={handleTimeLineDrawerClose}>
                    <FiPlus/>
                </Box>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='timeline-tab'>
                    <Tab className='timeline-btn' label="Timeline Updates" {...a11yProps(0)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <Box className="timeline-content" style={{ overflowY: 'auto', maxHeight: '850px' }}>
                        <Timeline
                            sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                },
                            }}
                        >
                            {
                                timeLineData?.length === 0 ? 
                                (
                                    <Typography>
                                        No timeline found.
                                    </Typography>
                                ) :
                                (timeLineData?.map((timeline, index) => (
                                    <TimelineItem key={index}>
                                        <TimelineSeparator>
                                            {index !== 0 && <TimelineConnector style={{maxHeight: '4px'}} />}
                                            <TimelineDot sx={{ backgroundColor: 'var(--color-green)' }}>
                                                {
                                                    timeline.action_type === 'created' ? <AddIcon /> :
                                                    timeline.action_type === 'updated' ? <EditIcon /> :
                                                    timeline.action_type === 'added' ? <PersonAddAltRoundedIcon /> :
                                                    timeline.action_type === 'tracker' || timeline.action_type === 'manual' ? <WatchLaterIcon /> :
                                                    timeline.action_type === 'removed' ? <PersonRemoveIcon /> : null
                                                }
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent className="timeline-container">
                                            <Box className="timeline-item">
                                                <Box className="timeline-update-wrapper">
                                                    <Box className="timeline-info">
                                                        <Typography className="timeline-text" variant="body2" color="text.secondary" sx={{lineHeight:'24px'}}>
                                                            {convertDateIntoDDMMYYYY(timeline.createdAt)} | {formatTime(timeline.createdAt)}
                                                        </Typography>
                                                    </Box>
                                                    <Typography className="timeline-description" component="span">
                                                        {timeline.description}
                                                    </Typography>
                                                </Box>
                                                {timeline.memo && 
                                                    <IconButton
                                                        className="timeline-memo-button"
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => handleExpandClick(index)}
                                                    >
                                                        {expandedTimelineIndex === index ? <FiChevronUp /> : <FiChevronRight />}
                                                    </IconButton>
                                                }
                                            </Box>
                                            {expandedTimelineIndex === index && (
                                                <Typography className="timeline-memo" style={{ marginTop: '10px' }}>
                                                    <span className="memo-title">Memo:</span> {formatPrintMemo(timeline.memo)}
                                                </Typography>
                                            )}
                                        </TimelineContent>
                                    </TimelineItem>
                                )))
                            }
                        </Timeline>
                    </Box>
                </TabPanel>
            </Drawer>
        </>
    )
}

export default TimeLineToDoDrawer;