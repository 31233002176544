import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";

const WindowActivityTable = ({ userRole, headers, tableData }) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xxl={12}>
          <Box className="card mb-0">
            <TableContainer className="report-table-wrapper mb-0">
              <Table>
                <TableHead>
                  <TableRow>
                    {headers?.map((header, index) => (
                      <TableCell key={index} >{header}</TableCell>
                    ))}
                    <TableCell align="center"  ></TableCell>
                    <TableCell align="center"  >
                      Time
                    </TableCell>
                    <TableCell align="center"  >
                        Activity
                        </TableCell>
                  </TableRow>
                </TableHead>
                {tableData}
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default WindowActivityTable;
