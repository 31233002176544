import { put, takeEvery } from "redux-saga/effects";
import {
  ADD_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_SAGA,
  FETCH_EMPLOYEE,
  FETCH_EMPLOYEE_INVITE_BY_MAIL,
  FETCH_USER_ROLES,
  SEARCH_EMPLOYEE,
  SEARCH_EMPLOYEE_INVITE,
  SET_EMPLOYEES,
  SET_EMPLOYEE_INVITE_BY_MAIL,
  SET_FEEDBACK,
  SET_LOADING,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SAGA,
  USER_ROLES,
} from "../redux/actionTypes";
import {
  createEmployeeInviteByMail,
  deleteEmployeeData,
  fetchEmployee,
  fetchEmployeeInviteByMail,
  fetchUserRoles,
  searchEmployeeData,
  searchEmployeeInviteData,
  updateEmployeeData,
} from "../../apis/employee";
import { showSuccessToast } from "../../utils/toast";

function* fetchEmployeeHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield fetchEmployee(action.payload);
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      const payload = response.data.data;

      yield put({ type: SET_EMPLOYEES, payload });
    } else if (response.data.status === 404 || response.data.status === 400) {
      yield put({
        type: SET_EMPLOYEES,
        payload: { totalUsers: 0, finalFilterUser: [] },
      });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* fetchEmployeeInviteByMailHandler() {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield fetchEmployeeInviteByMail();
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      const payload = response.data.data;
      yield put({ type: SET_EMPLOYEE_INVITE_BY_MAIL, payload });
    } else if (response.data.status === 404) {
      yield put({ type: SET_EMPLOYEE_INVITE_BY_MAIL, payload: [] });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* fetchRoleHandler() {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield fetchUserRoles();

    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 201) {
      const payload = response.data.data;
      yield put({ type: USER_ROLES, payload });
    } else if (response.data.status === 404) {
      yield put({ type: USER_ROLES, payload: [] });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* deleteEmployeeHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield deleteEmployeeData(action.payload);
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      yield showSuccessToast(response.data.message);
      yield put({ type: DELETE_EMPLOYEE, payload: action.payload });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* updateEmployeeHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const id = action.payload.id;

    const response = yield updateEmployeeData({ ...action.payload }, id);

    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      yield showSuccessToast(response.data.message);
      yield put({ type: UPDATE_EMPLOYEE, payload: response.data.data[0] });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* searchEmployeeHandler(action) {
  try {
    const response = yield searchEmployeeData(action.payload);

    if (response.data.status === 200) {
      const payload = response.data.data;
      yield put({ type: SET_EMPLOYEES, payload });
    } else if (response.data.status === 404) {
      yield put({ type: SET_EMPLOYEES, payload: [] });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* searchEmployeeInviteHandler(action) {
  try {
    const response = yield searchEmployeeInviteData(action.payload);
    if (response.data.status === 200) {
      const payload = response.data.data;
      yield put({ type: SET_EMPLOYEE_INVITE_BY_MAIL, payload });
    } else if (response.data.status === 404) {
      yield put({ type: SET_EMPLOYEE_INVITE_BY_MAIL, payload: [] });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* addEmployeeHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield createEmployeeInviteByMail(action.payload);
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      yield showSuccessToast(response.data.data);
      // yield put({ type: ADD_EMPLOYEE, payload: response.data.data });
      yield fetchEmployeeInviteByMailHandler();
    } else if (response.data.status === 500) {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message,
      };
      yield put({ type: SET_FEEDBACK, payload });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* watchEmployeeAction() {
  yield takeEvery(FETCH_EMPLOYEE, fetchEmployeeHandler);
  yield takeEvery(ADD_EMPLOYEE_SUCCESS, addEmployeeHandler);
  yield takeEvery(
    FETCH_EMPLOYEE_INVITE_BY_MAIL,
    fetchEmployeeInviteByMailHandler
  );
  yield takeEvery(FETCH_USER_ROLES, fetchRoleHandler);
  yield takeEvery(DELETE_EMPLOYEE_SAGA, deleteEmployeeHandler);
  yield takeEvery(UPDATE_EMPLOYEE_SAGA, updateEmployeeHandler);
  yield takeEvery(SEARCH_EMPLOYEE, searchEmployeeHandler);
  yield takeEvery(SEARCH_EMPLOYEE_INVITE, searchEmployeeInviteHandler);
}

export default watchEmployeeAction;
