import React, { useEffect, useMemo, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FiChevronRight, FiClock, FiSquare } from "react-icons/fi";
import {
  RiHome5Line,
  RiStackLine,
  RiGroupLine,
  RiSettings2Line,
  RiFileList3Line,
} from "react-icons/ri";
import { useSelector } from "react-redux";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsDownload } from "react-icons/bs";
import { ROLES } from "../../lib/constants";
import { useMediaQuery } from "@mui/material";

const Sidebar = ({ handleDrawerClose }) => {
  const location = useLocation();
  const subMenuLink = location.pathname.substring(1);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:991.98px)");
  const [expanded, setExpanded] = useState(false);
  useMemo(() => {
    let exp;
    if (location.pathname === "/") {
      exp = "panel1";
    } else if (
      location.pathname === "/timesheets" ||
      (location.pathname.startsWith("/timesheets/") &&
        (location.pathname.endsWith("/edit") ||
          location.pathname.endsWith("/split")))
    ) {
      exp = "panel2";
    } else if (
      location.pathname === "/projects" ||
      location.pathname === "/to-dos"
    ) {
      exp = "panel3";
    } else if (
      location.pathname === "/reports" ||
      location.pathname === "/screenshots" ||
      location.pathname === "/time-activity" ||
      location.pathname === "/incomplete-timesheets" ||
      location.pathname === "/billable-nonbillable" ||
      location.pathname === "/billable-nonbillable-projects"
    ) {
      exp = "panel4";
    } else if (
      location.pathname === "/users" ||
      (location.pathname.startsWith("/users/") &&
        location.pathname.endsWith("/edit"))
    ) {
      exp = "panel5";
    } else if (
      location.pathname.includes("/settings") ||
      location.pathname === "/app-settings"
    ) {
      exp = "panel6";
    }
    setExpanded(exp);
  }, [location.pathname]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const userData = useSelector((state) => state.user.data);
  const PermissionsData = userData?.role_permissions?.permissions;
  // for(let i = 0; i < permissionsArray.length; i++) {
  //     if(permissionsArray[i].permissions !== "none"){
  //         if(permissionsArray[i].module_name.toLowerCase() === "projects" || permissionsArray[i].module_name.toLowerCase() === "todos"){
  //             SUBMENU_ARRAY.push({...permissionsArray[i], path: `/${permissionsArray[i].module_name.toLowerCase()}`})
  //             MENU_ARRAY.push({ module_name: 'submenuList' });
  //         } else {
  //             MENU_ARRAY.push({...permissionsArray[i], path: `/${permissionsArray[i].module_name.toLowerCase()}`});
  //         }
  //     }
  // }

  return (
    <>
      {/* sidebar menu */}
      <Box className="sidebar-menu">
        <Box className="first-list">
          {/* Dynamic rendering */}

          {PermissionsData[0]?.permissions !== "none" && (
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className="menu-list"
              onClick={() => {
                isMobile && handleDrawerClose();
                navigate("/");
              }}
            >
              <AccordionSummary
                className="menu-title"
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  <RiHome5Line /> Dashboard
                </Typography>
              </AccordionSummary>
            </Accordion>
          )}
          {PermissionsData[1].permissions !== "none" && (
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              className="menu-list"
              onClick={() => {
                isMobile && handleDrawerClose();
                navigate("/timesheets");
              }}
            >
              <AccordionSummary
                className="menu-title"
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography>
                  <FiClock /> Timesheets
                </Typography>
              </AccordionSummary>
            </Accordion>
          )}
          {(PermissionsData[2].permissions !== "none" ||
            PermissionsData[3].permissions !== "none") && (
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              className="menu-list"
            >
              <AccordionSummary
                className="menu-title"
                expandIcon={<FiChevronRight />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography>
                  <RiStackLine /> Project Management
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="sub-menu-list">
                {PermissionsData[2].permissions !== "none" && (
                  <Box
                    className={`sub-menu-link ${
                      subMenuLink === "projects" && "active"
                    }`}
                    onClick={() => {
                      isMobile && handleDrawerClose();
                      navigate("/projects");
                    }}
                  >
                    <Typography>
                      <FiSquare /> Projects
                    </Typography>
                  </Box>
                )}
                {PermissionsData[3].permissions !== "none" && (
                  <Box
                    className={`sub-menu-link ${
                      subMenuLink === "to-dos" && "active"
                    }`}
                    onClick={() => {
                      isMobile && handleDrawerClose();
                      navigate("/to-dos");
                    }}
                  >
                    <Typography>
                      <FiSquare /> To Dos
                    </Typography>
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          )}

          {PermissionsData[4].permissions !== "none" && (
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              className="menu-list"
              onClick={() => {
                isMobile && handleDrawerClose();
                navigate("/reports");
              }}
            >
              <AccordionSummary
                className="menu-title"
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography>
                  <RiFileList3Line /> Reports
                </Typography>
              </AccordionSummary>
            </Accordion>
          )}

          {PermissionsData[5].permissions !== "none" &&
            userData.user_role.id === ROLES.admin && (
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
                className="menu-list"
                onClick={() => {
                  isMobile && handleDrawerClose();
                  navigate("/users");
                }}
              >
                <AccordionSummary
                  className="menu-title"
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <Typography>
                    <RiGroupLine /> Users
                  </Typography>
                </AccordionSummary>
              </Accordion>
            )}

          {userData.user_role.id === ROLES.admin && (
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
              className="menu-list"
              onClick={() => {
                isMobile && handleDrawerClose();
                navigate("/settings");
              }}
            >
              <AccordionSummary
                className="menu-title"
                aria-controls="panel6bh-content"
                id="panel6bh-header"
              >
                <Typography>
                  <RiSettings2Line /> Settings
                </Typography>
              </AccordionSummary>
            </Accordion>
          )}
        </Box>

        <Box className="second-list">
          <Link to={"/download-app"}>
            <Box className="download-app">
              <Typography>
                <BsDownload /> Download App
              </Typography>
            </Box>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
