import { put, takeEvery } from "redux-saga/effects";
import { ADD_TODO, /*ADD_TODO_SUCCESS,*/ COMPLETE_TODO_SUCCESS, DELETE_TODO, DELETE_TODO_SUCCESS, FETCH_TODOS, SET_FEEDBACK, SET_LOADING, STORE_TODOS, UPDATE_COMPLETE_TODO, UPDATE_TODO, UPDATE_TODO_SUCCESS } from "../redux/actionTypes";
import { addTodos, deleteTodo, fetchToDosByProjectId, updateTodo } from "../../apis/todos";
import { showSuccessToast } from "../../utils/toast";

function* fetchTodosHandler(action){
    try {
        yield put({ type: SET_LOADING, payload:true });
        const response = yield fetchToDosByProjectId(action.payload);
        yield put({ type: SET_LOADING, payload:false });
        if(response.data.status === 200){
            const payload = response.data.data;
            yield put({ type: STORE_TODOS, payload });
        } else if(response.data.status === 404){
            yield put({ type: STORE_TODOS, payload: {totalCount: 0, todos: []} });
        } else {
            const payload = { code: response.data.status, status:"fail", 
            message: response.data.message || "Something went wrong." };
            yield put({ type: SET_FEEDBACK, payload });
        }
    } catch (error) {
        yield put({ type: SET_LOADING, payload:false });
        const payload = { 
            code: error.status, 
            status:"fail", 
            message: error.message || "Something went wrong." 
        };
        yield put({ type: SET_FEEDBACK, payload });
    }
}

function* addTodoHandler(action){
    try {
        yield put({ type: SET_LOADING, payload:true });
        const response = yield addTodos(action.payload);
        yield put({ type: SET_LOADING, payload:false });
        
        if(response.data.status === 200){
            showSuccessToast(response.data.message);
            
            // yield put({ type: ADD_TODO_SUCCESS, payload: response.data.data[0] });
            // yield put({ type: ADD_TODO_SUCCESS, payload: response.data.data }); // this is 
        } else {
            const payload = { code: response.data.status, status:"fail", 
            message: response.data.message || "Something went wrong." };
            yield put({ type: SET_FEEDBACK, payload });
        }
    } catch (error) {
        yield put({ type: SET_LOADING, payload:false });
        const payload = { 
            code: error.status, 
            status:"fail", 
            message: error.message || "Something went wrong." 
        };
        yield put({ type: SET_FEEDBACK, payload });
    }
}

function* updateTodoHandler(action){
    try {
        yield put({ type: SET_LOADING, payload:true });
        const id = action.payload.id;
        const response = yield updateTodo({...action.payload}, id);
        yield put({ type: SET_LOADING, payload:false });

        if(response.data.status === 200){
            showSuccessToast(response.data.message);
            yield put({ type: UPDATE_TODO_SUCCESS, payload: response.data.data[0] });
        } else {
            const payload = { code: response.data.status, status:"fail", 
            message: response.data.message || "Something went wrong." };
            yield put({ type: SET_FEEDBACK, payload });
        }
    } catch (error) {
        yield put({ type: SET_LOADING, payload:false });
        const payload = { 
            code: error.status, 
            status:"fail", 
            message: error.message || "Something went wrong." 
        };
        yield put({ type: SET_FEEDBACK, payload });
    }
}

function* updateTodoCompleteHandler(action){
    try {
        yield put({ type: SET_LOADING, payload:true });
        const id = action.payload.id;
        const response = yield updateTodo({...action.payload}, id);
        yield put({ type: SET_LOADING, payload:false });

        if(response.data.status === 200){
            showSuccessToast(response.data.message);
            yield put({ type: COMPLETE_TODO_SUCCESS, payload: response.data.data[0] });
        } else {
            const payload = { code: response.data.status, status:"fail", 
            message: response.data.message || "Something went wrong." };
            yield put({ type: SET_FEEDBACK, payload });
        }
    } catch (error) {
        yield put({ type: SET_LOADING, payload:false });
        const payload = { 
            code: error.status, 
            status:"fail", 
            message: error.message || "Something went wrong." 
        };
        yield put({ type: SET_FEEDBACK, payload });
    }
}

function* deleteTodoHandler(action){
    try {
        yield put({ type: SET_LOADING, payload:true });
        const response = yield deleteTodo(action.id, action.payload);
        yield put({ type: SET_LOADING, payload:false });

        if(response.data.status === 200){
            showSuccessToast(response.data.message);
            yield put({ type: DELETE_TODO_SUCCESS, payload: action.payload });
        } else {
            const payload = { code: response.data.status, status:"fail", 
            message: response.data.message || "Something went wrong." };
            yield put({ type: SET_FEEDBACK, payload });
        }
    } catch (error) {
        yield put({ type: SET_LOADING, payload:false });
        const payload = { 
            code: error.status, 
            status:"fail", 
            message: error.message || "Something went wrong." 
        };
        yield put({ type: SET_FEEDBACK, payload });
    }
}

function* watchTodoAction(){
    yield takeEvery(FETCH_TODOS, fetchTodosHandler);
    yield takeEvery(ADD_TODO, addTodoHandler);
    yield takeEvery(UPDATE_TODO, updateTodoHandler);
    yield takeEvery(UPDATE_COMPLETE_TODO, updateTodoCompleteHandler);
    yield takeEvery(DELETE_TODO, deleteTodoHandler);
}

export default watchTodoAction;