import {
  ADD_EMPLOYEE,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_INVITE,
  FETCH_EMPLOYEE_BY_ID,
  FETCH_PROJECT_BY_USER_ID,
  FILTER_EMPLOYEE,
  RESEND_INVITE_ACTION,
  SET_EMPLOYEES,
  SET_EMPLOYEE_INVITE_BY_MAIL,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_STATUS,
  UPDATE_EMPLOYEE_TRACKING_STATUS,
  UPDATE_PROJECT_BY_USER_ID,
  USER_ROLES,
} from "../actionTypes";

const initialState = {
  count: 0,
  data: [],
};

const EmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPLOYEES:
      return {
        count: action.payload.totalUsers,
        data: action.payload.finalFilterUser,
      };

    case UPDATE_EMPLOYEE_STATUS:
      const { id, status } = action.payload;
      return {
        count: state.count,
        data: state.data.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              status: status,
            };
          }
          return item;
        }),
      };

    case UPDATE_EMPLOYEE_TRACKING_STATUS:
      const { id: eid, time_tracking_status } = action.payload;
      return {
        count: state.count,
        data: state.data.map((item) => {
          if (item.id === eid) {
            return {
              ...item,
              time_tracking_status: time_tracking_status,
            };
          }
          return item;
        }),
      };

    case UPDATE_EMPLOYEE:
      const {
        id: eId,
        first_name,
        last_name,
        email,
        phone,
        role,
        Time_Zone,
        skype_Id,
      } = action.payload;
      return {
        count: state.count,
        data: state.data.map((item) => {
          if (item.id === eId) {
            return {
              ...item,
              first_name: first_name,
              last_name: last_name,
              email: email,
              phone: phone,
              role: role,
              Time_Zone: Time_Zone,
              skype_Id: skype_Id,
            };
          }
          return item;
        }),
      };

    case DELETE_EMPLOYEE:
      const empId = action.payload;
      return {
        count: state.count - 1,
        // eslint-disable-next-line array-callback-return
        data: state.data.filter((emp) => {
          if (emp.id !== empId) {
            return {
              ...emp,
            };
          }
        }),
      };

    case FETCH_EMPLOYEE_BY_ID:
      const employeeId = action.payload;
      return [
        // eslint-disable-next-line array-callback-return
        ...state.map((emp) => {
          if (emp.id !== employeeId) {
            return {
              ...emp,
            };
          }
        }),
      ];

    case FILTER_EMPLOYEE:
      // return [...action.payload];
      return {
        count: action.payload.totalUsers,
        data: action.payload.finalFilterUser,
      };

    default:
      return state;
  }
};

const invitateEmployeeState = [
  {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    role: "",
    status: "",
    time_tracking_status: "",
    phone: "",
  },
];

export const InviteEmployeeReducer = (
  state = invitateEmployeeState,
  action
) => {
  switch (action.type) {
    case SET_EMPLOYEE_INVITE_BY_MAIL:
      return [...action.payload];

    case ADD_EMPLOYEE:
      return [...state, action.payload];

    case DELETE_EMPLOYEE_INVITE:
      const empId = action.payload;
      return [
        // eslint-disable-next-line array-callback-return
        ...state.filter((emp) => {
          if (emp.id !== empId) {
            return {
              ...emp,
            };
          }
        }),
      ];

    case RESEND_INVITE_ACTION:
      return [...action.payload];

    default:
      return state;
  }
};

const userRoleState = [
  {
    title: "",
    description: "",
    status: "",
  },
];

export const UserRolesReducer = (state = userRoleState, action) => {
  switch (action.type) {
    case USER_ROLES:
      return [...action.payload];
    default:
      return state;
  }
};

const userProjectsState = [
  {
    id: "",
    title: "",
    status: "",
    billable: "",
    updatedAt: "",
    members: "",
    todos: "",
  },
];

export const UserProjectsReducer = (state = userProjectsState, action) => {
  switch (action.type) {
    case FETCH_PROJECT_BY_USER_ID:
      let data = [];
      if (action.payload.length) {
        data = [...action.payload];
      } else {
        data = [];
      }
      return data;

    case UPDATE_PROJECT_BY_USER_ID:
      const { id: eId, projects } = action.payload;
      return [
        ...state.map((item) => {
          if (item.id === eId) {
            return {
              ...item,
              projects,
            };
          }
          return item;
        }),
      ];
    default:
      return state;
  }
};

export default EmployeeReducer;
