import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputBase from "@mui/material/InputBase";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import Select from "react-select";
import UserImage from "../../assets/employees/employee2.png";
import { FiPlus } from "react-icons/fi";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchProjectByEmployeeId,
  fetchUserById,
  resetPasswordData,
  updateEmployeeData,
  updateProjectByEmployeeId,
} from "../../apis/employee";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProjectByEmpId,
  fetchUserRolesData,
  updateEmployee,
  updateEmployeeTakingStatus,
  updateProjectByEmpId,
} from "../../store/redux/actions/employee.action";
import {
  setFeedback,
  setLoading,
} from "../../store/redux/actions/feedback.action";
import { showSuccessToast } from "../../utils/toast";
import { fetchProjects } from "../../store/redux/actions/project.action";
import { ROLES } from "../../lib/constants";
import { getPermissionAccess } from "../../utils/helper";
import { getNormalColorStyle } from "../../utils/helper";
import { fetchGlobalSettings } from "../../apis/settings";

// bootstrap input field
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
}));

// tabbing panel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className="my-account-content">{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// select react option
// const options = [
//   { value: 'India :(GMT+05:30) Asia/Kolkata', label: '(GMT+05:30) Asia/Kolkata' },
//   { value: 'Australia :(GMT+10:00) Australia/Sydney', label: '(GMT+10:00) Australia/Sydney' },
//   { value: 'America :(GMT-5:00) America/New_York', label: '(GMT-5:00) America/New_York' },
//   { value: 'America :(GMT-7:00) America/Denver', label: '(GMT-7:00) America/Denver' },
//   { value: 'America: (GMT-7:00) America/Phoenix', label: '(GMT-7:00) America/Phoenix' },
//   { value: 'America :(GMT-8:00) America/Los_Angeles', label: '(GMT-8:00) America/Los_Angeles' },
// ];

const timeOutOptions = [
  { label: "10 minitues", value: 10 },
  { label: "15 minitues", value: 15 },
  { label: "20 minitues", value: 20 },
  { label: "25 minitues", value: 25 },
  { label: "30 minitues", value: 30 },
  { label: "40 minitues", value: 40 },
];

let prevEmail = "";

const CENTRAL_BASE_URL = process.env.REACT_APP_CENTRAL_API_URL;

const EditEmployee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.data);
  const permissions = userData.role_permissions.permissions;
  const permissionsAccess = getPermissionAccess(permissions, "Users");
  if (userData.user_role.id !== ROLES.admin) {
    navigate("/", { replace: true });
  }
  if (!permissionsAccess.edit) {
    navigate("/", { replace: true });
  }

  const params = useParams();
  const employeeId = params.editId;

  const rolesData = useSelector((state) => state.roles);
  const projectsDataByUser = useSelector((state) => state.projects);
  const projectData = useSelector((state) => state.project.projects_list);

  const fetchRoles = useCallback(async () => {
    dispatch(fetchUserRolesData());
  }, [dispatch]);

  const fetchProject = useCallback(async () => {
    const params = { listing: true, isListing: true };
    dispatch(fetchProjects(params));
  }, [dispatch]);

  useEffect(() => {
    fetchRoles();
    fetchProject();
  }, [fetchProject, fetchRoles]);

  const [employeeData, setEmployeeData] = useState({
    fullName: "",
    email: "",
    first_name: "",
    company_id: "",
    id: "",
    last_name: "",
    phone: "",
    role: "",
    status: "",
    time_tracking_status: "",
    skype_Id: "",
    Time_Zone: "",
    required_timesheets: "",
    ideal_time: null,
  });
  const [employeeProfileImage, setEmployeeProfileImage] = useState(null);

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  // const [skypeIdError, setSkypeIdError] = useState("");
  // const [isTimeSelected, setIsTimeSelected] = useState(false);

  const fetchEmployee = useCallback(
    async (employeeId) => {
      try {
        const response = await fetchUserById(employeeId);
        if (response.data.status === 200) {
          const data = response.data.data;
          const empData = {
            fullName: data.first_name + " " + data.last_name,
            email: data.email,
            first_name: data.first_name,
            company_id: data.company_id,
            id: data.id,
            last_name: data.last_name,
            phone: data.phone,
            role: data.role,
            status: data.status,
            time_tracking_status: data.time_tracking_status,
            Time_Zone: data.Time_Zone,
            skype_Id: data.skype_Id,
            required_timesheets: data.required_timesheets,
            ideal_time: data.ideal_time,
          };
          prevEmail = empData.email;
          setEmployeeData(empData);
          setEmployeeProfileImage(
            data.profile_img_url
              ? `${CENTRAL_BASE_URL}/${data.profile_img_url}`
              : UserImage
          );
        } else {
          const errPayload = {
            code: response.data.status,
            status: "fail",
            message:
              response.data.message || "Something went wrong. Try again later!",
          };
          dispatch(setFeedback(errPayload));
        }
      } catch (error) {
        const payload = {
          code: error.status,
          status: "fail",
          message: error.message || "Something went wrong. Try again later!",
        };
        dispatch(setFeedback(payload));
      }
    },
    [dispatch]
  );

  // const [selectedOption, setSelectedOption] = useState('');

  const isValidate = () => {
    const tempName = employeeData.fullName.trim();
    const [first_name, last_name] = tempName.replace(/\s+/g, " ").split(" ");
    if (employeeData.fullName.trim() === "") {
      setNameError("Please enter full name.");
      return false;
    }
    if (!first_name || !last_name) {
      setNameError("Please enter valid full name (first and last name).");
      return false;
    }
    if (!employeeData.email || employeeData.email.trim() === "") {
      setEmailError("Please enter email address.");
      return false;
    }
    if (!isValidEmail(employeeData.email)) {
      setEmailError("Please enter valid email.");
      return false;
    }
    if (!employeeData.phone || employeeData.phone.trim() === "") {
      setPhoneError("Please enter phone number.");
      return false;
    }
    if (!isValidPhoneNumber(employeeData.phone)) {
      setPhoneError("Please enter 10 digits.");
      return false;
    }
    // if(!employeeData.skype_Id || employeeData.skype_Id.trim() === ""){
    //   setSkypeIdError("Please enter skype id.");
    //   return false;
    // }
    return true;
  };
  const onAccountUpdateHandler = async (e) => {
    try {
      e.preventDefault();
      if (isValidate()) {
        const tempName = employeeData.fullName.trim();
        const [first_name, last_name] = tempName
          .replace(/\s+/g, " ")
          .split(" ");

        const payload = {
          id: employeeId,
          first_name,
          last_name,
          email: employeeData.email,
          phone: employeeData.phone,
          skype_Id: employeeData.skype_Id,
          // Time_Zone: selectedOption?.value
        };
        const response = await updateEmployeeData(payload, employeeId);

        if (response.data.status === 200) {
          dispatch(updateEmployee(employeeId));
          // setEmployeeData(response.data.data)
          showSuccessToast(response.data.message);
          navigate("/users", { replace: true });
        } else {
          setEmployeeData((prev) => ({ ...prev, email: prevEmail }));
          const errPayload = {
            code: response.data.status,
            status: "fail",
            message:
              response.data.message || "Something went wrong. Try again later!",
          };
          dispatch(setFeedback(errPayload));
        }
      }
    } catch (error) {
      const payload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong. Try again later!",
      };
      dispatch(setFeedback(payload));
    }
  };

  const projectOptions = useCallback(() => {
    const data = projectData?.map((proj) => ({
      value: proj.id,
      label: proj.title,
    }));
    return data;
  }, [projectData]);

  useEffect(() => {
    if (employeeId) {
      fetchEmployee(employeeId);
      projectOptions();
    }
  }, [employeeId, fetchEmployee, projectOptions]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const handleChangeEmail = (event) => {
    const newEmail = event.target.value;
    setEmployeeData((prevData) => ({
      ...prevData,
      email: newEmail,
    }));

    if (newEmail.trim() === "") {
      setEmailError("Please enter email address.");
    } else if (!isValidEmail(newEmail)) {
      setEmailError("Please enter valid email.");
    } else {
      setEmailError("");
    }
  };

  const handleChangeCompany = (event) => {
    const newCompany = event.target.value;
    setEmployeeData((prevData) => ({
      ...prevData,
      company_id: newCompany,
    }));

    if (newCompany.trim() === "") {
      setEmailError("Please enter company.");
    } else if (!isValidEmail(newCompany)) {
      setEmailError("Please enter valid company.");
    } else {
      setEmailError("");
    }
  };

  const handleChangePhoneNo = (event) => {
    const newPhone = event.target.value;
    setEmployeeData((prevData) => ({
      ...prevData,
      phone: newPhone,
    }));

    if (newPhone.trim() === "") {
      setPhoneError("Phone number is required");
    } else if (!isValidPhoneNumber(newPhone)) {
      setPhoneError("Please enter 10 digits.");
    } else {
      setPhoneError("");
    }
  };

  const handleChangeSkypeID = (event) => {
    const newSkypeId = event.target.value;
    setEmployeeData((prevData) => ({
      ...prevData,
      skype_Id: newSkypeId,
    }));

    // if (newSkypeId.trim() === "") {
    //   setSkypeIdError("Please enter skype id.");
    // } else {
    //   setSkypeIdError("");
    // }
  };

  const handleChangeName = (event) => {
    const newName = event.target.value;
    setEmployeeData((prevData) => ({
      ...prevData,
      fullName: newName,
    }));

    if (newName.trim() === "") {
      setNameError("Please enter full name.");
    } else {
      setNameError("");
    }
  };

  const roleOptions = rolesData.map((role) => ({
    value: role.title,
    label: role.title,
  }));

  const onCancleHandler = () => {
    navigate("/users");
  };

  // pabbing panel
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [globalSettingData, setGlobalSettingData] = useState([]);
  const [selectedTimeoutOption, setSelectedTimeoutOption] = useState();

  const handleTimeoutSelectedOption = (option) => {
    setSelectedTimeoutOption(option.value);
  };

  const fetchGlobalSettingsData = useCallback(async () => {
    try {
      const response = await fetchGlobalSettings();
      setGlobalSettingData(response?.data?.data);
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchGlobalSettingsData();
  }, [fetchGlobalSettingsData]);

  useEffect(() => {
    if (globalSettingData?.length > 0) {
      setSelectedTimeoutOption(globalSettingData[0]?.value);
    }
  }, [globalSettingData]);

  // select option
  const [selectedRoleOption, setSelectedRoleOption] = useState("");
  const [selectedProject, setSelectedProject] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxCheckedTimesheet, setIsCheckboxCheckedTimesheet] =
    useState(false);
  const [isCheckboxCheckedIdealTime, setIsCheckboxCheckedIdealTime] =
    useState(false);

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const handleTimesheetCheckboxChange = (event) => {
    setIsCheckboxCheckedTimesheet(event.target.checked);
  };

  const handleCheckboxIdealTimeChange = (event) => {
    setIsCheckboxCheckedIdealTime(event.target.checked);
  };

  useEffect(() => {
    setIsCheckboxChecked(employeeData.time_tracking_status === "enabled");
    setIsCheckboxCheckedTimesheet(employeeData.required_timesheets === "yes");
    if (employeeData.ideal_time !== null) {
      setIsCheckboxCheckedIdealTime(true);
      setSelectedTimeoutOption(
        timeOutOptions.find(
          (option) => option.value === employeeData.ideal_time
        )?.value
      );
    }
  }, [
    employeeData.ideal_time,
    employeeData.required_timesheets,
    employeeData.time_tracking_status,
  ]);

  const fetchProjectDataByEmployeeId = useCallback(
    async (employeeId) => {
      try {
        const response = await fetchProjectByEmployeeId({}, employeeId);
        if (response.data.status === 200) {
          const payload = response.data.data.projects;
          dispatch(fetchProjectByEmpId(payload));
        } else if (response.data.status === 404) {
          const payload = [];
          dispatch(fetchProjectByEmpId(payload));
        }
        // else {
        //   const errPayload = { code: response.data.status, status:"fail",
        //   message: response.data.message || "Something went wrong. Try again later!" };
        //   // dispatch(setFeedback(errPayload));
        // }
      } catch (error) {
        const payload = {
          code: error.status,
          status: "fail",
          message: error.message || "Something went wrong. Try again later!",
        };
        dispatch(setFeedback(payload));
      }
    },
    [dispatch]
  );

  const handleRoleChange = (selectedRole) => {
    setSelectedRoleOption(selectedRole);
  };

  useEffect(() => {
    // if (projectsDataByUser && projectsDataByUser.length > 0) {
    const projectIds = projectsDataByUser?.map((project) => project.id);
    setSelectedProject(projectIds);
    // }
  }, [projectsDataByUser]);

  const getProjectOptions = () => {
    return projectData.map((proj) => ({
      value: proj.id,
      label: proj.title,
    }));
  };

  const handleProjectChange = (selectedOptions) => {
    const selectedProjectIds = selectedOptions.map((option) => option.value);
    setSelectedProject(selectedProjectIds);
  };

  useEffect(() => {
    if (employeeData.role && rolesData.length > 0) {
      const matchingRole = rolesData.find(
        (role) => role.id === employeeData.role
      );
      if (matchingRole) {
        setSelectedRoleOption({
          value: matchingRole.title,
          label: matchingRole.title,
        });
      }
    }
  }, [employeeData.role, rolesData]);

  useEffect(() => {
    if (employeeId) {
      fetchProjectDataByEmployeeId(employeeId);
    }
  }, [employeeId, fetchProjectDataByEmployeeId]);

  // useEffect(() => {
  //   const selectedOption = options.find(option => option.value === employeeData.Time_Zone);
  //   setSelectedOption(selectedOption);
  // }, [employeeData.Time_Zone]);

  // const handleBlurTime = () => {
  //   setIsTimeSelected(true);
  // }

  // const handleSelectedOption = (selectedOption) => {
  //   setSelectedOption(selectedOption);
  //   setEmployeeData(prevData => ({
  //     ...prevData,
  //     Time_Zone: selectedOption ? selectedOption.value : ''
  //   }));
  // };

  const onRoleProjectsUpdateHandler = async (e) => {
    e.preventDefault();
    try {
      // const payload = { id: employeeId, role: selectedRoleOption.value };
      const payloadProject = {
        projects: selectedRoleOption.value === "admin" ? [] : selectedProject,
      };
      // const response = await updateEmployeeData(payload, employeeId);
      const projResponse = await updateProjectByEmployeeId(
        payloadProject,
        employeeId
      );

      if (projResponse.data.status === 200) {
        // dispatch(updateEmployee(employeeId));
        dispatch(updateProjectByEmpId(employeeId));
        // setEmployeeData(response.data.data);
        showSuccessToast(projResponse.data.message);
        navigate("/users", { replace: true });
      } else {
        const message =
          projResponse.data.message || "Something went wrong. Try again later!";
        const errPayload = {
          code: projResponse.data.status,
          status: "fail",
          message: message,
        };
        dispatch(setFeedback(errPayload));
      }
    } catch (error) {
      const payload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong. Try again later!",
      };
      dispatch(setFeedback(payload));
    }
  };

  const resetPassword = async (e) => {
    try {
      e.preventDefault();
      modalClose();
      const payload = { userEmail: employeeData.email };
      dispatch(setLoading(true));
      const response = await resetPasswordData(payload);
      dispatch(setLoading(false));
      if (response.data.status === 200) {
        showSuccessToast(response.data.data);
        navigate("/users", { replace: true });
      } else {
        const errPayload = {
          code: response.data.status,
          status: "fail",
          message:
            response.data.message || "Something went wrong. Try again later!",
        };
        dispatch(setFeedback(errPayload));
      }
    } catch (error) {
      const payload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong. Try again later!",
      };
      dispatch(setFeedback(payload));
    }
  };

  const updateSettings = async (e) => {
    try {
      const payload = {
        id: employeeId,
        time_tracking_status: isCheckboxChecked ? "enabled" : "disabled",
        required_timesheets: isCheckboxCheckedTimesheet ? "yes" : "no",
        ideal_time: isCheckboxCheckedIdealTime
          ? selectedTimeoutOption
          : "global",
      };
      const response = await updateEmployeeData(payload, employeeId);
      if (response.data.status === 200) {
        dispatch(updateEmployeeTakingStatus(payload));

        showSuccessToast(response.data.message);
        navigate("/users", { replace: true });
      } else {
        const errPayload = {
          code: response.data.status,
          status: "fail",
          message:
            response.data.message || "Something went wrong. Try again later!",
        };
        dispatch(setFeedback(errPayload));
      }
    } catch (error) {
      const payload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong. Try again later!",
      };
      dispatch(setFeedback(payload));
    }
  };

  const [normalModalOpen, setOpen] = React.useState(false);
  const modalOpen = () => setOpen(true);
  const modalClose = () => setOpen(false);

  return (
    <>
      <KeyboardBackspaceIcon
        style={{
          fontSize: "36px",
          cursor: "pointer",
          fill: "#777",
          marginBottom: "16px",
        }}
        onClick={() => navigate("/users")}
        className="back-btn"
      />
      <Box className="account-wrapper edit-employee-wrapper">
        <Box className="account-tab card mb-0">
          <Box className="user-profile">
            <img src={employeeProfileImage} alt="" loading="lazy" />
          </Box>
          {/* tabbing section */}
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="my-account-tab"
          >
            <Tab className="btn" label="Account Details" {...a11yProps(0)} />
            <Tab className="btn" label="Settings" {...a11yProps(1)} />
            <Tab
              className="btn mb-4"
              label="Projects & Roles"
              {...a11yProps(2)}
            />
          </Tabs>
          <Button
            disabled
            className="btn btn-tertiary mt-auto"
            onClick={modalOpen}
          >
            Reset Password
          </Button>
        </Box>
        <Box className="account-content card mb-0">
          <TabPanel value={value} index={0}>
            <Typography
              variant="h6"
              component="h6"
              className="text-black card-title"
            >
              Account Details
            </Typography>
            {/* form field*/}
            <Grid container>
              <Grid item lg={7} md={12} xs={12}>
                <FormGroup className="form-field">
                  <FormControl variant="standard" className="form-control mb-3">
                    <Typography
                      variant="body2"
                      component="span"
                      className="text-black input-label"
                    >
                      Full Name
                      <Typography
                        variant="body2"
                        component="span"
                        className="text-black input-label asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <BootstrapInput
                      placeholder="Abelardo Leal"
                      className={!nameError ? "input-field" : "border-error"}
                      value={employeeData.fullName}
                      onChange={handleChangeName}
                      disabled
                    />
                    {nameError && <p className="error-text">{nameError}</p>}
                  </FormControl>
                  <FormControl variant="standard" className="form-control mb-3">
                    <Typography
                      variant="body2"
                      component="span"
                      className="text-black input-label"
                    >
                      Company
                      <Typography
                        variant="body2"
                        component="span"
                        className="text-black input-label asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <BootstrapInput
                      placeholder="-"
                      className={!emailError ? "input-field" : "border-error"}
                      value={
                        employeeData.company_id === 1
                          ? "Crest Infosystems Pvt. Ltd."
                          : employeeData.company_id === 2
                          ? "Crest Skillserve Pvt. Ltd."
                          : "-"
                      }
                      onChange={handleChangeCompany}
                      disabled
                    />
                    {emailError && <p className="error-text"> {emailError}</p>}
                  </FormControl>
                  <FormControl variant="standard" className="form-control mb-3">
                    <Typography
                      variant="body2"
                      component="span"
                      className="text-black input-label"
                    >
                      Email
                      <Typography
                        variant="body2"
                        component="span"
                        className="text-black input-label asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <BootstrapInput
                      placeholder="john.carter@gmail.com"
                      className={!emailError ? "input-field" : "border-error"}
                      value={employeeData.email}
                      onChange={handleChangeEmail}
                      disabled
                    />
                    {emailError && <p className="error-text"> {emailError}</p>}
                  </FormControl>

                  {/* <FormControl variant="standard" className='form-control'>
                      <Typography variant="body2" component="span" className='text-black input-label'>
                        Time Zone<Typography variant="body2" component="span" className='text-black input-label asterisk'>
                              * 
                            </Typography>
                      </Typography>
                      <Select 
                        className='select-time-zone'
                        value={selectedOption}
                        onChange={handleSelectedOption}
                        options={options}
                        onBlur={handleBlurTime}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: selectedOption !== ''  ? '#cccccc' : 'red',
                            minHeight: '50px'
                          }),
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 10,
                            colors: {
                            ...theme.colors,
                            primary25: 'rgba(140, 199, 20, 0.4);',
                            primary: '#8CC714',
                            },
                        })
                        }
                      />
                      {isTimeSelected && selectedOption === '' && <p className="error-text">Please select a role</p>}
                    </FormControl> */}

                  <FormControl variant="standard" className="form-control mb-3">
                    <Typography
                      variant="body2"
                      component="span"
                      className="text-black input-label"
                    >
                      Phone
                      <Typography
                        variant="body2"
                        component="span"
                        className="text-black input-label asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <BootstrapInput
                      type="number"
                      placeholder="Enter phone number"
                      className={!phoneError ? "input-field" : "border-error"}
                      value={employeeData.phone}
                      onChange={handleChangePhoneNo}
                      disabled
                    />
                    {phoneError && <p className="error-text">{phoneError}</p>}
                  </FormControl>
                  <FormControl variant="standard" className="form-control">
                    <Typography
                      variant="body2"
                      component="span"
                      className="text-black input-label"
                    >
                      Skype ID
                    </Typography>
                    <BootstrapInput
                      placeholder="live:cid.c62e458lfb411c45"
                      // className={!skypeIdError ? 'input-field' : 'border-error'}
                      className="input-field"
                      value={employeeData.skype_Id}
                      onChange={handleChangeSkypeID}
                    />
                    {/* {skypeIdError && <p className="error-text">{skypeIdError}</p>} */}
                  </FormControl>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={6}>
                      <Button
                        className="btn btn-primary w-100"
                        onClick={onAccountUpdateHandler}
                      >
                        Update
                      </Button>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Button
                        className="btn btn-secondary w-100"
                        onClick={onCancleHandler}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>
          </TabPanel>

          {/* Setting */}
          <TabPanel value={value} index={1}>
            <Typography
              variant="h6"
              component="h6"
              className="text-black card-title"
            >
              Settings
            </Typography>
            <Grid container>
              <Grid item lg={7} md={12} xs={12}>
                <FormGroup className="form-field">
                  <FormControl variant="standard" className="form-control mb-3">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isCheckboxChecked}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Able to track time"
                        className="checkbox-field "
                      />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isCheckboxCheckedTimesheet}
                            onChange={handleTimesheetCheckboxChange}
                          />
                        }
                        label="Is timesheet required"
                        className="checkbox-field "
                      />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isCheckboxCheckedIdealTime}
                            onChange={handleCheckboxIdealTimeChange}
                          />
                        }
                        label="Modify ideal time"
                        className="checkbox-field "
                      />
                    </Box>
                    {isCheckboxCheckedIdealTime && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "16px",
                        }}
                      >
                        <FormControl
                          variant="standard"
                          className="form-control mb-3 settting-form-field"
                        >
                          <Typography
                            variant="body2"
                            component="span"
                            className="text-black input-label"
                          >
                            Select Time
                          </Typography>
                          <Select
                            className="select-time-zone"
                            value={timeOutOptions.find(
                              (option) => option.value === selectedTimeoutOption
                            )}
                            onChange={handleTimeoutSelectedOption}
                            options={timeOutOptions}
                            placeholder="Select Time"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                // borderColor: selectedOption !== ''  ? '#cccccc' : 'red',
                                minHeight: "50px",
                                maxWidth: "100%",
                              }),
                            }}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 10,
                              colors: {
                                ...theme.colors,
                                primary25: "rgba(140, 199, 20, 0.4);",
                                primary: "#8CC714",
                              },
                            })}
                          />
                        </FormControl>
                      </Box>
                    )}
                    {/* <FormControlLabel control={<Checkbox />} label="Add member to all new projects" className='checkbox-field '/> */}
                  </FormControl>

                  <Grid container spacing={2} className="pt-1">
                    <Grid item md={6} xs={6}>
                      <Button
                        className="btn btn-primary"
                        onClick={updateSettings}
                      >
                        Update
                      </Button>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Button
                        className="btn btn-secondary"
                        onClick={onCancleHandler}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>
          </TabPanel>

          {/* Roles & Projects */}
          <TabPanel value={value} index={2}>
            <Typography
              variant="h6"
              component="h6"
              className="text-black card-title"
            >
              Roles & Projects
            </Typography>
            <Grid container>
              <Grid item lg={7} md={12} xs={12}>
                <FormGroup className="form-field">
                  <FormControl variant="standard" className="form-control mb-3">
                    <Typography
                      variant="body2"
                      component="span"
                      className="text-black input-label"
                    >
                      Role
                    </Typography>
                    <Select
                      isDisabled
                      className="select-time-zone"
                      value={selectedRoleOption}
                      onChange={handleRoleChange}
                      options={roleOptions}
                      styles={getNormalColorStyle("SINGLE")}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "rgba(140, 199, 20, 0.4);",
                          primary: "#8CC714",
                        },
                      })}
                    />
                  </FormControl>
                  {selectedRoleOption.value !== ROLES.admin && (
                    <FormControl
                      variant="standard"
                      className="form-control password-field mb-4"
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        className="text-black input-label"
                      >
                        Projects
                      </Typography>
                      <Select
                        className="select-time-zone select-filter"
                        value={projectOptions().filter((option) =>
                          selectedProject?.includes(option.value)
                        )}
                        onChange={handleProjectChange}
                        options={getProjectOptions()}
                        isMulti
                        classNamePrefix="select"
                        styles={getNormalColorStyle("MULTI")}
                        isClearable={false}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 10,
                          colors: {
                            ...theme.colors,
                            primary25: "rgba(140, 199, 20, 0.4);",
                            primary: "#8CC714",
                          },
                        })}
                      />
                    </FormControl>
                  )}
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={6}>
                      <Button
                        className="btn btn-primary"
                        onClick={onRoleProjectsUpdateHandler}
                      >
                        Update
                      </Button>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Button
                        className="btn btn-secondary"
                        onClick={onCancleHandler}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </Box>

      {/* Reset Password Model */}
      {normalModalOpen && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={normalModalOpen}
          onClose={modalClose}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={normalModalOpen}>
            <Box sx={styled} className="modal-wrapper modal-bg">
              <Typography
                variant="h6"
                component="h6"
                className="text-black modal-title"
              >
                Reset Password
              </Typography>
              <Box className="modal-close" onClick={modalClose}>
                <FiPlus />
              </Box>
              <Box className="modal-body">
                <Box className="confirmation-text">
                  <Typography paragraph>
                    User will receive instructions for reseting to his/her
                    password.
                  </Typography>
                </Box>
              </Box>
              <Box className="modal-footer">
                <Grid container spacing={3}>
                  <Grid item md={6} xs={6}>
                    <Button className="btn btn-primary" onClick={resetPassword}>
                      {" "}
                      Yes
                    </Button>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <Button className="btn btn-secondary" onClick={modalClose}>
                      No
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  );
};

export default EditEmployee;
