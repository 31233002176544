import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import Select from "react-select";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import { FiDownloadCloud } from "react-icons/fi";
import { DateRangePicker } from "rsuite";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { BiSortAlt2 } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import {
  convertDateIntoYYYYMMDD,
  convertSecondsIntoHHMM,
  getDateRangeOptions,
  getDotColorStyle,
  getFilterStorage,
  getLocalTimeZone,
  getPermissionAccess,
  handleLabel,
  setFilterStorage,
} from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_INCOMPLETE_TIME_SHEET_API,
  ROLES,
  storageName,
} from "../../lib/constants";
import { fetchEmployee } from "../../apis/employee";
import {
  setFeedback,
  setLoading,
} from "../../store/redux/actions/feedback.action";
import { fetchIncompleteTimeSheet } from "../../store/redux/actions/reports.action";
import axios from "axios";
import { TablePagination } from "@mui/material";
import { fetchIncompleteTimesheets } from "../../store/redux/actions/project.action";
import { CustomTablePaginationToolbar } from "../../components/common/Pagination";

const { afterToday } = DateRangePicker;
const ranges = getDateRangeOptions();

const DDMMYYYY = (inputDate) => {
  const dateParts = inputDate.split("-");
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};

const IncompleteTimesheets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.user.data);
  const permissions = userDetails.role_permissions.permissions;
  const permissionsAccess = getPermissionAccess(permissions, "Reports");
  if (
    !permissionsAccess.view &&
    !permissionsAccess.add &&
    !permissionsAccess.edit &&
    !permissionsAccess.delete
  ) {
    navigate("/", { replace: true });
  }

  if (
    userDetails?.user_role?.id === ROLES.employee ||
    userDetails?.user_role?.id === ROLES.viewer
  ) {
    navigate("/", { replace: true });
  }

  const incompleteTimeSheetData = useSelector(
    (state) => state.reports.incompleteTimesheetReport
  );
  const filterData = getFilterStorage(storageName.incompleteTimesheets);

  const [page, setPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // const fetchIncompleteTimesheetsData = useCallback(async () => {
  //   try {
  //     const params = {
  //       page: page + 1,
  //       rows: +rowsPerPage,
  //     };
  //     dispatch(fetchIncompleteTimesheets(params));
  //   } catch (error) {
  //     const errPayload = {
  //       code: error.status,
  //       status: "fail",
  //       message: error.message || "Something went wrong.",
  //     };
  //     dispatch(setFeedback(errPayload));
  //   }
  // }, [dispatch, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // filter states-------------------------------------
  const initialSelectedDates = [new Date(), new Date()];
  const [dateRangeValue, setDateRangeValue] = useState(
    filterData
      ? [
          new Date(filterData?.dateRangeValue[0]),
          new Date(filterData?.dateRangeValue[1]),
        ]
      : initialSelectedDates
  );
  const [assignee, setAssignee] = useState([]);
  const [sortValue, setSortValue] = useState("desc");
  const [isMissingTimesheet, setIsMissingTimesheet] = useState(
    filterData?.isMissingTimesheet || false
  );

  // const [selectedActivityLevel, setSelectedActivityLevel] = useState(null);
  const [selectedAssignee, setSelectedAssignee] = useState(
    filterData
      ? {
          value: filterData?.user_id,
          label: filterData?.user_name,
          dot_color: filterData?.dot_color,
          text_color: "#777777",
        }
      : { value: "", label: "All", dot_color: "#8CC714", text_color: "#777777" }
  );

  const changeIsMissingTimesheetHandler = (e) => {
    setIsMissingTimesheet(e.target.checked);
  };
  const changeSortOrderHandler = () => {
    setSortValue((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  const onChangeDateRangeHandler = (date) => {
    if (date === null) {
      setDateRangeValue(initialSelectedDates);
    } else {
      setDateRangeValue(date);
    }
  };

  const assigneeOption = useMemo(() => {
    let options = [
      { value: "", label: "All", dot_color: "#8CC714", text_color: "#777777" },
    ];
    if (userDetails?.user_role?.id === ROLES.admin) {
      assignee.forEach((row) => {
        if (row.status === "active") {
          options.push(
            handleLabel(true, row.id, `${row.first_name} ${row.last_name}`)
          );
        } else {
          options.push(
            handleLabel(false, row.id, `${row.first_name} ${row.last_name}`)
          );
        }
      });
    } else if (
      userDetails?.user_role?.id === ROLES.manager ||
      userDetails?.user_role?.id === ROLES.viewer
    ) {
      assignee.forEach((row) => {
        let flag = 0;
        if (row.status !== "active") {
          flag = 1;
        } else {
          row.project_assignments.some((row2) => {
            if (row2.deletedAt === null) {
              flag = 0;
              return true;
            } else {
              flag = 1;
              return false;
            }
          });
        }
        if (flag === 0) {
          options.push(
            handleLabel(true, row.id, `${row.first_name} ${row.last_name}`)
          );
        } else {
          options.push(
            handleLabel(false, row.id, `${row.first_name} ${row.last_name}`)
          );
        }
      });
    } else {
      options = [];
    }
    return options;
  }, [assignee, userDetails?.user_role?.id]);

  const fetchAssignee = async () => {
    try {
      const params = { required_timesheet: 1 };
      const response = await fetchEmployee(params);
      if (response.data.status === 200) {
        setAssignee(response.data.data);
      } else {
        setAssignee([]);
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };
  useLayoutEffect(() => {
    if (
      userDetails?.user_role?.id !== ROLES.employee ||
      userDetails?.user_role?.id !== ROLES.viewer
    ) {
      fetchAssignee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPage(0);
  }, [isMissingTimesheet]);

  // useEffect(() => {
  //   fetchIncompleteTimesheetsData();
  // }, [fetchIncompleteTimesheetsData]);

  // -------------------------------------

  const fetchIncompleteTimeSheetData = useCallback(
    async (tag = "") => {
      try {
        const filter = getFilterStorage(storageName.incompleteTimesheets);
        let startDate;
        let endDate;
        if (filter && filter.dateRangeValue !== null) {
          const [sDate, eDate] = filter.dateRangeValue;
          startDate = convertDateIntoYYYYMMDD(sDate);
          endDate = convertDateIntoYYYYMMDD(eDate);
        } else {
          startDate = convertDateIntoYYYYMMDD(new Date());
          endDate = convertDateIntoYYYYMMDD(new Date());
        }

        let params = {
          startDate: startDate,
          endDate: endDate,
          orderBy: filter.orderBy,
          page: page + 1,
          rows: rowsPerPage,
        };
        if (filter.isMissingTimesheet) {
          params.isMissingTS = filter.isMissingTimesheet ? 1 : 0;
        }
        if (filter.user_id && filter.user_id !== "") {
          params.user_id = filter.user_id;
        }

        // API integration start
        if (tag === "") {
          params && dispatch(fetchIncompleteTimeSheet(params));
        } else if (tag === "PDF" || tag === "CSV") {
          dispatch(setLoading(true));
          if (tag === "PDF")
            params = {
              ...params,
              download_pdf: 1,
              timezone: getLocalTimeZone(),
            };
          else if (tag === "CSV")
            params = {
              ...params,
              download_csv: 1,
              timezone: getLocalTimeZone(),
            };

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${FETCH_INCOMPLETE_TIME_SHEET_API}`,
            { params, responseType: "blob" }
          );
          if (response.status !== 200) {
            throw new Error("No Record Found!");
          }

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute(
            "download",
            `incomplete-timesheet_${filter.user_name.replaceAll(
              " ",
              "_"
            )}_${startDate.replaceAll("-", "")}_${endDate.replaceAll(
              "-",
              ""
            )}.${tag === "PDF" ? "pdf" : "xlsx"}`
          );
          document.body.appendChild(link);
          link.click();
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        const errPayload = {
          code: error.status,
          status: "fail",
          message:
            error.response.status === 404
              ? "No data found"
              : error.message || "Something went wrong.",
        };
        dispatch(setFeedback(errPayload));
      }
    },
    [dispatch, page, rowsPerPage]
  );

  useEffect(() => {
    let params;

    params = {
      dateRangeValue,
      orderBy: sortValue,
      user_id: selectedAssignee?.value,
      user_name: selectedAssignee?.label,
      dot_color: selectedAssignee?.dot_color,
      isMissingTimesheet: isMissingTimesheet,
    };

    if (params) {
      setFilterStorage(storageName.incompleteTimesheets, params);
      fetchIncompleteTimeSheetData();
    }
  }, [
    dateRangeValue,
    fetchIncompleteTimeSheetData,
    isMissingTimesheet,
    selectedAssignee,
    sortValue,
  ]);

  const downloadCsvHandler = () => {
    fetchIncompleteTimeSheetData("CSV");
  };
  const downloadPDFHandler = async () => {
    fetchIncompleteTimeSheetData("PDF");
  };

  return (
    <>
      <Box className="content-header report-top-bar incomplete-timesheet-report-module-wrapper">
        <Box className="report-top-filter">
          <KeyboardBackspaceIcon
            style={{ fontSize: "36px", cursor: "pointer", fill: "#777" }}
            onClick={() => navigate("/reports")}
            className="back-btn"
          />
          <Tooltip title={`${sortValue === "desc" ? "Asc" : "Desc"}`} className="desktop-sorting-btn">
            <Button
              className="btn btn-primary only-icon-btn"
              onClick={changeSortOrderHandler}
            >
              <BiSortAlt2 />
            </Button>
          </Tooltip>
          <Box className="calendar-ui">
            <DateRangePicker
              ranges={ranges}
              onChange={onChangeDateRangeHandler}
              value={dateRangeValue}
              // disabledDate={afterToday()}
              shouldDisableDate={afterToday()}
              format="dd-MM-yyyy"
            />
          </Box>
          <Select
            className="report-select-date select-field select-dot-option"
            placeholder="Select Member"
            value={selectedAssignee}
            onChange={setSelectedAssignee}
            options={assigneeOption}
            styles={getDotColorStyle("SINGLE")}
            classNamePrefix="select"
            theme={(theme) => ({
              ...theme,
              borderRadius: 10,
              colors: {
                ...theme.colors,
                primary25: "rgba(140, 199, 20, 0.4);",
                primary: "#8CC714",
              },
            })}
          />
          <FormControlLabel
            sx={{ margin: "0px" }}
            control={
              <Checkbox
                className="checkbox-field"
                checked={isMissingTimesheet}
                onChange={changeIsMissingTimesheetHandler}
              />
            }
            label="Missing Timesheets"
            className="report-checkbox-control"
          />
        </Box>
        <Box item className="content-header-right report-btn-icon mb-0">
          <Button onClick={downloadCsvHandler}>
            <FiDownloadCloud /> To CSV
          </Button>
          <Button onClick={downloadPDFHandler}>
            <FiDownloadCloud /> To PDF
          </Button>
          <Tooltip title={`${sortValue === "desc" ? "Asc" : "Desc"}`} className="mobile-sorting-btn">
            <Button
              className="btn btn-primary only-icon-btn"
              onClick={changeSortOrderHandler}
            >
              <BiSortAlt2 />
            </Button>
          </Tooltip>
        </Box>
      </Box>

      <Box className='card mb-0' sx={{ marginTop:'10px' }}>
        <Box className='incomplete-timesheets-table-listing'>
          <TableContainer className='table-wrapper mb-0'>
            <Table aria-label="collapsible table" className='incomplete-timesheets-table'>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Member</TableCell>
                  <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                    Non-billable
                  </TableCell>
                  <TableCell align="left">Manual</TableCell>
                  <TableCell align="left">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {incompleteTimeSheetData?.data?.map((row, index) => {
                  return (
                    <TableRow key={index + "incomplete"}>
                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        {DDMMYYYY(row.date)}
                      </TableCell>
                      <TableCell align="left">{`${row["user.first_name"]} ${row["user.last_name"]}`}</TableCell>
                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        {convertSecondsIntoHHMM(
                          row["user_activities.total_unbillable_time"]
                        )}{" "}
                        Hrs
                      </TableCell>
                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        {convertSecondsIntoHHMM(row["total_manual_time"])} Hrs
                      </TableCell>
                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        {convertSecondsIntoHHMM(row["total_tracked_time"])} Hrs
                      </TableCell>
                    </TableRow>
                  );
                })}
                {!incompleteTimeSheetData?.data?.length && (
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={incompleteTimeSheetData?.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          <CustomTablePaginationToolbar
            count={incompleteTimeSheetData?.totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
};

export default IncompleteTimesheets;
