import { CLEAR_FEEDBACK, SET_FEEDBACK, SET_LOADING } from "../actionTypes";

const initialState = {
    isLoading: false,
    code: "",
    status: "",
    message: ""
}

const FeedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FEEDBACK:
            return { isLoading: state.isLoading, ...action.payload};
    
        case CLEAR_FEEDBACK:
            return { isLoading: state.isLoading, code:"" , status:"", message:"" };

        case SET_LOADING:            
            return { isLoading: action.payload, code:state.code, status:state.status, message:state.message };

        default:
            return state;
    }
}

export default FeedbackReducer;