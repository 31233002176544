import { put, takeEvery } from "redux-saga/effects";
import { FETCH_TODO_PROJECT, FETCH_TODO_PROJECT_STORE, FETCH_WORK_TODAY_ACTIVITY, FETCH_WORK_TODAY_ACTIVITY_STORE, SET_FEEDBACK, SET_LOADING } from "../redux/actionTypes";
import { getTodoProjectData, getWorkedTodayActivityData } from "../../apis/dashboard";

function* fetchDashboardWorkedActivityHandler(action){
    try {
        yield put({ type: SET_LOADING, payload:true });
        const response = yield getWorkedTodayActivityData(action.payload);
        yield put({ type: SET_LOADING, payload:false });

        if(response.data.status === 200){
            const payload = response.data.data;
            yield put({ type: FETCH_WORK_TODAY_ACTIVITY, payload });
        } else if(response.data.status === 404){
            yield put({ type: FETCH_WORK_TODAY_ACTIVITY, payload:[] });
        } else {
            const payload = { code: response.data.status, status:"fail", 
            message: response.data.message || "Something went wrong." };
            if(payload.code !== 502){
                yield put({ type: SET_FEEDBACK, payload });
            }
        }
    } catch (error) {
        yield put({ type: SET_LOADING, payload:false });
        const payload = { 
            code: error.status, 
            status:"fail", 
            message: error.message || "Something went wrong." 
        };
        if(payload.code !== 502){
            yield put({ type: SET_FEEDBACK, payload });
        }
    }
}

function* fetchDashboardTodoProjectHandler(action){
    try {
        yield put({ type: SET_LOADING, payload:true });
        const response = yield getTodoProjectData(action.payload);
        yield put({ type: SET_LOADING, payload:false });

        if(response.data.status === 200){
            const payload = response.data.data;
            yield put({ type: FETCH_TODO_PROJECT, payload });
        } else if(response.data.status === 404){
            yield put({ type: FETCH_TODO_PROJECT, payload:[] });
        } else {
            const payload = { code: response.data.status, status:"fail", 
            message: response.data.message || "Something went wrong." };
            if(payload.code !== 502){
                yield put({ type: SET_FEEDBACK, payload });
            }
        }
    } catch (error) {
        yield put({ type: SET_LOADING, payload:false });
        const payload = { 
            code: error.status, 
            status:"fail", 
            message: error.message || "Something went wrong." 
        };
        if(payload.code !== 502){
            yield put({ type: SET_FEEDBACK, payload });
        }
    }
}

function* watchDashboardAction(){
    yield takeEvery(FETCH_WORK_TODAY_ACTIVITY_STORE, fetchDashboardWorkedActivityHandler);
    yield takeEvery(FETCH_TODO_PROJECT_STORE, fetchDashboardTodoProjectHandler);
}

export default watchDashboardAction;