import { getAPICall } from "../lib/callApi";
import { FETCH_SCREEN_CASTS_API, FETCH_TODO_PROJECT_API, FETCH_WORK_TODAY_ACTIVITY_API, GET_SINGLE_IMAGE_API } from "../lib/constants";

export const getWorkedTodayActivityData = async (params) => {
    const data = await getAPICall(FETCH_WORK_TODAY_ACTIVITY_API, true, params);
    return data;
}

export const getTodoProjectData = async (params) => {
    const data = await getAPICall(FETCH_TODO_PROJECT_API, true, params);
    return data;
}

export const fetchScreenCasts = async (params) => {
    // const data = await getAPICall(FETCH_SCREEN_CASTS_API, true, params, id);
    const data = await getAPICall(FETCH_SCREEN_CASTS_API, true, params);
    return data;
}

export const getSingleImage = async (image) => {
    const newUrl = `${GET_SINGLE_IMAGE_API}${image}`
    const data = await getAPICall(newUrl, true);
    return data;
}