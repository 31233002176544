import { redirect } from "react-router-dom";
import Cookies from "js-cookie";

const COOKIE_NAME = process.env.REACT_APP_COOKIE_NAME;
const USER_ID_COOKIE = process.env.REACT_APP_USER_ID_COOKIE;

export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem(COOKIE_NAME, token);
  }
};

export const getAuthToken = () => {
  // const token = localStorage.getItem('token');
  const token = Cookies.get(COOKIE_NAME);

  return token;
};

export const setLoggedInUserId = (id) => {
  if (id) {
    localStorage.setItem(USER_ID_COOKIE, id);
  }
};

export const getLoggedInUserId = () => {
  // const userId = JSON.parse(localStorage.getItem('userId'));
  const userId = Cookies.get(USER_ID_COOKIE);
  return userId;
};

export const removeAuthToken = () => {
  localStorage.removeItem(COOKIE_NAME);
};

export const checkIsAuthenticated = () => {
  const token = getAuthToken();
  if (!token) {
    return redirect("/login");
  }
  return null;
};
