import React, { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Slider from "react-slick";
import { styled } from "@mui/material";
import { FiDownloadCloud, FiPlus } from "react-icons/fi";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const BASE_PATH2 = process.env.REACT_APP_IMAGE_PATH2;
// const DRIVE_PATH = "https://drive.google.com/uc?id=<img-id>&export=view";
const DRIVE_PATH = "https://drive.google.com/thumbnail?id=<img-id>&sz=w1000"; // quality image path

function formatslotText(timeString) {
    const utcDate = new Date(timeString);
    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();

    return `${hours > 12 ? hours - 12 : hours}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
}

const getFullImagePath = (driveScreenShot, screenShot) => {
    let image = null;
        
    if(driveScreenShot){
        image = DRIVE_PATH.replace("<img-id>", driveScreenShot);
        return image;
    } 
    else if(screenShot) {
        image = BASE_PATH2 + screenShot;
        return image;
    }
    return image;
}

const ImageModal = ({ imageModalOpen, closeImageModalHandler, finalModalArray, imageIndex, userName }) => {

    const [currentIndex, setCurrentIndex] = useState(imageIndex);
    const sliderRef = useRef(null);
    const [downloadButtonClicked, setDownloadButtonClicked] = useState(false); 
    const [currentImageSrc] = useState('');

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        lazyLoad: "ondemand",
        initialSlide: imageIndex,
    };

    const downloadImage = (index, imageType) => {
        const img = finalModalArray[index];

        let blocktimeText;
        if (img.blocktime) {
            blocktimeText = img.blocktime;
        } else if (img.start_date && img.end_date) {
            blocktimeText = `${formatslotText(img.start_date)} - ${formatslotText(img.end_date)}`;
        } 

        const primaryImagePath = getFullImagePath(img.drive_primary, img.screenshot_primary);
        const secondaryImagePath = getFullImagePath(img.drive_secondary, img.screenshot_secondary);

        const imageUrl = imageType === 'primary' ? 
                                        primaryImagePath : 
                                        secondaryImagePath;

        const formattedBlocktimeText = blocktimeText.replace(/:/g, '-');

        const filename = imageType === 'primary' ? 
                                        `${userName}_${formattedBlocktimeText}_primary_screenshot` : 
                                        `${userName}_${formattedBlocktimeText}_secondary_screenshot`;

        const link = document.createElement('a');
        link.href = imageUrl.replace('view', 'download');
        link.download = filename;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    };       

    const handleDownloadClick = (imageType, index) => {
        if (!downloadButtonClicked) {
            setDownloadButtonClicked(true); 
            downloadImage(index, imageType);
        }
    };

    const previousClick = () => {
        const newIndex = currentIndex - 1;
        setCurrentIndex(newIndex);
        sliderRef.current.slickPrev();
        setDownloadButtonClicked(false);
    };
    
    const nextClick = () => {
        const newIndex = currentIndex + 1;
        setCurrentIndex(newIndex);
        sliderRef.current.slickNext();
        setDownloadButtonClicked(false);
    };
    
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === "ArrowLeft") {
                previousClick();
            } else if (e.key === "ArrowRight") {
                nextClick();
            }
        };

        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIndex]);

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={imageModalOpen}
                onClose={closeImageModalHandler}
                closeAfterTransition
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={imageModalOpen}>
                    <Box sx={styled} className='modal-wrapper modal-screencast-slider'>
                        <Box className='modal-close' onClick={closeImageModalHandler}>
                            <FiPlus/>
                        </Box>

                        <Slider ref={sliderRef} {...settings}>
                            {finalModalArray?.map((img, index) => {
                                
                                const imagesToDisplay = [];
                                const PRIMARY_IMAGE = getFullImagePath(img.drive_primary, img.screenshot_primary);
                                const SECONDARY_IMAGE = getFullImagePath(img.drive_secondary, img.screenshot_secondary);
                                let blocktimeText;

                                if (img.blocktime) {
                                    blocktimeText = img.blocktime;
                                } else if (img.start_date && img.end_date) {
                                    blocktimeText = `${formatslotText(img.start_date)} - ${formatslotText(img.end_date)}`;
                                } 
                                
                                if (PRIMARY_IMAGE !== null && PRIMARY_IMAGE !== "") {
                                    imagesToDisplay.push(
                                        <Box className='image-wrapper'>
                                            <Box className='download-screencast' onClick={() => handleDownloadClick('primary', index)}>
                                                <FiDownloadCloud />
                                            </Box>
                                            <img src={PRIMARY_IMAGE} key={index+"_primary"} className='screencast-img' alt='screencast' loading="lazy" />
                                            <Typography className="screen-name">Screen 1</Typography>
                                            <Typography className="screen-name">{blocktimeText} ({img.overall_activity}% Activity)</Typography>
                                        </Box>
                                    );
                                }

                                if (SECONDARY_IMAGE !== null && SECONDARY_IMAGE !== "") {
                                    imagesToDisplay.push(
                                        <Box className='image-wrapper'>
                                            <Box className='download-screencast' onClick={() => handleDownloadClick('secondary', index)}>
                                                <FiDownloadCloud />
                                            </Box>
                                            <img src={SECONDARY_IMAGE} key={index+"_secondary"} className='screencast-img' alt='screencast' loading="lazy" />
                                            <Typography className="screen-name">Screen 2</Typography>
                                            <Typography className="screen-name">{blocktimeText} ({img.overall_activity}% Activity)</Typography>
                                        </Box>
                                    );
                                }

                                return imagesToDisplay;
                            })}
                        </Slider>
                        
                        <Box className="custom-slider-buttons">
                            <button className="custom-prev-button" onClick={previousClick}>
                                <KeyboardArrowLeftIcon />
                            </button>
                            <button className="custom-next-button" onClick={nextClick}>
                                <KeyboardArrowRightIcon />
                            </button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>

            <img
                src={currentImageSrc}
                alt="Hidden"
                style={{ display: 'none' }}
                onLoad={() => URL.revokeObjectURL(currentImageSrc)}
            />
        </>
    );
}

export default ImageModal;