import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import InputBase from "@mui/material/InputBase";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { FiPlus } from "react-icons/fi";
import { showSuccessToast } from "../../utils/toast";
import {
  convertDateIntoDDMMYYYY,
  convertSecondsIntoHHMM,
} from "../../utils/helper";
import {
  setFeedback,
  setLoading,
} from "../../store/redux/actions/feedback.action";
import { fetchTimeSheetByDate, moveTimeSheet } from "../../apis/timeSheet";
import copy from "clipboard-copy";
import { ROLES } from "../../lib/constants";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
}));

const isSameDay = (inputDate) => {
  const today = new Date();
  const todayDate = `${today.getFullYear()}-${(today.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
  return todayDate === inputDate;
};

const ViewTimesheet = ({
  openModal,
  closeModal,
  userDetails,
  valueTab,
  projectId,
  getFetchInnerTimeSheetParams,
  date,
  fetchTimeSheetData,
  permissionsAccess,
  timeSheetUserRole,
  timeSheetUserRoleId,
  isAssistantManager,
  isUserUnderAManager,
}) => {
  const userData = useSelector((state) => state.user.data);
  const userRole = userData?.user_role?.id;
  const dispatch = useDispatch();
  const modalBodyRef = useRef();
  const [updateData, setUpdateData] = useState([]);
  const [inputText, setInputText] = useState([]);
  const [userInfo, setUserInfo] = useState();

  const fetchWorkUpdates = async () => {
    try {
      dispatch(setLoading(true));

      const payload = {
        user_id: userDetails.user_id,
        startDate: userDetails.date,
        endDate: userDetails.date,
        work_update: 1,
        approved: valueTab === 0 ? "no" : "yes",
      };
      if (projectId.value !== "") {
        payload.project_id = [projectId.value];
      }

      const response = await fetchTimeSheetByDate(payload);
      if (response.data.status === 200) {
        setUpdateData(response.data.data);
        setUserInfo(response.data.data.userDetails);
      } else {
        const payload = {
          code: response.data.status,
          status: "fail",
          message: response.data.message || "Something went wrong",
        };
        dispatch(setFeedback(payload));
      }

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong",
      };
      dispatch(setFeedback(errPayload));
    }
  };

  useEffect(() => {
    fetchWorkUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalWorkTimeSeconds = Number(updateData?.totalWorkedTime);
  const totalWorkedTime = !isNaN(totalWorkTimeSeconds)
    ? convertSecondsIntoHHMM(totalWorkTimeSeconds)
    : "00:00";

  const handleCopyClick = async () => {
    try {
      let finalText = `Updates for ${convertDateIntoDDMMYYYY(
        userDetails?.date
      )}\nTotal Time Spent: ${totalWorkedTime} hours\n`;

      updateData?.formattedData?.forEach((project) => {
        // finalText += `=================================================================\n`;
        finalText += `\nProject: ${project["project.title"]}\n`;

        project?.data?.forEach((todo) => {
          finalText += `\nTask: ${todo["todo.description"]}\n`;
          finalText += `Task Status: ${
            todo.status === "pending"
              ? "In-progress"
              : todo?.status.charAt(0).toUpperCase() + todo?.status.slice(1)
          }\n`;
          finalText += `Time: ${convertSecondsIntoHHMM(
            todo.tracked_time
          )} hours\n`;
          finalText += `${todo?.memo
            ?.split(/\r\n|\n/)
            .map((line, index) => `- ${line.replace(/^[^a-zA-Z0-9]+/, "")}\n`)
            .join("")}\n`;
          if (todo.status === "pending") {
            finalText += `What needs to be done still?\n`;
          }
          if (
            inputText[`${project["project.title"]}_${todo["todo.description"]}`]
          ) {
            const needsTobeDone =
              inputText[
                `${project["project.title"]}_${todo["todo.description"]}`
              ];

            finalText += `${needsTobeDone
              ?.split(/\r\n|\n/)
              .map((line, index) => `- ${line.replace(/^[^a-zA-Z0-9]+/, "")}\n`)
              .join("")}\n`;
          }
        });
      });

      await copy(finalText);
      showSuccessToast("Copied to clipboard!");
    } catch (err) {
      showSuccessToast("Copy to clipboard failed.");
    }
  };

  const approveTimeSheetHandler = async () => {
    try {
      dispatch(setLoading(true));
      const params = getFetchInnerTimeSheetParams();

      const response = await fetchTimeSheetByDate(params);
      if (response.data.status === 200) {
        const timeSheetData = response.data.data;
        const timesheet_id = timeSheetData?.map((row) => row.id);

        const payload = { date: date, approved: "yes", timesheet_id };

        const response2 = await moveTimeSheet(payload);
        if (response2.data.status === 200) {
          fetchTimeSheetData();
          setTimeout(() => {
            closeModal();
            showSuccessToast("Timesheet approved successfully.");
          }, [300]);
        }
      } else {
        const errPayload = {
          code: response.data.status,
          status: "fail",
          message: response.data.message || "Something went wrong.",
        };
        dispatch(setFeedback(errPayload));
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const unApproveTimeSheetHandler = async () => {
    try {
      dispatch(setLoading(true));
      const params = getFetchInnerTimeSheetParams();

      // API integration start
      const response = await fetchTimeSheetByDate(params);
      const timeSheetData = response.data.data;
      const timesheet_id = timeSheetData.map((row) => row.id);

      const payload = { date: date, approved: "no", timesheet_id };
      const response2 = await moveTimeSheet(payload);
      if (response2.data.status === 200) {
        fetchTimeSheetData();
        setTimeout(() => {
          closeModal();
          showSuccessToast("Timesheet un-approved successfully.");
        }, [300]);
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [openModal]);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={closeModal}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={styled} className="modal-wrapper modal-bg">
            <Typography
              variant="h6"
              component="h6"
              className="text-black modal-title"
            >
              Work Updates
            </Typography>
            <Box className="modal-close" onClick={closeModal}>
              <FiPlus />
            </Box>
            <Box className="modal-body" ref={modalBodyRef}>
              <Typography
                variant="subtitle4"
                component="span"
                className="text-black input-label"
              >
                Updates for {convertDateIntoDDMMYYYY(userDetails.date)}
              </Typography>
              <Box mt={1}>
                <Typography
                  variant="subtitle2"
                  component="span"
                  className="text-black input-label"
                >
                  Employee Name :{" "}
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ display: "inline" }}
                  >
                    {userInfo?.first_name} {userInfo?.last_name}
                  </Typography>
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography
                  variant="subtitle2"
                  component="span"
                  className="text-black input-label"
                >
                  Total Time :{" "}
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ display: "inline" }}
                  >
                    {totalWorkedTime} hours
                  </Typography>
                </Typography>
              </Box>
              {updateData?.formattedData?.map((project, index) => (
                <Stack
                  key={index + "_update"}
                  direction="column"
                  container
                  style={{ marginTop: "5px" }}
                >
                  <Box direction="row" spacing={1}>
                    <Typography
                      variant="subtitle2"
                      component="span"
                      className="text-black input-label"
                    >
                      Project :{" "}
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{ display: "inline" }}
                      >
                        {project["project.title"]}
                      </Typography>
                    </Typography>
                  </Box>
                  {project?.data?.map((todo) => (
                    <Box
                      key={todo["todo.description"]}
                      className="work-task-card"
                    >
                      <Stack direction="row" spacing={1} mt={1}>
                        <Typography
                          variant="subtitle2"
                          component="span"
                          className="text-black input-label"
                        >
                          Task :{" "}
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{ display: "inline" }}
                          >
                            {todo["todo.description"]}
                          </Typography>
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          variant="subtitle2"
                          component="span"
                          className="text-black input-label"
                        >
                          Task Status :{" "}
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{ display: "inline" }}
                          >
                            {todo.status === "pending"
                              ? "In-progress"
                              : todo?.status.charAt(0).toUpperCase() +
                                todo?.status.slice(1)}
                          </Typography>
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          variant="subtitle2"
                          component="span"
                          className="text-black input-label"
                        >
                          Total Time :{" "}
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{ display: "inline" }}
                          >
                            {convertSecondsIntoHHMM(todo.tracked_time)} hours
                          </Typography>
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          variant="subtitle2"
                          component="span"
                          className="text-black input-label"
                        >
                          Billable Time :{" "}
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{ display: "inline" }}
                          >
                            {convertSecondsIntoHHMM(todo.billable)} hours
                          </Typography>
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          variant="subtitle2"
                          component="span"
                          className="text-black input-label"
                        >
                          Non-Billable Time :{" "}
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{ display: "inline" }}
                          >
                            {convertSecondsIntoHHMM(todo.non_billable)} hours
                          </Typography>
                        </Typography>
                      </Stack>
                      <Stack direction="column" mb={1}>
                        {todo.memo && (
                          <>
                            {todo?.memo?.split(/\r\n|\n/).map((todo, index) => (
                              <Typography
                                key={index + "_todo_text"}
                                variant="body2"
                                component="span"
                                className="text-grey input-label"
                              >
                                - {todo.replace(/^[^a-zA-Z0-9]+/, "")}
                              </Typography>
                            ))}
                          </>
                        )}
                        {todo.status === "pending" && (
                          <Stack direction="column" mt={1}>
                            <Typography
                              variant="subtitle2"
                              component="span"
                              className="text-black input-label"
                            >
                              What needs to be done still?
                            </Typography>
                            <BootstrapInput
                              multiline
                              placeholder="What is to be done further?"
                              variant="body2"
                              className={"input-field inprogress-textarea"}
                              value={
                                inputText[
                                  `${project["project.title"]}_${todo["todo.description"]}`
                                ] || ""
                              }
                              onChange={(e) => {
                                setInputText((prevInputTextMap) => ({
                                  ...prevInputTextMap,
                                  [`${project["project.title"]}_${todo["todo.description"]}`]:
                                    e.target.value,
                                }));
                              }}
                            />
                          </Stack>
                        )}
                      </Stack>
                    </Box>
                  ))}
                </Stack>
              ))}
            </Box>
            <Box className="modal-footer">
              <Grid container spacing={3}>
                {userRole === ROLES.manager || userData.is_assistant_manager ? (
                  <>
                    <Grid
                      item
                      md={
                        !isSameDay(date) &&
                        permissionsAccess.edit &&
                        ((userData?.user_role?.id === ROLES.manager &&
                          (isAssistantManager ||
                            timeSheetUserRole !== ROLES.manager) &&
                          isUserUnderAManager &&
                          userData.id !== timeSheetUserRoleId) ||
                          (userData.is_assistant_manager &&
                            isUserUnderAManager &&
                            userData.id !== timeSheetUserRoleId &&
                            timeSheetUserRole !== ROLES.manager))
                          ? 4
                          : 6
                      }
                      xs={12}
                    >
                      <Button
                        className="btn btn-primary"
                        onClick={handleCopyClick}
                      >
                        Copy
                      </Button>
                    </Grid>

                    {valueTab === 0 &&
                      !isSameDay(date) &&
                      permissionsAccess.edit &&
                      ((userData?.user_role?.id === ROLES.manager &&
                        // !userData.is_assistant_manager &&
                        userData.id !== timeSheetUserRoleId &&
                        (isAssistantManager ||
                          timeSheetUserRole !== ROLES.manager)) ||
                        (userData.is_assistant_manager &&
                          userData.id !== timeSheetUserRoleId &&
                          timeSheetUserRole !== ROLES.manager)) && (
                        <Grid item md={4} xs={12}>
                          <Button
                            className="btn btn-primary"
                            onClick={approveTimeSheetHandler}
                          >
                            Approve
                          </Button>
                        </Grid>
                      )}

                    {valueTab === 1 &&
                      !isSameDay(date) &&
                      permissionsAccess.edit &&
                      ((userData?.user_role?.id === ROLES.manager &&
                        //  !userData.is_assistant_manager &&
                        userData.id !== timeSheetUserRoleId &&
                        (isAssistantManager ||
                          timeSheetUserRole !== ROLES.manager)) ||
                        (userData.is_assistant_manager &&
                          userData.id !== timeSheetUserRoleId &&
                          timeSheetUserRole !== ROLES.manager)) && (
                        <Grid item md={4} xs={12}>
                          <Button
                            className="btn btn-primary"
                            onClick={unApproveTimeSheetHandler}
                          >
                            Un Approve
                          </Button>
                        </Grid>
                      )}

                    <Grid
                      item
                      md={
                        !isSameDay(date) &&
                        permissionsAccess.edit &&
                        ((userData?.user_role?.id === ROLES.manager &&
                          // !userData.is_assistant_manager &&
                          userData.id !== timeSheetUserRoleId &&
                          (isAssistantManager ||
                            timeSheetUserRole !== ROLES.manager)) ||
                          (userData.is_assistant_manager &&
                            userData.id !== timeSheetUserRoleId &&
                            timeSheetUserRole !== ROLES.manager))
                          ? 4
                          : 6
                      }
                      xs={12}
                    >
                      <Button
                        className="btn btn-secondary"
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item md={6} sm={6} xs={6}>
                      <Button
                        className="btn btn-primary"
                        onClick={handleCopyClick}
                      >
                        Copy
                      </Button>
                    </Grid>
                    <Grid item md={6} sm={6} xs={6}>
                      <Button
                        className="btn btn-secondary"
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ViewTimesheet;
