import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import InputBase from '@mui/material/InputBase';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Tooltip from '@mui/material/Tooltip';

import { FiCheckCircle, FiEdit3, FiPlus, FiTrash2 } from 'react-icons/fi';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { IoFolderOpenOutline } from "react-icons/io5";
import EditProjectToDo from '../../pages/ProjectToDos/EditProjectToDo';
import { useDispatch, useSelector } from 'react-redux';
import { setFeedback, setLoading } from '../../store/redux/actions/feedback.action';
import { deleteTodo, updateTodoComplete } from '../../store/redux/actions/todos.action';
import { ROLES } from '../../lib/constants';
import { truncateString } from '../../utils/helper';
import ProjectName from './ProjectName';
import TimeLineToDoDrawer from './TimeLineToDoDrawer';
import { fetchTodoById } from '../../apis/todos';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3), 
    }
}));

const customToolTipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontFamily: 'Poppins, sans-serif', 
            backgroundColor: 'var(--color-white)', 
            color: 'var(--color-grey)',
            fontSize: '14px',
            margin: '0',
            padding: '0',
            border: '1px solid var(--color-light-grey)'
          },
          arrow: {
            color: 'var(--color-white)',
            '&::before': {
                border: '1px solid var(--color-light-grey)', 
            },
          }
        },
      },
    },
});

const ProjecttoDosTableRow = ({
        projectInitial, 
        projectTitle, 
        todoId, 
        AssigneeInitial, 
        AssigneeTitle, 
        member, 
        ToDos, 
        Created, 
        Completed, 
        permissionsAccess, 
        valueTab
    }) => {
    const dispatch = useDispatch();
    const userRole = useSelector(state => state.user.data?.user_role?.id);
    const [validationErrors, setValidationErrors] = useState({});
    const [reasonInput, setReasonInput] = useState('');

    // deleteTodos account modal
    const [deleteData, setDeleteData] = useState('')
    const [deleteToDosModalOpen, setOpen] = useState(false);
    const deleteTodosModalOpen = (todoId) => {
        fetchSingleTodo(todoId);
        setOpen(true)
    };
    const deleteTodosModalClose = () => setOpen(false);

    const clearStateHandler = () => {
        setReasonInput("");
        setValidationErrors('');
        deleteTodosModalClose();
    }

    const fetchTodoForEditById = async (id) => {
        try {
            dispatch(setLoading(true));
            const response = await fetchTodoById(id);
            if(response.data.status === 200){
                setEditData(response.data.data);
            } else {
                const payload = { code: response.data.status, status:"fail", 
                message: response.data.message || "Something went wrong." };
                dispatch(setFeedback(payload));
            }
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(setLoading(false));
            const errPayload = { code: error.status, status: 'fail', 
            message:error.message || "Something went wrong." }
            dispatch(setFeedback(errPayload));  
        }
    }

    const fetchSingleTodo = async (id, flag) => {
        try {
            const payload = {
                timeline: true
            }
            if(flag){
                dispatch(setLoading(true));
                const response = await fetchTodoById(id, payload);
                if(response.data.status === 200){
                    setTimelineData(response.data.data);
                } else {
                    const payload = { code: response.data.status, status:"fail", 
                    message: response.data.message || "Something went wrong." };
                    dispatch(setFeedback(payload));
                }
                dispatch(setLoading(false));
            }
            else{
                const response = await fetchTodoById(id);
                if(response.data.status === 200){
                    setDeleteData(response.data.data);
                } else {
                    const payload = { code: response.data.status, status:"fail", 
                    message: response.data.message || "Something went wrong." };
                    dispatch(setFeedback(payload));
                }
            }
        } catch (error) {
            dispatch(setLoading(false));
            const errPayload = { code: error.status, status: 'fail', 
            message:error.message || "Something went wrong." }
            dispatch(setFeedback(errPayload));  
        }
    }

    const handleReasonInput = (event) => {
        setReasonInput(event.target.value);
        if(event.target.value === ""){
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                reasonInput: 'Please enter a Reason.',
            }));
            return;
        }
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            reasonInput: '',
        }));
    };

    const validateForm = () => {
        const errors = {};
        if (!reasonInput.trim()) {
            errors.reasonInput = 'Please enter a Reason.';
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
        try {
        const isValid = validateForm();

        if(isValid){
            const payload = {
                reason: reasonInput
            };
            dispatch(deleteTodo(deleteData.id, payload));
            clearStateHandler();
            // deleteTodosModalClose(); 
        }
        } catch (error) {
            const errPayload = { 
                code: error.status, 
                status: 'fail', 
                message: error.message || 'Something went wrong.' 
            };
            dispatch(setFeedback(errPayload));
        }
    };

    // editTodos account modal
    const [editData, setEditData] = useState('');
    const [editToDosModalOpen, editSetOpen] = useState(false);
    const editTodosModalOpen = (todoId) => {
        fetchTodoForEditById (todoId);
        editSetOpen(true);
    }
    const editTodosModalClose = () => editSetOpen(false);

    // editTodos account modal
    const [completeToDosModalOpen, completeSetOpen] = useState(false);
    const completeTodosModalOpen = (todoId) => {
        // fetchSingleTodo(todoId);
        completeSetOpen(true);
    }
    const completeTodosModalClose = () => completeSetOpen(false);

    const handleCompleted = async () => {
        try {
            const completedPayload = Completed === 'pending' ? "completed" : "pending";
            const payload = {
                id: todoId,
                status: completedPayload,
            };
            dispatch(updateTodoComplete(payload));
            completeTodosModalClose();
        } catch (error) {
            const errPayload = { 
                code: error.status, 
                status: 'fail', 
                message: error.message || 'Something went wrong.' 
            };
            dispatch(setFeedback(errPayload));
        }
    };

    const membersArray = [];
    for (let i = 0; i < member?.length; i++) {
        membersArray.push(member[i]);
    }

    //right side drawer for timeline
    const [timelineData, setTimelineData] = useState('');
    const [timeLineState, timeLineSetState] = useState(false);
    const handleTimeLineDrawerOpen = (todoId) => {
        fetchSingleTodo(todoId, true);
        timeLineSetState(true);
    }
    const handleTimeLineDrawerClose = () => {
        timeLineSetState(false);
    }

    return (
        <>
            <TableRow>
                <TableCell>
                    <ProjectName 
                        ProjectInitial={projectInitial} 
                        ProjectTitle={projectTitle} 
                    />
                </TableCell>
                <TableCell>{truncateString(ToDos, 50)}</TableCell>
                <TableCell>
                    <ThemeProvider theme={customToolTipTheme}>
                        <Tooltip
                            title={
                                <ul className='tooltip-list'>
                                     {membersArray.map((row, index) => (
                                        <Box key={row?.user_id}>
                                            <li>{row?.user?.first_name + ' ' + row?.user?.last_name}</li>
                                            {index !== membersArray.length - 1 && <hr className="divider" />}
                                        </Box>
                                    ))}
                                </ul>
                            }
                            placement='left-start'
                            arrow
                        >
                            <AvatarGroup max={3} className='avatar-group' sx={{cursor:'pointer'}} 
                            onClick={() => valueTab !== 1 && editTodosModalOpen(todoId)}>   
                                {membersArray.map((row, index) => (
                                    <Avatar className="avatar" alt={row ? `${row.toString()}_${index}` : `Avatar_${index}`} key={row} />
                                ))}
                            </AvatarGroup>
                        </Tooltip>
                    </ThemeProvider>
                </TableCell>
                <TableCell>{Created}</TableCell>
                { userRole !== ROLES.viewer ? 
                    <TableCell>
                        <Box className='table-action-btn'>
                            {
                                permissionsAccess.edit && valueTab === 0 && 
                                <Tooltip title='Edit To-do'>
                                    <Button className='btn btn-primary' onClick={() => editTodosModalOpen(todoId)}>
                                        <FiEdit3/>
                                    </Button>
                                </Tooltip>
                            }
                            { Completed === 'completed' && permissionsAccess.edit &&
                                <Tooltip title='Reopen To-do'>
                                    <Button className='btn btn-primary' onClick={()=>completeTodosModalOpen(todoId)}>
                                        <IoFolderOpenOutline/>
                                    </Button> 
                                </Tooltip>
                            }
                            { Completed === 'pending' && permissionsAccess.edit &&
                                <Tooltip title='Complete To-do'>
                                    <Button className='btn btn-primary' onClick={()=>completeTodosModalOpen(todoId)}>
                                        <FiCheckCircle/>
                                    </Button>
                                </Tooltip>
                            }
                            { permissionsAccess.delete && 
                                <Tooltip title='Remove To-do'>
                                    <Button className='btn btn-primary' onClick={()=>deleteTodosModalOpen(todoId)}>
                                        <FiTrash2/>
                                    </Button>
                                </Tooltip>
                            }
                            {
                                permissionsAccess.view && 
                                <Tooltip title='Timeline'>
                                    <Button className='btn btn-primary' onClick={()=>handleTimeLineDrawerOpen(todoId)}>
                                        <AccessAlarmIcon/>
                                    </Button>
                                </Tooltip>
                            }
                        </Box>
                    </TableCell> : <TableCell></TableCell>
                }
            </TableRow>

            {editToDosModalOpen && 
                <EditProjectToDo 
                    editToDosModalOpen={editToDosModalOpen}
                    editTodosModalClose={editTodosModalClose}
                    editData={editData} 
                />
            }

            {timeLineState &&
                <TimeLineToDoDrawer 
                    timeLineState={timeLineSetState}
                    handleTimeLineDrawerClose={handleTimeLineDrawerClose}
                    timelineData={timelineData}
                />    
            }
            
            {/* delete to dos modal */}
            {deleteToDosModalOpen && 
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={deleteToDosModalOpen}
                    onClose={clearStateHandler}
                    closeAfterTransition
                    slotProps={{
                    backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={deleteToDosModalOpen}>
                        <Box sx={styled} className='modal-wrapper modal-bg'>
                            <Typography variant="h6" component="h6" className='text-black modal-title' >
                                Delete To-Dos
                            </Typography>
                            <Box className='modal-close' onClick={clearStateHandler}>
                                <FiPlus/>
                            </Box>
                            <Box className='modal-body'>
                                <Box className='confirmation-text' sx={{marginBottom:'20px'}}>
                                    <Grid container spacing={2}>
                                        <Grid item md={12}>
                                            <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                                To-Do
                                            </Typography>
                                            <Typography variant="body2" component="span" className='text-grey'>
                                                {deleteData?.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <FormGroup className='form-field'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl variant="standard" className='form-control'>
                                                <Typography variant="body2" component="span" className='text-black input-label'>
                                                    Reason<Typography variant="body2" component="span" className="asterisk">*</Typography>
                                                </Typography>
                                                <BootstrapInput
                                                    multiline 
                                                    className={`input-field ${validationErrors.reasonInput && 'border-error'}`}
                                                    placeholder="Why are you deleting this to-do?" value={reasonInput} 
                                                    onChange={handleReasonInput}
                                                />
                                                {validationErrors.reasonInput && (
                                                    <span className="error-text">
                                                        {validationErrors.reasonInput}
                                                    </span>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </Box>
                            <Box className='modal-footer'>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={6}>
                                        <Button className='btn btn-tertiary' onClick={handleSubmit}>Delete</Button>
                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        <Button className='btn btn-secondary' onClick={clearStateHandler}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            }

            {/* complete to dos modal */}
            {completeToDosModalOpen && 
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={completeToDosModalOpen}
                    onClose={completeTodosModalClose}
                    closeAfterTransition
                    slotProps={{
                    backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={completeToDosModalOpen}>
                        <Box sx={styled} className='modal-wrapper modal-bg'>
                            <Typography variant="h6" component="h6" className='text-black modal-title' >
                                {Completed ==='pending' ?  'Mark As Complete To-Do' : 'Reopen To-Do'}
                            </Typography>
                            <Box className='modal-close' onClick={completeTodosModalClose}>
                                <FiPlus/>
                            </Box>
                            <Box className='modal-body'>
                                <Box className='confirmation-text' sx={{marginBottom:'20px'}}>
                                    <Typography paragraph className='text-black'>
                                        {Completed ==='pending' ? 'Are you sure want to move as completed this To-Do?' :'Are you sure want to repeon this To-Do?'}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className='modal-footer'>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={6}>
                                        <Button className='btn btn-primary' onClick={handleCompleted}>Yes</Button>
                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        <Button className='btn btn-secondary' onClick={completeTodosModalClose}>No</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            }
        </>
    );
}

export default ProjecttoDosTableRow;
   