import React, { useCallback, useEffect, useState } from 'react'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { fetchGlobalSettings, updateGlobalSettings } from '../../../apis/settings';
import { setFeedback } from '../../../store/redux/actions/feedback.action';
import { useDispatch } from 'react-redux';
import { showSuccessToast } from '../../../utils/toast';
import { TbInfoCircle } from "react-icons/tb";

const options = [
    { label: "10 minutes", value: 10 },
    { label: "15 minutes", value: 15 },
    { label: "20 minutes", value: 20 },
    { label: "25 minutes", value: 25 },
    { label: "30 minutes", value: 30 },
    { label: "40 minutes", value: 40 },
];

const TimeActivitySetting = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [globalSettingData, setGlobalSettingData] = useState([]);
    const [selectedTimeOption, setSelectedTimeOption] = useState();

    const handleSelectedTimeOption = (option) => {
        setSelectedTimeOption(option.value);
    };

    const fetchGlobalSettingsData = useCallback(async () => {
        try {
            const response = await fetchGlobalSettings();
            if(response.data.status === 200) {
                const result = response.data.data;
                setGlobalSettingData(result);
                setSelectedTimeOption(result.find(row => row.name.includes('time')).value);
            }
        } catch (error) {
            const errPayload = {
                code: error.status,
                status: "fail",
                message: error.message || "Something went wrong.",
            };
            dispatch(setFeedback(errPayload));
        }
    }, [dispatch]);
    
    useEffect(() => {
        fetchGlobalSettingsData();
    }, [fetchGlobalSettingsData]);

    const handleSubmit = async () => {
        try {
            let settingId = globalSettingData.find(row => row.name.includes('time')).id; 
            let settingValue = selectedTimeOption;
        
            const payload = {
                value: settingValue,
            };
            
            const response = await updateGlobalSettings(payload, settingId);
            if (response.data.status === 200) {
                showSuccessToast(response.data.message);
            } else {
                const feedbackpayload = {
                    code: response.data.status,
                    status: "fail",
                    message: response.data.message || "Something went wrong.",
                };
                dispatch(setFeedback(feedbackpayload));
            }
        } catch (error) {
            const errPayload = {
                code: error.status,
                status: "fail",
                message: error.message || "Something went wrong.",
            };
            dispatch(setFeedback(errPayload));
        }
    };

    return (
        <>
            <KeyboardBackspaceIcon style={{ fontSize : '36px', cursor:'pointer', fill: "#777", marginBottom:'16px' }} onClick={() => navigate("/settings")} className='setting-back-btn' />

            <Box className="card mb-0">
                <FormGroup className="form-field">
                    <FormControl
                        variant="standard"
                        className="form-control add-form-space"
                    >
                        
                        <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'baseline'} gap={'5px'}>
                            <Typography variant="body2"component="span"className="text-black input-label">
                                Ideal Time <TbInfoCircle style={{ color:'#777', width:'15px', height:'15px', cursor:'pointer' }} />
                            </Typography>
                            
                        </Stack>
                        <Select
                            className="select-time"
                            placeholder="Select Time"
                            value={options.find(
                                (option) => option.value === selectedTimeOption
                            )}
                            onChange={handleSelectedTimeOption}
                            options={options}
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                    // maxWidth: "1000px",
                                }),
                                }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 10,
                                colors: {
                                ...theme.colors,
                                primary25: "rgba(140, 199, 20, 0.4);",
                                primary: "#8CC714",
                                },
                            })}
                        />
                    </FormControl>
                </FormGroup>
                <Grid container spacing={2}>
                    <Grid item lg={2} md={3} xs={6}>
                        <Button className="btn btn-primary" sx={{maxWidth:'100%'}} onClick={handleSubmit}>
                            Save
                        </Button>
                    </Grid>
                    <Grid item lg={2} md={3} xs={6}>
                        <Button className="btn btn-secondary" sx={{maxWidth:'100%'}} onClick={() => navigate('/settings')}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default TimeActivitySetting