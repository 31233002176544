import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import styled from '@mui/system/styled';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SiteLogo from '../../assets/authentication/siteLogo.png';
// import { BsDownload } from "react-icons/bs";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import GuideSteps from './GuideSteps';
import WindowIcon from '@mui/icons-material/Window';
import AppleIcon from '@mui/icons-material/Apple';
import DownloadIcon from '@mui/icons-material/Download';

const DOWNLOAD_APP_LINK = process.env.REACT_APP_DOWNLOAD_APP_URL;
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
} 


const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#8CC714',
  },
  '& .MuiTab-root': {
    fontWeight: 500,
    fontSize:'16px',
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'capitalize',
  },
  '& .MuiTab-textColorInherit': {
    color: '#777777',
  },
});

const CustomTab = styled(Tab)(({ theme }) => ({
  '&.Mui-selected': {
    color: '#8CC714',
    borderBottom: `2px solid #8CC714`,
  },
  '&:hover': {
    // color: '#8CC714',
  },
}));


const DownloadApp = () => {

  const [isUsageClick, setIsUsageClick] = useState(false);

  const [valueTab, setValueTab] = useState(0);
  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };

  // download dropdown
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  // const downloadHandler = (OS) => {
  //   console.log(OS);
  //   const downloadLink = document.createElement('a');
  //   downloadLink.href = DOWNLOAD_APP_LINK;
  //   downloadLink.download = true;
  //   if(OS === "window"){
      
  //   } else if(OS === "mac_arm64"){
      
  //   } else if(OS === "mac_x64"){
      
  //   }
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   setAnchorEl(null);
  // }

  return (
    <>
      <Box className="card">
        {
          !isUsageClick ?
          <Box className="download-wrapper">
            <Stack py={6} justifyContent="center" alignItems="center" px={4} spacing={2} maxWidth="1200px" m="0 auto" p="0px"> 
              <Box className='site-logo'>
                <img src={SiteLogo} alt='Site logo' />
              </Box>
              <Typography variant='h5' component='h5' className='text-black download-title'>
                Download Our Desktop App
              </Typography>
              <Typography variant='body1' component='span' className='text-grey download-para' maxWidth="750px" textAlign="center">
                Are you ready to take control of your time, boost productivity, and
                gain invaluable insights into your daily tasks? Look no further! Our
                Time Tracking Desktop Application is here to simplify your workday.
              </Typography>
              {/* <Button className="btn btn-primary" variant="contained" href={DOWNLOAD_APP_LINK} download sx={{textDecoration: "none"}}> 
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center"> <BsDownload/> <span>Download App</span></Stack>
              </Button>        */}
              <Button 
                className="btn btn-primary" 
                variant="contained" 
                // startIcon={<BsDownload/>} 
                id="demo-positioned-button"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              > 
                Download App
              </Button> 
              <Menu
                className='download-dropdown-menu'
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.15))",
                    mt: 1.5,
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 14,
                      height: 14,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0
                    }
                  }
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              >
                <Box className="links">
                  <MenuItem onClick={handleClose}>
                    <a href={`${DOWNLOAD_APP_LINK}?platform=win`} download rel="noreferrer" className='menu-link'> 
                      <Box className="title"><WindowIcon /> Window </Box>
                      <DownloadIcon/>
                    </a>
                  </MenuItem>
                  <hr className='divider-border' />
                  <MenuItem onClick={handleClose}>
                    <a href={`${DOWNLOAD_APP_LINK}?arch=arm64&platform=mac`} download rel="noreferrer" className='menu-link'> 
                      <Box className="title"><AppleIcon />Mac arm64</Box> 
                      <DownloadIcon/>
                    </a>
                  </MenuItem>
                  <hr className='divider-border' />
                  <MenuItem onClick={handleClose}>
                    <a href={`${DOWNLOAD_APP_LINK}?arch=x64&platform=mac`} download rel="noreferrer" className='menu-link'> 
                      <Box className="title"><AppleIcon />Mac x64 </Box>
                      <DownloadIcon/>
                    </a>
                  </MenuItem>
                  {/* <MenuItem onClick={handleClose}>Linux</MenuItem> */}
                </Box>
              </Menu>
              <Box className='use-text' onClick={() => setIsUsageClick(true)}>
                <Typography>How to use?</Typography>
              </Box>
            </Stack>
            <Box className="download-details">
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CustomTabs value={valueTab} onChange={handleChangeTabs}  >
                  <CustomTab label={`Window`} {...a11yProps(0)} />
                  <CustomTab label={`Mac`} {...a11yProps(0)} />
                  <CustomTab label={`Linux`} {...a11yProps(1)} />
                </CustomTabs>
              </Box>
              {
                valueTab === 0 &&
                <Stack spacing={2}>   
                  <Typography variant='h4' component="h4" mt={4}>Step-by-Step Guide to Install CrestTracker App On Windows</Typography>
                  <ol>
                    <li className='li-items'>
                      <h4> Download Installer </h4>
                      <p>
                        Download the CrestTracker App installer by clicking on the above Download App button.
                      </p>
                    </li>
                    <li className='li-items'>
                      <h4> Install application </h4>
                      <p>
                        Locate the downloaded .exe file on your computer and double-click on the .exe file. Now you might face unknow publisher warning, just click on more info (if using window 10 or below) and click on run anyway.
                      </p>
                    </li>
                    <li className='li-items'>
                      <h4> Launch the Application </h4>
                      <p>
                        Application will launch automatically if you have selected a option "Run app" else you can search for application from windows search.
                      </p>
                    </li>
                  </ol>
                </Stack>
              }
              {
                valueTab === 1 &&
                <Stack spacing={2}>   
                  <Typography variant='h4' component="h4" mt={4}>Step-by-Step Guide to Install CrestTracker App On Mac</Typography>
                  <ol>
                    <li className='li-items'>
                      <h4> Download Installer </h4>
                      <p>
                        Download the CrestTracker DMG by selecting either x64 (for Intel processors) or arm64 (for M series processors) from the download app icon.
                      </p>
                    </li>
                    <li className='li-items'>
                      <h4> Open DMG File </h4>
                      <p>
                        Double-click on the DMG file. This action mounts the disk image and opens a window displaying the application's contents.
                      </p>
                    </li>
                    <li className='li-items'>
                      <h4> Install </h4>
                      <p>
                        Drag the application icon to the "Applications" folder icon and wait for the copying process to complete. The application is now copied to their "Applications" folder.
                      </p>
                    </li>
                    <li className='li-items'>
                      <h4> Eject DMG </h4>
                      <p>
                        Right-click on the DMG icon on desktop. Select "Eject" to unmount the disk image.
                      </p>
                    </li>
                    <li className='li-items'>
                      <h4> Open Application </h4>
                      <p>
                        Locate” CrestTracker inside applications" folder (you access it from the Dock or Finder). Double-click to launch application and go through a displayed guide to give required permission.
                      </p>
                    </li>
                  </ol>
                </Stack>
              }
              {
                valueTab === 2 &&
                <Stack spacing={2}>   
                  <Typography variant='h4' component="h4" mt={4}>Not Compatible for Linux</Typography>
                </Stack>
              }
            </Box>
          </Box>
          :
          <GuideSteps setIsUsageClick={setIsUsageClick} />
        }
      </Box>
    </>
  );
}

export default DownloadApp;