import React, { useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
// import { RiCheckboxCircleFill } from "react-icons/ri";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";

// import { Checkbox } from '@mui/material'
import Tooltip from "@mui/material/Tooltip";
import { FiEdit3, /*FiLogOut*/ FiPlus, FiTrash2 } from "react-icons/fi";
// import ProjectName from './ProjectName';
import { useDispatch, useSelector } from "react-redux";
import {
  leaveProject,
  deleteProject,
} from "../../store/redux/actions/project.action";
import {
  setFeedback,
  setLoading,
} from "../../store/redux/actions/feedback.action";
import { ROLES } from "../../lib/constants";
import { Hidden, List, ListItem, ListItemText } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

const customToolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: "Poppins, sans-serif",
          backgroundColor: "var(--color-white)",
          color: "var(--color-grey)",
          fontSize: "14px",
          margin: "0",
          padding: "0",
          border: "1px solid var(--color-light-grey)",
        },
        arrow: {
          color: "#f1f3f5",
          "&::before": {
            border: "1px solid var(--color-light-grey)",
          },
        },
      },
    },
  },
});

const ProjectListingTableRow = (props) => {
  const {
    isItemSelected,
    /*labelId, handleClick,*/
    PmsProjectId,
    ProjectId,
    ProjectInitial,
    editButtonHandler,
    ProjectTitle,
    Status,
    Billable,
    Member,
    LabelMembers,
    ToDos,
    LastActive,
    handleStatusChange,
    valueTab,
    membersButtonHandler,
    permissionsAccess,
  } = props;

  const dispatch = useDispatch();

  //for acccordian of hover
  const [accordionExpanded, setAccordionExpanded] = useState(null);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };

  const [show, setShow] = React.useState(false);
  //--------------------

  const userRole = useSelector((state) => state.user.data?.user_role?.id);
  const userId = useSelector((state) => state.user.data.id);

  // leave account modal
  const [leaveProjectModalOpen, setLeaveProjectModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const leaveModalOpen = () => setLeaveProjectModalOpen(true);
  const leaveModalClose = () => setLeaveProjectModalOpen(false);

  // delete project modal
  const [deleteProjectModalOpen, setDeleteProjectModalOpen] = useState(false);
  const deleteModalOpen = () => {
    setDeleteProjectModalOpen(true);
  };
  const deleteModalClose = () => setDeleteProjectModalOpen(false);

  // Leave project code
  const leaveProjectHandler = () => {
    try {
      const data = { userId, ProjectId };
      dispatch(leaveProject(data));
      leaveModalClose();
    } catch (error) {
      dispatch(setLoading(false));
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };

  function CustomTooltip({ name }) {
    return (
      <div className="custom-tooltip">
        <span>{name}</span>
      </div>
    );
  }

  // Delete Project Code
  const deleteProjectHandler = () => {
    try {
      const flag = valueTab === 0 ? "active" : "inactive";
      dispatch(deleteProject(ProjectId, flag));
      deleteModalClose();
    } catch (error) {
      dispatch(setLoading(false));
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };

  const editHandler = () => {
    editButtonHandler(ProjectId);
  };
  const memberHandler = () => {
    membersButtonHandler(ProjectId);
  };

  const label_members = { ...LabelMembers };
  let combinedValues = [];

  for (const key in label_members) {
    if (key === " 1" || key === " 3") {
      combinedValues = combinedValues.concat(LabelMembers[key]);
    }
  }

  label_members[" 1"] = combinedValues;
  delete label_members[" 3"];

  return (
    <>
      <TableRow
        hover
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
      >
        {/* {userRole === "admin" && <TableCell padding="checkbox">
                    <Checkbox className='table-checkbox'
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                        'aria-labelledby': labelId,
                        }}
                        onClick={(event) => handleClick(event, ProjectId)}
                        role="checkbox"
                        sx={{ cursor: 'pointer' }}
                    />
                </TableCell>} */}
        <TableCell>
          <Box style={{ display: "flex", gap: "8px" }}>
            {/* <ProjectName
                            ProjectInitial={ProjectInitial} 
                            ProjectTitle={ProjectTitle}
                        /> */}
            <Box className="project-name" style={{ minWidth: "0px" }}>
              <Box className="initial">
                <Typography
                  variant="body1"
                  component="span"
                  className="text-white"
                >
                  {ProjectInitial}
                </Typography>
              </Box>
              <Typography variant="h3" component="h3" className="text-grey">
                {ProjectTitle}
              </Typography>
            </Box>
            {PmsProjectId && (
              <Tooltip title="Synced from PMS" style={{ cursor: "pointer" }}>
                <PublishedWithChangesIcon
                  style={{
                    fill: "green",
                    fontSize: "20px",
                    position: "relative",
                    top: "4px",
                    left: "-2px",
                    marginRight: "3px",
                  }}
                />
              </Tooltip>
            )}
          </Box>
        </TableCell>
        {userRole === ROLES.admin && permissionsAccess.edit ? (
          <TableCell align="left">
            <Box className="mask-box-container">
              <Box className="mask-box">
                <Box
                  className="mask mask-1"
                  style={{
                    transform: `translateX(${
                      Status === "active" ? 0 : "80px"
                    })`,
                  }}
                />
                <Button
                  className="mask-btn mask-btn-1"
                  disableRipple
                  variant="text"
                  onClick={() =>
                    valueTab === 1 && handleStatusChange(ProjectId, "active")
                  }
                >
                  {" "}
                  Active{" "}
                </Button>
                <Button
                  className="mask-btn mask-btn-1"
                  disableRipple
                  variant="text"
                  onClick={() =>
                    valueTab === 0 && handleStatusChange(ProjectId, "inactive")
                  }
                >
                  {" "}
                  Archive{" "}
                </Button>
              </Box>
            </Box>
          </TableCell>
        ) : (
          <TableCell align="left" className={Status}>
            {Status?.charAt(0).toUpperCase() + Status?.slice(1)}
          </TableCell>
        )}
        <TableCell align="left">{Billable ? Billable : ""} </TableCell>
        <TableCell align="left">
          {Member.length ? (
            <ThemeProvider theme={customToolTipTheme}>
              <Tooltip
                open={show}
                title={
                  <Box>
                  {Object.entries(label_members).map(
                    ([key, value], index) => (
                      <>
                        <Box key={index}
                          >
                          <Accordion
                          className="td-accordion-wrapper"
                          onChange={handleAccordionChange(key)}
                            expanded={
                              accordionExpanded === key ||
                              (index === 0 && accordionExpanded === null)
                            }
                            sx={{
                              borderBottom: "2px solid #f1f3f5",
                              boxShadow:"0px 3px 6px 0px rgba(0, 0, 0, 0.2)",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{
                                backgroundColor: "#f1f3f5",

                                // Change this to the desired hover background color
                              }}
                            >
                              <Typography variant="h7">
                                {(key === " " + ROLES.admin ||
                                  key === " " + ROLES.manager) &&
                                  "Manager"}
                                {key === " " + ROLES.viewer && "Viewers"}
                                {key === " " + ROLES.employee && "Users"}
                                {key === "assistant_manager" &&
                                  "Associate manager \u00A0 "}
                              </Typography>
                            </AccordionSummary>
                            <Box className="tooltip-list">
                              <AccordionDetails
                                sx={{
                                  fontSize: "14px",
                                  paddingBottom: "0",
                                }}
                              >
                                {value.length > 0 ? (
                                  <>
                                  <List className="p-0 mb-1">
                                    {value.map((array, arrayIndex) => {
                                      const name = `${arrayIndex + 1}. ${
                                        array.first_name
                                      } ${array.last_name}`;

                                      return (
                                        <>
                                        
                                          {name.length >= 25 && (
                                            <ListItem className="m-0 p-0"
                                              key={arrayIndex}
                                              sx={{ color: "#777777" }}
                                              data-bs-toggle={
                                                name.length >= 25 && "tooltip"
                                              }
                                              data-bs-placement={
                                                name.length >= 25 && "right"
                                              }
                                              title={name}
                                            >
                                              {name.slice(0, 24) + "..."}
                                            </ListItem>
                                          )}
                                          {name.length < 25 && (
                                            // <List>
                                              <ListItem className="m-0 p-0"
                                                key={arrayIndex}
                                                sx={{ color: "#777777" }}
                                              >
                                              {name}
                                              </ListItem>
                                            // </List>
                                          )}
                                        </>
                                      );
                                    })}
                                    </List>
                                  </>
                                ) : (
                                  <List className="p-0 mb-1">
                                    <ListItem className="text-center d-block m-0 p-0"
                                      sx={{
                                        color: "#777777"
                                      }}
                                    >
                                      {(key === " " + ROLES.admin ||
                                        key === " " + ROLES.manager) &&
                                        "No Manager"}
                                      {key === " " + ROLES.viewer &&
                                        "No Viewers"}
                                      {key === " " + ROLES.employee &&
                                        "No Users"}
                                      {key === "assistant_manager" &&
                                        "No Associate Manager"}
                                    </ListItem>
                                  </List>
                                )}
                              </AccordionDetails>
                            </Box>
                          </Accordion>
                        </Box>
                      </>
                    )
                  )}
                </Box>
                }
                placement="left-start"
                arrow
                PopperProps={{
                  disablePortal: true,
                }}
                disableFocusListener
                onClose={(event) => {
                  event.preventDefault();
                  setShow(false);
                }}
                onOpen={() => setShow(true)}
              >
                <AvatarGroup
                  max={3}
                  className="avatar-group"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    if (userRole === ROLES.admin && valueTab === 0) {
                      setShow(false);
                      memberHandler();
                    }
                  }}
                >
                  {Member.map((row) => {
                    return (
                      <Avatar
                        key={`${row.user_id}_avatar`}
                        className="avatar"
                        alt={row.toString()}
                      />
                    );
                  })}
                </AvatarGroup>
              </Tooltip>
            </ThemeProvider>
          ) : (
            <Typography sx={{whiteSpace:"nowrap"}}>No-Members</Typography>
          )}
        </TableCell>
        <TableCell sx={{whiteSpace:"nowrap"}} align="left">{ToDos !== 0 ? ToDos : "No to-dos"}</TableCell>
        {userRole !== ROLES.admin && (
          <TableCell align="left">{LastActive}</TableCell>
        )}
        <TableCell align="left">
          <Box
            className="table-action-btn"
            style={{
              justifyContent: `${valueTab === 0 ? "center" : "center"}`,
            }}
          >
            {/* {
                            (userRole === ROLES.employee || userRole === ROLES.manager) && (permissionsAccess.edit) && 
                            <Button className='btn btn-primary' onClick={leaveModalOpen}>
                                <FiLogOut/>
                            </Button>
                        } */}
            {userRole === ROLES.admin &&
              permissionsAccess.edit &&
              valueTab === 0 && (
                <Tooltip title="Edit Project">
                  <Button className="btn btn-primary" onClick={editHandler}>
                    <FiEdit3 />
                  </Button>
                </Tooltip>
              )}
            {userRole === ROLES.admin &&
              permissionsAccess.delete &&
              valueTab === 1 && (
                <Tooltip title="Remove Project">
                  <Button className="btn btn-primary" onClick={deleteModalOpen}>
                    <FiTrash2 />
                  </Button>
                </Tooltip>
              )}
          </Box>
        </TableCell>
      </TableRow>

      {/* leave project modal start */}
      {leaveProjectModalOpen && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={leaveProjectModalOpen}
          onClose={leaveModalClose}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={leaveProjectModalOpen}>
            <Box sx={styled} className="modal-wrapper modal-bg">
              <Typography
                variant="h6"
                component="h6"
                className="text-black modal-title"
              >
                Leave Project
              </Typography>
              <Box className="modal-close" onClick={leaveModalClose}>
                <FiPlus />
              </Box>
              <Box className="modal-body">
                <Box className="confirmation-text">
                  <Typography paragraph>
                    Are you sure want to leave this project?
                  </Typography>
                </Box>
              </Box>
              <Box className="modal-footer">
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Button
                      className="btn btn-primary"
                      onClick={leaveProjectHandler}
                    >
                      Yes
                    </Button>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      className="btn btn-secondary"
                      onClick={leaveModalClose}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
      {/* leave project modal end */}

      {/* delete project modal start */}
      {deleteProjectModalOpen && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={deleteProjectModalOpen}
          onClose={deleteModalClose}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={deleteProjectModalOpen}>
            <Box sx={styled} className="modal-wrapper modal-bg">
              <Typography
                variant="h6"
                component="h6"
                className="text-black modal-title"
              >
                Delete Project
              </Typography>
              <Box className="modal-close" onClick={deleteModalClose}>
                <FiPlus />
              </Box>
              <Box className="modal-body">
                <Box className="confirmation-text">
                  <Typography paragraph>
                    Are you sure want to delete this project?
                  </Typography>
                </Box>
              </Box>
              <Box className="modal-footer">
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Button
                      className="btn btn-primary"
                      onClick={deleteProjectHandler}
                    >
                      Yes
                    </Button>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      className="btn btn-secondary"
                      onClick={deleteModalClose}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
      {/* delete project modal end */}
    </>
  );
};

export default ProjectListingTableRow;
