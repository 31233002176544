import React, { useLayoutEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styled from "@mui/system/styled";
import PropTypes from "prop-types";
import { FiPlus } from "react-icons/fi";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMembers,
  updateProject,
} from "../../store/redux/actions/project.action";
import { setFeedback } from "../../store/redux/actions/feedback.action";
import { getNormalColorStyle } from "../../utils/helper";
import { ROLES } from "../../lib/constants";

const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#8CC714",
  },
  "& .MuiTab-root": {
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
    textTransform: "capitalize",
  },
  "& .MuiTab-textColorInherit": {
    color: "#777777",
  },
});

const CustomTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    color: "#8CC714",
    borderBottom: `2px solid #8CC714`,
  },
  "&:hover": {
    // color: '#8CC714',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
}));

const EditProject = ({
  editProjectModalIsOpen,
  editProjectModalClose,
  editProject,
  isMemberClick,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data);
  const userRole = userData?.user_role?.id;
  const { members } = useSelector((state) => state.project);

  const ViewerOptions = members?.viewer?.map((item) => {
    return { value: item.id, label: `${item.first_name} ${item.last_name}` };
  });

  const oldMembers = [
    ...editProject.members[1].map((item) => item.id),
    ...editProject.members[3].map((item) => item.id),
    ...editProject.members[2].map((item) => item.id),
    ...editProject.members[4].map((item) => item.id),
  ];

  //state for tabs
  const [valueTab, setValueTab] = useState(isMemberClick);
  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };

  // form states
  const [formData, setFormData] = useState({
    projectName: editProject.title,
    description: editProject.description,
    client: editProject.client,
    billable: editProject.billable,
    disableActivity: false,
  });

  const admin_manager = [...editProject.members[1], ...editProject.members[3]]; //change i think only manager not Associatemanager
  const user_manager = [...editProject.assistant_managers]; //replace with editProject.AssociateManager
  const [managers, setManagers] = useState(
    admin_manager.map((item) => {
      return { value: item.id, label: `${item.first_name} ${item.last_name}` };
    })
  );
  const [associateManagers, setAssociateManagers] = useState(
    user_manager.map((item) => {
      return { value: item.id, label: `${item.first_name} ${item.last_name}` };
    })
  );
  const [users, setUsers] = useState(
    editProject.members[2].map((item) => {
      return { value: item.id, label: `${item.first_name} ${item.last_name}` };
    })
  );
  const [viewers, setViewers] = useState(
    editProject.members[4].map((item) => {
      return { value: item.id, label: `${item.first_name} ${item.last_name}` };
    })
  );

  let ManagerOptions = members?.manager?.filter(
    (item) => !associateManagers.map((m) => m.value).includes(item.id)
  );
  ManagerOptions = ManagerOptions?.map((item) => {
    return { value: item.id, label: `${item.first_name} ${item.last_name}` };
  });

  let AssociateManagerOptions = members?.assistantManager
    ?.filter((item) => !managers.map((m) => m.value).includes(item.id))
    .filter((item) => !users.map((m) => m.value).includes(item.id));
  AssociateManagerOptions = AssociateManagerOptions?.map((item) => {
    return { value: item.id, label: `${item.first_name} ${item.last_name}` };
  });

  let UserOptions = members?.employee?.filter(
    (item) => !associateManagers.map((m) => m.value).includes(item.id)
  );
  UserOptions = UserOptions?.map((item) => {
    return { value: item.id, label: `${item.first_name} ${item.last_name}` };
  });
  const [validationErrors, setValidationErrors] = useState({
    projectNameError: "",
    descriptionError: "",
    clientError: "",
  });

  useLayoutEffect(() => {
    const fetchMembersHandler = async () => {
      if (userRole === ROLES.admin) {
        try {
          dispatch(fetchMembers());
        } catch (error) {
          const errPayload = {
            code: error.status,
            status: "fail",
            message: error.message || "Something went wrong.",
          };
          dispatch(setFeedback(errPayload));
        }
      }
    };
    fetchMembersHandler();
  }, [dispatch, userRole]);

  const changeFormDataHandler = (e) => {
    const name = e.target.name.trim();
    const value = e.target.value.trimLeft();
    if (name === "disableActivity") {
      setFormData((prevState) => {
        return { ...prevState, [name]: e.target.checked };
      });
    } else {
      setFormData((prevState) => {
        return { ...prevState, [name]: value };
      });
    }
  };
  const changeBillableHandler = (value) => {
    setFormData((prevState) => {
      return { ...prevState, billable: value };
    });
  };

  const blurFormDataHandler = (e) => {
    const name = e.target.name.trim();
    const value = e.target.value.trim();
    if (value === "") {
      if (name === "projectName") {
        setValidationErrors((prev) => {
          return { ...prev, projectNameError: "Please enter project name." };
        });
      }
      if (name === "description") {
        setValidationErrors((prev) => {
          return {
            ...prev,
            descriptionError: "Please enter project description.",
          };
        });
      }
      if (name === "client") {
        setValidationErrors((prev) => {
          return { ...prev, clientError: "Please enter client name." };
        });
      }
    } else {
      if (name === "projectName") {
        setValidationErrors((prev) => {
          return { ...prev, projectNameError: "" };
        });
      }
      if (name === "description") {
        setValidationErrors((prev) => {
          return { ...prev, descriptionError: "" };
        });
      }
      if (name === "client") {
        setValidationErrors((prev) => {
          return { ...prev, clientError: "" };
        });
      }
    }
  };

  const changeManagerHandler = (selectedOptions) => {
    setManagers(selectedOptions);
  };
  const changeAssociateManagerHandler = (selectedOptions) => {
    setAssociateManagers(selectedOptions);
  };
  const changeUsersHandler = (selectedOptions) => {
    setUsers(selectedOptions);
  };
  const changeViewersHandler = (selectedOptions) => {
    setViewers(selectedOptions);
  };

  const resetAllData = () => {
    setFormData({
      projectName: "",
      description: "",
      client: "",
      billable: true,
      disableActivity: false,
    });
    setManagers([]);
    setAssociateManagers([]);
    setUsers([]);
    setViewers([]);
    setValidationErrors({
      projectNameError: "",
      descriptionError: "",
      clientError: "",
    });
  };

  const UpdateProjectHandler = (e) => {
    try {
      e.preventDefault();
      setValidationErrors({
        projectNameError: "",
        descriptionError: "",
        clientError: "",
      });
      if (!formData.projectName.trim()) {
        setValidationErrors((prevState) => {
          return {
            ...prevState,
            projectNameError: "Please enter project name.",
          };
        });
        setValueTab(0);
        return;
      }
      if (!formData.description.trim()) {
        setValidationErrors((prevState) => {
          return {
            ...prevState,
            descriptionError: "Please enter project description.",
          };
        });
        setValueTab(0);
        return;
      }
      if (!formData.client.trim()) {
        setValidationErrors((prevState) => {
          return { ...prevState, clientError: "Please enter client name." };
        });
        setValueTab(0);
        return;
      }

      //while update,first show old Associate managers and when clcik update, send only added new Associate_managers to backend
      const associate_managers_oldids = editProject.assistant_managers.map(
        (am) => am.id
      );
      const associate_managers_ids = associateManagers.map((am) => am.value);

      const addAssociateManagers = associate_managers_ids.filter(
        (value) => !associate_managers_oldids.includes(value)
      );
      const deleteAssociateManagers = associate_managers_oldids.filter(
        (value) => !associate_managers_ids.includes(value)
      );

      const newMembers = [
        ...managers.map((item) => item.value),
        ...users.map((item) => item.value),
        ...viewers.map((item) => item.value),
      ];

      const addedValues = newMembers.filter(
        (value) => !oldMembers.includes(value)
      );

      const deletedValues = oldMembers.filter(
        (value) => !newMembers.includes(value)
      );

      const payload = {
        id: editProject.id,
        title: formData.projectName,
        description: formData.description,
        client: formData.client,
        billable: formData.billable,
        deleteMembers: deletedValues,
        addMembers: addedValues,
        addAssistantManagers: addAssociateManagers,
        deleteAssistantManagers: deleteAssociateManagers,
      };

      dispatch(updateProject(payload));

      // dispatch(fetchProjects());
      resetAllData();
      editProjectModalClose();
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={editProjectModalIsOpen}
        onClose={editProjectModalClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={editProjectModalIsOpen}>
          <Box sx={styled} className="modal-wrapper modal-bg">
            <Typography
              variant="h6"
              component="h6"
              className="text-black modal-title"
            >
              Edit Project
            </Typography>
            <Box className="modal-close" onClick={editProjectModalClose}>
              <FiPlus />
            </Box>

            <Box className="modal-body" sx={{marginTop:"8px !important"}}>
              <Box sx={{ width: "100%" }}>
                <Box>
                  <CustomTabs value={valueTab} onChange={handleChangeTabs}>
                    <CustomTab label="General" {...a11yProps(0)} />
                    <CustomTab label="Members" {...a11yProps(1)} />
                  </CustomTabs>
                </Box>
              </Box>
              <br />

              <TabPanel value={valueTab} index={0}>
                <FormGroup className="form-field">
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormControl variant="standard" className="form-control">
                        <Typography
                          variant="body2"
                          component="span"
                          className="text-black input-label"
                        >
                          Project Name
                          <Typography
                              variant="body2"
                              component="span"
                              className="asterisk"
                          >
                              *
                          </Typography>
                        </Typography>
                        <BootstrapInput
                          name="projectName"
                          placeholder="Enter project name"
                          className={
                            !validationErrors.projectNameError
                              ? "input-field"
                              : "border-error"
                          }
                          value={formData.projectName}
                          onChange={changeFormDataHandler}
                          onBlur={blurFormDataHandler}
                        />
                        {validationErrors.projectNameError && (
                          <p className="error-text">
                            &nbsp;{validationErrors.projectNameError}
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                    {/*  */}
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormControl variant="standard" className="form-control">
                        <Typography
                          variant="body2"
                          component="span"
                          className="text-black input-label"
                        >
                          Project Description<Typography
                              variant="body2"
                              component="span"
                              className="asterisk"
                          >
                              *
                          </Typography>
                        </Typography>
                        <BootstrapInput sx={{padding:"0"}}
                          name="description"
                          multiline
                          placeholder="Description"
                          className={
                            !validationErrors.descriptionError
                              ? "input-field"
                              : "border-error"
                          }
                          value={formData.description}
                          onChange={changeFormDataHandler}
                          onBlur={blurFormDataHandler}
                        />
                        {validationErrors.descriptionError && (
                          <p className="error-text">
                            &nbsp;{validationErrors.descriptionError}
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormControl variant="standard" className="form-control">
                        <Typography
                          variant="body2"
                          component="span"
                          className="text-black input-label"
                        >
                          Client
                          <Typography
                              variant="body2"
                              component="span"
                              className="asterisk"
                          >
                              *
                          </Typography>
                        </Typography>
                        <BootstrapInput
                          name="client"
                          placeholder="Select a client"
                          className={
                            !validationErrors.clientError
                              ? "input-field"
                              : "border-error"
                          }
                          value={formData.client}
                          onChange={changeFormDataHandler}
                          onBlur={blurFormDataHandler}
                        />
                        {validationErrors.clientError && (
                          <p className="error-text">
                            &nbsp;{validationErrors.clientError}
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormControl
                        variant="standard"
                        className="form-control"
                      >
                        <Typography
                          variant="body2"
                          component="span"
                          className="text-black input-label"
                        >
                          &nbsp;Is it Billable
                            <Typography
                              variant="body2"
                              component="span"
                              className="asterisk"
                          >
                              *
                          </Typography>
                        </Typography>
                        <Box className="mask-box-container">
                          <Box className="mask-box">
                            <Box
                              className="mask mask-2"
                              style={{
                                transform: `translateX(${
                                  formData.billable === "yes" ? 0 : "100px"
                                })`,
                              }}
                            />
                            <Button
                              className="mask-btn mask-btn-2"
                              disableRipple
                              variant="text"
                              onClick={(e) => changeBillableHandler("yes")}
                            >
                              Billable
                            </Button>
                            <Button
                              className="mask-btn mask-btn-2"
                              disableRipple
                              variant="text"
                              onClick={(e) => changeBillableHandler("no")}
                            >
                              Non-Billable
                            </Button>
                          </Box>
                        </Box>
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormGroup>
              </TabPanel>

              <TabPanel value={valueTab} index={1}>
                <FormGroup className="form-field">
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormControl variant="standard" className="form-control">
                        <Typography
                          variant="body2"
                          component="span"
                          className="text-black input-label"
                        >
                          Manager <Typography variant="body2" component="span" color={"GrayText"} sx={{fontSize:"14px"}}>(Oversees and manages the project)</Typography>
                        </Typography>
                        <Select
                          name="managers"
                          value={managers}
                          onChange={changeManagerHandler}
                          placeholder="Select Managers"
                          className="Select-filter"
                          defaultValue={""}
                          options={ManagerOptions}
                          isMulti
                          classNamePrefix="select"
                          styles={getNormalColorStyle("MULTI")}
                          isClearable={false}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 10,
                            colors: {
                              ...theme.colors,
                              primary25: "rgba(140, 199, 20, 0.4);",
                              primary: "#8CC714",
                            },
                          })}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormControl variant="standard" className="form-control">
                        <Typography
                          variant="body2"
                          component="span"
                          className="text-black input-label"
                        >
                          Associate Manager <Typography variant="body2" component="span" color={"GrayText"} sx={{fontSize:"14px"}}>(Oversees and manages the project under manager)</Typography>
                        </Typography>
                        <Select
                          name="associateManagers"
                          value={associateManagers}
                          onChange={changeAssociateManagerHandler}
                          placeholder="Select Associate Managers"
                          className="Select-filter"
                          defaultValue={""}
                          options={AssociateManagerOptions}
                          isMulti
                          classNamePrefix="select"
                          styles={getNormalColorStyle("MULTI")}
                          isClearable={false}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 6,
                            colors: {
                              ...theme.colors,
                              primary25: "rgba(140, 199, 20, 0.4);",
                              primary: "#8CC714",
                            },
                          })}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormControl variant="standard" className="form-control">
                        <Typography
                          variant="body2"
                          component="span"
                          className="text-black input-label"
                        >
                          User <Typography variant="body2" component="span" color={"GrayText"} sx={{fontSize:"14px"}}>(Works in the projects, will not see other users (most
                          common))</Typography>
                        </Typography>
                        <Select
                          name="users"
                          value={users}
                          onChange={changeUsersHandler}
                          placeholder="Select Users"
                          className="Select-filter"
                          defaultValue={""}
                          options={UserOptions}
                          isMulti
                          classNamePrefix="select"
                          styles={getNormalColorStyle("MULTI")}
                          isClearable={false}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 10,
                            colors: {
                              ...theme.colors,
                              primary25: "rgba(140, 199, 20, 0.4);",
                              primary: "#8CC714",
                            },
                          })}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormControl variant="standard" className="form-control">
                        <Typography
                          variant="body2"
                          component="span"
                          className="text-black input-label"
                        >
                          Viewer <Typography variant="body2" component="span" color={"GrayText"} sx={{fontSize:"14px"}}>(Can view team reports for this project)</Typography>
                        </Typography>
                        <Select
                          name="viewers"
                          value={viewers}
                          onChange={changeViewersHandler}
                          placeholder="Select Viewers"
                          className="Select-filter"
                          defaultValue={""}
                          options={ViewerOptions}
                          isMulti
                          classNamePrefix="select"
                          styles={getNormalColorStyle("MULTI")}
                          isClearable={false}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 10,
                            colors: {
                              ...theme.colors,
                              primary25: "rgba(140, 199, 20, 0.4);",
                              primary: "#8CC714",
                            },
                          })}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormGroup>
              </TabPanel>
            </Box>
            <Box className="modal-footer">
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Button
                    className="btn btn-primary"
                    onClick={UpdateProjectHandler}
                  >
                    Update
                  </Button>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Button
                    className="btn btn-secondary"
                    onClick={() => {
                      resetAllData();
                      editProjectModalClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default EditProject;
