import { FETCH_RECENT_SCREEN_SORT, FETCH_TODO_PROJECT_STORE, FETCH_WORK_TODAY_ACTIVITY_STORE } from "../actionTypes";

export const fetchWorkActivity = (payload) => {
    return { type: FETCH_WORK_TODAY_ACTIVITY_STORE, payload };
}

export const fetchTodoProject = (payload) => {
    return { type: FETCH_TODO_PROJECT_STORE, payload };
}

export const fetchRecentScreenSort = (payload) => {
    return { type: FETCH_RECENT_SCREEN_SORT, payload };
}