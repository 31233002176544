import React, { useState, useCallback, useEffect, useMemo } from "react";
import Select from "react-select";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Drawer from '@mui/material/Drawer';
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import styled from '@mui/system/styled';
import { FiDownloadCloud, FiFilter, FiPlus, FiSearch, FiEdit3, FiTrash2 } from "react-icons/fi";
import { DateRangePicker } from 'rsuite';
import { useDispatch, useSelector } from "react-redux";
import ReportActivityCard from "./ReportActivityCard";
import { fetchProjects } from "../../store/redux/actions/project.action";
import { setFeedback, setLoading } from "../../store/redux/actions/feedback.action";
import { fetchTodos } from "../../store/redux/actions/todos.action";
import { addFilter, deleteFilter, fetchFilters, fetchReports, updateFilter } from "../../store/redux/actions/reports.action";
import  KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { fetchFilterById } from "../../apis/reports";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { fetchEmployee } from "../../apis/employee";
import { convertDateIntoYYYYMMDD, getFilterStorage, getPermissionAccess, handleLabel, setFilterStorage, getDateRangeOptions, getDotColorStyle, getNormalColorStyle, getLocalTimeZone } from "../../utils/helper";
import { FETCH_REPORTS_API, ROLES, storageName } from "../../lib/constants";
import debounce from "lodash.debounce";
import { BiSortAlt2 } from "react-icons/bi";

const { afterToday } = DateRangePicker;
const ranges = getDateRangeOptions();

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}  

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className="filter-content">{children}</Box>}
    </div>
  );
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
}));

const TimeAndActivity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filtersData = useSelector(state => state.reports.filters);
  const storeFilterData = getFilterStorage(storageName.timeActivity);

  const projects = useSelector(state => state.project.projects_list);
  const todos = useSelector(state => state.todos.todos.todos);
  const projectOptions = projects?.map(proj => (
    { value: proj.id, label: proj.title}
  ));

  const userData = useSelector(state => state.user.data);
  const permissions = userData.role_permissions.permissions;
  const userRole = userData?.user_role?.id;

  const permissionsAccess = getPermissionAccess(permissions, "Reports");
  if(!permissionsAccess.view && !permissionsAccess.add && !permissionsAccess.edit && !permissionsAccess.delete){
    navigate('/', { replace: true });
  }

  let options = [
    { value: "date", label: "Date" },
    { value: "project", label: "Project" },
    { value: "todo", label: "To-do" }
  ];

  if (userRole !== ROLES.employee) {
    options = [
      ...options,
      { value: "member", label: "Members" },
      // { value: "client", label: "Clients" },
    ];
  }

  // eslint-disable-next-line no-unused-vars
  let [todoOptions, setTodoOption] = useState([]);
  // let [userOptions, setUserOption] = useState([]);
  const [editData, setEditData] = useState('');
  const [selectedOption, setSelectedOption] = useState(storeFilterData?.group_by || options[0]);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(-1);
  // const [selectedClient, setSelectedClient] = useState(null);
  const [selectedProject, setSelectedProject] = useState(storeFilterData?.project || []);
  const [selectedTodos, setSelectedTodos] = useState(storeFilterData?.todo || []);
  // const [selectedUserOption, setSelectedUserOption] = useState([]);
  const [filterDescription, setFilterDescription] = useState('');
  const [filterName, setFilterName] = useState('');
  const [assignee, setAssignee] = useState([]);
  const [sortValue, setSortValue] = useState(storeFilterData?.order_by || "desc");
  // const [dataString, setDataString] = useState('');
  const [state, setState] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [selectedBillable, setSelectedBillable] = useState(storeFilterData?.billable || "");
  const billableOption = [
    { value: "", label: "All" },
    { value: "yes", label: "Billable" },
    { value: "no", label: "Non-Billable" },
  ]

  const initialSelectedDates = [new Date(), new Date()];
    const [selectedDates, setSelectedDates] = useState(
      storeFilterData && storeFilterData.selectedDates && storeFilterData.selectedDates[0] && storeFilterData.selectedDates[1]
        ? [new Date(storeFilterData.selectedDates[0]), new Date(storeFilterData.selectedDates[1])]
        : initialSelectedDates
  );

  const assigneeOption = useMemo(() => {
    let options = [];
    if(userRole === ROLES.admin){
      assignee?.forEach((row) => {
        if(row.status === "active"){
          options.push(handleLabel(true, row.id, `${row.first_name} ${row.last_name}`))
        } else {
          options.push(handleLabel(false, row.id, `${row.first_name} ${row.last_name}`))
        }
      })
    } else if (userRole === ROLES.manager || userRole === ROLES.viewer) {
      assignee?.forEach((row) => {
        let flag = 0;
        if(row.status !== "active"){
          flag = 1;
        } else {
          row.project_assignments.some((row2) => {
            if(row2.deletedAt === null){
              flag = 0;
              return true;
            } else {
              flag = 1;
              return false;
            }
          })
        }

        if(flag === 0){
          options.push(handleLabel(true, row.id, `${row.first_name} ${row.last_name}`))
        } else {
          options.push(handleLabel(false, row.id, `${row.first_name} ${row.last_name}`))
        }
      })
    } else {
      options = [];
    }
    return options;
  }, [assignee, userRole]);

  const [selectedAssignee, setSelectedAssignee] = useState(storeFilterData?.user || '');
  const fetchAssignee = useCallback(async () => {
    try {
      const params = { timesheet: 1 };
      const response = await fetchEmployee(params);
      setAssignee(response.data.data);
    } catch (error) {
      const errPayload = { 
        code: error.status, 
        status: 'fail', 
        message:error.message || "Something went wrong." 
      };
      dispatch(setFeedback(errPayload));  
    }
  }, [dispatch]);

  const handleSelectedUser = (selectedOptions) => {
    setSelectedAssignee(selectedOptions.map(option => option.value));
  };

  const todosOptions = selectedProject.length > 0 ? todos?.map(todo => (
    { value: todo.id, label: todo.description }
  )) : [];

  const handleDateRangeChange = (value) => {
    if (value !== null) {
      setSelectedDates(value);
    } else {
      setSelectedDates(initialSelectedDates); 
    }
  };

  const handleFilterName = (e) => {
    setFilterName(e.target.value);
  };

  const handleFilterDescription = (e) => {
    setFilterDescription(e.target.value);
  };

  const changeSortOrderHandler = () => {
    setSortValue((prev) => prev === "asc" ? "desc" : "asc");
  }

  const [searchValue, setSearchValue] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((params) => {
      // console.log('params', params)
      dispatch(fetchFilters(params));
    }, 800),
    [fetchTodos]
  );

  const changeSearchValueHandler = async (e) => {
    try {
      setSearchValue(e.target.value);
      const params = { name: e.target.value.trim().replace(/\s+/g, ' ') };
      debouncedSearch(params);
    } catch (error) {
      const errPayload = { 
        code: error.status, 
        status: 'fail', 
        message:error.message || "Something went wrong." 
      };
      dispatch(setFeedback(errPayload));  
    }
  }

  const fetchFiltersData = useCallback(async () => {
    try {
      dispatch(fetchFilters());
    } catch (error) {
      const errPayload = { 
        code: error.status, 
        status: 'fail', 
        message:error.message || "Something went wrong." 
      };
      dispatch(setFeedback(errPayload));  
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProjectsData = useCallback( async () => {
    try {
      const params= { listing: true, isListing: true };
      dispatch(fetchProjects(params));
    } catch (error) {
      const errPayload = { 
        code: error.status, 
        status: 'fail', 
        message:error.message || "Something went wrong." 
      };
      dispatch(setFeedback(errPayload));  
    }
  },[dispatch]);

  const fetchTodosByProject = useCallback( async () => {
    try{
      let assignees = [];
      assignees.push(userData.id);
      const params = { project: selectedProject, listing: true};
      if(userRole === ROLES.employee)
      {
        params.assignee = assignees
      }
      dispatch(fetchTodos(params));
    }catch(error){
      const errPayload = { 
        code: error.status, 
        status: 'fail', 
        message:error.message || "Something went wrong." 
      };
      dispatch(setFeedback(errPayload));  
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch, selectedProject, userRole.user_id, userRole.user_role])

  useEffect(() => {
    fetchFiltersData();
  },[fetchFiltersData])
  
  useEffect(() => {
    fetchProjectsData();
    fetchAssignee();
    if (selectedProject.length > 0) {
      fetchTodosByProject();
      // fetchUsersByProjectData();
    }
  }, [fetchAssignee, fetchProjectsData, fetchTodosByProject, selectedProject.length]);  

  const handleSelectedProject = (selectedOption) => {
    if (selectedOption) {
      const selectedProjectId = selectedOption.map(option => option.value);
      setSelectedProject(selectedProjectId);
      if (selectedProjectId.length === 0) {
        setSelectedTodos([]);
        setTodoOption([]);
        // setSelectedUserOption('');
        // setUserOption([]);
      } else {
        setSelectedTodos([]);
        setTodoOption([]); 
        // setSelectedUserOption([]); 
      }
    }
  };
  
  const handleSelectedTodo = (selectedOption) => {
    if (selectedOption) {
      const selectedTodoIds = selectedOption.map(option => option.value);
      setSelectedTodos(selectedTodoIds);
    }
  }; 

  const handleSelectedBillable = (selectedOption) => {
    if (selectedOption) {
      setSelectedBillable(selectedOption.value);
    }
  }; 

  const clearFilters = () => {
    const today = new Date(); 
    let initialDate = [today, today];

    setFilterStorage(storageName.timeActivity, {
      // start_date: convertDateIntoYYYYMMDD(initialDate[0]),
      // end_date: convertDateIntoYYYYMMDD(initialDate[1]),
      selectedDates,
      group_by: options[0],
      order_by : "desc", 
      project: [],
      todo: [],
      billable: ""
    });

    setSelectedProject([]);
    setSelectedTodos([]);
    // setSelectedUserOption('');
    // setUserOption([]);
    setSelectedAssignee('');
    setSelectedDates(initialDate);
    setSelectedOption(options[0]);
    setFilterName('');
    setFilterDescription('');
    setSelectedFilterIndex(-1);
    setSelectedBillable("");
    
    handleDrawerClose();
  }

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const modalOpen = () => {
    setIsOpen(true);
  };

  const modalClose = () => {
    setIsOpen(false);
  };

  const handleDrawerOpen = () => {
    setState(true);
  };
  const handleDrawerClose = () => {
    setState(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchReportTopFilter = useCallback(async ()=>{
    try {
      const params = {
        
        start_date: selectedDates[0] ? convertDateIntoYYYYMMDD(selectedDates[0]) : convertDateIntoYYYYMMDD(new Date()),
        end_date: selectedDates[1] ? convertDateIntoYYYYMMDD(selectedDates[1]) : convertDateIntoYYYYMMDD(new Date()),
        group_by: selectedOption.value, 
        order_by: sortValue,
        project: selectedProject, 
        todo: selectedTodos, 
        user: selectedAssignee ? selectedAssignee : undefined
      };
      if(selectedBillable){
        params.billable = selectedBillable;
      }
      setFilterStorage(storageName.timeActivity, {
        // start_date: params.start_date,
        // end_date: params.end_date,
        selectedDates,
        group_by: selectedOption,
        order_by: sortValue,
        project: selectedProject, 
        todo: selectedTodos, 
        user: selectedAssignee ? selectedAssignee : undefined,
        billable: selectedBillable
      });
      // console.log('params', params)
      dispatch(fetchReports(params));
    } catch (error) {
      const payload = { 
        code: error.status, 
        status:"fail", 
        message: error.message || "Something went wrong. Try again later!" 
      };
      dispatch(setFeedback(payload));
    }
  },[dispatch, selectedAssignee, selectedDates, selectedBillable, selectedOption, selectedProject, selectedTodos, sortValue])

  const downloadPDF = useCallback(async (flag = "") => {
    try {
      dispatch(setLoading(true));
      let params = {
        // start_date: formattedStartDate ? formattedStartDate : convertDateIntoYYYYMMDD(new Date()), 
        // end_date: formattedEndDate ? formattedEndDate : convertDateIntoYYYYMMDD(new Date()), 
        start_date: selectedDates[0] ? convertDateIntoYYYYMMDD(selectedDates[0]) : convertDateIntoYYYYMMDD(new Date()),
        end_date: selectedDates[1] ? convertDateIntoYYYYMMDD(selectedDates[1]) : convertDateIntoYYYYMMDD(new Date()),
        group_by: selectedOption.value, 
        order_by: sortValue,
        project: selectedProject, 
        todo: selectedTodos,
        user: selectedAssignee ? selectedAssignee : undefined
      };
      if(selectedBillable){
        params.billable = selectedBillable;
      }

      if(flag === "PDF"){
        params.file = "pdf";
        params.timezone = getLocalTimeZone();
      } else if (flag === "CSV") {
        params.file = "excel";
        params.timezone = getLocalTimeZone();
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}${FETCH_REPORTS_API}`, {params, responseType: 'blob'});
      dispatch(setLoading(false));

      if (response.status !== 200) {
        throw new Error('No Record Found!');
      }

      const stream = await response.data;
      const blob = new Blob([stream], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      if(flag === "PDF"){
        downloadLink.download = `report_${params.start_date.replaceAll("-", "")}_${params.end_date.replaceAll("-", "")}.pdf`; 
      } else {
        downloadLink.download = `report_${params.start_date.replaceAll("-", "")}_${params.end_date.replaceAll("-", "")}.xlsx`; 
      }
      document.body.appendChild(downloadLink);
      downloadLink.click();
      
      URL.revokeObjectURL(url);
    } catch (error) {
      const payload = { 
        code: error.response.status, 
        status:"fail", 
        message: error.response.status === 404 ? "No data found" : (error.message || "Something went wrong.") 
      };
      dispatch(setFeedback(payload));
    } finally {
      dispatch(setLoading(false));
    }
  },[dispatch, selectedAssignee, selectedDates, selectedOption.value, selectedBillable, selectedProject, selectedTodos, sortValue]) 

  const handleSubmit = async () => {
    try {
      let project_string = "";
      if (selectedProject.length > 0) {
        project_string = "&" + selectedProject.map(project_id => `project:${project_id}`).join('&');
      }
      let todos_string = "";
      if (selectedTodos.length > 0) {
        todos_string = "&" + selectedTodos.map(todo_id => `todo:${todo_id}`).join('&');
      }
      // let users_string = "";
      // if (selectedUserOption.length > 0) {
      //   users_string = "&" + selectedUserOption.map(user_id => `user:${user_id}`).join('&');
      // }
      let users_string = "";
      if (selectedAssignee.length > 0) {
        users_string = "&" + selectedAssignee.map(user_id => `user:${user_id}`).join('&');
      }
      let billable_string = "";
      if(selectedBillable){
        billable_string = `&billable:${selectedBillable}`;
      }
      let data_string = `?start_date=${convertDateIntoYYYYMMDD(selectedDates[0])}&end_date=${convertDateIntoYYYYMMDD(selectedDates[1])}&group_by=${selectedOption.value}&order_by=${sortValue}${project_string}${todos_string}${users_string}${billable_string}`;
  
      const payload = {
          name: filterName,
          description: filterDescription,
          data: data_string
      };
      dispatch(addFilter(payload));
      setFilterName('');
      setFilterDescription('');
      modalClose();
    } catch (error) {
      const errPayload = { 
        code: error.status, 
        status: 'fail', 
        message: error.message || 'Something went wrong.' 
      };
      dispatch(setFeedback(errPayload));
    }
  };

  const fetchSingleFilter = useCallback(async (id) => {
  try {
    const response = await fetchFilterById(id);
    if (response.data.status === 200) {
      setEditData(response.data.data);
    } else {
      const payload = {
        code: response.data.status,
        status: 'fail',
        message: response.data.message || 'Something went wrong.',
      };
      dispatch(setFeedback(payload));
    }
  } catch (error) {
    dispatch(setLoading(false));
    const errPayload = {
      code: error.status,
      status: 'fail',
      message: error.message || 'Something went wrong.',
    };
    dispatch(setFeedback(errPayload));
  }
  }, [dispatch]);


  const deleteFilterHandler = async () =>{
    try {
      const payload = deleteFilterId;
      dispatch(deleteFilter(payload));
      deleteSetOpen(false);
      clearFilters();
      setSelectedFilterIndex(-1);
    } catch (error) {
      const payload = { 
        code: error.status, 
        status:"fail", 
        message: error.message || "Something went wrong. Try again later!" 
      };
      dispatch(setFeedback(payload));
    }
  }

  const updateFilterData = async () => {
    try {
      let project_string = "";
      if (selectedProject.length > 0) {
        project_string = "&" + selectedProject.map(project_id => `project:${project_id}`).join('&');
      }
      let todos_string = "";
      if (selectedTodos.length > 0) {
        todos_string = "&" + selectedTodos.map(todo_id => `todo:${todo_id}`).join('&');
      }
      // let users_string = "";
      // if (selectedUserOption.length > 0) {
      //   users_string = "&" + selectedUserOption.map(user_id => `user:${user_id}`).join('&');
      // }
      let users_string = "";
      if (selectedAssignee.length > 0) {
        users_string = "&" + selectedAssignee.map(user_id => `user:${user_id}`).join('&');
      }
      let billable_string = "";
      if(selectedBillable){
        billable_string = `&billable:${selectedBillable}`;
      }
      let data_string = `?start_date=${convertDateIntoYYYYMMDD(selectedDates[0])}&end_date=${convertDateIntoYYYYMMDD(selectedDates[1])}&group_by=${selectedOption.value}&order_by=${sortValue}${project_string}${todos_string}${users_string}${billable_string}`;

      const payload = {
        id: editData.id,
        name: filterName,
        description: filterDescription,
        data: data_string
      };
      dispatch(updateFilter(payload));
      editModalClose();
    } catch (error) {
      const errPayload = { 
        code: error.status, 
        status: 'fail', 
        message: error.message || 'Something went wrong.' 
      };
      dispatch(setFeedback(errPayload));
    }
};

  useEffect(()=>{
    fetchReportTopFilter();
  },[fetchReportTopFilter])
  
  // edit modal
  const [editModalOpen, editSetOpen] = React.useState(false);
  const editFilterModalOpen = useCallback((filterId) => {
    fetchSingleFilter(filterId);
    editSetOpen(true);
  }, [fetchSingleFilter])

  const editModalClose = () => {
    setEditData('');
    setFilterDescription('');
    setFilterName('');
    editSetOpen(false)
  };
  
  //delete modal
  const [deleteModalOpen, deleteSetOpen] = useState(false);
  const [deleteFilterId, setDeleteFilterId] = useState(null);
  const [deleteFilterName, setDeleteFilterName] = useState(null);
  const deleteFilterModalOpen = (filter) => {
    setDeleteFilterId(filter.id);
    setDeleteFilterName(filter.name);
    deleteSetOpen(true)
  };
  const deleteModalClose = () => {
    setDeleteFilterId('');
    setDeleteFilterName('');
    deleteSetOpen(false);
  }

  useEffect(() => {
    if (editData !== '') {
      setFilterName(editData.name);
      setFilterDescription(editData.description);
      const params_string = new URLSearchParams(editData.data);

      const formattedStartDate = params_string.get('start_date');
      const formattedEndDate = params_string.get('end_date');
      const groupBy = params_string.get('group_by');
      // const orderBy = params_string.get('order_by');
      // const selectedProjects = params_string.getAll('project');
      let selectedProjects = [];
      let selectedTodos = [];
      let selectedUsers = [];
      let billableValue = "";
      for (const entry of params_string.entries()) {
        if (entry[0].startsWith('project:')) {
          selectedProjects.push(entry[0].split(':')[1]);
        } else if (entry[0].startsWith('todo:')) {
          selectedTodos.push(entry[0].split(':')[1]);
        } else if (entry[0].startsWith('user:')) {
          selectedUsers.push(entry[0].split(':')[1]);
        } else if (entry[0].startsWith('billable:')) {
          billableValue = entry[0].split(':')[1];
        }
      }

      setSelectedDates([new Date(formattedStartDate), new Date(formattedEndDate)]);

      let groupByLabel = '';
      if (groupBy === 'date') {
        groupByLabel = 'Date';
      } else if (groupBy === 'project') {
        groupByLabel = 'Project';
      } else if (groupBy === 'todo') {
        groupByLabel = 'To-dos';
      } else if (groupBy === 'member') {
        groupByLabel = 'Members';
      } else {
        // eslint-disable-next-line no-unused-vars
        groupByLabel = 'Date';
      }

      const option = options.find((option) => option.value === groupBy);
      const selectedOption = option ? option : options[0];
      setSelectedOption(selectedOption);

      const updatedProjectOptions = selectedProjects.map(option => +option);
      const updatedTodosOptions = selectedTodos.map(option => +option);
      const updatedUsersOptions = selectedUsers.map(option => +option);
  
      setSelectedProject(updatedProjectOptions);
      setSelectedTodos(updatedTodosOptions);
      // setSelectedUserOption(updatedUsersOptions);
      setSelectedAssignee(updatedUsersOptions);
      setSelectedBillable(billableValue);

      // fetchReportTopFilter();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

    return (
        <>
          <Box className="content-header report-top-bar time-activity-wrapper">
            <Box className="report-top-filter">
              <KeyboardBackspaceIcon style={{fontSize : '36px', cursor:'pointer', fill: '#777'}} onClick={() => navigate("/reports")} className="back-btn"/>
              <Tooltip title={`Sort Report ${selectedOption.label} Wise`} className="desktop-sorting-btn">
                  <Button className='btn btn-primary only-icon-btn' onClick={changeSortOrderHandler}>
                    <BiSortAlt2 />
                  </Button>
                </Tooltip>
              <Box className='calendar-ui'>
                <DateRangePicker 
                  ranges={ranges} 
                  onChange={handleDateRangeChange} 
                  value={selectedDates} 
                  // disabledDate={afterToday()} 
                  shouldDisableDate={afterToday()}
                  format="dd-MM-yyyy"
                />
              </Box>
              <Select
                className="report-select-date select-field bg-white"
                placeholder='Group by '
                value={selectedOption}
                onChange={handleOptionChange}
                options={options}
                classNamePrefix="select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "rgba(140, 199, 20, 0.4);",
                    primary: "#8CC714",
                  },
                })}
              />
            </Box>
            <Box item className="content-header-right report-btn-icon mb-0">
                <Button onClick={() => downloadPDF("CSV")}>
                  <FiDownloadCloud /> To CSV
                </Button>
                <Button onClick={() => downloadPDF("PDF")}>
                  <FiDownloadCloud /> To PDF
                </Button>
                <Tooltip title={`Sort Report ${selectedOption.label} Wise`} className="mobile-sorting-btn filter-btn-icon">
                  <Button className='btn btn-primary only-icon-btn' onClick={changeSortOrderHandler}>
                    <BiSortAlt2 />
                  </Button>
                </Tooltip>
                <Tooltip title='Filter'>
                  <Button
                    className="filter-btn-icon"
                    sx={{ maxWidth: "50px", minWidth: "0px" }}
                    onClick={handleDrawerOpen}
                  >
                    <FiFilter />
                  </Button>
                </Tooltip>
            </Box>
          </Box>
          <Drawer
            anchor="right"
            open={state}
            onClose={handleDrawerClose}
            className="filter-drawer report-drawer-panel"
          >
            <Box className="close-filter" onClick={handleDrawerClose}>
              <FiPlus />
            </Box>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="filter-tab">
              <Tab className="filter-btn" label="Filter" {...a11yProps(0)} />
              <Tab className="filter-btn" label="Saved Filters" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <FormGroup className="form-field drawer-padding">
                {/* { ((valueTab === 1 && userRole !== 'employee') || userRole === 'admin' || userRole === 'viewer') && */}
                { userRole !== ROLES.employee &&
                  <FormControl variant="standard" className="form-control mb-3">
                    <Typography variant="body2" component="span" className="text-black input-label">
                      Members
                    </Typography>
                    <Select className='select-filter'
                      placeholder="Select Members"
                      value={assigneeOption.filter(option => selectedAssignee?.includes(option.value))}
                      onChange={handleSelectedUser}
                      options={assigneeOption}
                      isMulti
                      isClearable={false}
                      classNamePrefix="select"
                      styles={getDotColorStyle('MULTI')}
                      theme={(theme) => ({
                          ...theme,
                          borderRadius: 10,
                          colors: {
                          ...theme.colors,
                          primary25: 'rgba(140, 199, 20, 0.4);',
                          primary: '#8CC714',
                          },
                        })
                      }
                    />
                  </FormControl>
                }
                <FormControl variant="standard" className="form-control mb-3">
                  <Typography variant="body2" component="span" className="text-black input-label">
                    Projects
                  </Typography>
                  <Select
                    isMulti
                    className="select-filter"
                    placeholder='Select Projects'
                    value={projectOptions?.filter(option => selectedProject.includes(option.value))}
                    // value={selectedProject}
                    onChange={handleSelectedProject}
                    options={projectOptions}
                    classNamePrefix="select"
                    styles={getNormalColorStyle("MULTI")}
                    isClearable={false}
                    theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "rgba(140, 199, 20, 0.4);",
                      primary: "#8CC714",
                    },
                    })}
                  />
                </FormControl>
                <FormControl variant="standard" className="form-control mb-3">
                  <Typography variant="body2" component="span" className="text-black input-label" >
                    To-Dos
                  </Typography>
                  <Select
                    isMulti
                    className="select-filter"
                    placeholder='Select To-dos'
                    value={todosOptions?.filter(option => selectedTodos.includes(option.value))}
                    // value={selectedTodos}
                    onChange={handleSelectedTodo}
                    options={todosOptions}
                    isClearable={false}
                    classNamePrefix="select"
                    styles={getNormalColorStyle("MULTI")}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "rgba(140, 199, 20, 0.4);",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </FormControl>
                <FormControl variant="standard" className="form-control mb-3">
                  <Typography variant="body2" component="span" className="text-black input-label" >
                    Billable
                  </Typography>
                  <Select
                    className="select-filter"
                    placeholder='Select Billable'
                    value={billableOption?.filter(option => selectedBillable === option.value)}
                    // value={selectedTodos}
                    onChange={handleSelectedBillable}
                    options={billableOption}
                    isClearable={false}
                    classNamePrefix="select"
                    styles={getNormalColorStyle("SINGLE")}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "rgba(140, 199, 20, 0.4);",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </FormControl>
                <FormGroup> 
                  <Button className="btn btn-primary mb-2" onClick={modalOpen}>Save Filters</Button>
                  <Button className="btn btn-secondary" onClick={clearFilters}>Clear Filters</Button>
                </FormGroup>
              </FormGroup>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box className="form-field drawer-padding">
                <FormControl variant="standard" className="form-control">
                  <BootstrapInput placeholder="Search saved filters"
                    value={searchValue}
                    onChange={changeSearchValueHandler}                
                    className='input-field'
                    endAdornment={
                      <InputAdornment
                      position="end"
                      className="end-input-icon text-grey"
                      >
                      <IconButton
                        aria-label="toggle password visibility"
                        // onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                      <FiSearch />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>

              <Box className='saved-filter-wrapper'>
              {filtersData.length ? (
                filtersData.map((FiltersList, index) => (
                  <Box
                    className={`saved-filter-list ${selectedFilterIndex === index && 'selected'}`}
                    onClick={() => {
                      fetchSingleFilter(FiltersList.id);
                      setSelectedFilterIndex(index);
                    }}
                    key={index}
                  >
                    <Typography variant="body2" component="span" className='text-black'>
                      {FiltersList.name}
                    </Typography>
                    <Box className='action-btn'>
                      <Button className="btn btn-primary" onClick={() => {editFilterModalOpen(FiltersList.id)}}>
                        <FiEdit3/>
                      </Button>
                      <Button className="btn btn-primary" onClick={() => {deleteFilterModalOpen(FiltersList)}}>
                        <FiTrash2/>
                      </Button>
                    </Box>
                  </Box>
                ))
              ) : (
                <Box sx={{textAlign: 'center'}}>
                  <Typography className='text-grey'>No Filters</Typography>
                </Box>
              )}
            </Box>

            </TabPanel>
          </Drawer>

          {/* saved filter modal */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isOpen}
            onClose={modalClose}
            closeAfterTransition
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={isOpen}>
              <Box sx={styled} className="modal-wrapper modal-bg">
                <Typography variant="h6" component="h6" className="text-black modal-title" >
                  Save Filters
                </Typography>
                <Box className="modal-close" onClick={modalClose}>
                  <FiPlus />
                </Box>
                <Box className="modal-body">
                    <FormGroup className='form-field'>
                          <FormControl variant="standard" className='form-control mb-20'>
                              <Typography variant="body2" component="span" className='text-black input-label'>
                                Name
                              </Typography>
                              <BootstrapInput placeholder="Enter filter name" className='input-field' 
                              value={filterName} 
                              onChange={(e) => {
                                    handleFilterName(e);
                                }}/>
                          </FormControl>
                          <FormControl variant="standard" className='form-control'>
                              <Typography variant="body2" component="span" className='text-black input-label'>
                                Description
                              </Typography>
                              <TextareaAutosize placeholder="Enter your description..."
                              value={filterDescription} 
                              onChange={(e) => {
                                  handleFilterDescription(e);
                              }}/>
                          </FormControl>
                    </FormGroup> 
                </Box>
                <Box className="modal-footer">
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Button className="btn btn-primary" onClick={handleSubmit}>Save</Button>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Button className="btn btn-secondary" onClick={modalClose}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Fade>
          </Modal>

          {/* Edit filter modal */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={editModalOpen}
            onClose={editModalClose}
            closeAfterTransition
            slotProps={{
            backdrop: {
                timeout: 500,
              },
            }}
            >
              <Fade in={editModalOpen}>
                <Box sx={styled} className='modal-wrapper modal-bg'>
                  <Typography variant="h6" component="h6" className='text-black modal-title' >
                      Edit Filter
                  </Typography>
                  <Box className='modal-close' onClick={editModalClose}>
                      <FiPlus/>
                  </Box>
                  <Box className='modal-body'>
                    <FormGroup className='form-field'>
                      <FormControl variant="standard" className='form-control mb-20'>
                        <Typography variant="body2" component="span" className='text-black input-label'>
                          Name
                        </Typography>
                        <BootstrapInput 
                          placeholder="Enter filter name" 
                          className='input-field'
                          value={filterName} 
                          onChange={(e) => {
                              handleFilterName(e);
                          }}
                        />
                      </FormControl>
                      <FormControl variant="standard" className='form-control'>
                        <Typography variant="body2" component="span" className='text-black input-label'>
                          Description
                        </Typography>
                          <TextareaAutosize 
                            placeholder="Enter your description..." 
                            value={filterDescription} 
                            onChange={(e) => {
                              handleFilterDescription(e);
                            }}
                          />
                      </FormControl>
                    </FormGroup> 
                  </Box>
                  <Box className='modal-footer'>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <Button className='btn btn-primary' onClick={updateFilterData}>Update</Button>
                      </Grid>
                      <Grid item md={6} xs={12}>
                          <Button className='btn btn-secondary' onClick={editModalClose}>Cancel</Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Fade>
          </Modal>

          {/* delete to dos modal */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={deleteModalOpen}
            onClose={deleteModalClose}
            closeAfterTransition
            slotProps={{
            backdrop: {
                timeout: 500,
              },
            }}
            >
              <Fade in={deleteModalOpen}>
                <Box sx={styled} className='modal-wrapper modal-bg'>
                  <Typography variant="h6" component="h6" className='text-black modal-title' >
                      {deleteFilterName}
                  </Typography>
                  <Box className='modal-close' onClick={deleteModalClose}>
                      <FiPlus/>
                  </Box>
                  <Box className='modal-body'>
                      <Box className='confirmation-text' sx={{marginBottom:'20px'}}>
                          <Typography paragraph className='text-black'>
                              Are you sure want to delete this filter?
                          </Typography>
                      </Box>
                  </Box>
                  <Box className='modal-footer'>
                      <Grid container spacing={3}>
                          <Grid item md={6} xs={12}>
                              <Button className='btn btn-primary' onClick={deleteFilterHandler}>Delete</Button>
                          </Grid>
                          <Grid item md={6} xs={12}>
                              <Button className='btn btn-secondary' onClick={deleteModalClose}>Cancel</Button>
                          </Grid>
                      </Grid>
                  </Box>
                </Box>
              </Fade>
          </Modal>

          {/* activity card */}
          <ReportActivityCard selectedOption={selectedOption} />
        </>
    );
}

export default TimeAndActivity;