import axios from "axios";
import {
  getFilterStorage,
  getLocalTimeZone,
  setFilterStorage,
} from "../utils/helper";
import { storageName } from "./constants";
axios.defaults.withCredentials = true;

const BASE_URL = process.env.REACT_APP_API_URL;
// const BASE_URL = 'http://dev-tms.crestinfosystems.net:5000/api';

// const token = getAuthToken();

const axiosInstance = axios.create();

export const setIdInUrl = (url, id) => {
  if (id) {
    return url.replace("id", id);
  } else {
    return url;
  }
};

export const getAPICall = (url, header, params = {}, id = "", query = "") =>
  new Promise((resolve, reject) => {
    let myUrl = id ? `${BASE_URL}${url}/${id}` : `${BASE_URL}${url}`;
    if (query) {
      myUrl += "?" + query;
    }
    const timeZone = getLocalTimeZone();
    // console.log(params);
    const reqParams = {
      ...params,
      timezone: timeZone,
    };
    if (header) {
      axiosInstance
        .get(myUrl, {
          params: reqParams,
          headers: {
            // authorization: `Bearer ${token}`,
          },
        })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response.data));
    } else {
      axios
        .get(myUrl)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response.data));
    }
  });

export const postAPICall = (url, header, body) =>
  new Promise((resolve, reject) => {
    const timeZone = getLocalTimeZone();
    body.timezone = timeZone;
    if (header) {
      axiosInstance
        .post(`${BASE_URL}${url}`, body, {
          headers: {
            "Content-Type": "application/json",
            // 'Authorization':`Bearer ${token}`
          },
        })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response.data));
    } else {
      axios
        .post(`${BASE_URL}${url}`, body)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response.data));
    }
  });

export const patchAPICall = (url, header, body, id = "") =>
  new Promise((resolve, reject) => {
    const timeZone = getLocalTimeZone();
    body.timezone = timeZone;
    const myUrl = id ? `${BASE_URL}${url}/${id}` : `${BASE_URL}${url}`;
    if (header) {
      axiosInstance
        .patch(myUrl, body, {
          headers: {
            "Content-Type": "application/json",
            // 'Authorization':`Bearer ${token}`
          },
        })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response.data));
    } else {
      axios
        .patch(myUrl, body)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response.data));
    }
  });

export const deleteAPICall = (url, header, id, body = {}) =>
  new Promise((resolve, reject) => {
    const timeZone = getLocalTimeZone();
    body.timezone = timeZone;
    if (header) {
      axiosInstance
        .delete(`${BASE_URL}${url}/${id}`, {
          headers: {
            // authorization: `Bearer ${token}`,
          },
          data: body,
        })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response.data));
    } else {
      axios
        .delete(`${BASE_URL}${url}/${id}`, {
          data: body,
        })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response.data));
    }
  });

export const AxiosInterceptor = ({ children }) => {
  axiosInstance.interceptors.response.use(
    (res) => res,
    (err) => {
      // console.warn("AxiosInterceptor ",err.response);
      if (err?.response?.status === 403) {
        // localStorage.clear();
        const userCredential = getFilterStorage(
          storageName.timeTrackerCredential
        );
        if (userCredential) {
          localStorage.clear();
          setFilterStorage(storageName.timeTrackerCredential, userCredential);
        } else {
          localStorage.clear();
        }

        // const errPayload = {
        //   code: err.response.data.status,
        //   status:"fail",
        //   message:err.response.data.message}
        // dispatch(setFeedback(errPayload));
      }
      return err.response;
    }
  );

  return children;
};
