import React, { useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { FiEdit3 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateEmployeeTakingStatus } from "../../store/redux/actions/employee.action";
import { updateEmployeeData } from "../../apis/employee";
import { setFeedback } from "../../store/redux/actions/feedback.action";
import { showSuccessToast } from "../../utils/toast";
import { ROLES } from "../../lib/constants";
import Assignee from "../../components/common/Assignee";
import { CustomTablePaginationToolbar } from "../../components/common/Pagination";

const headCells = [
  {
    id: "User",
    numeric: false,
    disablePadding: true,
    label: "User",
  },
  {
    id: "company",
    numeric: false,
    disablePadding: true,
    label: "Company",
  },
  {
    id: "role",
    numeric: true,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "projects",
    numeric: true,
    disablePadding: false,
    label: "Projects",
  },
  // {
  //   id: 'user status',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'User Status',
  // },
  {
    id: "timetrackingstatus",
    numeric: true,
    disablePadding: false,
    label: "Time Tacking Status",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            className="head-styles"
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      className="delete-wrapper"
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        minHeight: "auto !important",
        ...(numSelected > 0 && {
          minHeight: "64px !important",
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : null}

      {numSelected > 0 ? (
        <Tooltip title="Delete All Users">
          <Button className="btn btn-tertiary">Selected Delete</Button>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const EmployeeTable = ({
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  permissionsAccess,
}) => {
  const dispatch = useDispatch();
  const { count: employeeCount, data: employeeData } = useSelector(
    (state) => state.employee
  );
  const userRole = useSelector((state) => state.user.data?.user_role?.id);

  const [selected, setSelected] = useState([]);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = employeeData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (employee) => selected.indexOf(employee) !== -1;

  const handleTimeTrackingStatus = async (id, newStatus) => {
    try {
      const payload = { id, time_tracking_status: newStatus };
      const response = await updateEmployeeData(payload, id);
      if (response.data.status === 200) {
        dispatch(updateEmployeeTakingStatus(payload));
        showSuccessToast(response.data.message);
      } else {
        const payload = {
          code: response.data.status,
          status: "fail",
          message:
            response.data.message || "Something went wrong. Try again later!",
        };
        dispatch(setFeedback(payload));
      }
    } catch (error) {
      const payload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong. Try again later!",
      };
      dispatch(setFeedback(payload));
    }
  };

  return (
    <>
      {/* data table */}
      <Box className="card mb-0">
        <Box className="timesheet-table-listing">
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer className="table-wrapper">
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
              className="employee-table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={employeeData?.length}
              />
              <TableBody>
                {employeeData?.length && userRole === ROLES.admin ? (
                  employeeData?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          component="td"
                          id={labelId}
                          scope="row"
                          padding="normal"
                        >
                          <Assignee
                            key={row.id + "user"}
                            AssigneeInitial={
                              row.first_name
                                ? row.first_name.charAt(0).toUpperCase()
                                : ""
                            }
                            AssigneeTitle={`${row.first_name} ${row.last_name}`}
                          />
                        </TableCell>
                        <TableCell
                          component="td"
                          id={labelId}
                          scope="row"
                          padding="normal"
                        >
                          {row.company?.company_name ?? "-"}
                        </TableCell>
                        <TableCell align="left">
                          {row.user_role.title}
                        </TableCell>
                        <TableCell align="left">
                          {row.project_assignments}
                        </TableCell>
                        {/* <TableCell align="left" className={row.status}> */}
                        {/* {
                            permissionsAccess.edit && userRole === ROLES.admin ? 
                            <Box className="mask-box-container">
                              <Box className="mask-box">
                                <Box
                                  className="mask mask-1"
                                  style={{
                                    transform: `translateX(${row.status === "active" ? 0 : "80px"})`
                                  }}
                                />
                                <Button
                                  className="mask-btn mask-btn-1"
                                  disableRipple
                                  variant="text"
                                  onClick={() => handleStatusChange(row.id, "active")}
                                >
                                  Active
                                </Button>
                                <Button
                                  className="mask-btn mask-btn-1"
                                  disableRipple
                                  variant="text"
                                  onClick={() => handleStatusChange(row.id, "archive")}
                                >
                                  Archive
                                </Button>
                              </Box>
                            </Box>
                            :
                            row.status
                          }   */}
                        {/* {row.status?.charAt(0).toUpperCase() + row.status?.slice(1)} */}
                        {/* </TableCell> */}
                        <TableCell align="left">
                          {permissionsAccess.edit &&
                          userRole === ROLES.admin ? (
                            <Box className="mask-box-container">
                              <Box className="mask-box">
                                <Box
                                  className="mask mask-1"
                                  style={{
                                    transform: `translateX(${
                                      row.time_tracking_status === "enabled"
                                        ? 0
                                        : "80px"
                                    })`,
                                  }}
                                />
                                <Button
                                  className="mask-btn mask-btn-1"
                                  disableRipple
                                  variant="text"
                                  onClick={() =>
                                    handleTimeTrackingStatus(row.id, "enabled")
                                  }
                                >
                                  Enabled
                                </Button>
                                <Button
                                  className="mask-btn mask-btn-1"
                                  disableRipple
                                  variant="text"
                                  onClick={() =>
                                    handleTimeTrackingStatus(row.id, "disabled")
                                  }
                                >
                                  Disabled
                                </Button>
                              </Box>
                            </Box>
                          ) : (
                            row.time_tracking_status
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Box
                            className="table-action-btn"
                            style={{ justifyContent: "center" }}
                          >
                            {permissionsAccess.edit &&
                              userRole === ROLES.admin && (
                                <Link to={`/users/${row.id}/edit`}>
                                  <Tooltip title="Edit User">
                                    <Button className="btn btn-primary">
                                      <FiEdit3 />
                                    </Button>
                                  </Tooltip>
                                </Link>
                              )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell sx={{ textAlign: "center" }} colSpan={6}>
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={employeeCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          <CustomTablePaginationToolbar
            count={employeeCount}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
};

export default EmployeeTable;
