import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Select from "react-select";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import { TbArrowsSort } from "react-icons/tb";
import { FiDownloadCloud } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_BILLABLE_NONBILLABLE_REPORT_API,
  ROLES,
  storageName,
} from "../../lib/constants";
import {
  convertDateIntoDDMMYYYY,
  convertDateIntoYYYYMMDD,
  convertSecondsIntoHHMM,
  getDateRangeOptions,
  getDotColorStyle,
  getFilterStorage,
  handleLabel,
  getPermissionAccess,
  setFilterStorage,
  getLocalTimeZone,
} from "../../utils/helper";
import { fetchProjects } from "../../store/redux/actions/project.action";
import {
  setFeedback,
  setLoading,
} from "../../store/redux/actions/feedback.action";
import { fetchBillableNonBillableReports } from "../../store/redux/actions/reports.action";
import ProjectName from "../../components/common/ProjectName";
import { fetchEmployee } from "../../apis/employee";
import axios from "axios";
import { createTheme, IconButton, ThemeProvider, Tooltip } from "@mui/material";
import helpicon from "../../assets/layout/helpicon.svg";

const { afterToday } = DateRangePicker;
const ranges = getDateRangeOptions();

const BillableNonBillable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.user.data);
  const userRole = useSelector((state) => state.user.data?.user_role?.id);
  const permissions = userDetails.role_permissions.permissions;
  const permissionsAccess = getPermissionAccess(permissions, "Reports");
  if (
    !permissionsAccess.view &&
    !permissionsAccess.add &&
    !permissionsAccess.edit &&
    !permissionsAccess.delete
  ) {
    navigate("/", { replace: true });
  }

  if (
    (userDetails?.user_role?.id === ROLES.employee &&
      !userDetails.is_assistant_manager) ||
    userDetails?.user_role?.id === ROLES.viewer
  ) {
    navigate("/", { replace: true });
  }
  const [order, setOrder] = useState("ASC");
  const projects = useSelector((state) => state.project.projects_list);
  const filterData = getFilterStorage(storageName.billableNonBillable);

  const reportsData = useSelector(
    (state) => state.reports.billableNonbillableReport
  );

  const billableNonbillableReportData = reportsData.slice(1);

  let projectOptions = [];
  projectOptions.push(
    ...projects?.map((proj) => ({ value: proj.id, label: proj.title }))
  );

  const [assignee, setAssignee] = useState([]);
  const assigneeOption = useMemo(() => {
    let options = [
      { value: "", label: "All", dot_color: "#8CC714", text_color: "#777777" },
    ];
    if (userDetails?.user_role?.id === ROLES.admin) {
      assignee.forEach((row) => {
        if (row.status === "active") {
          options.push(
            handleLabel(true, row.id, `${row.first_name} ${row.last_name}`)
          );
        } else {
          options.push(
            handleLabel(false, row.id, `${row.first_name} ${row.last_name}`)
          );
        }
      });
    } else if (
      userDetails?.user_role?.id === ROLES.manager ||
      userDetails.is_assistant_manager
    ) {
      assignee.forEach((row) => {
        let flag = 0;
        if (row.status !== "active") {
          flag = 1;
        } else {
          row.project_assignments.some((row2) => {
            if (row2.deletedAt === null) {
              flag = 0;
              return true;
            } else {
              flag = 1;
              return false;
            }
          });
        }

        if (flag === 0) {
          options.push(
            handleLabel(true, row.id, `${row.first_name} ${row.last_name}`)
          );
        } else {
          options.push(
            handleLabel(false, row.id, `${row.first_name} ${row.last_name}`)
          );
        }
      });
    } else {
      options = [];
    }
    return options;
  }, [assignee, userDetails?.user_role?.id]);

  const initialSelectedDates = [new Date(), new Date()];
  const [selectedDates, setSelectedDates] = useState(
    filterData &&
      filterData.selectedDates &&
      filterData.selectedDates[0] &&
      filterData.selectedDates[1]
      ? [
          new Date(filterData.selectedDates[0]),
          new Date(filterData.selectedDates[1]),
        ]
      : initialSelectedDates
  );

  const [selectedProject, setSelectedProject] = useState(
    filterData?.project_id || []
  );

  const [selectedAssignee, setSelectedAssignee] = useState(
    filterData?.user_id
      ? {
          value: filterData?.user_id,
          label: filterData?.user_label,
          dot_color: filterData.dot_color,
          text_color: "#777777",
        }
      : { value: "", label: "All", dot_color: "#8CC714", text_color: "#777777" }
  );

  const handleDateRangeChange = (date) => {
    if (date === null) {
      setSelectedDates(initialSelectedDates);
    } else {
      setSelectedDates(date);
    }
  };

  const handleSelectedProject = (options) => {
    setSelectedProject(options);
  };

  const fetchProjectsData = useCallback(async () => {
    try {
      const params = {
        listing: true,
        isListing: true,
        billable_nonbillable: true,
      };
      dispatch(fetchProjects(params));
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  }, [dispatch]);

  const fetchAssignee = async () => {
    try {
      const params = { billable_unbillable: 1 };
      const response = await fetchEmployee(params);

      if (response.data.status === 200) {
        setAssignee(response.data.data);
      } else {
        setAssignee([]);
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };

  useEffect(() => {
    fetchProjectsData();
    if (
      userRole.is_assistant_manager ||
      userRole !== ROLES.employee ||
      userRole !== ROLES.viewer
    ) {
      fetchAssignee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchReportData = useCallback(
    async (flag = "") => {
      try {
        let params = {
          start_date: selectedDates[0]
            ? convertDateIntoYYYYMMDD(selectedDates[0])
            : convertDateIntoYYYYMMDD(new Date()),
          end_date: selectedDates[1]
            ? convertDateIntoYYYYMMDD(selectedDates[1])
            : convertDateIntoYYYYMMDD(new Date()),

          user: selectedAssignee?.value ? selectedAssignee?.value : undefined,
          sort: order,
        };
        let query_string = "";
        selectedProject?.map(
          (project) => (query_string += `project=${project.value}&`)
        );
        query_string = query_string.slice(0, -1);

        setFilterStorage(storageName.billableNonBillable, {
          selectedDates,
          project_id: selectedProject,
          user_id: selectedAssignee?.value ? selectedAssignee?.value : "",
          user_label: selectedAssignee?.label ? selectedAssignee?.label : "All",
          dot_color: selectedAssignee?.dot_color,
        });
        if (flag === "") {
          dispatch(
            fetchBillableNonBillableReports({
              params: params,
              query_string: query_string,
            })
          );
        } else if (flag === "excel") {
          params = { ...params, file: "excel", timezone: getLocalTimeZone() };
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${FETCH_BILLABLE_NONBILLABLE_REPORT_API}?${query_string}`,
            { params, responseType: "blob" }
          );
          dispatch(setLoading(false));

          if (response.status !== 200) {
            throw new Error("No Record Found!");
          }

          const stream = await response.data;
          const blob = new Blob([stream], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = `billable_nonbillable_report_${params.start_date.replaceAll(
            "-",
            ""
          )}_${params.end_date.replaceAll("-", "")}.xlsx`;

          document.body.appendChild(downloadLink);
          downloadLink.click();

          URL.revokeObjectURL(url);
        }
      } catch (error) {
        const payload = {
          code: error.status,
          status: "fail",
          message:
            error.response.status === 404
              ? "No data found"
              : error.message || "Something went wrong.",
        };
        dispatch(setFeedback(payload));
      }
    },
    [
      dispatch,
      selectedAssignee?.dot_color,
      selectedAssignee?.label,
      selectedAssignee?.value,
      selectedDates,
      selectedProject,
      order,
    ]
  );

  const downloadCSV = () => {
    fetchReportData("excel");
  };

  useEffect(() => {
    fetchReportData();
  }, [fetchReportData]);

  const totalPerDayBillable =
    new Array(reportsData.length > 0 ? reportsData[0].length : 0).fill(0) || [];
  const totalPerDayNonBillable =
    new Array(reportsData.length > 0 ? reportsData[0].length : 0).fill(0) || [];

  billableNonbillableReportData.forEach((item, i) => {
    item.data.forEach((item2, index) => {
      if (item2 !== "No Data") {
        totalPerDayBillable[index] += +item2.billable;
        totalPerDayNonBillable[index] += +item2.non_billable;
      }
    });
  });

  const mainTotalBillable = totalPerDayBillable.reduce(
    (acc, item) => acc + +item,
    0
  );
  const mainTotalNonBillable = totalPerDayNonBillable.reduce(
    (acc, item) => acc + +item,
    0
  );
  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#8CC714" : "rgba(140, 199, 20, 0.4)",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : "#000",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : "#000",
    }),
  };

  const customToolTipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "#232323",
            color: "#E8E8E8",
            border: "1px solid #ccc",
            fontSize: "14px",
            // width:'max-content',
            maxWidth: 410,
            // whiteSpace: "pre-line",
            textAlign: "justify",
          },
          arrow: {
            color: "#232323",
          },
        },
      },
    },
  });
  const tooltipContent = (
    <ul
      style={{
        margin: 0,
        padding: "0.5em 1em",
        display: "flex",
        gap: 7,
        flexDirection: "column",
        textAlign: "left",
      }}
    >
      <li>
        <b>Managers :</b> Can view the Report for All Members.
      </li>
      <li>
        <b>Assistant Managers :</b> Can view the Report for Users only.
      </li>
      <li>
        <b>Users & Viewers :</b> Do not have access to the Report.
      </li>
    </ul>
  );

  return (
    <>
      <Box className="content-header report-top-bar billable-report-module-wrapper">
        <Box className="report-top-filter">
          <KeyboardBackspaceIcon
            style={{ fontSize: "36px", cursor: "pointer", fill: "#777" }}
            onClick={() => navigate("/reports")}
            className="back-btn"
          />
          <Box className="calendar-ui">
            <DateRangePicker
              ranges={ranges}
              onChange={handleDateRangeChange}
              value={selectedDates}
              shouldDisableDate={afterToday()}
              format="dd-MM-yyyy"
            />
          </Box>
          <Select
            className=" report-select-date report-select-options select-project select-dot-option"
            placeholder={
              userRole === ROLES.admin ? "Select Manager" : "Select Member"
            }
            value={selectedAssignee}
            onChange={setSelectedAssignee}
            options={assigneeOption}
            classNamePrefix="select"
            styles={getDotColorStyle("SINGLE")}
            theme={(theme) => ({
              ...theme,
              borderRadius: 10,
              colors: {
                ...theme.colors,
                primary25: "rgba(140, 199, 20, 0.4);",
                primary: "#8CC714",
              },
            })}
          />

          <Select
            isMulti
            className=" report-select-date report-select-options2 select-project2 bg-white"
            placeholder="Select Project"
            value={selectedProject}
            onChange={handleSelectedProject}
            options={projectOptions}
            classNamePrefix="select"
            theme={(theme) => ({
              ...theme,
              borderRadius: 10,
              colors: {
                ...theme.colors,
                primary25: "rgba(140, 199, 20, 0.4);",
                primary: "#8CC714",
              },
            })}
            styles={customStyles}
          />
          <Box item className="content-header-right report-btn-icon mb-0">
            <Button onClick={downloadCSV}>
              <FiDownloadCloud /> To CSV
            </Button>
            <ThemeProvider theme={customToolTipTheme}>
              <Tooltip
                title={tooltipContent}
                placement="bottom"
                arrow
                sx={{ backgroundColor: "none !important" }}
              >
                <img src={helpicon} alt="Help Icon" />
              </Tooltip>
            </ThemeProvider>
          </Box>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xxl={12}>
          <Box className="card mb-0">
            <TableContainer
              className="billable-report-table-wrapper"
              style={{
                maxHeight:
                  reportsData.length !== 0 ? "calc(100vh - 235px)" : "auto",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#C5E389",
                        position: "sticky",
                        top: 0,
                        left: 0,
                        zIndex: 1000,
                      }}
                    >
                      Member
                    </TableCell>
                    {reportsData[0]?.map((date, index) => (
                      <TableCell
                        key={index + "dates"}
                        colSpan={2}
                        style={{
                          textAlign: "center",
                          position: "sticky",
                          top: 0,
                          backgroundColor: "#C5E389",
                        }}
                      >
                        {convertDateIntoDDMMYYYY(date)}
                      </TableCell>
                    ))}
                    <TableCell
                      colSpan={2}
                      sx={{
                        textAlign: "center",
                        top: 0,
                        // right: 0,
                        position: "sticky",
                        zIndex: 1001,
                        backgroundColor: "#C5E389",
                      }}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      // colSpan={1}
                      sx={{
                        textAlign: "center",
                        top: 0,
                        right: "0",
                        position: "sticky",
                        zIndex: 1001,
                        backgroundColor: "#C5E389",
                      }}
                    >
                      Percentage
                      <TbArrowsSort
                        style={{
                          stroke: "rgb(0 0 0 / 52%)",
                          width: "15px",
                          cursor: "pointer",
                          marginLeft: "1px",
                        }}
                        onClick={() => {
                          setOrder(order === "DESC" ? "ASC" : "DESC");
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportsData.length !== 0 ? (
                    <>
                      <TableRow className="report-table-row">
                        <TableCell
                          sx={{
                            position: "sticky",
                            left: "0",
                            top: "48px",
                            zIndex: 1000,
                            backgroundColor: "#323232",
                          }}
                          className="report-table-date"
                        >
                          {" "}
                        </TableCell>
                        {reportsData[0]?.map((date, index) => (
                          <>
                            <TableCell
                              key={index + "billable"}
                              style={{
                                textAlign: "center",
                                color: "#ffffff",
                                borderLeft: "1px solid white",
                                position: "sticky",
                                top: "48px",
                                backgroundColor: "#323232",
                              }}
                            >
                              B
                            </TableCell>
                            <TableCell
                              key={index + "nonbillable"}
                              style={{
                                textAlign: "center",
                                color: "#ffffff",
                                borderRight: "1px solid white",
                                position: "sticky",
                                top: "48px",
                                backgroundColor: "#323232",
                              }}
                            >
                              NB
                            </TableCell>
                          </>
                        ))}
                        {/* <TableCell
                          className="report-table-date"
                          style={{
                            position: "sticky",
                            right: 0,
                            top: "48px",
                            zIndex: 1000,
                            backgroundColor: "#323232",
                          }}
                        > */}
                        <TableCell
                          style={{
                            textAlign: "center",
                            color: "#ffffff",
                            borderLeft: "1px solid white",
                            position: "sticky",
                            top: "48px",
                            zIndex: 1000,
                            right: "284.5px",
                            backgroundColor: "#323232",
                            minWidth: "141px",
                            maxWidth: "141px",
                          }}
                        >
                          B
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            color: "#ffffff",
                            borderRight: "1px solid white",
                            position: "sticky",
                            top: "48px",
                            zIndex: 1000,
                            right: "144px",
                            backgroundColor: "#323232",
                            minWidth: "141px",
                            maxWidth: "141px",
                          }}
                        >
                          NB
                        </TableCell>
                        <TableCell
                          className="report-table-date"
                          style={{
                            position: "sticky",
                            right: 0,
                            top: "48px",
                            zIndex: 1000,
                            backgroundColor: "#323232",
                          }}
                        ></TableCell>
                        {/* <Grid container justifyContent="space-around">
                            <Grid item>B</Grid>
                            <Grid item>NB</Grid>
                          </Grid> */}
                        {/* </TableCell> */}
                      </TableRow>
                      {billableNonbillableReportData?.map((item, index) => (
                        <>
                          <TableRow key={index + "billable_nonbillable"}>
                            <TableCell
                              style={{
                                textAlign: "left",
                                position: "sticky",
                                left: "0",
                                zIndex: 999,
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <ProjectName
                                ProjectInitial={
                                  item.first_name
                                    ? item.first_name.charAt(0).toUpperCase()
                                    : ""
                                }
                                ProjectTitle={
                                  item.first_name + " " + item.last_name
                                }
                              />
                            </TableCell>
                            {item?.data?.map((data, index) => (
                              <>
                                <TableCell key={index + "billable"}>
                                  {data === "No Data"
                                    ? "00:00"
                                    : convertSecondsIntoHHMM(
                                        data.billable
                                      )}{" "}
                                  Hrs
                                </TableCell>
                                <TableCell key={index + "nonbillable"}>
                                  {data === "No Data"
                                    ? "00:00"
                                    : convertSecondsIntoHHMM(
                                        data.non_billable
                                      )}{" "}
                                  Hrs
                                </TableCell>
                              </>
                            ))}
                            {/* <TableCell
                              sx={{
                                textAlign: "left",
                                position: "sticky",
                                right: "0",
                                zIndex: 999,
                                backgroundColor: "white",
                              }}
                            >
                              <Grid container justifyContent="space-around">
                                <Grid item>
                                  {convertSecondsIntoHHMM(item.total_billable)}{" "}
                                  Hrs
                                </Grid>
                                <Grid item>
                                  {convertSecondsIntoHHMM(
                                    item.total_non_billable
                                  )}{" "}
                                  Hrs
                                </Grid>
                              </Grid>
                            </TableCell> */}
                            <TableCell
                              sx={{
                                textAlign: "left",
                                position: "sticky",
                                right: "284.5px",
                                zIndex: 999,
                                backgroundColor: "white",
                                minWidth: "141px",
                                maxWidth: "141px",
                              }}
                            >
                              {convertSecondsIntoHHMM(item.total_billable)} Hrs
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "left",
                                position: "sticky",
                                right: "144.5px",
                                zIndex: 999,
                                backgroundColor: "white",
                                minWidth: "141px",
                                maxWidth: "141px",
                              }}
                            >
                              {convertSecondsIntoHHMM(item.total_non_billable)}{" "}
                              Hrs
                            </TableCell>

                            <TableCell
                              sx={{
                                textAlign: "left",
                                position: "sticky",
                                right: "0",
                                zIndex: 999,
                                backgroundColor: "white",
                              }}
                            >
                              {Math.round(item?.percentage)}%
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                      <TableRow>
                        <TableCell
                          style={{
                            textAlign: "left",
                            position: "sticky",
                            left: "0",
                            bottom: 0,
                            zIndex: 999,
                            borderRight: "1px solid white",
                            backgroundColor: "#C5E389",
                          }}
                        ></TableCell>
                        {totalPerDayBillable?.map((data, index) => (
                          <>
                            <TableCell
                              key={index + "billable"}
                              sx={{
                                textAlign: "left",
                                position: "sticky",
                                right: "0",
                                bottom: 0,
                                zIndex: 998,
                                borderLeft: "1px solid white",
                                color: "black !important",
                                fontWeight: 300,
                                backgroundColor: "#C5E389",
                              }}
                            >
                              {convertSecondsIntoHHMM(
                                totalPerDayBillable[index]
                              )}
                              Hrs
                            </TableCell>
                            <TableCell
                              key={index + "nonbillable"}
                              className="temp"
                              sx={{
                                position: "sticky",
                                bottom: 0,
                                zIndex: 998,
                                color: "black !important",
                                fontWeight: 400,
                                backgroundColor: "#C5E389",
                              }}
                            >
                              {convertSecondsIntoHHMM(
                                totalPerDayNonBillable[index]
                              )}
                              Hrs
                            </TableCell>
                          </>
                        ))}
                        {/* <TableCell
                          sx={{
                            position: "sticky",
                            right: "0",
                            bottom: 0,
                            zIndex: 999,
                            color: "black !important",
                            fontWeight: 400,
                            backgroundColor: "#C5E389",
                          }}
                        >
                          <Grid container justifyContent="space-around">
                            <Grid item>
                              {convertSecondsIntoHHMM(mainTotalBillable)} Hrs
                            </Grid>
                            <Grid item>
                              {convertSecondsIntoHHMM(mainTotalNonBillable)} Hrs
                            </Grid>
                          </Grid>
                        </TableCell> */}
                        <TableCell
                          // key={index + "nonbillable"}
                          className="temp"
                          sx={{
                            position: "sticky",
                            bottom: 0,
                            right: "284.5px",
                            zIndex: 999,
                            color: "black !important",
                            fontWeight: 400,
                            backgroundColor: "#C5E389",
                            minWidth: "141px",
                            maxWidth: "141px",
                          }}
                        >
                          {convertSecondsIntoHHMM(mainTotalBillable)} Hrs
                        </TableCell>
                        <TableCell
                          // key={index + "nonbillable"}
                          className="temp"
                          sx={{
                            position: "sticky",
                            bottom: 0,
                            zIndex: 999,
                            right: "144.5px",
                            color: "black !important",
                            fontWeight: 400,
                            backgroundColor: "#C5E389",
                            minWidth: "141px",
                            maxWidth: "141px",
                          }}
                        >
                          {convertSecondsIntoHHMM(mainTotalNonBillable)} Hrs
                        </TableCell>
                        <TableCell
                          // key={index + "nonbillable"}
                          className="temp"
                          sx={{
                            position: "sticky",
                            bottom: 0,
                            right: "0",
                            zIndex: 1005,
                            color: "black !important",
                            fontWeight: 400,
                            backgroundColor: "#C5E389",
                          }}
                        >
                          <Grid container justifyContent="space-around">
                            {Math.round(
                              (mainTotalBillable /
                                (mainTotalBillable + mainTotalNonBillable)) *
                                100
                            )}
                            %
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <TableRow style={{ backgroundColor: "#fff" }}>
                      <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                        No Records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BillableNonBillable;
