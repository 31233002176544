import React from 'react';
import SiteLogo from '../../assets/authentication/siteLogo.png';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

const Loader = () => {
  return (
    <Backdrop
        sx={{ color: '#fff', zIndex: 9999 }}
        open={true}
        >
        <Box className="loader-container">
          <Box className='loader-logo pulse'>
            <img src={SiteLogo} alt='Loader-logo' />
          </Box>
        </Box> 
    </Backdrop>
  )
}

export default Loader