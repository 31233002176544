import { put, takeEvery } from "redux-saga/effects";
import {
  addFilters,
  deleteFilterById,
  fetchIncompleteTimeSheet,
  getBillableNonBillableProjectwiseUsersData,
  getBillableNonBillableReportData,
  getBillableNonBillableTypewiseReportData,
  getFilterData,
  getReportsData,
  getScreenshotReportData,
  getWindowReportsData,
  updateFilter,
} from "../../apis/reports";
import {
  ADD_FILTER,
  ADD_FILTER_SUCCESS,
  DELETE_FILTER,
  DELETE_FILTER_SUCCESS,
  FETCH_BILLABLE_NONBILLABLE_REPORTS,
  FETCH_BILLABLE_NONBILLABLE_TYPEWISE_REPORTS,
  FETCH_FILTERS,
  FETCH_INCOMPLETE_TIME_SHEET,
  FETCH_REPORTS,
  FETCH_WINDOW_REPORTS,
  FETCH_SCREENSHOT_REPORTS,
  SET_FEEDBACK,
  SET_LOADING,
  STORE_BILLABLE_NONBILLABLE_REPORTS,
  STORE_FILTERS,
  STORE_INCOMPLETE_TIME_SHEET,
  STORE_REPORTS,
  STORE_SCREENSHOT_REPORTS,
  UPDATE_FILTER,
  UPDATE_FILTER_SUCCESS,
  STORE_WINDOWREPORTS,
  STORE_BILLABLE_TYPEWISE_NONBILLABLE_REPORTS,
  FETCH_BILLABLE_NONBILLABLE_PROJECTWISE_USERS,
  STORE_BILLABLE_NONBILLABLE_PROJECTWISE_USERS,
} from "../redux/actionTypes";
import { showSuccessToast } from "../../utils/toast";

function* fetchReportHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield getReportsData(action.payload);
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      const payload = response.data.data;
      yield put({ type: STORE_REPORTS, payload });
    } else if (response.data.status === 404) {
      yield put({ type: STORE_REPORTS, payload: [] });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* fetchWindowReportHandler(action) {
  try {
    // console.log("action *******************", action);
    yield put({ type: SET_LOADING, payload: true });
    const response = yield getWindowReportsData(action.payload);
    yield put({ type: SET_LOADING, payload: false });

    // console.log('response ****************', response)

    if (response.data.status === 200) {
      const payload = response.data.data;
      yield put({ type: STORE_WINDOWREPORTS, payload });
    } else if (response.data.status === 404) {
      yield put({ type: STORE_WINDOWREPORTS, payload: [] });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    console.log("err", error);
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* fetchFilterHandler(action) {
  try {
    // yield put({ type: SET_LOADING, payload:true });
    const response = yield getFilterData(action.payload);
    // yield put({ type: SET_LOADING, payload:false });

    if (response.data.status === 200) {
      const payload = response.data.data;
      yield put({ type: STORE_FILTERS, payload });
    } else if (response.data.status === 404) {
      yield put({ type: STORE_FILTERS, payload: [] });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* addFilterHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield addFilters(action.payload);
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      showSuccessToast("Filter saved successfully");

      yield put({ type: ADD_FILTER_SUCCESS, payload: response.data.data });
      // yield fetchFilterHandler();
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* updateFilterHandler(action) {
  try {
    const id = action.payload.id;
    const response = yield updateFilter({ ...action.payload }, id);

    if (response.data.status === 200) {
      showSuccessToast("Filter Updated Successfully");
      yield put({ type: UPDATE_FILTER_SUCCESS, payload: response.data.data });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* deleteFilterHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield deleteFilterById(action.payload);
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      yield showSuccessToast("Filter deleted successfully");
      yield put({ type: DELETE_FILTER_SUCCESS, payload: action.payload });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* fetchScreenshotReportHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield getScreenshotReportData(action.payload);
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      const payload = response.data.data;
      yield put({ type: STORE_SCREENSHOT_REPORTS, payload });
    } else if (response.data.status === 404) {
      yield put({ type: STORE_SCREENSHOT_REPORTS, payload: [] });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* fetchIncompleteTimeSheetHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield fetchIncompleteTimeSheet(action.payload);
    if (response.data.status === 200) {
      // const payload = { totalCount: response.data.data.totalCount, data: response.data.data.finalData };
      const payload = {
        totalCount: response.data.data.totalCount,
        data: response.data.data.finalData,
      };
      yield put({ type: STORE_INCOMPLETE_TIME_SHEET, payload });
    } else if (response.data.status === 404) {
      const payload = { totalCount: 0, data: [] };
      yield put({ type: STORE_INCOMPLETE_TIME_SHEET, payload });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  } finally {
    yield put({ type: SET_LOADING, payload: false });
  }
}

function* fetchBillableNonbillableHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield getBillableNonBillableReportData(action.payload);
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      const payload = response.data.data;
      yield put({ type: STORE_BILLABLE_NONBILLABLE_REPORTS, payload });
    } else if (response.data.status === 404) {
      yield put({ type: STORE_BILLABLE_NONBILLABLE_REPORTS, payload: [] });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* fetchBillableNonbillableTypewiseHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield getBillableNonBillableTypewiseReportData(
      action.payload
    );
    yield put({ type: SET_LOADING, payload: false });
    // console.log("response", response);
    if (response.data.status === 200) {
      const payload = response.data.data;
      yield put({ type: STORE_BILLABLE_TYPEWISE_NONBILLABLE_REPORTS, payload });
    } else if (response.data.status === 404) {
      yield put({
        type: STORE_BILLABLE_TYPEWISE_NONBILLABLE_REPORTS,
        payload: [],
      });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}
function* fetchBillableNonbillableProjectwiseUsersHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield getBillableNonBillableProjectwiseUsersData(
      action.payload
    );
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      const payload = response.data.data;
      yield put({
        type: STORE_BILLABLE_NONBILLABLE_PROJECTWISE_USERS,
        payload,
      });
    } else if (response.data.status === 404) {
      yield put({
        type: STORE_BILLABLE_NONBILLABLE_PROJECTWISE_USERS,
        payload: [],
      });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* watchReportAction() {
  yield takeEvery(FETCH_REPORTS, fetchReportHandler);
  yield takeEvery(FETCH_WINDOW_REPORTS, fetchWindowReportHandler);
  yield takeEvery(FETCH_FILTERS, fetchFilterHandler);
  yield takeEvery(ADD_FILTER, addFilterHandler);
  yield takeEvery(UPDATE_FILTER, updateFilterHandler);
  yield takeEvery(DELETE_FILTER, deleteFilterHandler);
  yield takeEvery(FETCH_SCREENSHOT_REPORTS, fetchScreenshotReportHandler);
  yield takeEvery(FETCH_INCOMPLETE_TIME_SHEET, fetchIncompleteTimeSheetHandler);
  yield takeEvery(
    FETCH_BILLABLE_NONBILLABLE_REPORTS,
    fetchBillableNonbillableHandler
  );
  yield takeEvery(
    FETCH_BILLABLE_NONBILLABLE_TYPEWISE_REPORTS,
    fetchBillableNonbillableTypewiseHandler
  );
  yield takeEvery(
    FETCH_BILLABLE_NONBILLABLE_PROJECTWISE_USERS,
    fetchBillableNonbillableProjectwiseUsersHandler
  );
}

export default watchReportAction;
