import {
  deleteAPICall,
  getAPICall,
  patchAPICall,
  postAPICall,
  setIdInUrl,
} from "../lib/callApi";
import {
  CREATE_EMPLOYEE_BY_EMAIL_API,
  DELETE_EMPLOYEE_API,
  FETCH_ALL_EMPLOYEES_INVITE_BY_MAIL_API,
  FETCH_ALL_EMPLOYEES_API,
  FETCH_EMPLOYEES_BY_ID_API,
  FETCH_USER_ROLES_API,
  FILTER_EMPLOYEE_API,
  INVITE_USER_UPDATE_API,
  PROJECT_BY_USER_FOR_UPDATE_API,
  RESEND_INVITE,
  RESET_PASSWORD_API,
  SEARCH_EMPLOYEES_API,
  SEARCH_EMPLOYEES_INVITE_API,
  UPDATE_EMPLOYEE_API,
  UPDATE_PROJECT_BY_USER_API,
  VERIFY_TOKEN_API,
} from "../lib/constants";

export const fetchEmployee = async (params) => {
  const data = await getAPICall(FETCH_ALL_EMPLOYEES_API, true, params);

  return data;
};

export const fetchEmployeeInviteByMail = async () => {
  const data = await getAPICall(FETCH_ALL_EMPLOYEES_INVITE_BY_MAIL_API, true);
  return data;
};

export const fetchUserRoles = async () => {
  const data = await getAPICall(FETCH_USER_ROLES_API, true);
  return data;
};

export const fetchUserById = async (id) => {
  const data = await getAPICall(FETCH_EMPLOYEES_BY_ID_API, true, undefined, id);
  return data;
};

export const createEmployeeInviteByMail = async (payload) => {
  const data = await postAPICall(CREATE_EMPLOYEE_BY_EMAIL_API, true, payload);
  return data;
};

export const deleteEmployeeData = async (payload) => {
  const data = await deleteAPICall(DELETE_EMPLOYEE_API, true, payload);
  return data;
};

export const updateEmployeeData = async (payload, id) => {
  const data = await patchAPICall(UPDATE_EMPLOYEE_API, true, payload, id);
  return data;
};

export const filterUser = async (payload) => {
  const data = await getAPICall(FILTER_EMPLOYEE_API, true, payload);
  return data;
};

export const searchEmployeeData = async (params) => {
  const data = await getAPICall(SEARCH_EMPLOYEES_API, true, params);
  return data;
};

export const searchEmployeeInviteData = async (params) => {
  const data = await getAPICall(SEARCH_EMPLOYEES_INVITE_API, true, params);
  return data;
};

export const resetPasswordData = async (payload) => {
  const data = await postAPICall(RESET_PASSWORD_API, true, payload);
  return data;
};

export const fetchProjectByEmployeeId = async (params, id) => {
  const newUrl = setIdInUrl(PROJECT_BY_USER_FOR_UPDATE_API, id);
  const data = await getAPICall(newUrl, true, params);
  return data;
};

export const updateProjectByEmployeeId = async (payload, id) => {
  const newUrl = setIdInUrl(UPDATE_PROJECT_BY_USER_API, id);
  const data = await patchAPICall(newUrl, true, payload);
  return data;
};

export const resendInvitation = async (payload) => {
  const data = await postAPICall(RESEND_INVITE, true, payload);
  return data;
};

export const inviteEmployeeUpdate = async (payload) => {
  const data = await patchAPICall(INVITE_USER_UPDATE_API, true, payload);
  return data;
};

export const verifyInviteToken = async (payload) => {
  // const data = await postAPICall(VERIFY_INVITE_TOKEN_API, false, payload);
  const data = await postAPICall(VERIFY_TOKEN_API, false, payload);
  return data;
};
