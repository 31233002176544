import {
  deleteAPICall,
  getAPICall,
  patchAPICall,
  postAPICall,
} from "../lib/callApi";
import {
  CREATE_FILTER_API,
  DELETE_FILTER_API,
  FETCH_BILLABLE_NONBILLABLE_PROJECTWISE_USERS_API,
  FETCH_BILLABLE_NONBILLABLE_REPORT_API,
  FETCH_BILLABLE_NONBILLABLE_TYPEWISE_REPORT_API,
  FETCH_FILTERS_API,
  FETCH_FILTER_BY_ID_API,
  FETCH_INCOMPLETE_TIME_SHEET_API,
  FETCH_REPORTS_API,
  FETCH_SCREENSHOT_REPORT_API,
  FETCH_WINDOWREPORTS_API,
  UPDATE_FILTER_API,
} from "../lib/constants";
import {
  FETCH_BILLABLE_NONBILLABLE_PROJECTWISE_USERS,
  FETCH_BILLABLE_NONBILLABLE_TYPEWISE_REPORTS,
} from "../store/redux/actionTypes";

export const getReportsData = async (params) => {
  const data = await getAPICall(FETCH_REPORTS_API, true, params);
  return data;
};
export const getWindowReportsData = async (params) => {
  const data = await getAPICall(FETCH_WINDOWREPORTS_API, true, params);
  return data;
};

export const getFilterData = async (params) => {
  const data = await getAPICall(FETCH_FILTERS_API, true, params);
  return data;
};

export const addFilters = async (payload) => {
  const data = await postAPICall(CREATE_FILTER_API, true, payload);
  return data;
};

export const fetchFilterById = async (filterId) => {
  const data = await getAPICall(
    FETCH_FILTER_BY_ID_API,
    true,
    undefined,
    filterId
  );
  return data;
};

export const updateFilter = async (payload, filterId) => {
  const data = await patchAPICall(UPDATE_FILTER_API, true, payload, filterId);
  return data;
};

export const deleteFilterById = async (filterId) => {
  const data = await deleteAPICall(DELETE_FILTER_API, true, filterId);
  return data;
};

export const getScreenshotReportData = async (params) => {
  const data = await getAPICall(FETCH_SCREENSHOT_REPORT_API, true, params);
  return data;
};

export const getBillableNonBillableReportData = async (dataa) => {
  const data = await getAPICall(
    FETCH_BILLABLE_NONBILLABLE_REPORT_API,
    true,
    dataa.params,
    "",
    dataa.query_string
  );
  return data;
};
export const getBillableNonBillableTypewiseReportData = async (dataa) => {
  const data = await getAPICall(
    FETCH_BILLABLE_NONBILLABLE_TYPEWISE_REPORT_API,
    true,
    dataa.params,
    "",
    dataa.query_string
  );
  return data;
};
export const getBillableNonBillableProjectwiseUsersData = async (dataa) => {
  const data = await getAPICall(
    FETCH_BILLABLE_NONBILLABLE_PROJECTWISE_USERS_API,
    true,
    dataa.params,
    "",
    dataa.query_string
  );
  return data;
};

export const fetchIncompleteTimeSheet = async (params) => {
  const response = await getAPICall(
    FETCH_INCOMPLETE_TIME_SHEET_API,
    true,
    params
  );
  return response;
};
