/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Select from "react-select";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import { TbArrowsSort } from "react-icons/tb";
import { FiDownloadCloud } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_BILLABLE_NONBILLABLE_TYPEWISE_REPORT_API,
  ROLES,
  storageName,
} from "../../lib/constants";
import {
  convertDateIntoDDMMYYYY,
  convertDateIntoYYYYMMDD,
  convertSecondsIntoHHMM,
  getDateRangeOptions,
  getFilterStorage,
  getPermissionAccess,
  setFilterStorage,
  getLocalTimeZone,
} from "../../utils/helper";
import { fetchProjects } from "../../store/redux/actions/project.action";
import {
  setFeedback,
  setLoading,
} from "../../store/redux/actions/feedback.action";
import {
  fetchBillableNonBillableProjectwiseUsers,
  fetchBillableNonBillableTyewiseReports,
} from "../../store/redux/actions/reports.action";
import ProjectName from "../../components/common/ProjectName";
import axios from "axios";
import {
  Checkbox,
  createTheme,
  FormControlLabel,
  IconButton,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import helpicon from "../../assets/layout/helpicon.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const { afterToday } = DateRangePicker;
const ranges = getDateRangeOptions();

const BillableNonBillableProjects = () => {
  const filterData = getFilterStorage(storageName.billableNonBillableTypewise);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const [isNonBillable, setIsNonBillable] = useState(filterData?.isNonBillable);

  const [order, setOrder] = useState("ASC");
  const [projectsFetched, setprojectsFetched] = useState(false);
  const userDetails = useSelector((state) => state.user.data);
  const userRole = useSelector((state) => state.user.data?.user_role?.id);
  const permissions = userDetails.role_permissions.permissions;
  const permissionsAccess = getPermissionAccess(permissions, "Reports");
  if (
    !permissionsAccess.view &&
    !permissionsAccess.add &&
    !permissionsAccess.edit &&
    !permissionsAccess.delete
  ) {
    navigate("/", { replace: true });
  }

  if (
    (userDetails?.user_role?.id === ROLES.employee &&
      !userDetails.is_assistant_manager) ||
    userDetails?.user_role?.id === ROLES.viewer
  ) {
    navigate("/", { replace: true });
  }

  const projects = useSelector((state) => state.project.projects_list);
  const BNBprojectReportsData = useSelector(
    (state) => state.reports.billableNonbillableTypewiseReport
  );
  const BNBprojectWiseUsers = useSelector(
    (state) => state.reports.billableNonbillableProjectwiseUsers
  );
  const billableNonbillableProjectReportData = BNBprojectReportsData.slice(1);

  const workedDates = BNBprojectReportsData[0];
  let projectOptions = [];
  projectOptions.push(
    ...projects?.map((proj) => ({ value: proj.id, label: proj.title }))
  );

  const initialSelectedDates = [new Date(), new Date()];
  const [selectedDates, setSelectedDates] = useState(
    filterData &&
      filterData.selectedDates &&
      filterData.selectedDates[0] &&
      filterData.selectedDates[1]
      ? [
          new Date(filterData.selectedDates[0]),
          new Date(filterData.selectedDates[1]),
        ]
      : initialSelectedDates
  );

  const [selectedProject, setSelectedProject] = useState(
    filterData?.project_id || []
  );

  const handleDateRangeChange = (date) => {
    setOpen(null);
    // setprojectsFetched(false);
    if (date === null) {
      setSelectedDates(initialSelectedDates);
    } else {
      setSelectedDates(date);
    }
  };

  const handleSelectedProject = (options) => {
    setOpen(null);
    // setprojectsFetched(false);
    setSelectedProject(options);
  };
  const changeIsNonBillable = (e) => {
    setOpen(null);
    // setprojectsFetched(false);
    setIsNonBillable(e.target.checked);
  };
  const fetchProjectsData = useCallback(async () => {
    try {
      setprojectsFetched(false);
      const params = {
        listing: true,
        isListing: true,
        billable_nonbillable: true,
        is_non_billable: isNonBillable ? 1 : 0,
        start_date: selectedDates[0]
          ? convertDateIntoYYYYMMDD(selectedDates[0])
          : convertDateIntoYYYYMMDD(new Date()),
        end_date: selectedDates[1]
          ? convertDateIntoYYYYMMDD(selectedDates[1])
          : convertDateIntoYYYYMMDD(new Date()),
        setprojectsFetched,
      };
      dispatch(fetchProjects(params));
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  }, [dispatch, isNonBillable, selectedDates]);

  useEffect(() => {
    fetchProjectsData();
  }, [isNonBillable, selectedDates]);

  const fetchBillNonbillTypewiseReportData = useCallback(
    async (flag = "") => {
      try {
        let params = {
          start_date: selectedDates[0]
            ? convertDateIntoYYYYMMDD(selectedDates[0])
            : convertDateIntoYYYYMMDD(new Date()),
          end_date: selectedDates[1]
            ? convertDateIntoYYYYMMDD(selectedDates[1])
            : convertDateIntoYYYYMMDD(new Date()),

          is_non_billable: isNonBillable ? 1 : 0,
          sort: order,
        };
        let query_string = "";
        selectedProject?.map(
          (project) => (query_string += `project=${project.value}&`)
        );
        query_string = query_string.slice(0, -1);

        setFilterStorage(storageName.billableNonBillableTypewise, {
          selectedDates,
          project_id: selectedProject,
          isNonBillable,
        });
        if (flag === "" && projectsFetched) {
          dispatch(
            fetchBillableNonBillableTyewiseReports({
              params: params,
              query_string: query_string,
            })
          );
        } else if (flag === "excel") {
          params = { ...params, file: "excel", timezone: getLocalTimeZone() };
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${FETCH_BILLABLE_NONBILLABLE_TYPEWISE_REPORT_API}?${query_string}`,
            { params, responseType: "blob" }
          );
          dispatch(setLoading(false));

          if (response.status !== 200) {
            throw new Error("No Record Found!");
          }

          const stream = await response.data;
          const blob = new Blob([stream], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = `billable_nonbillable_projects_${params.start_date.replaceAll(
            "-",
            ""
          )}_${params.end_date.replaceAll("-", "")}.xlsx`;

          document.body.appendChild(downloadLink);
          downloadLink.click();

          URL.revokeObjectURL(url);
        }
      } catch (error) {
        const payload = {
          code: error.status,
          status: "fail",
          message:
            error.response.status === 404
              ? "No data found"
              : error.message || "Something went wrong.",
        };
        dispatch(setFeedback(payload));
      }
    },
    [
      dispatch,
      selectedDates,
      selectedProject,
      isNonBillable,
      order,
      projectsFetched,
    ]
  );

  const downloadCSV = () => {
    fetchBillNonbillTypewiseReportData("excel");
  };

  useEffect(() => {
    fetchBillNonbillTypewiseReportData();
  }, [fetchBillNonbillTypewiseReportData]);

  const handleFetchProjectwiseUsers = (pid) => {
    try {
      let params = {
        start_date: selectedDates[0]
          ? convertDateIntoYYYYMMDD(selectedDates[0])
          : convertDateIntoYYYYMMDD(new Date()),
        end_date: selectedDates[1]
          ? convertDateIntoYYYYMMDD(selectedDates[1])
          : convertDateIntoYYYYMMDD(new Date()),
        project: pid,
      };
      let query_string = "";
      workedDates?.map((date) => (query_string += `dates=${date}&`));
      query_string = query_string.slice(0, -1);

      dispatch(
        fetchBillableNonBillableProjectwiseUsers({
          params: params,
          query_string: query_string,
        })
      );
    } catch (err) {
      console.log("err", err);
    }
  };
  const totalPerDayBillable =
    new Array(
      BNBprojectReportsData.length > 0 ? BNBprojectReportsData[0].length : 0
    ).fill(0) || [];
  const totalPerDayNonBillable =
    new Array(
      BNBprojectReportsData.length > 0 ? BNBprojectReportsData[0].length : 0
    ).fill(0) || [];

  billableNonbillableProjectReportData.forEach((item, i) => {
    item.data.forEach((item2, index) => {
      if (item2 !== "No Data") {
        totalPerDayBillable[index] += +item2.billable;
        totalPerDayNonBillable[index] += +item2.non_billable;
      }
    });
  });

  const mainTotalBillable = totalPerDayBillable.reduce(
    (acc, item) => acc + +item,
    0
  );
  const mainTotalNonBillable = totalPerDayNonBillable.reduce(
    (acc, item) => acc + +item,
    0
  );
  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#8CC714" : "rgba(140, 199, 20, 0.4)",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : "#000",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : "#000",
    }),
  };
  const customToolTipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "#232323",
            color: "#E8E8E8",
            border: "1px solid #ccc",
            fontSize: "14px",
            // width:'max-content',
            maxWidth: 410,
            // whiteSpace: "pre-line",
            textAlign: "justify",
          },
          arrow: {
            color: "#232323",
          },
        },
      },
    },
  });
  const tooltipContent = (
    <ul
      style={{
        margin: 0,
        padding: "0.5em 1em",
        display: "flex",
        gap: 7,
        flexDirection: "column",
        textAlign: "left",
      }}
    >
      <li>
        <b>Managers and Assistant Managers :</b> Can view the Report for their
        own Projects.
      </li>
      <li>
        <b>Users & Viewers :</b> Do not have access to the Report.
      </li>
    </ul>
  );

  return (
    <>
      <Box className="content-header report-top-bar billable-report-module-wrapper">
        <Box className="report-top-filter">
          <KeyboardBackspaceIcon
            style={{ fontSize: "36px", cursor: "pointer", fill: "#777" }}
            onClick={() => navigate("/reports")}
            className="back-btn"
          />
          <Box className="calendar-ui">
            <DateRangePicker
              ranges={ranges}
              onChange={handleDateRangeChange}
              value={selectedDates}
              shouldDisableDate={afterToday()}
              format="dd-MM-yyyy"
            />
          </Box>

          <Select
            isMulti
            className=" report-select-date report-select-options2 select-project2 bg-white"
            placeholder="Select Project"
            value={selectedProject}
            onChange={handleSelectedProject}
            options={projectOptions}
            classNamePrefix="select"
            theme={(theme) => ({
              ...theme,
              borderRadius: 10,
              colors: {
                ...theme.colors,
                primary25: "rgba(140, 199, 20, 0.4);",
                primary: "#8CC714",
              },
            })}
            styles={customStyles}
          />

          <Box item className="content-header-right report-btn-icon mb-0">
            <FormControlLabel
              sx={{ margin: "0px" }}
              control={
                <Checkbox
                  className="checkbox-field"
                  checked={isNonBillable}
                  onChange={changeIsNonBillable}
                />
              }
              label="Non-Billable"
              className="report-checkbox-control"
            />
            <Button onClick={downloadCSV}>
              <FiDownloadCloud /> To CSV
            </Button>
            <ThemeProvider theme={customToolTipTheme}>
              <Tooltip
                title={tooltipContent}
                placement="bottom"
                arrow
                sx={{ backgroundColor: "none !important" }}
              >
                <img src={helpicon} alt="Help Icon" />
              </Tooltip>
            </ThemeProvider>
          </Box>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xxl={12}>
          <Box className="card mb-0">
            <TableContainer
              className="billable-report-table-wrapper"
              style={{
                maxHeight:
                  BNBprojectReportsData.length !== 0
                    ? "calc(100vh - 235px)"
                    : "auto",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#C5E389",
                        position: "sticky",
                        top: 0,
                        left: 0,
                        zIndex: 1001,
                      }}
                    >
                      Project
                    </TableCell>
                    {BNBprojectReportsData[0]?.map((date, index) => (
                      <TableCell
                        key={index + "dates"}
                        colSpan={2}
                        style={{
                          textAlign: "center",
                          position: "sticky",
                          top: 0,
                          zIndex: 1000,
                          backgroundColor: "#C5E389",
                        }}
                      >
                        {convertDateIntoDDMMYYYY(date)}
                      </TableCell>
                    ))}
                    <TableCell
                      colSpan={2}
                      sx={{
                        textAlign: "center",
                        top: 0,
                        // right: 0,
                        position: "sticky",
                        zIndex: 1001,
                        backgroundColor: "#C5E389",
                      }}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      // colSpan={1}
                      sx={{
                        textAlign: "center",
                        top: 0,
                        right: "0",
                        position: "sticky",
                        zIndex: 1001,
                        backgroundColor: "#C5E389",
                      }}
                    >
                      Percentage
                      <TbArrowsSort
                        style={{
                          stroke: "rgb(0 0 0 / 52%)",
                          width: "15px",
                          cursor: "pointer",
                          marginLeft: "1px",
                        }}
                        onClick={() => {
                          setOpen(null);
                          setOrder(order === "DESC" ? "ASC" : "DESC");
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {BNBprojectReportsData.length !== 0 ? (
                    <>
                      <TableRow
                        className="report-table-row"
                        sx={{ backgroundColor: "#323232" }}
                      >
                        <TableCell
                          sx={{
                            position: "sticky",
                            left: "0",
                            top: "48px",
                            zIndex: 1000,
                            backgroundColor: "#323232",
                          }}
                          className="report-table-date"
                        >
                          {" "}
                        </TableCell>
                        {BNBprojectReportsData[0]?.map((date, index) => (
                          <>
                            <TableCell
                              key={index + "billable"}
                              style={{
                                textAlign: "center",
                                color: "#ffffff",
                                borderLeft: "1px solid white",
                                position: "sticky",
                                top: "48px",
                                backgroundColor: "#323232",
                              }}
                            >
                              B
                            </TableCell>
                            <TableCell
                              key={index + "nonbillable"}
                              style={{
                                textAlign: "center",
                                color: "#ffffff",
                                borderRight: "1px solid white",
                                position: "sticky",
                                top: "48px",
                                backgroundColor: "#323232",
                              }}
                            >
                              NB
                            </TableCell>
                          </>
                        ))}

                        <TableCell
                          style={{
                            textAlign: "center",
                            color: "#ffffff",
                            borderLeft: "1px solid white",
                            position: "sticky",
                            top: "48px",
                            zIndex: 1000,
                            right: "285px",
                            backgroundColor: "#323232",
                            minWidth: "141px",
                            maxWidth: "141px",
                          }}
                        >
                          B
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            color: "#ffffff",
                            borderRight: "1px solid white",
                            position: "sticky",
                            top: "48px",
                            zIndex: 1000,
                            right: "145px",
                            backgroundColor: "#323232",
                            minWidth: "141px",
                            maxWidth: "141px",
                          }}
                        >
                          NB
                        </TableCell>
                        <TableCell
                          className="report-table-date"
                          style={{
                            position: "sticky",
                            right: 0,
                            top: "48px",
                            zIndex: 1000,
                            backgroundColor: "#323232",
                          }}
                        ></TableCell>
                      </TableRow>
                      {billableNonbillableProjectReportData?.map(
                        (item, index) => (
                          <>
                            <TableRow key={index + "billable_nonbillable"}>
                              <TableCell
                                style={{
                                  textAlign: "left",
                                  position: "sticky",
                                  left: "0",
                                  zIndex: 999,
                                  backgroundColor: "#ffffff",
                                  display: "flex",
                                }}
                              >
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                >
                                  {open === item?.id ? (
                                    <div onClick={() => setOpen(null)}>
                                      <KeyboardArrowUpIcon />
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => {
                                        handleFetchProjectwiseUsers(item?.id);
                                        setOpen(item?.id);
                                      }}
                                    >
                                      <KeyboardArrowDownIcon />
                                    </div>
                                  )}
                                </IconButton>
                                <ProjectName
                                  ProjectInitial={
                                    item?.title
                                      ? item.title
                                          .trim()
                                          .charAt(0)
                                          .toUpperCase()
                                      : ""
                                  }
                                  ProjectTitle={item.title}
                                />
                              </TableCell>
                              {item?.data?.map((data, index) => (
                                <>
                                  <TableCell key={index + "billable"}>
                                    {data === "No Data"
                                      ? "00:00"
                                      : convertSecondsIntoHHMM(
                                          data.billable
                                        )}{" "}
                                    Hrs
                                  </TableCell>
                                  <TableCell key={index + "nonbillable"}>
                                    {data === "No Data"
                                      ? "00:00"
                                      : convertSecondsIntoHHMM(
                                          data.non_billable
                                        )}{" "}
                                    Hrs
                                  </TableCell>
                                </>
                              ))}

                              <TableCell
                                sx={{
                                  textAlign: "left",
                                  position: "sticky",
                                  right: "285px",
                                  zIndex: 999,
                                  backgroundColor: "white",
                                  minWidth: "141px",
                                  maxWidth: "141px",
                                }}
                              >
                                {convertSecondsIntoHHMM(item.total_billable)}{" "}
                                Hrs
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "left",
                                  position: "sticky",
                                  right: "145px",
                                  zIndex: 999,
                                  backgroundColor: "white",
                                  minWidth: "141px",
                                  maxWidth: "141px",
                                }}
                              >
                                {convertSecondsIntoHHMM(
                                  item.total_non_billable
                                )}{" "}
                                Hrs
                              </TableCell>

                              <TableCell
                                sx={{
                                  textAlign: "left",
                                  position: "sticky",
                                  right: "0",
                                  zIndex: 999,
                                  backgroundColor: "white",
                                }}
                              >
                                {Math.round(item?.percentage)}%
                              </TableCell>
                            </TableRow>

                            {open === item.id ? (
                              BNBprojectWiseUsers?.length !== 0 ? (
                                BNBprojectWiseUsers?.map((u, idd) => (
                                  <TableRow
                                    key={idd + "billable_nonbillable_user"}
                                    sx={{ backgroundColor: "#f4f4f4" }}
                                  >
                                    <TableCell
                                      style={{
                                        textAlign: "left",
                                        position: "sticky",
                                        left: "0",
                                        zIndex: 999,
                                        backgroundColor: "#f4f4f4",
                                        display: "flex",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <div style={{ marginLeft: "75px" }}>
                                        {u.first_name + " " + u.last_name}
                                      </div>
                                    </TableCell>
                                    {u?.data?.map((data, index) => (
                                      <>
                                        <TableCell key={index + "billable"}>
                                          {data === "No Data"
                                            ? "00:00"
                                            : convertSecondsIntoHHMM(
                                                data.billable
                                              )}{" "}
                                          Hrs
                                        </TableCell>
                                        <TableCell key={index + "nonbillable"}>
                                          {data === "No Data"
                                            ? "00:00"
                                            : convertSecondsIntoHHMM(
                                                data.non_billable
                                              )}{" "}
                                          Hrs
                                        </TableCell>
                                      </>
                                    ))}
                                    <TableCell
                                      key={index + "billable"}
                                      sx={{
                                        right: "285px",
                                        position: "sticky",
                                        zIndex: 999,
                                        backgroundColor: "#f4f4f4",
                                      }}
                                    >
                                      {convertSecondsIntoHHMM(u.total_billable)}{" "}
                                      Hrs
                                    </TableCell>
                                    <TableCell
                                      key={index + "billable"}
                                      sx={{
                                        right: "145px",
                                        position: "sticky",
                                        zIndex: 999,
                                        backgroundColor: "#f4f4f4",
                                      }}
                                    >
                                      {convertSecondsIntoHHMM(
                                        u.total_non_billable
                                      )}{" "}
                                      Hrs
                                    </TableCell>

                                    <TableCell
                                      sx={{
                                        textAlign: "left",
                                        position: "sticky",
                                        right: "0",
                                        zIndex: 999,
                                        backgroundColor: "#f4f4f4",
                                      }}
                                    >
                                      {Math.round(u?.percentage)}%
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <Box
                                  sx={{
                                    padding: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  No Users Found
                                </Box>
                              )
                            ) : null}
                          </>
                        )
                      )}
                      <TableRow>
                        <TableCell
                          style={{
                            textAlign: "left",
                            position: "sticky",
                            left: "0",
                            bottom: 0,
                            zIndex: 999,
                            borderRight: "1px solid white",
                            backgroundColor: "#C5E389",
                          }}
                        ></TableCell>
                        {totalPerDayBillable?.map((data, index) => (
                          <>
                            <TableCell
                              key={index + "billable"}
                              // colSpan={2}
                              sx={{
                                textAlign: "left",
                                position: "sticky",
                                right: "0",
                                bottom: 0,
                                zIndex: 998,
                                borderLeft: "1px solid white",
                                color: "black !important",
                                fontWeight: 300,
                                backgroundColor: "#C5E389",
                              }}
                            >
                              {convertSecondsIntoHHMM(
                                totalPerDayBillable[index]
                              )}
                              Hrs
                            </TableCell>
                            <TableCell
                              key={index + "nonbillable"}
                              className="temp"
                              sx={{
                                position: "sticky",
                                bottom: 0,
                                zIndex: 998,
                                color: "black !important",
                                fontWeight: 400,
                                backgroundColor: "#C5E389",
                              }}
                            >
                              {convertSecondsIntoHHMM(
                                totalPerDayNonBillable[index]
                              )}
                              Hrs
                            </TableCell>
                          </>
                        ))}
                        <TableCell
                          // key={index + "nonbillable"}
                          className="temp"
                          sx={{
                            position: "sticky",
                            bottom: 0,
                            right: "285px",
                            zIndex: 999,
                            color: "black !important",
                            fontWeight: 400,
                            backgroundColor: "#C5E389",
                            minWidth: "141px",
                            maxWidth: "141px",
                          }}
                        >
                          {convertSecondsIntoHHMM(mainTotalBillable)} Hrs
                        </TableCell>
                        <TableCell
                          // key={index + "nonbillable"}
                          className="temp"
                          sx={{
                            position: "sticky",
                            bottom: 0,
                            zIndex: 999,
                            right: "145px",
                            color: "black !important",
                            fontWeight: 400,
                            backgroundColor: "#C5E389",
                            minWidth: "141px",
                            maxWidth: "141px",
                          }}
                        >
                          {convertSecondsIntoHHMM(mainTotalNonBillable)} Hrs
                        </TableCell>

                        <TableCell
                          // key={index + "nonbillable"}
                          className="temp"
                          sx={{
                            position: "sticky",
                            bottom: 0,
                            right: "0",
                            zIndex: 1005,
                            color: "black !important",
                            fontWeight: 400,
                            backgroundColor: "#C5E389",
                          }}
                        >
                          <Grid container justifyContent="space-around">
                            {Math.round(
                              (mainTotalBillable /
                                (mainTotalBillable + mainTotalNonBillable)) *
                                100
                            )}
                            %
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <TableRow style={{ backgroundColor: "#fff" }}>
                      <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                        No Records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BillableNonBillableProjects;
