import React, { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import  KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from "react-router-dom";
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import SettingModal from "./SettingsModal";

export const AppSettingCard = ({ onClick, icon, title }) => {
    return (
        <Grid item lg={4} sm={6} md={4} xs={12}>
            <Box className='settings-card' onClick={onClick}>
                <Box className='settings-content'>
                    {icon}
                    <Typography variant="h6" component="h6" className='text-white'>
                        {title}
                    </Typography>
                </Box>
            </Box>
        </Grid>
    )
}

const AppSettings = () => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Box className="report-top-bar">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={6} md={6} className="report-top-filter">
                        <KeyboardBackspaceIcon style={{fontSize : '36px', cursor:'pointer', fill: '#777'}} onClick={() => navigate("/settings")}/>
                    </Grid>
                </Grid>
            </Box>
            <Box className="settings-card-wrapper mb-24">
                <Grid container spacing={0}>
                    <AppSettingCard 
                        onClick={handleOpenModal}
                        icon={<ManageHistoryIcon />}
                        title='Ideal Time'
                    />
                    {
                        openModal && 
                        <SettingModal 
                            openModal={openModal} 
                            closeModal={handleCloseModal} 
                        />
                    }
                </Grid>
            </Box>
        </>
    )
}

export default AppSettings;