import { put, takeEvery } from "redux-saga/effects";
import {
  FETCH_TIME_SHEET,
  SET_FEEDBACK,
  SET_LOADING,
  STORE_TIME_SHEET,
} from "../redux/actionTypes";
import { fetchTimeSheetByDate } from "../../apis/timeSheet";

function* fetchTimeSheetHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    // console.log('action.payload in saga', action.payload)
    const response = yield fetchTimeSheetByDate(action.payload);
    // console.log('response in saga', response)
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      const payload = response.data.data;
      yield put({
        type: STORE_TIME_SHEET,
        payload: { count: payload.totalCount, data: payload.userTimes },
      });
    } else if (response.data.status === 404) {
      yield put({ type: STORE_TIME_SHEET, payload: { count: 0, data: [] } });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* watchTimeSheetAction() {
  yield takeEvery(FETCH_TIME_SHEET, fetchTimeSheetHandler);
}

export default watchTimeSheetAction;
