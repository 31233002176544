import { ADD_TODO_SUCCESS, COMPLETE_TODO_SUCCESS, DELETE_TODO_SUCCESS, STORE_TODOS, UPDATE_TODO_SUCCESS } from "../actionTypes";

const initialState = {
    todos: [],
    totalCount: 0
}

const TodoReducer = (state = initialState, action) =>{
    switch(action.type){
        case STORE_TODOS:
            return {
                ...state,
                todos: action.payload,
            };
    // case UPDATE_TODO_SUCCESS:
        //     const updatedTodoID = action.payload[0].id;
        //     const updatedTodos = state.todos.map(todo => {
        //         if (todo.id === updatedTodoID) {
        //             return {
        //                 ...todo, 
        //                 ...action.payload[0], 
        //             };
        //         }
        //         return todo;
        //     });
        //     return {
        //         ...state,
        //         todos: updatedTodos
        //     };
        // case COMPLETE_TODO_SUCCESS:
        //     const completedTodoId = action.payload[0].id; 
        //     return {
        //         todos: state.todos.filter(todo => todo.id !== completedTodoId)
        //     };
        // case DELETE_TODO_SUCCESS:
        //     const todoId = action.payload;
        //     return {
        //         todos: state.todos.filter(todo => todo.id !== todoId) 
        //     };
        case ADD_TODO_SUCCESS:
            const newTodo = action.payload;
            return {
                ...state,
                todos: {
                    totalCount: state.todos.totalCount + 1,
                    todos: [...newTodo, ...state.todos.todos]
                }
            };
        case UPDATE_TODO_SUCCESS:
            const updatedTodoID = action.payload.id;
            const updatedTodos = state.todos.todos.map(todo => {
                if (todo.id === updatedTodoID) {
                    return {
                        ...todo, 
                        ...action.payload, 
                    };
                }
                return todo;
            });
            return {
                ...state,
                todos: {
                    ...state.todos,
                    todos: updatedTodos
                }
            };
        case COMPLETE_TODO_SUCCESS:
            const completedTodoId = action.payload.id; 
            return {
                ...state,
                todos: {
                    totalCount: state.todos.totalCount - 1,
                    todos: state.todos.todos.filter(todo => todo.id !== completedTodoId)
                }
            };
        case DELETE_TODO_SUCCESS:
            const todoId = action.payload;
            return {
                ...state,
                todos: {
                    todos: state.todos.todos.filter(todo => todo.id !== todoId), 
                    totalCount: state.todos.totalCount - 1
                }
            };
        default:
            return state;
    }
}

export default TodoReducer;