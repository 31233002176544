import { getAPICall, postAPICall } from '../lib/callApi'
import { FORGOT_PASSWORD_API, USER_LOGIN_API, USER_LOGOUT_API, VERIFY_TOKEN_API, storageName } from '../lib/constants'
import { getFilterStorage, setFilterStorage } from '../utils/helper';

export const checkUserLoginCredentials = async (payload) => {
    const data = await postAPICall(USER_LOGIN_API, false, payload)
    return data;
}

export const logoutAction = async () => {
    const userCredential = getFilterStorage(storageName.timeTrackerCredential);
    if(userCredential){
        localStorage.clear();
        setFilterStorage(storageName.timeTrackerCredential, userCredential);
    } else {
        localStorage.clear();
    }
    window.location.href = "/login";
}

export const userLogout = async () => {
    const data = await getAPICall(USER_LOGOUT_API, true);
    return data;
}

export const forgotPassword = async (payload) => {
    const data = await postAPICall(FORGOT_PASSWORD_API, false, payload);
    return data;
}

export const verifyForgotPasswordToken = async (payload) => {
    const data = await postAPICall(VERIFY_TOKEN_API, false, payload);
    return data;
}