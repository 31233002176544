import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import styled from "@mui/system/styled";
import { useCallback, useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { fetchGlobalSettings, updateGlobalSettings } from "../../apis/settings";
import { setFeedback } from "../../store/redux/actions/feedback.action";
import { showSuccessToast } from "../../utils/toast";

const options = [
  { label: "10 minutes", value: 10 },
  { label: "15 minutes", value: 15 },
  { label: "20 minutes", value: 20 },
  { label: "25 minutes", value: 25 },
  { label: "30 minutes", value: 30 },
  { label: "40 minutes", value: 40 },
];

const dayOptions = [];

for (let i = 5; i <= 60; i++) {
  dayOptions.push({ label: `${i} days`, value: i });
}

const SettingModal = ({ openModal, closeModal, cardTitle }) => {
  const dispatch = useDispatch();
  const [globalSettingData, setGlobalSettingData] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedDayOption, setSelectedDayOption] = useState();

  const handleSelectedDayOption = (option) => {
    setSelectedDayOption(option.value);
  };

  const handleSelectedOption = (option) => {
    setSelectedOption(option.value);
  };

  const fetchGlobalSettingsData = useCallback(async () => {
    try {
      const response = await fetchGlobalSettings();
      setGlobalSettingData(response.data.data);
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchGlobalSettingsData();
  }, [fetchGlobalSettingsData]);

  useEffect(() => {
    if (globalSettingData.length > 0) {
      // setSelectedOption(globalSettingData[0].value);
      setSelectedOption(
        globalSettingData.find((item) => item.name.includes("ideal")).value
      );
      // setSelectedDayOption(globalSettingData[1].value);
      setSelectedDayOption(
        globalSettingData.find((item) => item.name.includes("todo")).value
      );
    }
  }, [globalSettingData]);

  const handleSubmit = async () => {
    try {
      let settingId, settingValue;
      if (cardTitle === "Ideal Time") {
        settingId = globalSettingData[0].id;
        settingValue = selectedOption;
      } else if (cardTitle === "Task Scheduler") {
        settingId = globalSettingData[1].id;
        settingValue = selectedDayOption;
      }

      const payload = {
        value: settingValue,
      };

      const response = await updateGlobalSettings(payload, settingId);
      if (response.data.status === 200) {
        closeModal();
        showSuccessToast(response.data.message);
      } else {
        const feedbackpayload = {
          code: response.data.status,
          status: "fail",
          message: response.data.message || "Something went wrong.",
        };
        dispatch(setFeedback(feedbackpayload));
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };

  return (
    <>
      {cardTitle === "Ideal Time" && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openModal}
          onClose={closeModal}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openModal}>
            <Box sx={styled} className="modal-wrapper modal-bg">
              <Typography
                variant="h6"
                component="h6"
                className="text-black modal-title"
              >
                Set Ideal Time
              </Typography>
              <Box className="modal-close" onClick={closeModal}>
                <FiPlus />
              </Box>
              <Box className="modal-body">
                <FormGroup className="form-field">
                  <FormControl
                    variant="standard"
                    className="form-control add-form-space"
                  >
                    <Typography
                      variant="body2"
                      component="span"
                      className="text-black input-label"
                    >
                      Select Time
                    </Typography>
                    <Select
                      className="select-time"
                      placeholder="Select Time"
                      value={options.find(
                        (option) => option.value === selectedOption
                      )}
                      onChange={handleSelectedOption}
                      options={options}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                          maxWidth: "1000px",
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "rgba(140, 199, 20, 0.4);",
                          primary: "#8CC714",
                        },
                      })}
                    />
                  </FormControl>
                </FormGroup>
              </Box>
              <Box className="modal-footer">
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Button className="btn btn-primary" onClick={handleSubmit}>
                      Save
                    </Button>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button className="btn btn-secondary" onClick={closeModal}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
      {cardTitle === "Task Scheduler" && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openModal}
          onClose={closeModal}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openModal}>
            <Box sx={styled} className="modal-wrapper modal-bg">
              <Typography
                variant="h6"
                component="h6"
                className="text-black modal-title"
              >
                Schedule task time to mark as completed
              </Typography>
              <Box className="modal-close" onClick={closeModal}>
                <FiPlus />
              </Box>
              <Box className="modal-body">
                <FormGroup className="form-field">
                  <FormControl
                    variant="standard"
                    className="form-control add-form-space"
                  >
                    <Typography
                      variant="body2"
                      component="span"
                      className="text-black input-label"
                    >
                      Select Time
                    </Typography>
                    <Select
                      className="select-time"
                      placeholder="Select Time"
                      value={dayOptions.find(
                        (option) => option.value === selectedDayOption
                      )}
                      onChange={handleSelectedDayOption}
                      options={dayOptions}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                          maxWidth: "1000px",
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "rgba(140, 199, 20, 0.4);",
                          primary: "#8CC714",
                        },
                      })}
                    />
                  </FormControl>
                </FormGroup>
              </Box>
              <Box className="modal-footer">
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Button className="btn btn-primary" onClick={handleSubmit}>
                      Save
                    </Button>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button className="btn btn-secondary" onClick={closeModal}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  );
};

export default SettingModal;
