import { ADD_TODO, DELETE_TODO, FETCH_TODOS, UPDATE_COMPLETE_TODO, UPDATE_TODO } from "../actionTypes";

export const fetchTodos = (payload) => {
    return { type: FETCH_TODOS, payload };
}

export const addTodo = (payload) => {
    return { type: ADD_TODO, payload };
}

export const updateTodo = (payload) => {
    return { type: UPDATE_TODO, payload };
}

export const updateTodoComplete = (payload) => {
    return { type: UPDATE_COMPLETE_TODO, payload };
}

export const deleteTodo = (payload, id) => {
    return { type: DELETE_TODO, payload, id };
}