import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "react-select";
import InputBase from "@mui/material/InputBase";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { FiFilter, FiPlus, FiSearch } from "react-icons/fi";
import EmployeeTable from "./employeeTable";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmployee,
  fetchEmployeeData,
  fetchUserRolesData,
  searchEmployee,
} from "../../store/redux/actions/employee.action";
import { setFeedback } from "../../store/redux/actions/feedback.action";
import debounce from "lodash.debounce";
import { fetchProjects } from "../../store/redux/actions/project.action";
import { useNavigate } from "react-router-dom";
import { ROLES, storageName } from "../../lib/constants";
import {
  getFilterStorage,
  getPermissionAccess,
  setFilterStorage,
  getNormalColorStyle,
} from "../../utils/helper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
}));

// activityOptions option
const timetrackingstatusOptions = [
  // { value: 'All', label: 'All' },
  { value: "disabled", label: "Disable" },
  { value: "enabled", label: "Enable" },
];

const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#8CC714",
  },
  "& .MuiTab-root": {
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
    textTransform: "capitalize",
  },
  "& .MuiTab-textColorInherit": {
    color: "#777777",
  },
});

const CustomTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    color: "#8CC714",
    borderBottom: `2px solid #8CC714`,
  },
  "&:hover": {
    // color: '#8CC714',
  },
}));

const Employees = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companyId, setCompanyId] = useState(null);

  const storeFilterData = getFilterStorage(storageName.user);

  const userData = useSelector((state) => state.user.data);
  const permissions = userData.role_permissions.permissions;
  const permissionsAccess = getPermissionAccess(permissions, "Users");
  if (userData.user_role.id !== ROLES.admin) {
    navigate("/", { replace: true });
  }
  if (
    !permissionsAccess.view &&
    !permissionsAccess.add &&
    !permissionsAccess.edit &&
    !permissionsAccess.delete
  ) {
    navigate("/", { replace: true });
  }

  const rolesData = useSelector((state) => state.roles);
  const projectData = useSelector((state) => state.project.projects_list);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //add new user modal
  const [addUserModalOpen, setOpen] = useState(false);
  // const userModalOpen = () => setOpen(true);
  const modalClose = () => {
    setOpen(false);
    setEmails([""]);
    setRoleError("");
    setSelectedProjectOption("");
    setSelectedRoleOption("");
    setEmailError("");
    // setProjectError('');
  };

  //right side drawer for filter
  const [state, setState] = useState(false);
  const handleDrawerOpen = () => {
    setState(true);
  };
  const handleDrawerClose = () => {
    setState(false);
  };

  // state for tabs
  const [valueTab, setValueTab] = useState(0);
  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
    if (newValue === 0) {
      setStatusFilter("active");
    } else if (newValue === 1) {
      setStatusFilter("archive");
    }
  };

  //Tabbing panel
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");
  // const [searchInviteValue, setSearchInviteValue] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      // dispatch(searchEmployee(params));
      setSearch(value);
    }, 800),
    [searchEmployee]
  );

  const changeSearchValueHandler = async (e) => {
    try {
      setSearchValue(e.target.value);
      setPage(0);
      debouncedSearch(e.target.value);
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };

  //states for status of active/archive and enable/disable
  const [status, setStatus] = useState("active");
  const [statusFilter, setStatusFilter] = useState("active");
  const [timeStatus, setTimeStatus] = useState("disabled");

  const roleOptions = rolesData.map((role) => ({
    value: role.id,
    label: role.title,
    description: role.description,
  }));

  const projectOptions = projectData.map((proj) => ({
    value: proj.id,
    label: proj.title,
  }));

  const [selectedRoleOption, setSelectedRoleOption] = useState("");
  const [selectedRoleOptionFilter, setSelectedRoleOptionFilter] = useState(
    storeFilterData?.role || ""
  );
  const [selectedProjectOption, setSelectedProjectOption] = useState([]);
  const [selectedProjectOptionFilter, setSelectedProjectOptionFilter] =
    useState(storeFilterData?.project_id || []);
  const [selectedTimeTracking, setSelectedTimeTrackingOption] = useState(
    storeFilterData?.time_tracking_status || ""
  );
  // const [selectedDate, setSelectedDate] = useState('');

  const [emails, setEmails] = useState([""]);
  const [emailError, setEmailError] = useState("");
  const [roleError, setRoleError] = useState("");
  // const [projectError, setProjectError] = useState("");

  const handleAddEmployee = () => {
    setEmails([...emails, ""]);
    setEmailError([...emailError, ""]);
  };

  const handleEmailChange = (index, event) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = event.target.value;
    setEmails(updatedEmails);

    const updatedEmailErrors = [...emailError];
    if (event.target.value.trim() === "") {
      updatedEmailErrors[index] = "Please enter your email";
    } else if (!isValidEmail(event.target.value)) {
      updatedEmailErrors[index] = "Please enter a valid email address";
    } else if (
      updatedEmails.some(
        (email, i) => email === event.target.value && i !== index
      )
    ) {
      updatedEmailErrors[index] = "Email already exists";
    } else {
      updatedEmailErrors[index] = "";
    }
    setEmailError(updatedEmailErrors);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const fetchAllEmployee = useCallback(
    async (params = {}) => {
      try {
        // let params = { name: '' };

        dispatch(fetchEmployeeData(params));
      } catch (error) {
        const payload = {
          code: error.status,
          status: "fail",
          message: error.message || "Something went wrong. Try again later!",
        };
        dispatch(setFeedback(payload));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    let params = {};
    if (
      selectedTimeTracking !== "" ||
      selectedProjectOptionFilter.length !== 0 ||
      selectedRoleOptionFilter !== "" ||
      statusFilter !== ""
    ) {
      const stringifiedArray = JSON.stringify(
        selectedProjectOptionFilter
      ).replace(/,\s*$/, "");
      params = {
        filterusers: 1,
        role: selectedRoleOptionFilter.value,
        status: statusFilter,
        time_tracking_status: selectedTimeTracking.value,
        project_id: stringifiedArray,
        company_id: companyId?.value,
      };
    }
    if (search) {
      params = { ...params, name: search.trim().replace(/\s+/g, " ") };
    }
    params = {
      ...params,
      page: page + 1,
      rows: rowsPerPage,
    };
    setFilterStorage(storageName.user, {
      role: selectedRoleOptionFilter,
      status: statusFilter,
      time_tracking_status: selectedTimeTracking,
      project_id: selectedProjectOptionFilter,
    });

    fetchAllEmployee(params);
  }, [
    fetchAllEmployee,
    page,
    rowsPerPage,
    search,
    selectedProjectOptionFilter,
    selectedRoleOptionFilter,
    selectedTimeTracking,
    statusFilter,
    companyId,
  ]);

  const fetchRoles = useCallback(async () => {
    dispatch(fetchUserRolesData());
  }, [dispatch]);

  const fetchProject = useCallback(async () => {
    const params = { listing: true, isListing: true };
    dispatch(fetchProjects(params));
  }, [dispatch]);

  const validateForm = () => {
    if (selectedRoleOption === "") {
      setRoleError("Please select role.");
      return false;
    }
    return true;
  };

  const addEmployeeClick = async (e) => {
    e.preventDefault();
    let hasError = false;
    const updatedEmailErrors = emails.map((email) => {
      if (email.trim() === "") {
        hasError = true;
        return "Please enter an email";
      } else if (!isValidEmail(email)) {
        hasError = true;
        return "Please enter a valid email address";
      } else {
        return "";
      }
    });

    setEmailError(updatedEmailErrors);
    if (!hasError) {
      try {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
          const payload = {
            userEmail: emails,
            role: selectedRoleOption,
            status: status,
            time_tracking_status: timeStatus,
            projects:
              selectedRoleOption === ROLES.admin
                ? []
                : [...selectedProjectOption.map((item) => item.value)],
          };
          dispatch(addEmployee(payload));
          modalClose();
        }
      } catch (error) {
        const errPayload = {
          code: error.status,
          status: "fail",
          message: error.message || "Something went wrong.",
        };
        dispatch(setFeedback(errPayload));
      }
    }
  };

  const handleSelectRoleChange = (selectedValue) => {
    if (!selectedValue) {
      setRoleError("Please select role.");
      return;
    }
    setRoleError("");
    setSelectedRoleOption(selectedValue.value);
  };

  const handleSelectRoleChangeFilter = (selectedValue) => {
    setSelectedRoleOptionFilter(selectedValue);
  };

  const handleBlurRole = () => {
    if (selectedRoleOption === "") {
      setRoleError("Please select a role.");
    }
  };

  const handleBlurProject = () => {
    // if(!selectedProjectOption.length){
    //   setProjectError("Please select a project.")
    // }
  };

  const handleSlectedTimeTrackingChange = (selectedValue) => {
    setSelectedTimeTrackingOption(selectedValue);
  };

  const clearAllFilterData = () => {
    setSelectedRoleOptionFilter("");
    setCompanyId("");
    setSelectedTimeTrackingOption("");
    setSelectedProjectOptionFilter([]);
    setStatusFilter("");
    // setSelectedDate('');
    // fetchAllEmployee();
    setState(false);
  };

  useEffect(() => {
    fetchRoles();
    fetchProject();
  }, [fetchProject, fetchRoles]);

  return (
    <>
      <Box className="content-header users-module-wrapper top-bar mb-4">
        <Box className="top-filter-field">
          <CustomTabs value={valueTab} onChange={handleChangeTabs}>
            <CustomTab label="Active" {...a11yProps(0)} />
            <CustomTab label="Archive" {...a11yProps(1)} />
          </CustomTabs>
        </Box>
        <Box className="content-header-right top-filter-btn top-emp-content">
          <Box className="top-emp-tab top-filter-field">
            <Box className="searchbox bg-white">
              <BootstrapInput
                name="user-search"
                value={searchValue}
                onChange={changeSearchValueHandler}
                placeholder="Search user"
                endAdornment={
                  <InputAdornment
                    position="end"
                    className="end-input-icon text-grey"
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <FiSearch />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          </Box>
          <Tooltip title="Filter User">
            <Button
              className="btn btn-primary only-icon-btn"
              onClick={handleDrawerOpen}
            >
              <FiFilter />
            </Button>
          </Tooltip>
        </Box>
      </Box>

      <EmployeeTable
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        permissionsAccess={permissionsAccess}
      />

      {/* Add new user modal */}
      {addUserModalOpen && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={addUserModalOpen}
          onClose={modalClose}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={addUserModalOpen}>
            <Box sx={styled} className="modal-wrapper modal-bg">
              <Typography
                variant="h6"
                component="h6"
                className="text-black modal-title"
              >
                Add Employee
                <span
                  style={{
                    color: "#969494",
                    fontWeight: "350",
                    marginLeft: "10px",
                  }}
                >
                  (invite via mail)
                </span>
              </Typography>
              <Box className="modal-close" onClick={modalClose}>
                <FiPlus />
              </Box>
              <Box className="modal-body">
                <FormGroup className="form-field">
                  <Grid container spacing={2}>
                    {emails.map((email, index) => (
                      <Grid item xs={12} key={index}>
                        <FormControl
                          variant="standard"
                          className="form-control"
                        >
                          <Typography
                            variant="body2"
                            component="span"
                            className="text-black input-label"
                          >
                            Email *
                          </Typography>
                          <Autocomplete
                            options={[]}
                            freeSolo
                            value={email}
                            onChange={(event, value) =>
                              handleEmailChange(index, event, value)
                            }
                            renderInput={(params) => (
                              <BootstrapInput
                                {...params}
                                placeholder="Add an Email"
                                className={
                                  !emailError[index]
                                    ? "input-field"
                                    : "border-error"
                                }
                                value={email}
                                onChange={(event) =>
                                  handleEmailChange(
                                    index,
                                    event,
                                    event.target.value
                                  )
                                }
                              />
                            )}
                          />
                          {emailError[index] && (
                            <p style={{ color: "red", fontSize: "14px" }}>
                              {emailError[index]}
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Button
                        className="add-employee btn btn-primary"
                        onClick={handleAddEmployee}
                      >
                        <FiPlus /> Add an Employee
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="standard" className="form-control">
                        <Typography
                          variant="body2"
                          component="span"
                          className="text-black input-label"
                        >
                          Role *
                        </Typography>
                        <Select
                          className="select-date"
                          placeholder="Select Role"
                          defaultValue={selectedRoleOption}
                          onChange={handleSelectRoleChange}
                          onBlur={handleBlurRole}
                          options={roleOptions}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          formatOptionLabel={({ label, description }) => (
                            <div>
                              <div>{label}</div>
                              <div style={{ fontSize: "12px", color: "gray" }}>
                                {description}
                              </div>
                            </div>
                          )}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: !roleError ? "#cccccc" : "red",
                              minHeight: "50px",
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 10,
                            colors: {
                              ...theme.colors,
                              primary25: "rgba(140, 199, 20, 0.4);",
                              primary: !roleError ? "#8CC714" : "red",
                            },
                          })}
                        />
                        {roleError && <p className="error-text">{roleError}</p>}
                      </FormControl>
                    </Grid>
                    {selectedRoleOption !== "admin" && (
                      <Grid item xs={12}>
                        <FormControl
                          variant="standard"
                          className="form-control"
                        >
                          <Typography
                            variant="body2"
                            component="span"
                            className="text-black input-label"
                          >
                            Project *
                          </Typography>
                          <Select
                            placeholder="Select Project"
                            className="select-date"
                            value={selectedProjectOption}
                            onChange={(selectedOption) => {
                              // !selectedOption.length ? setProjectError("Please select a project.")
                              // : setProjectError("");
                              setSelectedProjectOption(selectedOption);
                            }}
                            onBlur={handleBlurProject}
                            options={projectOptions}
                            isMulti
                            classNamePrefix="select"
                            styles={getNormalColorStyle("MULTI")}
                            isClearable={false}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 10,
                              colors: {
                                ...theme.colors,
                                primary25: "rgba(140, 199, 20, 0.4);",
                                // primary: !projectError ? '#8CC714' : 'red',
                                primary: "#8CC714",
                              },
                            })}
                          />
                          {/* {projectError && <p className="error-text">{projectError}</p>} */}
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <FormControl variant="standard" className="form-control">
                        <Typography
                          variant="body2"
                          component="span"
                          className="text-black input-label"
                        >
                          Status
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <Box className="mask-box">
                            <Box
                              className="mask"
                              style={{
                                transform: `translateX(${
                                  status === "active" ? 0 : "80px"
                                })`,
                              }}
                            />
                            <Button
                              disableRipple
                              variant="text"
                              sx={{
                                color:
                                  status === "active" ? "#ffffff" : "#ffffff",
                              }}
                              onClick={() => setStatus("active")}
                            >
                              Active
                            </Button>
                            <Button
                              disableRipple
                              variant="text"
                              sx={{
                                color:
                                  status === "archive" ? "#ffffff" : "#ffffff",
                              }}
                              onClick={() => setStatus("archive")}
                            >
                              Archive
                            </Button>
                          </Box>
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="standard" className="form-control">
                        <Typography
                          variant="body2"
                          component="span"
                          className="text-black input-label"
                        >
                          Time Tracking Status
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <Box className="mask-box">
                            <Box
                              className="mask"
                              style={{
                                transform: `translateX(${
                                  timeStatus === "Enabled" ? 0 : "80px"
                                })`,
                              }}
                            />
                            <Button
                              disableRipple
                              variant="text"
                              sx={{
                                color:
                                  timeStatus === "Enabled"
                                    ? "#ffffff"
                                    : "#ffffff",
                              }}
                              onClick={() => setTimeStatus("Enabled")}
                            >
                              Enabled
                            </Button>
                            <Button
                              disableRipple
                              variant="text"
                              setTimeStatus
                              sx={{
                                color:
                                  timeStatus === "Disabled"
                                    ? "#ffffff"
                                    : "#ffffff",
                              }}
                              onClick={() => setTimeStatus("Disabled")}
                            >
                              Disabled
                            </Button>
                          </Box>
                        </Box>
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Box>
              <Box className="modal-footer">
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Button
                      className="btn btn-primary"
                      onClick={addEmployeeClick}
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button className="btn btn-secondary" onClick={modalClose}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}

      {/* filter drawer */}
      <Drawer
        anchor="right"
        open={state}
        onClose={handleDrawerClose}
        className="filter-drawer user-module-drawer"
      >
        <Box className="close-filter" onClick={handleDrawerClose}>
          <FiPlus />
        </Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="filter-tab"
        >
          <Tab className="filter-btn" label="Filter" {...a11yProps(0)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Box className="filter-content">
            <FormGroup className="form-field">
              <FormControl variant="standard" className="form-control mb-3">
                <Typography
                  variant="body2"
                  component="span"
                  className="text-black input-label"
                >
                  Roles
                </Typography>
                <Select
                  className="select-time-zone select-filter"
                  placeholder="Select Roles"
                  value={selectedRoleOptionFilter}
                  onChange={handleSelectRoleChangeFilter}
                  options={roleOptions}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "rgba(140, 199, 20, 0.4);",
                      primary: "#8CC714",
                    },
                  })}
                />
              </FormControl>
              <FormControl variant="standard" className="form-control mb-3">
                <Typography
                  variant="body2"
                  component="span"
                  className="text-black input-label"
                >
                  Projects
                </Typography>
                <Select
                  placeholder="Select Projects"
                  className="select-filter"
                  defaultValue={projectOptions.filter((option) =>
                    selectedProjectOptionFilter.includes(option.value)
                  )}
                  onChange={(selectedOptions) => {
                    const data = selectedOptions.map((option) => option.value);
                    if (data.length > 0) {
                      setSelectedProjectOptionFilter(data);
                    } else {
                      setSelectedProjectOptionFilter(data);
                    }
                  }}
                  options={projectOptions}
                  isMulti
                  classNamePrefix="select"
                  styles={getNormalColorStyle("MULTI")}
                  isClearable={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "rgba(140, 199, 20, 0.4)",
                      primary: "#8CC714",
                    },
                  })}
                />
              </FormControl>
              <FormControl variant="standard" className="form-control mb-3">
                <Typography
                  variant="body2"
                  component="span"
                  className="text-black input-label"
                >
                  Company
                </Typography>
                <Select
                  placeholder="Select Company"
                  className="select-filter"
                  onChange={(selectedOption) => {
                    let companyId = null;
                    if (selectedOption) {
                      if (selectedOption.value === 1) {
                        companyId = 1;
                      } else if (selectedOption.value === 2) {
                        companyId = 2;
                      }
                    }
                    // Update the company_id state or variable here
                    setCompanyId(selectedOption); // Replace setCompanyId with your state update function
                  }}
                  options={[
                    { value: 1, label: "Crest Infosystems Pvt. Ltd." },
                    { value: 2, label: "Crest Skillserve Pvt. Ltd." },
                  ]}
                  classNamePrefix="select"
                  styles={getNormalColorStyle("MULTI")}
                  isClearable={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "rgba(140, 199, 20, 0.4)",
                      primary: "#8CC714",
                    },
                  })}
                  value={companyId}
                />
              </FormControl>
              <FormControl variant="standard" className="form-control mb-3">
                <Typography
                  variant="body2"
                  component="span"
                  className="text-black input-label"
                >
                  Time Tracking Status
                </Typography>
                <Select
                  className="select-filter"
                  placeholder="Select Status"
                  value={selectedTimeTracking}
                  onChange={handleSlectedTimeTrackingChange}
                  options={timetrackingstatusOptions}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "rgba(140, 199, 20, 0.4);",
                      primary: "#8CC714",
                    },
                  })}
                />
              </FormControl>
            </FormGroup>
            <FormGroup className="form-field">
              <FormControl variant="standard" className="form-control">
                <Button
                  className="btn btn-primary"
                  onClick={() => clearAllFilterData()}
                >
                  Clear All
                </Button>
              </FormControl>
            </FormGroup>
          </Box>
        </TabPanel>
      </Drawer>
    </>
  );
};

export default Employees;
