import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";
// import Select from 'react-select';
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { setFeedback } from "../../store/redux/actions/feedback.action";
import { addTodos, fetchUsersByProject } from "../../apis/todos";
// import { addTodo } from '../../store/redux/actions/todos.action';
import { ROLES } from "../../lib/constants";
import { getSelectStyles } from "../../utils/helper";
import { showSuccessToast } from "../../utils/toast";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
}));

const AddProjectToDo = ({
  addToDosModalOpen,
  addTodosModalClose,
  member,
  project,
  fetchTodoHandler,
}) => {
  let newAddedTodo;
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.user.data?.user_role?.id);
  const projects = useSelector((state) => state.project.projects_list);
  const projectOptions = projects.map((proj) => ({
    value: proj.id,
    label: proj.title,
  }));
  const [validationErrors, setValidationErrors] = useState({});
  const [userOptions, setUserOption] = useState([]);
  const [selectedProjectOption, setSelectedProjectOption] = useState("");
  const [selectedUserOption, setSelectedUserOption] = useState([]);
  const [todoInput, setTodoInput] = useState("");
  // setUserOption(member);

  const colorStyle = {
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: "rgba(140, 199, 20, 0.2)",
      borderRadius: "10px",
      border: `1px solid #8CC714`,
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      fontSize: "16px",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      minHeight: "50px",
      borderColor: !validationErrors.assignee ? "#cccccc" : "red",
    }),
  };

  const handleSelectedProject = (selectedOption) => {
    if (selectedOption) {
      const selectedProjectId = selectedOption.value;
      setSelectedProjectOption(selectedProjectId);

      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        project: "",
      }));
    }
  };

  const handleSelectedUser = (selectedOption) => {
    if (selectedOption) {
      //   const selectedUserId = selectedOption.value;
      setSelectedUserOption(selectedOption);

      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        assignee: "",
      }));
    }
  };

  const handleTodoInput = (e) => {
    setTodoInput(e.target.value);

    e.target.value === ""
      ? setValidationErrors((prev) => ({
          ...prev,
          todoInput: "Please enter a todo.",
        }))
      : setValidationErrors((prev) => ({ ...prev, todoInput: "" }));
  };

  const fetchUsersByProjectData = useCallback(async () => {
    try {
      //   const params = { role:['user', 'manager'] };
      const roleData = userRole !== ROLES.employee ? [2, 3, 1] : [2, 3, 1];
      const params = { role: roleData, paranoid: true };
      const response = await fetchUsersByProject(params, selectedProjectOption);
      const userData = response.data.data;

      setUserOption(
        userData?.map((user) => ({
          value: user.user.id,
          label: user.user.first_name + " " + user.user.last_name,
        }))
      );
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedProjectOption]);

  const validateForm = () => {
    const errors = {};

    // if (!selectedProjectOption) {
    //     errors.project = 'Please select a project.';
    // }

    if (!todoInput.trim()) {
      errors.todoInput = "Please enter a to-do.";
    }

    // if (!selectedUserOption.length) {
    //     errors.assignee = 'Please select member.';
    // }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    try {
      const isValid = validateForm();
      if (isValid) {
        const payload = {
          description: todoInput,
          assignee: member.value,
          project: project.value,
        };
        // await dispatch(addTodo(payload));
        const response = await addTodos(payload);

        newAddedTodo = {
          label: response.data.data[0]["description"],
          value: response.data.data[0]["id"],
        };
        if (response.data.status === 200) {
          showSuccessToast(response.data.message);
          fetchTodoHandler(newAddedTodo);
        } else {
          const payload = {
            code: response.data.status,
            status: "fail",
            message: response.data.message || "Something went wrong.",
          };
          dispatch(setFeedback(payload));
        }
        addTodosModalClose();
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };

  const clearData = () => {
    setSelectedProjectOption("");
    setSelectedUserOption([]);
    setUserOption([]);
    setTodoInput("");
    addTodosModalClose();
    setValidationErrors({});
  };

  useEffect(() => {
    if (selectedProjectOption !== "") {
      fetchUsersByProjectData();
    }
  }, [fetchUsersByProjectData, selectedProjectOption]);

  useEffect(() => {
    if (!addToDosModalOpen) {
      setUserOption([]);
    }
  }, [addToDosModalOpen]);

  const selectProjectStyles = getSelectStyles(validationErrors.project);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={addToDosModalOpen}
      onClose={clearData}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={addToDosModalOpen}>
        <Box sx={styled} className="modal-wrapper modal-bg">
          <Typography
            variant="h6"
            component="h6"
            className="text-black modal-title"
          >
            Add To-Dos
          </Typography>
          <Box className="modal-close" onClick={clearData}>
            <FiPlus />
          </Box>
          <Box className="modal-body">
            <FormGroup className="form-field">
              <FormControl variant="standard" className="form-control">
                <Typography
                  variant="body2"
                  component="span"
                  className="text-black input-label"
                >
                  To-Do
                  <Typography
                    variant="body2"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <BootstrapInput
                  placeholder="Enter to-do"
                  className={
                    !validationErrors.todoInput ? "input-field" : "border-error"
                  }
                  value={todoInput}
                  onChange={(e) => {
                    handleTodoInput(e);
                  }}
                />
                {validationErrors.todoInput && (
                  <span className="error-text">
                    {validationErrors.todoInput}
                  </span>
                )}
              </FormControl>
            </FormGroup>
          </Box>
          <Box className="modal-footer">
            <Grid container spacing={3}>
              <Grid item md={6} xs={6}>
                <Button className="btn btn-primary" onClick={handleSubmit}>
                  Save
                </Button>
              </Grid>
              <Grid item md={6} xs={6}>
                <Button className="btn btn-secondary" onClick={clearData}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddProjectToDo;
