import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { FiActivity, FiClock } from "react-icons/fi";
import ActivityCard from "../../components/common/ActivityCard";
import ProjectName from "../../components/common/ProjectName";
import { useSelector } from "react-redux";
import { ROLES } from "../../lib/constants";
import { convertDateIntoDDMMYYYY } from "../../utils/helper";
import TimeAndActivityTable from "./TimeAndActivityTable";

const convertSecondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    return formattedTime;
};

const activityPercentage = (activity, count) => {
    return count ? Math.round(activity / count) + "%" : '0%';
}

const ReportActivityCard = ({selectedOption}) => {
    const userRole = useSelector(state => state.user.data?.user_role?.id);
    const reportsData = useSelector(state => state.reports.reports);

    const ActivityCardArray = [
        {
          ActivityTitle: "Average Activity",
          ActivityNumber: isNaN(reportsData?.activity) ? "0%" : Math.round(reportsData?.activity) + "%",
          ActivityIcon: <FiActivity />,
        },
        {
          ActivityTitle: "Time",
          ActivityNumber: reportsData?.data ? convertSecondsToTime(reportsData?.worked_time) : "00:00:00",
          ActivityIcon: <FiClock />,
        },
    ];

    const dateData = (
        <>
            <TableBody>
                { reportsData?.data?.length ?
                    reportsData?.data?.map((ReportTableItem, index) => (
                        <>
                            <TableRow className="report-table-row" key={index+'timeActivity'}>
                                <TableCell
                                    align="left"
                                    colSpan={ userRole !== ROLES.employee ? 
                                    5 : 4 }
                                    className="report-table-date"
                                >
                                    {/* {new Date(ReportTableItem['start_date']).toLocaleString('en-US', dateFormatOptions)} */}
                                    {convertDateIntoDDMMYYYY(ReportTableItem['start_date'])}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell 
                                colSpan={userRole !== ROLES.employee 
                                    ? 3 : 2 } 
                                    className="project-total"
                                >
                                    Total
                                </TableCell>
                                <TableCell align="right">
                                    {convertSecondsToTime(ReportTableItem.worked_time)}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className="activity-left-space"
                                >
                                    {activityPercentage(ReportTableItem.activity, ReportTableItem.count)}
                                </TableCell>
                            </TableRow>
                            {ReportTableItem.data.map((projectDetails)=>(
                                <>
                                    <TableRow>
                                        <TableCell className="report-table-avatar">
                                            <ProjectName
                                                ProjectInitial={projectDetails["project.title"] ? projectDetails["project.title"].charAt(0).toUpperCase() : ''}
                                                ProjectTitle={projectDetails["project.title"]}
                                            />
                                        </TableCell>
                                        <TableCell align="right" 
                                            colSpan={userRole !== ROLES.employee 
                                            ? 3 : 2 }
                                        >
                                            {convertSecondsToTime(projectDetails.worked_time)}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            className="activity-left-space"
                                        >
                                            {activityPercentage(projectDetails.activity, projectDetails.count)}
                                        </TableCell>
                                    </TableRow>
                                    {projectDetails.data.map((items)=>(
                                        <>
                                            <TableRow>
                                                <TableCell className="td-border-none"></TableCell>
                                                { 
                                                (userRole !== ROLES.employee) &&        
                                                    <TableCell>
                                                        <ProjectName
                                                            ProjectInitial={items["user.first_name"] ? items["user.first_name"].charAt(0).toUpperCase() : ''} 
                                                            ProjectTitle={items['user.first_name'] + ' ' + items['user.last_name']}
                                                        />
                                                    </TableCell> 
                                                }
                                                <TableCell>
                                                    {items['todo.description']?.length > 35 ? items['todo.description']?.substr(0,40) + "..." : items['todo.description']}
                                                </TableCell>
                                                {/* { 
                                                (userRole === ROLES.admin || userRole ===ROLES.manager) && <TableCell >
                                                    {ReportTableItem.ClientName}
                                                </TableCell>
                                                } */}
                                                <TableCell align="right">
                                                    {convertSecondsToTime(items.worked_time)}
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    className="activity-left-space"
                                                >
                                                {/* {Math.round(items.activity)+"%"} */}
                                                    {activityPercentage(items.activity, items.count)}
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ))}
                                </>
                            ))}
                            <TableRow className="report-table-hidden">
                                <TableCell></TableCell>
                            </TableRow>
                        </>
                    )) :
                    <TableRow >
                <TableCell sx={{textAlign:'center', backgroundColor: '#fff'}} colSpan={6}>
                    No Records Found
                </TableCell>
                </TableRow>
                }
            </TableBody>
        </>
    );

    const projectData = ( 
        <>
            <TableBody>
                { reportsData?.data?.length ?
                reportsData?.data?.map((ReportTableItem, index) => (
                    <>
                        <TableRow className="project-table-row" key={index+'timeActivity'}>
                            <TableCell
                                align="left"
                                colSpan={ userRole !== ROLES.employee ? 
                                5 : 4 }
                                className="project-table-avatar"
                            >
                            <Box className='project-name'>
                                <Box className='initial'>
                                    <Typography variant="body1" component="span" className='text-white'>
                                        {ReportTableItem['project.title']?  ReportTableItem['project.title'].charAt(0).toUpperCase() : ''}
                                    </Typography>
                                </Box>
                                <Typography variant="h3" component="h3" >
                                    {ReportTableItem['project.title']}
                                </Typography>
                            </Box> 
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                colSpan={userRole !== ROLES.employee 
                                ? 3 : 2 }
                                className="project-total"
                            >
                                Total
                            </TableCell>
                            <TableCell align="right">
                                {convertSecondsToTime(ReportTableItem.worked_time)}
                            </TableCell>
                            <TableCell
                                align="right"
                                className="activity-left-space"
                            >
                                {activityPercentage(ReportTableItem.activity, ReportTableItem.count)}
                            </TableCell>
                        </TableRow>
                        {ReportTableItem.data.map(
                            (projectDetail) => (
                            <>
                                <TableRow>
                                <TableCell
                                    align="left"
                                    className="report-table-date"
                                >
                                    {/* {new Date(projectDetail.start_date).toLocaleString('en-US', dateFormatOptions)} */}
                                    {convertDateIntoDDMMYYYY(projectDetail.start_date)}
                                </TableCell>
                                <TableCell align="right" 
                                    colSpan={userRole !== ROLES.employee 
                                    ? 3 : 2 }
                                >
                                    {convertSecondsToTime(projectDetail.worked_time)}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className="activity-left-space"
                                >
                                    {activityPercentage(projectDetail.activity, projectDetail.count)}
                                </TableCell>
                                </TableRow>
                                    {projectDetail.data.map((item) => (
                                        <TableRow>
                                            <TableCell className="td-border-none"></TableCell>
                                            { 
                                            (userRole !== ROLES.employee) && 
                                                <TableCell align="right">
                                                    <ProjectName
                                                    ProjectInitial={item["user.first_name"] ? item["user.first_name"].charAt(0).toUpperCase() : ''} 
                                                    ProjectTitle={item['user.first_name'] + ' ' + item['user.last_name']}
                                                    />
                                                </TableCell>
                                            }
                                            <TableCell>
                                                {item['todo.description']?.length > 35 ? item['todo.description']?.substr(0,40) + "..." : item['todo.description']}
                                            </TableCell>
                                            {/* { 
                                            (userRole === ROLES.admin || userRole ===ROLES.manager) && <TableCell>
                                                {item.Client}
                                            </TableCell>
                                            } */}
                                            <TableCell align="right">
                                                {convertSecondsToTime(item.worked_time)}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                className="activity-left-space"
                                            >
                                                {/* {Math.round(item.activity)+"%"} */}
                                                {activityPercentage(item.activity, item.count)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </>
                            )
                        )}
                    </>
                )) :
                <TableRow >
                <TableCell sx={{textAlign:'center', backgroundColor: '#fff'}} colSpan={6}>
                    No Records Found
                </TableCell>
                </TableRow>
                }
                </TableBody>
        </>
    );

    const todosData = (
        <>
            <TableBody>
                { reportsData?.data?.length ?
                    reportsData?.data?.map((ReportTableItem, index) => (
                        <>
                            <TableRow className="project-table-row" key={index+'timeActivity'}>
                                <TableCell
                                    align="left"
                                    colSpan={ userRole !== ROLES.employee ? 
                                    5 : 4 }
                                    className="project-table-avatar"
                                >
                                    {ReportTableItem['todo.description']?.length > 65 ? ReportTableItem['todo.description']?.substr(0, 70) + "..." : ReportTableItem['todo.description']}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    colSpan={userRole !== ROLES.employee 
                                    ? 3 : 2 }
                                    className="project-total"
                                >
                                    Total
                                </TableCell>
                                <TableCell align="right">
                                    {convertSecondsToTime(ReportTableItem.worked_time)}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className="activity-left-space"
                                >
                                    {activityPercentage(ReportTableItem.activity, ReportTableItem.count)}
                                </TableCell>
                            </TableRow>
                            {ReportTableItem.data.map(
                                (projectDetail) => (
                                <>
                                    <TableRow>
                                        <TableCell align="left" className="report-table-date">
                                            {convertDateIntoDDMMYYYY(projectDetail.start_date)}
                                        </TableCell>
                                        <TableCell align="right" colSpan={userRole !== ROLES.employee ? 3 : 2 }>
                                            {convertSecondsToTime(projectDetail.worked_time)}
                                        </TableCell>
                                        <TableCell align="right" className="activity-left-space">
                                            {activityPercentage(projectDetail.activity, projectDetail.count)}
                                        </TableCell>
                                    </TableRow>
                                    
                                    {projectDetail.data.map((item) => (
                                        <TableRow>
                                            <TableCell className="td-border-none"></TableCell>
                                            <TableCell align="right">
                                                <ProjectName
                                                    ProjectInitial={item['project.title'] ?  item['project.title'].charAt(0).toUpperCase() : ''} 
                                                    ProjectTitle={item['project.title']}
                                                />
                                            </TableCell>
                                            { 
                                                (userRole !== ROLES.employee) && 
                                                <TableCell align="right">
                                                    <ProjectName
                                                    ProjectInitial={item["user.first_name"] ? item["user.first_name"].charAt(0).toUpperCase() : ''} 
                                                    ProjectTitle={item['user.first_name'] + ' ' + item['user.last_name']}
                                                    />
                                                </TableCell>
                                            }
                                        
                                        {/* { 
                                        (userRole === ROLES.admin || userRole ===ROLES.manager) && <TableCell>
                                            {item.Client}
                                        </TableCell>
                                        } */}
                                            <TableCell align="right">
                                                {convertSecondsToTime(item.worked_time)}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                className="activity-left-space"
                                            >
                                                {/* {Math.round(item.activity)+"%"} */}
                                                {activityPercentage(item.activity, item.count)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                                )
                            )}
                        </>
                    )) :
                <TableRow >
                    <TableCell sx={{textAlign:'center', backgroundColor: '#fff'}} colSpan={6}>
                        No Records Found
                    </TableCell>
                </TableRow>
                }
            </TableBody>
        </>
    );

    const memberData = (
        <>
            <TableBody>
                { reportsData?.data?.length ? 
                    reportsData?.data?.map((ReportTableItem, index) => (
                        <>
                            <TableRow className="project-table-row" key={index+'timeActivity'}>
                                <TableCell
                                    align="left"
                                    colSpan={5} 
                                    className="project-table-avatar"
                                >
                                <Box className='project-name'>
                                    <Box className='initial'>
                                        <Typography variant="body1" component="span" className='text-white'>
                                            {ReportTableItem['user.first_name'] ?  ReportTableItem['user.first_name'].charAt(0).toUpperCase() : ''}
                                        </Typography>
                                    </Box>
                                    <Typography variant="h3" component="h3" >
                                        {ReportTableItem['user.first_name'] + ' ' + ReportTableItem['user.last_name']}
                                    </Typography>
                                </Box> 
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    className="project-total"
                                >
                                    Total
                                </TableCell>
                                <TableCell align="right">
                                    {convertSecondsToTime(ReportTableItem.worked_time)}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className="activity-left-space"
                                >
                                    {activityPercentage(ReportTableItem.activity, ReportTableItem.count)}
                                </TableCell>
                            </TableRow>
                            {ReportTableItem.data.map(
                                (projectDetail) => (
                                <>
                                    <TableRow>
                                    <TableCell
                                        align="left"
                                        className="report-table-date"
                                    >
                                        {/* {new Date(projectDetail.start_date).toLocaleString('en-US', dateFormatOptions)} */}
                                        {convertDateIntoDDMMYYYY(projectDetail.start_date)}
                                    </TableCell>
                                    <TableCell align="right" 
                                        colSpan={3}
                                    >
                                        {convertSecondsToTime(projectDetail.worked_time)}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className="activity-left-space"
                                    >
                                        {activityPercentage(projectDetail.activity, projectDetail.count)}
                                    </TableCell>
                                    </TableRow>
                                    {projectDetail.data.map((item) => {
                                        return (
                                            <TableRow>
                                                <TableCell className="td-border-none"></TableCell>
                                                <TableCell align="right">
                                                    <ProjectName
                                                    ProjectInitial={item['project.title'] ?  item['project.title'].charAt(0).toUpperCase() : ''} 
                                                    ProjectTitle={item['project.title']}
                                                    />
                                                </TableCell>
                                                <TableCell> 
                                                    {item['todo.description']?.length > 35 ? item['todo.description']?.substr(0,40) + "..." : item['todo.description']}
                                                </TableCell>
                                                {/* <TableCell>
                                                    {item.Client}
                                                </TableCell> */}
                                                <TableCell align="right">
                                                    {convertSecondsToTime(item.worked_time)}
                                                </TableCell>
                                                <TableCell
                                                align="right"
                                                className="activity-left-space"
                                                >
                                                    {/* {Math.round(item.activity)+"%"} */}
                                                    {activityPercentage(item.activity, item.count)}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </>
                                )
                            )}
                        </>
                    )):
                    <TableRow >
                    <TableCell sx={{textAlign:'center', backgroundColor: '#fff'}} colSpan={6}>
                        No Records Found
                    </TableCell>
                    </TableRow>
                }
            </TableBody>
        </>
    );

    return (
        <>
           <Box className="activity-card-wrapper mb-24">
                <Grid container spacing={3}>
                {ActivityCardArray?.map((ActivityCardList, index) => (
                    <ActivityCard
                        key={index}
                        ActivityTitle={ActivityCardList.ActivityTitle}
                        ActivityNumber={ActivityCardList.ActivityNumber}
                        ActivityIcon={ActivityCardList.ActivityIcon}
                    />
                ))}
                </Grid>
            </Box>

            {(selectedOption?.value === 'date' || selectedOption === null) && 
                <>
                    <TimeAndActivityTable 
                        userRole={userRole} 
                        headers={userRole !== ROLES.employee ? ['Project', 'Member', 'To-do'] : ['Project', 'To-do']}
                        tableData={dateData} 
                    />
                </>
            }

            {(selectedOption?.value === 'project' ) && 
                <>
                    <TimeAndActivityTable 
                        userRole={userRole} 
                        headers={userRole !== ROLES.employee ? ['Date', 'Member', 'To-do'] : ['Date', 'To-do']}
                        tableData={projectData}
                    />
                </>
            }

            {(selectedOption?.value === 'todo' ) && 
                <>
                    <TimeAndActivityTable 
                        userRole={userRole} 
                        headers={userRole !== ROLES.employee ? ['Date', 'Project', 'Member'] : ['Date', 'Project']}
                        tableData={todosData}
                    />
                </>
            }

            {(selectedOption?.value === 'member' ) && 
                <>
                    <TimeAndActivityTable 
                        userRole={userRole} 
                        headers={['Date', 'Project', 'To-do']} 
                        tableData={memberData}
                    />
                </>
            }

        </>
    );
}

export default ReportActivityCard;