import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";

export const CustomTablePaginationToolbar = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const isSmallScreen = useMediaQuery("(max-width:460px)");
  const isVerySmallScreen = useMediaQuery("(max-width:387px)");
  const isMediumScreen = useMediaQuery("(max-width:715px)");
  let siblingCount = 1;
  let boundaryCount = 1;
  if (isVerySmallScreen) {
    siblingCount = 0;
    boundaryCount = 0;
  } else if (isSmallScreen) {
    siblingCount = 1;
    boundaryCount = 0;
  } else if (isMediumScreen) {
    siblingCount = 1;
    boundaryCount = 1;
  }
  if (count === 0) {
    return null;
  }
  return (
    <Box
      className="pagination-wrapper"
    >
      <Box className="rows-per-page-wrapper">
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "450",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          Rows per page
        </Typography>
        <FormControl
          margin="dense"
          sx={{
            minWidth: 120,
            borderRadius: 1,

            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
              height: "40px", 
              width: "60px",
              "& .MuiSelect-select": {
                paddingTop: "1.5rem !important",
              },
              "& .MuiSvgIcon-root": {
                paddingTop: "5px !important",
              },
            },
          }}
        >
          <Select
            value={rowsPerPage}
            onChange={onRowsPerPageChange}
            sx={{
              borderRadius: 2,
              "& ul": {
                padding: 0, 
              },
              padding: " 0 !important",
            }}
          >
            {[10, 25, 50, 100].map((rows) => (
              <MenuItem
                key={rows}
                value={rows}
                sx={{
                  "&:hover": {
                    backgroundColor: "#c5e38980 !important",
                  },

                  "&.Mui-selected": {
                    backgroundColor: "var(--color-green) !important",
                    color: "white !important", 
                  },
                }}
              >
                {rows}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Pagination
        count={Math.ceil(count / rowsPerPage)}
        page={page + 1}
        onChange={(event, value) => onPageChange(event, value - 1)}
        showFirstButton
        showLastButton
        siblingCount={siblingCount}
        boundaryCount={boundaryCount}
        className="pagination-count-wrapper"
        sx={{
          "& .MuiPaginationItem-root": {
            margin: "0 1px",

            color: "var(--color-grey)",
            "&.Mui-selected": {
              bgcolor: "var(--color-green)",
              color: "white",
              "&:hover": {
                bgcolor: "var(--color-green)", 
              },
            },
            "&:not(.Mui-selected)": {
              "&:hover": {
                bgcolor: "#c5e38980 ", 
              },
            },
          },
        }}
      />
    </Box>
  );
};
