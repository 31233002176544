import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Assignee = ({AssigneeInitial, AssigneeTitle}) => {
    return (
        <Box className='project-name'>
            <Box className='initial'>
                {AssigneeInitial}
            </Box>
            <Typography variant="h3" component="h3" className='text-grey'>
                {AssigneeTitle}
            </Typography>
        </Box>
    );
}

export default Assignee;
   