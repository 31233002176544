import { takeEvery, put } from "redux-saga/effects";
import {
  ADD_PROJECT,
  ADD_PROJECT_SUCCESS,
  CHANGE_PROJECT_STATUS,
  CHANGE_PROJECT_STATUS_SUCCESS,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  FETCH_MEMBERS,
  FETCH_PROJECTS,
  LEAVE_PROJECT,
  SET_FEEDBACK,
  SET_LOADING,
  STORE_MEMBERS,
  STORE_PROJECTS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
} from "../redux/actionTypes";
import {
  createProject,
  deleteProjectById,
  fetchAllMembers,
  fetchProjectsByUserId,
  leaveProjectById,
  updateProject,
} from "../../apis/project";
import { showSuccessToast } from "../../utils/toast";

function* fetchProjectHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const params = action.payload;

    const isListing = params.isListing;
    params.isListing && delete params.isListing;

    const response = yield fetchProjectsByUserId(params);

    yield put({ type: SET_LOADING, payload: false });
    if (params?.setprojectsFetched) {
      params?.setprojectsFetched(true);
    }
    if (response.data.status === 200) {
      if (!isListing) {
        const payload = response.data.data;

        yield put({ type: STORE_PROJECTS, payload });
      } else {
        const payload = { projects_list: response.data.data.projects };
        yield put({ type: STORE_PROJECTS, payload });
      }
    } else if (response.data.status === 404) {
      yield put({
        type: STORE_PROJECTS,
        payload: {
          projects_list: [],
          projects: [],
          activeProjectCount: 0,
          inactiveProjectCount: 0,
          totalCount: 0,
        },
      });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* leaveProjectHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield leaveProjectById(
      action.payload.userId,
      action.payload.ProjectId
    );

    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      yield showSuccessToast(response.data.data);
      const response2 = yield fetchProjectsByUserId();

      if (response2.data.status === 200) {
        const payload = response2.data.data;
        yield put({ type: STORE_PROJECTS, payload });
      } else if (response2.data.status === 404) {
        yield put({
          type: STORE_PROJECTS,
          payload: {
            projects: [],
            activeProjectCount: 0,
            inactiveProjectCount: 0,
            totalCount: 0,
          },
        });
      } else {
        const payload = {
          code: response2.data.status,
          status: "fail",
          message: response2.data.message || "Something went wrong.",
        };
        yield put({ type: SET_FEEDBACK, payload });
      }
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* deleteProjectHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield deleteProjectById(action.payload.ProjectId);
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      yield showSuccessToast(response.data.message);
      yield put({ type: DELETE_PROJECT_SUCCESS, payload: action.payload });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* addProjectHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield createProject(action.payload.data);
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 201) {
      yield showSuccessToast(response.data.message);
      const payload = {
        data: response.data.data[0],
        flag: action.payload.flag,
      };
      yield put({ type: ADD_PROJECT_SUCCESS, payload });

      // yield fetchProjectHandler();
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* fetchMemberHandler() {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield fetchAllMembers();

    if (response.data.status === 200) {
      const managerData = response.data.data[3];
      const adminData = response.data.data[1];
      const userData = response.data.data[2];
      const dataArr = managerData.concat(adminData);
      const dataArr2 = managerData.concat(userData);
      //list of users that are displayed under list of assistant manager
      const payload = {
        manager: dataArr,
        assistantManager: dataArr2,
        employee: response.data.data[2],
        viewer: response.data.data[4],
      };

      yield put({ type: STORE_MEMBERS, payload });
    } else if (response.data.status === 400) {
      yield put({ type: STORE_MEMBERS, payload: [] });
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
    yield put({ type: SET_LOADING, payload: false });
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  } finally {
    yield put({ type: SET_LOADING, payload: false });
  }
}

function* changeProjectStatusHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const id = action.payload.id;
    const payload = { status: action.payload.status };
    const response = yield updateProject(payload, id);
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 201) {
      yield showSuccessToast(response.data.message);
      const payload = response.data.data[0];
      yield put({ type: CHANGE_PROJECT_STATUS_SUCCESS, payload });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* updateProjectHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const id = action.payload.id;

    const response = yield updateProject({ ...action.payload }, id);
    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 201) {
      yield showSuccessToast(response.data.message);

      yield put({
        type: UPDATE_PROJECT_SUCCESS,
        payload: response.data.data[0],
      });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    yield put({ type: SET_FEEDBACK, payload });
  }
}

function* watchProjectAction() {
  yield takeEvery(FETCH_PROJECTS, fetchProjectHandler);
  yield takeEvery(LEAVE_PROJECT, leaveProjectHandler);
  yield takeEvery(DELETE_PROJECT, deleteProjectHandler);
  yield takeEvery(ADD_PROJECT, addProjectHandler);
  yield takeEvery(FETCH_MEMBERS, fetchMemberHandler);
  yield takeEvery(CHANGE_PROJECT_STATUS, changeProjectStatusHandler);
  yield takeEvery(UPDATE_PROJECT, updateProjectHandler);
}

export default watchProjectAction;
