import { SUCCESS_USER_LOGIN, FAILED_USER_LOGIN, SET_USER_DATA, UPDATE_USER_EMAIL_SUCCESS } from "../actionTypes";

const initialState = {
    data:{
        id:"",
        first_name:"",
        last_name:"",
        email:"",
        role:"",
        status: "active",
        time_tracking_status: "",
        phone: "",
        skype_Id: "",
        Time_Zone: ""
    }
}

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUCCESS_USER_LOGIN:
            return { data: action.payload };
        
        case FAILED_USER_LOGIN:
            return { 
                data: { 
                    id:"",
                    first_name:"",
                    last_name:"",
                    email:"",
                    role:"",
                    status: "active",
                    time_tracking_status: "",
                    phone: "",
                    skype_Id: "",
                    Time_Zone: ""
                } 
        }

        case SET_USER_DATA:
            return {
                data: action.payload
            }
        
        case UPDATE_USER_EMAIL_SUCCESS:
            const updatedState = { 
                data: { ...state.data, email: action.payload.newEmail } 
            }
            return updatedState;

        default:
            return state;
    }
}

export default UserReducer;