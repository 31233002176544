import { combineReducers } from "redux";
import UserReducer from "./user.reducer";
import FeedbackReducer from "./feedback.reducer";
import EmployeeReducer, {
  InviteEmployeeReducer,
  UserProjectsReducer,
  UserRolesReducer,
} from "./employee.reducer";
import ProjectReducer from "./project.reducer";
import TodoReducer from "./todos.reducer";
import TimeSheetReducer, { TimeSheetFilterReducer } from "./timesheet.reducer";
import ReportsReducer from "./reports.reducer";
import DashboardReducer from "./dashboard.reducer";
import ApplicationPermissionReducer from "./applicationPermission.reducer";

const rootReducer = combineReducers({
  feedback: FeedbackReducer,
  user: UserReducer,
  employee: EmployeeReducer,
  invitedEmployee: InviteEmployeeReducer,
  roles: UserRolesReducer,
  project: ProjectReducer,
  todos: TodoReducer,
  projects: UserProjectsReducer,
  timeSheet: TimeSheetReducer,
  reports: ReportsReducer,
  dashboards: DashboardReducer,
  timeSheetFilter: TimeSheetFilterReducer,
  applicationPermissions: ApplicationPermissionReducer,
});

export default rootReducer;
