import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import UserImage from '../../assets/employees/employee2.png';
import AccountDetails from './AccountDetails';
// import ChangeEmail from './ChangeEmail';
import { useState } from 'react';
import { getUserProfileDataFromCookies } from '../../utils/helper';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className='my-account-content'>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MyAccount = () => {
  const userProfileData = getUserProfileDataFromCookies();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    if(newValue === 1){
      window.open(process.env.REACT_APP_CHANGE_PASSWORD_URL, '_blank');
    } else {
      setValue(newValue);
    }
  };

  return (
    <>
      <Box className='account-wrapper'>
        <Box className='account-tab card mb-0'>
          <Box className='user-profile'>
            <img src={userProfileData.imgUrl || UserImage} alt='user-img'
      
            />
          </Box>

          {/* tabbing section */}
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='my-account-tab'>
            <Tab className='btn' label="Account Details" {...a11yProps(0)} />
            <Tab className='btn' label="Change Password" {...a11yProps(2)} />
          </Tabs>

        </Box>
        <Box className='account-content card mb-0'>
          <TabPanel value={value} index={0}>
            <AccountDetails/>
          </TabPanel>
        </Box>
      </Box>
    </>
  );
}

export default MyAccount;
