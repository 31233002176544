import {  UPDATE_EMPLOYEE_STATUS, UPDATE_EMPLOYEE_TRACKING_STATUS, FETCH_EMPLOYEE_BY_ID, FILTER_EMPLOYEE, SEARCH_EMPLOYEE, UPDATE_EMPLOYEE, FETCH_PROJECT_BY_USER_ID, UPDATE_PROJECT_BY_USER_ID, SEARCH_EMPLOYEE_INVITE, RESEND_INVITE_ACTION, DELETE_EMPLOYEE_INVITE, FETCH_EMPLOYEE, FETCH_EMPLOYEE_INVITE_BY_MAIL, FETCH_USER_ROLES, ADD_EMPLOYEE_SUCCESS, DELETE_EMPLOYEE_SAGA } from "../actionTypes";

export const fetchEmployeeData = (payload) => {
    return { type: FETCH_EMPLOYEE, payload }
}

export const fetchEmployeeInviteByData = () => {
    return { type: FETCH_EMPLOYEE_INVITE_BY_MAIL }
}

export const fetchEmployeeDataByID = (payload) => {
    return { type: FETCH_EMPLOYEE_BY_ID, payload }
}

export const addEmployee = (payload) => {
    return { type: ADD_EMPLOYEE_SUCCESS, payload };
}

export const updateEmployeeStatus = (payload) => {
    return { type: UPDATE_EMPLOYEE_STATUS, payload };
}

export const updateEmployeeTakingStatus = (payload) => {
    return { type: UPDATE_EMPLOYEE_TRACKING_STATUS, payload };
}

export const updateEmployee = (payload) => {
    return { type: UPDATE_EMPLOYEE, payload };
}

export const fetchUserRolesData = () => {
    return { type: FETCH_USER_ROLES }
}

export const deleteEmployeeById = (payload) => {
    return { type: DELETE_EMPLOYEE_SAGA, payload }
}

export const deleteEmployeeInviteById = (payload) => {
    return { type: DELETE_EMPLOYEE_INVITE, payload }
}

export const filterEmployeeData = (payload) => {
    return { type: FILTER_EMPLOYEE, payload }
}

export const searchEmployee = (payload) => {
    return { type: SEARCH_EMPLOYEE, payload };
}

export const searchEmployeeInvite = (payload) => {
    return { type: SEARCH_EMPLOYEE_INVITE, payload };
}

export const fetchProjectByEmpId = (payload) => {
    return { type: FETCH_PROJECT_BY_USER_ID, payload };
}

export const updateProjectByEmpId = (payload) => {
    return { type: UPDATE_PROJECT_BY_USER_ID, payload};
}

export const resendInvite = (payload) => {
    return { type: RESEND_INVITE_ACTION, payload};
}