import React, { useCallback, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import styled from '@mui/system/styled';
import Select from 'react-select';
import { FiPlus } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsersByProject } from '../../apis/todos';
import { setFeedback } from '../../store/redux/actions/feedback.action';
import { updateTodo } from '../../store/redux/actions/todos.action';
import { ROLES } from '../../lib/constants';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3), 
    }
}));

const EditProjectToDo = ({ editToDosModalOpen, editTodosModalClose, editData }) => {
    const dispatch = useDispatch();
    const userID = useSelector(state => state.user.data.id);
    const userRole = useSelector(state => state.user.data?.user_role?.id);
    const projects = useSelector(state => state.project.projects_list);
    const projectOptions = projects?.map(proj => (
        { value: proj.id, label: proj.title}
    ));
    const [validationErrors, setValidationErrors] = useState({});
    const [userOptions, setUserOption] = useState([]);
    const [selectedProjectOption, setSelectedProjectOption] = useState('');
    const [selectedUserOption, setSelectedUserOption] = useState([]);
    const [todoInput, setTodoInput] = useState('');

    const colorStyle = {
        multiValue: (styles, { data }) => ({
            ...styles,
            backgroundColor: "rgba(140, 199, 20, 0.2)",
            borderRadius: "6px",
            border: `1px solid #8CC714`,
        }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            fontSize: "16px",
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight:'50px',
            borderColor: !validationErrors.assignee ? '#cccccc' : 'red'
        }),
    };
  
    const handleSelectedUser = (selectedOption) => {
      if (selectedOption) {
        // const selectedUserId = selectedOption.value;
        setSelectedUserOption(selectedOption);
      }
    };

    const handleTodoInput = (e) => {
        setTodoInput(e.target.value);

        e.target.value === "" ? 
            setValidationErrors(prev => ({...prev, todoInput:"Please enter a todo."})) : 
            setValidationErrors(prev => ({...prev, todoInput: ""}))
    };

    const fetchUsersByProjectData = useCallback( async () => {
        try{
        //   const params = { role:['user', 'manager'] };
            const roleData = userRole !== ROLES.employee ? [2,3,1] :[2,3,1];
            const params = { role: roleData, paranoid: true };
            const response = await fetchUsersByProject(params, selectedProjectOption);
            const userData = response.data.data;

          setUserOption(userData ? userData.map(user => (
            { value: user.user.id, label: user.user.first_name + ' ' + user.user.last_name }
        )) : []);
        }catch(error){
            const errPayload = { 
                code: error.status, 
                status: 'fail', 
                message:error.message || "Something went wrong." 
            };
            dispatch(setFeedback(errPayload));  
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[dispatch, selectedProjectOption])

    const validateForm = () => {
        const errors = {};
        
        if (!todoInput.trim()) {
            errors.todoInput = 'Please enter a to-do.';
        }
        
        if (!selectedUserOption.length) {
            errors.assignee = 'Please select member.';
        }
        
        setValidationErrors(errors);
        
        return Object.keys(errors).length === 0;
    };

    const clearData = () => {
        setSelectedProjectOption('');
        setSelectedUserOption('');
        setUserOption([]);
        setTodoInput('');
        editTodosModalClose();
    }

    useEffect(()=>{
    if(selectedProjectOption !== ''){
        fetchUsersByProjectData();
    }
    },[fetchUsersByProjectData, selectedProjectOption])  
    
    useEffect(() => {
    if (!editToDosModalOpen) {
        setUserOption([]);
    }
    }, [editToDosModalOpen]);
    
    useEffect(() => {
        setSelectedProjectOption(editData.project_id);
        const users = editData?.todo_assignments?.map(
            item => ({
                value: item.user.id,
                lable: `${item.user.first_name} ${item.user.last_name}`
            })
        );
        setSelectedUserOption(users);
        setTodoInput(editData.description);
    }, [editData]);

    const handleSubmit = async () => {
        try {
        const isValid = validateForm();

        if(isValid){
            const payload = {
                id: editData.id,
                assignee: selectedUserOption ? [...selectedUserOption.map(item=>item.value)] : [userID],
            };
            if (todoInput !== editData.description) {
                payload.description = todoInput;
            }
            dispatch(updateTodo(payload));
            editTodosModalClose(); 
        }
        } catch (error) {
            const errPayload = { 
                code: error.status, 
                status: 'fail', 
                message: error.message || 'Something went wrong.' 
            };
            dispatch(setFeedback(errPayload));
        }
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={editToDosModalOpen}
            onClose={editTodosModalClose}
            closeAfterTransition
            slotProps={{
            backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={editToDosModalOpen}>
                <Box sx={styled} className='modal-wrapper modal-bg'>
                    <Typography variant="h6" component="h6" className='text-black modal-title' >
                        Edit To-Dos
                    </Typography>
                    <Box className='modal-close' onClick={editTodosModalClose}>
                        <FiPlus/>
                    </Box>
                    <Box className='modal-body'>
                        <FormGroup className='form-field'>
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormControl variant="standard" className='form-control'>
                                        <Typography variant="body2" component="span" className='text-black input-label'>
                                            Select Project<Typography variant="body2" component="span" className="asterisk">*</Typography>
                                        </Typography>
                                        <Select className='select-field select-peojects'
                                            value={projectOptions.find(option => option.value === selectedProjectOption)}
                                            // onChange={handleSelectedProject}
                                            isDisabled={true}
                                            placeholder='Select Project'
                                            options={projectOptions}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 10,
                                                colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(140, 199, 20, 0.4);',
                                                primary: '#8CC714',
                                                },
                                            })
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormControl variant="standard" className='form-control'>
                                        <Typography variant="body2" component="span" className='text-black input-label'>
                                            To-Do<Typography variant="body2" component="span" className="asterisk">*</Typography>
                                        </Typography>
                                        <BootstrapInput 
                                            placeholder="Enter to-do" 
                                            className={!validationErrors.todoInput ? 'input-field' : 'border-error'}  
                                            value={todoInput} 
                                            onChange={(e) => {
                                                handleTodoInput(e);
                                            }}
                                        />
                                        {validationErrors.todoInput && (
                                            <span className="error-text">{validationErrors.todoInput}</span>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormControl variant="standard" className='form-control'>
                                        <Typography variant="body2" component="span" className='text-black input-label'>
                                            Select Member<Typography variant="body2" component="span" className="asterisk">*</Typography>
                                        </Typography>
                                        <Select 
                                            isMulti
                                            className='select-peojects'
                                            placeholder='Select Member'
                                            value={userOptions.filter(option => selectedUserOption.some(selected => selected.value === option.value))}
                                            onChange={handleSelectedUser}
                                            options={userOptions}
                                            isClearable={false}
                                            styles={colorStyle}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 10,
                                                colors: {
                                                ...theme.colors,
                                                primary25: "rgba(140, 199, 20, 0.4);",
                                                primary: !validationErrors.assignee ? '#8CC714' : 'red',
                                                },
                                            })}
                                        />
                                        {validationErrors.assignee && (
                                            <span className="error-text">{validationErrors.assignee}</span>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </FormGroup>              
                    </Box>
                    <Box className='modal-footer'>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6}>
                                <Button className='btn btn-primary' onClick={handleSubmit}>Save</Button>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Button className='btn btn-secondary' onClick={clearData}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export default EditProjectToDo;