import {
  ADD_PROJECT,
  CHANGE_PROJECT_STATUS,
  DELETE_PROJECT,
  FETCH_MEMBERS,
  FETCH_PROJECTS,
  LEAVE_PROJECT,
  UPDATE_PROJECT,
} from "../actionTypes";

export const fetchProjects = (payload) => {
  return { type: FETCH_PROJECTS, payload };
};
export const fetchIncompleteTimesheets = (payload) => {
  return { type: FETCH_PROJECTS, payload };
};

export const leaveProject = (payload) => {
  return { type: LEAVE_PROJECT, payload };
};

export const deleteProject = (id, flag) => {
  return { type: DELETE_PROJECT, payload: { ProjectId: id, flag } };
};

export const addProject = (payload) => {
  return { type: ADD_PROJECT, payload };
};

export const fetchMembers = () => {
  return { type: FETCH_MEMBERS };
};

export const changeProjectStatus = (payload) => {
  return { type: CHANGE_PROJECT_STATUS, payload };
};

export const updateProject = (payload) => {
  return { type: UPDATE_PROJECT, payload };
};
