import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import styled from '@mui/system/styled';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import ScreenCastCard from '../../components/common/ScreenCastCard';
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { setFeedback } from '../../store/redux/actions/feedback.action';
import { fetchToDosByProjectId } from '../../apis/todos';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { deleteTimeSheet, fetchTimeSheetByDate, moveTimeSheet } from '../../apis/timeSheet';
import { fetchProjectByEmployeeId } from '../../apis/employee';
import { showSuccessToast } from '../../utils/toast';
import { convertSecondsIntoTime, convertTimeInto12HoursFormat, getPermissionAccess, getSelectStyles } from '../../utils/helper';
import ImageModal from '../../components/common/ImageModal';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3), 
    }
}));

const convertIntoAmPmFormat = (time) => {
    let hours;
    let minutes;
    let hoursInt;
    let period;
    let hours12;
    const [sTime, eTime] = time.replace(' ', '').split('to');

    [hours, minutes]  = sTime.split(':');
    hoursInt = parseInt(hours);
    period = hoursInt >= 12 ? 'pm' : 'am';
    hours12 = hoursInt > 12 ? hoursInt - 12 : hoursInt;
    const SAmPmTime = `${hours12}:${minutes} ${period}`;

    [hours, minutes]  = eTime.split(':');
    hoursInt = parseInt(hours);
    period = hoursInt >= 12 ? 'pm' : 'am';
    hours12 = hoursInt > 12 ? hoursInt - 12 : hoursInt;
    const EAmPmTime = `${hours12}:${minutes} ${period}`;

    return `${SAmPmTime} to ${EAmPmTime}`;
}


const convertDDMMYYYY = (dateValue) => {
    const utcDate = new Date(dateValue);
    const date = utcDate.getUTCDate();
    const month = utcDate.getUTCMonth();
    const year = utcDate.getUTCFullYear();
    const convertedDate = `${date}-${month + 1}-${year}`;
    return convertedDate;
}

const changeDateFormat = (dateValue) => {
    // const convertedDate = convertDateIntoYYYYMMDD(date);
    const utcDate = new Date(dateValue);
    const date = utcDate.getUTCDate();
    const month = utcDate.getUTCMonth();
    const year = utcDate.getUTCFullYear();
    const convertedDate = `${year}-${(month + 1).toString().padStart(2,0)}-${date.toString().padStart(2,0)}`;

    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();
    const seconds = utcDate.getUTCSeconds();
    const  convertedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    return `${convertedDate} ${convertedTime}`;
}

let MODE = "";

const EditTimePage = () => {
    let tempIndex = -1;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state.assignee;
    const isManual = location.state.isManual;
    MODE = location.pathname.includes('edit') ? "EDIT" : "SPLIT";
    const { timesheetId } = useParams();

    const [timeSheetData, setTimeSheetData] = useState([]);
    const [isScrollable, setIsScrollable] = useState(true); 
    const [todo, setTodo] = useState([]);
    const [projects, setProjects] = useState([]);
    const [moveSlotArray, setMoveSlotArray] = useState([]);
    const [billableSlotArray, setBillableSlotArray] = useState([]);

    const userDetails = useSelector(state => state.user.data);
    const permissions = userDetails.role_permissions.permissions;
    const permissionsAccess = getPermissionAccess(permissions, "Timesheets");
    if(!permissionsAccess.edit){
        navigate('/', { replace: true });
    }

    const changeBillableSlotArrayHandler = (index1, index2, value) => {
        const cloneBillableSlotArray = [...billableSlotArray];
        cloneBillableSlotArray[index1][index2].billable = value === true ? "yes" : "no";
        cloneBillableSlotArray[index1][index2].isModify = true;
        setBillableSlotArray(cloneBillableSlotArray);
    }

    
    const [formData, setFormData] = useState({
        project: null,
        todo: null,
        memo:""
    })
    const [error, setError] = useState({
        project: null,
        todo: null,
        memo:""
    })

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay: false,
        slidesToShow: 11,
        slidesToScroll: 1,
  
        draggable: isScrollable,
        swipeToSlide: isScrollable,
        touchMove: isScrollable,
  
        nextArrow: <FiChevronRight />,
        prevArrow: <FiChevronLeft/>,
        responsive: [
          {
            breakpoint: 1930,
            settings: {
              slidesToShow: 8,
            }
          },
          {
              breakpoint: 1570,
              settings: {
                slidesToShow: 7,
              }
          },
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 5,
            }
          },
          {
              breakpoint: 1100,
              settings: {
                slidesToShow: 4,
              }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 412,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
    };

    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    const openImageModalHandler = (index) => {
        setImageIndex(index);
        setImageModalOpen(true);
    }

    const closeImageModalHandler = () => {
        setImageIndex(0);
        setImageModalOpen(false);
    }
      
    useLayoutEffect(() => {
        const getSingleTimeSheetData = async() =>{
            try {
                const params = { timesheet_id: timesheetId };
                const response = await fetchTimeSheetByDate(params);
                if(response.data.status === 200){
                    const data = response.data.data[0];
                    setTimeSheetData(data);
                    if(MODE === "EDIT"){
                        setFormData((prev) => {
                            return {  
                                project: data.project ? { value: data.project.id, label: data.project.title, billable: data.project.billable } : null,  
                                todo: data.todo ?  {value: data.todo.id, label: data.todo.description} : null,  
                                memo: data.memo  
                            }
                        })
                    }
                    let billableArray = [];
                    data.trackedTime.forEach(row => {
                        const subArray = row.data.map((item) => ({ billable: item.billable, start_date: item.start_date, end_date: item.end_date, isModify: false }))
                        billableArray.push(subArray);
                    })
                    setBillableSlotArray(billableArray)


                } else if(response.data.status === 404){
                    setTimeSheetData([]);
                } else {
                    const errPayload = { code: response.data.status, status: 'fail', 
                    message:response.data.message || "Something went wrong." }
                    dispatch(setFeedback(errPayload));  
                }
            } catch (error) {
                const errPayload = { code: error.status, status: 'fail', 
                message:error.message || "Something went wrong." }
                dispatch(setFeedback(errPayload));  
            }
        }
        getSingleTimeSheetData();
    }, [dispatch, timesheetId])

    const projectOptions = useMemo(() => {
        return projects.length ? 
          projects.map(row => {
            return { value: row.id, label: row.title, billable: row.billable }
          })
        : []
    }, [projects]);
  
    const todoOptions = useMemo(() => {
        return todo.length ? 
          todo.map(row => {
            return { value: row.id, label: row.description }
          })
          : []
    }, [todo]);
  
    useEffect(() => {
        const fetchProjectHandler = async () => {
            try {
                // let response = await fetchProjectByEmployeeId(location.state.assignee);
                const params = { status: "active" };
                let response = await fetchProjectByEmployeeId(params, userId);
    
                if(response.data.status === 200){
                    setProjects(response.data.data.projects);            
                }
                else if(response.data.status === 404){
                    setProjects([]);
                } else {
                    const errPayload = { code: response.data.status, status: 'fail', 
                    message:response.data.message || "Something went wrong." }
                    dispatch(setFeedback(errPayload));  
                }
            } catch (error) {
                const errPayload = { code: error.status, status: 'fail', 
                message:error.message || "Something went wrong." }
                dispatch(setFeedback(errPayload));  
            } 
        };
        fetchProjectHandler();
    }, [dispatch, userId])

    useEffect(() => {
        const fetchTodoHandler =  async () => {
            try {
                // setFormData((prev) => ({...prev, todo: null }))
                if(formData.project !== null){
                    // const params = { project: formData.project.value, assignee: location.state.assignee }; // need to focus on this params 
                    const params = { project: formData.project.value, assignee: userId, status: "pending" }; // need to focus on this params 
                    const response = await fetchToDosByProjectId(params);
                    if(response.data.status === 200){
                        setTodo(response.data.data.todos);
                    }
                    else if(response.data.status === 404){
                        setTodo([]);
                        return;
                    } else {
                        const errPayload = { code: response.data.status, status: 'fail', 
                        message:response.data.message || "Something went wrong." }
                        dispatch(setFeedback(errPayload));  
                    }
                }
            } catch (error) {
                const errPayload = { code: error.status, status: 'fail', 
                message:error.message || "Something went wrong." }
                dispatch(setFeedback(errPayload));  
            } 
        };

        fetchTodoHandler(); 
    }, [dispatch, formData.project, userId]);

    const changeProjectHandler = (selectedOption) => {
        if(selectedOption){
          setFormData(prev => ({ ...prev,  project: selectedOption, todo: null }));
          setError(prev => ({ ...prev, project: "" }));
        }
    }
    const changeTodoHandler = (selectedOption) => {
        if(selectedOption){        
          setFormData(prev => ({ ...prev,  todo: selectedOption }));
          setError(prev => ({ ...prev, todo: "" }));
        }
    }
    const changeMemoHandler = (e) => {
        setError(prev => ({ ...prev, memo: e.target.value !== "" ? "" : "Please enter memo." }));  
        setFormData(prev => ({ ...prev,  memo: e.target.value }))
    }

    const checkBoxHandler = (e, slot) => {
        if(e.target.checked){
            setMoveSlotArray((prev) => {
                return [ ...prev, slot ]
            })
        } else {
            setMoveSlotArray((prev) => {
               return prev.filter((row) => row.start_date !== slot.start_date && row.end_date !== slot.end_date)
            });
        }
    }
    const deleteScreenShotHandler = (isDelete, slot) => {
        if(isDelete){
            setMoveSlotArray((prev) => {
                return [ ...prev, slot ]
            })
        } else {
            setMoveSlotArray((prev) => {
               return prev.filter((row) => row.start_date !== slot.start_date && row.end_date !== slot.end_date)
            });
        }
    }

    // for split time sheet
    const moveButtonHandler = async (e) => {
        e.preventDefault();
        try {
            setError({ project: "", todo: "", memo: "" });
            if(formData.project === null){
              setError((prev) => ({ ...prev, project:"Please select project." }))
              return;
            } else if(formData.todo === null){
              setError((prev) => ({ ...prev, todo:"Please select todo." }));
              return;
            } else if(formData.memo.trim() === ""){
              setError((prev) => ({ ...prev, memo:"Please enter memo." }));
              return;
            } 
            
            if(!moveSlotArray.length){
                const errPayload = { 
                    code: 400, 
                    status: 'fail', 
                    message:"Please select slot." 
                };
                dispatch(setFeedback(errPayload));
                return;
            }
            
            const payload = {
                // timesheet_id: location.state.assignee,
                project_id: formData.project.value,
                todo_id: formData.todo.value,
                memo: formData.memo,
                trackedTime: moveSlotArray?.map(({ start_date, end_date }) => {
                    return { start_date: changeDateFormat(start_date), end_date: changeDateFormat(end_date) };
                })
            }
            const response = await moveTimeSheet(payload, timesheetId);
            if(response.data.status === 200){
                showSuccessToast(response.data.data);
                navigate("/timesheets");
            } else {
                const errPayload = { code: response.data.status, status: 'fail', 
                message:response.data.message || "Something went wrong." }
                dispatch(setFeedback(errPayload));
            }
        } catch (error) {
            const errPayload = { code: error.status, status: 'fail', 
            message:error.message || "Something went wrong." }
            dispatch(setFeedback(errPayload));  
        }
    }

    // for edit time sheet
    const saveButtonHandler = async (e) => {
        e.preventDefault();
        try {
            let deleteResponseIds;
            setError({ project: "", todo: "", memo: "" });
            if(formData.project === null){
              setError((prev) => ({ ...prev, project:"Please select project." }))
              return;
            } else if(formData.todo === null){
              setError((prev) => ({ ...prev, todo:"Please select todo." }));
              return;
            } else if(formData.memo.trim() === ""){
              setError((prev) => ({ ...prev, memo:"Please enter memo." }));
              return;
            }
            const billablePayload = [];
            billableSlotArray.forEach(row => {
                row.forEach(({ isModify, start_date, end_date, billable }) => {
                    if(isModify){
                        billablePayload.push({ start_date: changeDateFormat(start_date), end_date: changeDateFormat(end_date), billable });
                    }
                })
            });

            if(billablePayload.length || formData.project.billable === 'no'){
                let payload;
                if(formData.project.billable === 'yes'){
                    payload = {
                        billable: 1,
                        trackedTime: billablePayload
                    }
                } else if(formData.project.billable === 'no') {
                    payload = {
                        billable: 1,
                    }
                    if(billablePayload.length){
                        payload.trackedTime = billablePayload.map((row) => ({ ...row, billable: 'no' }))
                    } else {
                        const trackedTimePayload = [];
                        billableSlotArray.forEach(row => {
                            row.forEach(({ isModify, start_date, end_date, billable }) => {
                                trackedTimePayload.push({ start_date: changeDateFormat(start_date), end_date: changeDateFormat(end_date), billable: 'no' });
                            })
                        })
                        payload.trackedTime = trackedTimePayload
                    }
                }
                
                const response = await moveTimeSheet(payload, timesheetId);
                if(response.data.status !== 200){
                    const errPayload = { code: response.data.status, status: 'fail', 
                    message:response.data.message || "Something went wrong." }
                    dispatch(setFeedback(errPayload));
                    return;
                }
            }

            if(moveSlotArray.length){
                const payload = {
                    deleteTimesheet: 1,
                    trackedTime: moveSlotArray.map(row => {
                        return { ...row, start_date: changeDateFormat(row.start_date), end_date: changeDateFormat(row.end_date) };
                    })
                }
                const response = await deleteTimeSheet(timesheetId, payload);
                if(response.data.status === 200){
                    deleteResponseIds = response.data.data;
                }else if(response.data.status !== 200){
                    const errPayload = { code: response.data.status, status: 'fail', 
                    message:response.data.message || "Something went wrong." }
                    dispatch(setFeedback(errPayload));
                    return;
                }
            }

            const payload = {
                // timesheet_id: location.state.assignee,
                project_id: formData.project.value,
                todo_id: formData.todo.value,
                memo: formData.memo,
                // trackedTime: moveSlotArray
            }
            
            if(deleteResponseIds === undefined) {
                const response = await moveTimeSheet(payload, timesheetId);
                if(response.data.status === 200){
                    showSuccessToast(response.data.data);
                } else {
                    const errPayload = { code: response.data.status, status: 'fail', 
                    message:response.data.message || "Something went wrong." }
                    dispatch(setFeedback(errPayload));
                }
                navigate("/timesheets");
            }  else if(deleteResponseIds.length){
                let successMessage = "";
                for (const row of deleteResponseIds) {
                    const response = await moveTimeSheet(payload, row);
                    if(response.data.status === 200){
                        // showSuccessToast(response.data.data);
                        successMessage = response.data.data;
                    } else {
                        const errPayload = { code: response.data.status, status: 'fail', 
                        message:response.data.message || "Something went wrong." }
                        dispatch(setFeedback(errPayload));
                    }
                }
                successMessage && showSuccessToast(successMessage);
                navigate("/timesheets");
            } else if(!deleteResponseIds.length){ 
                navigate("/timesheets");
            }        
        } catch (error) {
            const errPayload = { code: error.status, status: 'fail', 
            message:error.message || "Something went wrong." }
            dispatch(setFeedback(errPayload));  
        }
    }

    const cancelButtonHandler = () => {
        navigate('/timesheets');
    }
  
    // Usage example:
    const selectProjectStyles = getSelectStyles(error.project);
    const selectTodoStyles = getSelectStyles(error.todo);

    function imageModalData(data) {
        let result = [];
      
        function recursiveModalData(items) {
          if (Array.isArray(items)) {
            for (const item of items) {
              if (item.data && Array.isArray(item.data)) {
                recursiveModalData(item.data);
              } else {
                result.push(item);
              }
            }
          }
        }
      
        recursiveModalData(data);
        return result;
    }
       
    const finalModalArray = imageModalData(timeSheetData.trackedTime);    

    return (
        <>
            <Box className="edit-time">
                <Box className="card">
                    <Grid container spacing={2}>
                        {
                            MODE === "SPLIT" ? 
                            <Grid item md={12}>
                                <Box className='confirmation-text'>
                                    <Grid container spacing={0} rowSpacing={2}>
                                        {/* <Grid item md={12} lg={MODE === "SPLIT" ? 6 : 12}> */}
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                                User
                                            </Typography>
                                            <Typography variant="h3" component="h3" className='text-grey confirmation-sub-text'>
                                                {timeSheetData?.user?.first_name}{" "}{timeSheetData?.user?.last_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                                Project
                                            </Typography>
                                            <Typography variant="h3" component="h3" className='text-grey confirmation-sub-text'>
                                                {timeSheetData?.project?.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                                Date
                                            </Typography>
                                            <Typography variant="body2" component="span" className='text-grey'>
                                                {timeSheetData.start_date ? convertDDMMYYYY(timeSheetData.start_date) : "-"}
                                            </Typography>                                
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                                Time Span
                                            </Typography>
                                            <Typography variant="body2" component="span" className='text-grey'>
                                                {timeSheetData.start_date ? convertTimeInto12HoursFormat(timeSheetData.start_date) : "-"} 
                                                {" "}<b className='text-black'>To</b>{" "}
                                                {timeSheetData.end_date ? convertTimeInto12HoursFormat(timeSheetData.end_date) : "-"}
                                            </Typography>                                
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                                To-Do
                                            </Typography>
                                            <Typography variant="body2" component="span" className='text-grey'>
                                            {timeSheetData?.todo?.description}
                                            </Typography>                                
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                                Memo
                                            </Typography>
                                            <Typography variant="body2" component="span" className='text-grey'>
                                                {timeSheetData?.memo}
                                            </Typography>                                
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                                Duration
                                            </Typography>
                                            <Typography variant="body2" component="span" className='text-grey'>
                                                {timeSheetData.duration ? convertSecondsIntoTime(parseInt(timeSheetData.duration)) : ""}
                                            </Typography>                                
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            :
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Box className='confirmation-text'>
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                                User
                                            </Typography>
                                            <Typography variant="h3" component="h3" className='text-grey confirmation-sub-text'>
                                                {timeSheetData?.user?.first_name}{" "}{timeSheetData?.user?.last_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                                Date
                                            </Typography>
                                            <Typography variant="body2" component="span" className='text-grey'>
                                                {timeSheetData.start_date ? convertDDMMYYYY(timeSheetData.start_date) : ""}
                                            </Typography>                                
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                                Time Span
                                            </Typography>
                                            <Typography variant="body2" component="span" className='text-grey'>
                                                {timeSheetData.start_date ? convertTimeInto12HoursFormat(timeSheetData.start_date) : "-"} 
                                                {" "}<b className='text-black'>To</b>{" "}
                                                {timeSheetData.end_date ? convertTimeInto12HoursFormat(timeSheetData.end_date) : "-"}
                                            </Typography>                                
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                                Duration
                                            </Typography>
                                            <Typography variant="body2" component="span" className='text-grey'>
                                                {timeSheetData.duration ? convertSecondsIntoTime(parseInt(timeSheetData.duration)) : ""}
                                            </Typography>                                
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        }
                        <Grid item lg={MODE === "SPLIT" ? 12 : 8} md={MODE === "SPLIT" ? 12 : 8} xs={12} >
                            <FormGroup className='form-field'>
                                <Grid container spacing={2} justifyContent={"space-between"}>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <FormControl variant="standard" className='form-control mb-0'>
                                            <Typography variant="body2" component="span" className='text-black input-label'>
                                                Select Project<Typography
                                                    variant="body2"
                                                    component="span"
                                                    className="asterisk"
                                                >
                                                    *
                                                </Typography>
                                            </Typography>
                                            <Select 
                                                placeholder='Select Project'
                                                className='select-date'
                                                value={formData.project}
                                                onChange={changeProjectHandler}
                                                options={projectOptions}
                                                styles={selectProjectStyles.styles}
                                                theme={selectProjectStyles.theme}
                                            />
                                            {error.project && (
                                            <span className="error-text">
                                                {error.project}
                                            </span>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <FormControl variant="standard" className='form-control'>
                                            <Typography variant="body2" component="span" className='text-black input-label'>
                                                Select To-Do
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    className="asterisk"
                                                >
                                                    *
                                                </Typography>
                                            </Typography>
                                            <Select className='select-date'
                                                placeholder='Select To-dos'
                                                value={formData.todo}
                                                onChange={changeTodoHandler}
                                                options={todoOptions}
                                                styles={selectTodoStyles.styles}
                                                theme={selectTodoStyles.theme}    
                                            />
                                            {error.todo && (
                                            <span className="error-text">
                                                {error.todo}
                                            </span>)}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FormControl variant="standard" className='form-control mb-0'>
                                        <Typography variant="body2" component="span" className='text-black input-label'>
                                            Memo
                                            <Typography
                                                variant="body2"
                                                component="span"
                                                className="asterisk"
                                            >
                                                *
                                            </Typography>
                                        </Typography>
                                        <BootstrapInput 
                                            multiline
                                            rows={4.5}
                                            placeholder="Memo" 
                                            className={!error.memo ? 'input-field' : 'border-error'}
                                            value={formData.memo}
                                            onChange={changeMemoHandler}
                                            />
                                            {error.memo && (
                                            <span className="error-text">{error.memo}</span>)}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </FormGroup>
                        </Grid>
                    </Grid> 
                </Box>
                
                <Box className="card screenshot-wrapper mb-0" style={{marginTop:'10px'}}>
                    {timeSheetData?.trackedTime?.length ?
                        timeSheetData?.trackedTime?.map((row, i) => {
                        return (
                            <Box className='screencast-slider' key={i+"_edit-timesheet-slider"}>
                                <Box className='slider-title'>
                                    <Typography variant="h6" component="h6" className='text-white card-title'>
                                    {convertIntoAmPmFormat(row.time)}
                                    </Typography>
                                </Box>

                                <Slider {...settings}>
                                    {row.data?.map((ScreenCastCardList,index)=>{
                                            let currentCard = [];
                                            if (ScreenCastCardList?.drive_primary || ScreenCastCardList?.screenshot_primary){
                                                tempIndex = tempIndex + 1;
                                                currentCard.push(tempIndex);
                                            } if (ScreenCastCardList?.drive_secondary || ScreenCastCardList?.screenshot_secondary) {
                                                tempIndex = tempIndex + 1;
                                                currentCard.push(tempIndex);
                                            }
                                        //}
                                        return (
                                            <ScreenCastCard
                                                key={index+"_edit_timeSheet"}
                                                flag={0}
                                                ScreenCastImage={[
                                                    { screen:"Screen 1", image: ScreenCastCardList?.drive_primary || ScreenCastCardList?.screenshot_primary, isDriveImage: ScreenCastCardList?.drive_primary ? true : false },
                                                    { screen:"Screen 2", image: ScreenCastCardList?.drive_secondary || ScreenCastCardList?.screenshot_secondary, isDriveImage: ScreenCastCardList?.drive_secondary ? true : false }
                                                ]}
                                                setIsScrollable={setIsScrollable}
                                                Slot={{start_date: ScreenCastCardList.start_date, end_date: ScreenCastCardList.end_date}}
                                                checkBoxHandler={checkBoxHandler}
                                                deleteScreenShotHandler={deleteScreenShotHandler}
                                                MODE={MODE}
                                                ProjectTitle={timeSheetData.project.title}
                                                moveSlotArray={moveSlotArray}
                                                activity={ScreenCastCardList.overall_activity}
                                                openImageModalHandler = {openImageModalHandler}
                                                tempIndex={currentCard[0]}
                                                billable={billableSlotArray[i][index].billable}
                                                isProjectBillable={formData?.project?.billable}
                                                changeBillableSlotArrayHandler={changeBillableSlotArrayHandler}
                                                primaryIndex={i}
                                                secondaryIndex={index}
                                                isManual={isManual}
                                            />
                                        )}
                                    )}
                                </Slider> 
                            </Box>
                        )
                    })
                    : <Typography className="text-grey" style={{textAlign:'center'}}>No Screenshots in your time sheet</Typography>
                    }
                    
                </Box>

                <Box>
                    <Grid container spacing={2} marginTop={2} justifyContent={'flex-end'} alignItems={'center'}>
                        {
                        MODE === "EDIT" ? 
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                            <Button className='btn btn-primary' onClick={saveButtonHandler}>Save</Button>
                        </Grid>
                        : 
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                            <Button className='btn btn-primary' onClick={moveButtonHandler}>Move</Button>
                        </Grid>
                        }
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                            <Button className='btn btn-secondary' onClick={cancelButtonHandler}>Cancel</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        {
          imageModalOpen && 
            <ImageModal 
              imageModalOpen={imageModalOpen} 
              closeImageModalHandler={closeImageModalHandler} 
              finalModalArray={finalModalArray}
              imageIndex={imageIndex}
              userName={`${timeSheetData?.user.first_name} ${timeSheetData?.user.last_name}`}
            />
        } 
    </>
  )
}

export default EditTimePage