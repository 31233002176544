import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import InputBase from "@mui/material/InputBase";
import InputAdornment from "@mui/material/InputAdornment";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { FiPlus, FiSearch } from "react-icons/fi";
import ProjectListingTableRow from "../../components/common/ProjectListingTableRow";
import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import {
  changeProjectStatus,
  fetchProjects,
} from "../../store/redux/actions/project.action";
import {
  setFeedback,
  setLoading,
} from "../../store/redux/actions/feedback.action";
import AddProject from "./AddProject";
import styled from "@mui/system/styled";
import { alpha } from "@mui/material/styles";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import EditProject from "./EditProject";
import { fetchSingleProject } from "../../apis/project";
import { ROLES } from "../../lib/constants";
import { useNavigate } from "react-router-dom";
import { getPermissionAccess } from "../../utils/helper";
import { CustomTablePaginationToolbar } from "../../components/common/Pagination";
import {
  PaginationRsuite,
  Pagination_rsuite,
} from "../../components/common/Pagination_rsuite";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
}));

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      className="delete-wrapper"
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        minHeight: "auto !important",
        ...(numSelected > 0 && {
          minHeight: "64px !important",
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : null}
      {numSelected > 0 ? (
        <Tooltip title="Delete All Projects">
          <Button className="btn btn-tertiary">Selected Delete</Button>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#8CC714",
  },
  "& .MuiTab-root": {
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
    textTransform: "capitalize",
  },
  "& .MuiTab-textColorInherit": {
    color: "#777777",
  },
});

const CustomTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    color: "#8CC714",
    borderBottom: `2px solid #8CC714`,
  },
  "&:hover": {
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

const Projects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);

  const userData = useSelector((state) => state.user.data);

  const permissions = userData.role_permissions.permissions;
  const userRole = userData?.user_role?.id;

  const permissionsAccess = getPermissionAccess(permissions, "Projects");
  if (
    !permissionsAccess.view &&
    !permissionsAccess.add &&
    !permissionsAccess.edit &&
    !permissionsAccess.delete
  ) {
    navigate("/", { replace: true });
  }

  // tab panel code
  const [valueTab, setValueTab] = useState(0);
  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };

  // pagination code start
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [valueTab]);
  // pagination code end

  //add new project modal
  const [addProjectModalOpen, setOpen] = useState(false);
  const addProjectModalOpenHandler = () => setOpen(true);
  const addProjectModalCloseHandler = () => setOpen(false);

  //edit project modal
  const [editProject, setEditProject] = useState({});
  const [isMemberClick, setIsMemberClick] = useState(0);
  const [editProjectModalIsOpen, setEditProjectModalIsOpen] = useState(false);
  const editProjectModalOpenHandler = () => setEditProjectModalIsOpen(true);
  const editProjectModalCloseHandler = () => setEditProjectModalIsOpen(false);

  const editButtonHandler = async (id) => {
    try {
      setIsMemberClick(0);
      dispatch(setLoading(true));
      const response = await fetchSingleProject(id);
      dispatch(setLoading(false));
      if (response.data.status === 200) {
        setEditProject(response.data.data);
        editProjectModalOpenHandler();
      } else {
        const payload = {
          code: response.data.status,
          status: "fail",
          message: response.data.message || "Something went wrong.",
        };
        dispatch(setFeedback(payload));
      }
    } catch (error) {
      dispatch(setLoading(false));
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };

  const membersButtonHandler = async (id) => {
    try {
      setIsMemberClick(1);
      dispatch(setLoading(true));
      const response = await fetchSingleProject(id);
      dispatch(setLoading(false));
      if (response.data.status === 200) {
        setEditProject(response.data.data);
        editProjectModalOpenHandler();
      } else {
        const payload = {
          code: response.data.status,
          status: "fail",
          message: response.data.message || "Something went wrong.",
        };
        dispatch(setFeedback(payload));
      }
    } catch (error) {
      dispatch(setLoading(false));
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };

  // checkbox checked code start
  const [selected, setSelected] = useState([]);

  const handleClick = (event, ProjectId) => {
    const selectedIndex = selected.indexOf(ProjectId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, ProjectId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // checkbox checked code end

  // search code start
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");

  const fetchProjectsData = useCallback(async () => {
    try {
      const params = {
        title: search.trim().replace(/\s+/g, " "),
        status: valueTab === 0 ? "active" : "inactive",
        page: page + 1,
        rows: rowsPerPage,
      };
      dispatch(fetchProjects(params));
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  }, [dispatch, page, rowsPerPage, search, valueTab]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearch(value);
    }, 800),
    [fetchProjects]
  );

  const changeSearchValueHandler = async (e) => {
    setSearchValue(e.target.value);
    setPage(0);
    debouncedSearch(e.target.value);
  };

  const handleStatusChange = (id, newStatus) => {
    try {
      const payload = { id, status: newStatus };
      dispatch(changeProjectStatus(payload));
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };

  useEffect(() => {
    fetchProjectsData();
  }, [fetchProjectsData]);

  return (
    <>
      <Box className="content-header project-module-wrapper mb-4">
        {userRole === ROLES.admin && (
          <Box>
            <CustomTabs
              value={valueTab}
              onChange={handleChangeTabs}
              className="mb-0"
            >
              <CustomTab
                label={`Active (${project.activeProjectCount})`}
                {...a11yProps(0)}
              />
              <CustomTab
                label={`Archive (${project.inactiveProjectCount})`}
                {...a11yProps(1)}
              />
            </CustomTabs>
          </Box>
        )}
        <Box item className="content-header-right top-filter-btn top-bar mb-0">
          <Box className="searchbox bg-white">
            <BootstrapInput
              name="project-search"
              value={searchValue}
              onChange={changeSearchValueHandler}
              placeholder="Search projects"
              endAdornment={
                <InputAdornment
                  position="end"
                  className="end-input-icon text-grey"
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <FiSearch />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          {userRole === ROLES.admin && permissionsAccess.add && (
            <Button
              component={"button"}
              className="btn btn-primary"
              onClick={addProjectModalOpenHandler}
            >
              <FiPlus /> Add Project
            </Button>
          )}
        </Box>
      </Box>

      {/* project listing */}
      <Box className="card mb-0">
        <Box className="project-table-listing">
          {userRole === ROLES.admin && (
            <EnhancedTableToolbar numSelected={selected.length} />
          )}
          <TableContainer className="table-wrapper">
            <Table
              className={
                userRole === ROLES.admin
                  ? "project-table"
                  : "other-project-table"
              }
            >
              <TableHead>
                <TableRow>
                  <TableCell>Project Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Billable</TableCell>
                  <TableCell>Member</TableCell>
                  <TableCell>To-Dos</TableCell>
                  {userRole !== ROLES.admin ? (
                    <TableCell>Last Active</TableCell>
                  ) : null}
                  {userRole === ROLES.admin &&
                  (permissionsAccess.edit || permissionsAccess.delete) ? (
                    <TableCell>Action</TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {project.projects?.length ? (
                  project.projects?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <ProjectListingTableRow
                        key={row.id + "_project"}
                        PmsProjectId={row?.pms_project_id}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        handleClick={handleClick}
                        ProjectId={row?.id}
                        ProjectInitial={row?.title[0].toUpperCase()}
                        // ProjectInitial={"P"}
                        ProjectTitle={row?.title}
                        Status={
                          row?.status === "inactive" ? "Archive" : row?.status
                        }
                        Billable={
                          row?.billable?.charAt(0).toUpperCase() +
                          row?.billable?.slice(1)
                        }
                        Member={row?.members}
                        LabelMembers={row?.label_members}
                        ToDos={row?.todos}
                        LastActive={formatDate(row?.updatedAt)}
                        editButtonHandler={editButtonHandler}
                        handleStatusChange={handleStatusChange}
                        valueTab={valueTab}
                        membersButtonHandler={membersButtonHandler}
                        permissionsAccess={permissionsAccess}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell sx={{ textAlign: "center" }} colSpan={7}>
                      No Projects Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomTablePaginationToolbar
            count={project?.totalCount || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>

      {addProjectModalOpen && (
        <AddProject
          addProjectModalOpen={addProjectModalOpen}
          projectModalClose={addProjectModalCloseHandler}
          flag={valueTab === 0 ? "active" : "inactive"}
        />
      )}

      {editProjectModalIsOpen && (
        <EditProject
          editProjectModalIsOpen={editProjectModalIsOpen}
          editProjectModalClose={editProjectModalCloseHandler}
          editProject={editProject}
          isMemberClick={isMemberClick}
        />
      )}
    </>
  );
};

export default Projects;
