import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { FiDownloadCloud, FiPlus } from "react-icons/fi";
import { BiSortAlt2 } from "react-icons/bi";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { DateRangePicker, Stack } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import {
  setFeedback,
  setLoading,
} from "../../store/redux/actions/feedback.action";
import { fetchProjectsByUserId } from "../../apis/project";
import {
  fetchTimeSheet,
  updateTimeSheetFilter,
} from "../../store/redux/actions/timesheet.action";
import AddTime from "./AddTime";
import TimeSheetTableRow from "./TimeSheetTableRow";
import { fetchEmployee } from "../../apis/employee";
import { createTimeSheet, storeTimeSheetFilter } from "../../apis/timeSheet";
import { showSuccessToast } from "../../utils/toast";
import {
  DATE_RANGE_TYPE,
  FETCH_TIME_SHEET_API,
  ROLES,
} from "../../lib/constants";
import {
  getDotColorStyle,
  convertDateIntoYYYYMMDD,
  getPermissionAccess,
  handleLabel,
  getLocalTimeZone,
  getDateValueBasedOnDateType,
} from "../../utils/helper";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import { TablePagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomTablePaginationToolbar } from "../../components/common/Pagination";

const { afterToday } = DateRangePicker;

const today = new Date();

const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);

const lastMonthStartDate = new Date(
  today.getFullYear(),
  today.getMonth() - 1,
  1
);
const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
const thisMonthStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
const lastWeekStartDate = new Date(
  today.getTime() - (today.getDay() + 7) * 24 * 60 * 60 * 1000
);
const lastWeekEndDate = new Date(
  today.getTime() - (today.getDay() + 1) * 24 * 60 * 60 * 1000
);
const firstDayOfWeek = today.getDate() - today.getDay();
const thisWeekStartDate = new Date(today.setDate(firstDayOfWeek));
const thisWeekEndDate = new Date();

// tabbing panel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className="filter-content">{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      className="delete-wrapper"
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        minHeight: "auto !important",
        ...(numSelected > 0 && {
          minHeight: "64px !important",
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : null}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <Button className="btn btn-tertiary">Selected Delete</Button>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#8CC714",
  },
  "& .MuiTab-root": {
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
    textTransform: "capitalize",
  },
  "& .MuiTab-textColorInherit": {
    color: "#777777",
  },
});

const CustomTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    color: "#8CC714",
    borderBottom: `2px solid #8CC714`,
  },
  "&:hover": {
    // color: '#8CC714',
  },
}));

// -----------------------------------------

const TimesheetViewEditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const filterData = getFilterStorage(storageName.timeSheet);
  const filterData = useSelector((state) => state.timeSheetFilter);

  const userDetails = useSelector((state) => state.user.data);
  const permissions = userDetails.role_permissions.permissions;

  const permissionsAccess = getPermissionAccess(permissions, "Timesheets");
  if (
    !permissionsAccess.view &&
    !permissionsAccess.add &&
    !permissionsAccess.edit &&
    !permissionsAccess.delete
  ) {
    navigate("/", { replace: true });
  }

  // tab panel code
  const [valueTab, setValueTab] = useState(0);
  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };

  // Add new time modal
  const [addTimeModalOpen, setAddTimeModalOpen] = useState(false);
  const addModalOpen = () => setAddTimeModalOpen(true);
  const addModalClose = () => setAddTimeModalOpen(false);

  // const [selected, setSelected] = useState([]);

  const [dateRangeLabel, setDateRangeLabel] = useState(
    filterData.date_range_type || DATE_RANGE_TYPE.today
  );
  let initialSelectedDates = [new Date(), new Date()];
  if (filterData?.date_range_type !== DATE_RANGE_TYPE.custom) {
    initialSelectedDates = getDateValueBasedOnDateType(
      filterData.date_range_type
    );
  } else {
    initialSelectedDates = getDateValueBasedOnDateType(
      filterData.date_range_type,
      filterData?.start_date,
      filterData?.end_date
    );
  }

  const [dateRangeValue, setDateRangeValue] = useState(initialSelectedDates);
  const datePickerRef = useRef();
  const closeDateRangePicker = () => {
    datePickerRef.current.close();
  };

  const ranges = [
    {
      label: DATE_RANGE_TYPE.today,
      value: [new Date(), new Date()],
      onClick: function () {
        setDateRangeLabel(DATE_RANGE_TYPE.today);
        setDateRangeValue([new Date(), new Date()]);
        closeDateRangePicker();
      },
    },
    {
      label: DATE_RANGE_TYPE.yesterday,
      value: [yesterday, yesterday],
      onClick: function () {
        setDateRangeLabel(DATE_RANGE_TYPE.yesterday);
        setDateRangeValue([yesterday, yesterday]);
        closeDateRangePicker();
      },
    },
    {
      label: DATE_RANGE_TYPE.thisWeek,
      value: [thisWeekStartDate, thisWeekEndDate],
      onClick: function () {
        setDateRangeLabel(DATE_RANGE_TYPE.thisWeek);
        setDateRangeValue([thisWeekStartDate, thisWeekEndDate]);
        closeDateRangePicker();
      },
    },
    {
      label: DATE_RANGE_TYPE.lastWeek,
      value: [lastWeekStartDate, lastWeekEndDate],
      onClick: function () {
        setDateRangeLabel(DATE_RANGE_TYPE.lastWeek);
        setDateRangeValue([lastWeekStartDate, lastWeekEndDate]);
        closeDateRangePicker();
      },
    },
    {
      label: DATE_RANGE_TYPE.last7Days,
      value: [
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        new Date(),
      ],
      onClick: function () {
        setDateRangeLabel(DATE_RANGE_TYPE.last7Days);
        setDateRangeValue([
          new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
          new Date(),
        ]);
        closeDateRangePicker();
      },
    },
    {
      label: DATE_RANGE_TYPE.thisMonth,
      value: [thisMonthStartDate, new Date()],
      onClick: function () {
        setDateRangeLabel(DATE_RANGE_TYPE.thisMonth);
        setDateRangeValue([thisMonthStartDate, new Date()]);
        closeDateRangePicker();
      },
    },
    {
      label: DATE_RANGE_TYPE.lastMonth,
      value: [lastMonthStartDate, lastMonthEndDate],
      onClick: function () {
        setDateRangeLabel(DATE_RANGE_TYPE.lastMonth);
        setDateRangeValue([lastMonthStartDate, lastMonthEndDate]);
        closeDateRangePicker();
      },
    },
    {
      label: DATE_RANGE_TYPE.last30Days,
      value: [
        new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        new Date(),
      ],
      onClick: function () {
        setDateRangeLabel(DATE_RANGE_TYPE.last30Days);
        setDateRangeValue([
          new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
          new Date(),
        ]);
        closeDateRangePicker();
      },
    },
  ];

  const [projects, setProjects] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const [sortValue, setSortValue] = useState(filterData?.orderBy || "desc");
  const [flag, setflag] = useState(0);
  // const [selectedActivityLevel, setSelectedActivityLevel] = useState(null);

  const [selectedProject, setSelectedProject] = useState(
    filterData?.filtered_project
      ? {
          value: filterData?.filtered_project?.value,
          label: filterData?.filtered_project?.label,
        }
      : { value: "", label: "All" }
  );
  const [selectedAssignee, setSelectedAssignee] = useState(
    filterData.filtered_user
      ? {
          value: filterData?.filtered_user?.value,
          label: filterData?.filtered_user?.label,
          dot_color: filterData?.filtered_user?.dot_color,
        }
      : {
          value: userDetails.id,
          label: `${userDetails.first_name} ${userDetails.last_name}`,
          dot_color: "#8CC714",
        }
  );

  const timeSheetData = useSelector((state) => state.timeSheet);
  const [expandCollapseIndex, setExpandCollapseIndex] = useState([]);
  const row_wise = timeSheetData?.data?.userTimes;
  const all = timeSheetData?.data;

  const setOpen = (index, value) => {
    const updatedExpandCollapseIndex = [...expandCollapseIndex];
    updatedExpandCollapseIndex[index] = value;
    setExpandCollapseIndex(updatedExpandCollapseIndex);
  };
  useEffect(() => {
    setExpandCollapseIndex(timeSheetData?.data?.userTimes?.map(() => false));
  }, [timeSheetData]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const changeSortOrderHandler = () => {
    setSortValue((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  const changeDateRangeHandler = (date) => {
    if (date === null) {
      setDateRangeValue([new Date(), new Date()]);
    } else {
      setDateRangeValue(date);
    }
    setDateRangeLabel(DATE_RANGE_TYPE.custom);
  };
  const projectOptions = useMemo(() => {
    let data = [{ value: "", label: "All" }];

    if (projects?.length) {
      projects?.forEach((row) => {
        data.push({ value: row.id, label: row.title });
      });
    }
    return data;
  }, [projects]);

  const assigneeOption = useMemo(() => {
    // let flag = 0; // 0 = G            1 = B
    let options = [{ value: "", label: "All", dot_color: "#8CC714" }];
    if (userDetails?.user_role?.id === ROLES.admin) {
      assignee.forEach((row) => {
        if (row.status === "active") {
          options.push(
            handleLabel(true, row.id, `${row.first_name} ${row.last_name}`)
          );
        } else {
          options.push(
            handleLabel(false, row.id, `${row.first_name} ${row.last_name}`)
          );
        }
      });
    } else if (
      userDetails?.user_role?.id === ROLES.manager ||
      userDetails?.user_role?.id === ROLES.viewer ||
      userDetails.is_assistant_manager
    ) {
      assignee.forEach((row) => {
        let flag = 0;
        if (row.status !== "active") {
          flag = 1;
        } else {
          row.project_assignments.some((row2) => {
            if (row2.deletedAt === null) {
              flag = 0;
              return true;
            } else {
              flag = 1;
              return false;
            }
          });
        }
        // final calculation
        if (flag === 0) {
          options.push(
            handleLabel(true, row.id, `${row.first_name} ${row.last_name}`)
          );
        } else {
          options.push(
            handleLabel(false, row.id, `${row.first_name} ${row.last_name}`)
          );
        }
      });
    } else {
      options = [];
    }

    return options;
  }, [assignee, userDetails?.user_role?.id, userDetails.is_assistant_manager]);

  const fetchProjectsData = async () => {
    try {
      const params = { listing: true, paranoid: false };
      const response = await fetchProjectsByUserId(params);
      if (response.data.status === 200) {
        setProjects(response.data.data.projects);
        setflag((prev) => !prev);
      } else if (response.data.status === 404) {
        setProjects([]);
      } else {
        const errPayload = {
          code: response.data.status,
          status: "fail",
          message: response.data.message || "Something went wrong.",
        };
        dispatch(setFeedback(errPayload));
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };

  const fetchAssignee = async () => {
    try {
      const params = { timesheet: 1 };
      const response = await fetchEmployee(params);
      //fetchLoggedInUserData

      if (response.data.status === 200) {
        setAssignee(response.data.data);
        setflag((prev) => !prev);
      } else {
        setAssignee([]);
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };

  useLayoutEffect(() => {
    fetchProjectsData();
    if (
      userDetails.is_assistant_manager ||
      userDetails?.user_role?.id !== ROLES.employee
    ) {
      fetchAssignee();
    }
    setflag((prev) => !prev);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //-------------------------------------------------------------------------------------------------------------------

  const fetchTimeSheetData = useCallback(
    async (paramsData = {}) => {
      try {
        // const filter = getFilterStorage(storageName.timeSheet);
        // const filter = paramsData;
        let startDate;
        let endDate;
        if (dateRangeValue !== null) {
          const [sDate, eDate] = dateRangeValue;
          startDate = convertDateIntoYYYYMMDD(sDate);
          endDate = convertDateIntoYYYYMMDD(eDate);
        } else {
          startDate = convertDateIntoYYYYMMDD(new Date());
          endDate = convertDateIntoYYYYMMDD(new Date());
        }

        let params = {
          approved: valueTab === 0 ? "no" : "yes",
          startDate: startDate,
          endDate: endDate,
          orderBy: sortValue,
          page: page + 1,
          rows: rowsPerPage,
        };

        if (selectedAssignee && selectedAssignee?.value !== "") {
          params.user_id = selectedAssignee?.value;
        }
        if (selectedProject && selectedProject.value !== "") {
          params.project_id = selectedProject.value;
        }
        if (
          !userDetails.is_assistant_manager &&
          userDetails?.user_role?.id === ROLES.employee
        ) {
          params.user_id = userDetails.id;
        }

        // API integration start
        if (params) {
          dispatch(fetchTimeSheet(params));
        }
      } catch (error) {
        dispatch(setLoading(false));
        const errPayload = {
          code: error.status,
          status: "fail",
          message:
            error.response.status === 404
              ? "No data found"
              : error.message || "Something went wrong.",
        };
        dispatch(setFeedback(errPayload));
      }
    },
    [
      dateRangeValue,
      dispatch,
      page,
      rowsPerPage,
      selectedAssignee,
      selectedProject,
      sortValue,
      valueTab,
    ]
  );

  useEffect(() => {
    setPage(0);
  }, [valueTab]);

  useEffect(() => {
    fetchTimeSheetData();
  }, [
    dateRangeValue,
    // fetchTimeSheetData,
    selectedAssignee,
    selectedProject,
    page,
    rowsPerPage,
    sortValue,
    valueTab,
    flag,
  ]);

  const storeTimeSheetFilterData = useCallback(
    async (payload) => {
      try {
        const data = {
          date_type: payload.dateRangeLabel,
          user: payload.selectedAssignee,
          project: payload.selectedProject,
        };
        if (data.date_type === DATE_RANGE_TYPE.custom) {
          data.start_date = convertDateIntoYYYYMMDD(dateRangeValue[0]);
          data.end_date = convertDateIntoYYYYMMDD(dateRangeValue[1]);
        } else {
          data.start_date = null;
          data.end_date = null;
        }

        const response = await storeTimeSheetFilter(data);
        if (response.data.status === 200) {
          const actionPayload = {
            date_range_type: data.date_type,
            filtered_project: data.project,
            filtered_user: data.user,
          };
          if (data.date_type === DATE_RANGE_TYPE.custom) {
            actionPayload.start_date = data.start_date;
            actionPayload.end_date = data.end_date;
          } else {
            actionPayload.start_date = null;
            actionPayload.end_date = null;
          }
          dispatch(updateTimeSheetFilter(actionPayload));
        } else {
          const errPayload = {
            code: response.data.status,
            status: "fail",
            message: response.data.message || "Something went wrong.",
          };
          dispatch(setFeedback(errPayload));
        }
      } catch (error) {
        // dispatch(setLoading(false));
        const errPayload = {
          code: error.status,
          status: "fail",
          message:
            error.response.status === 404
              ? "No data found"
              : error.message || "Something went wrong.",
        };
        dispatch(setFeedback(errPayload));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch, dateRangeValue]
  );

  useEffect(() => {
    if (
      filterData.date_range_type === DATE_RANGE_TYPE.custom &&
      dateRangeLabel === DATE_RANGE_TYPE.custom
    ) {
      if (
        filterData.start_date !== convertDateIntoYYYYMMDD(dateRangeValue[0]) ||
        filterData.end_date !== convertDateIntoYYYYMMDD(dateRangeValue[1])
      ) {
        storeTimeSheetFilterData({
          dateRangeLabel,
          selectedAssignee,
          selectedProject,
        });
      }
    }
    if (
      filterData.date_range_type !== dateRangeLabel ||
      filterData.filtered_project?.value !== selectedProject.value ||
      filterData.filtered_user?.value !== selectedAssignee.value
    ) {
      storeTimeSheetFilterData({
        dateRangeLabel,
        selectedAssignee,
        selectedProject,
      });
    }
  }, [
    dateRangeLabel,
    dateRangeValue,
    filterData,
    selectedAssignee,
    selectedProject,
    storeTimeSheetFilterData,
  ]);

  const addTimeSheetHandler = async (payload) => {
    try {
      dispatch(setLoading(true));
      const response = await createTimeSheet(payload);
      if (response.data.status === 200) {
        showSuccessToast("Entry added successfully");
        addModalClose();
        fetchTimeSheetData();
      } else {
        const errPayload = {
          code: response.data.status,
          status: "fail",
          message: response.data.message || "Something went wrong.",
        };
        dispatch(setFeedback(errPayload));
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const downloadTimeSheetPDFCSV = async (tag = "") => {
    try {
      dispatch(setLoading(true));
      let params;
      let startDate;
      let endDate;
      if (dateRangeValue !== null) {
        const [sDate, eDate] = dateRangeValue;
        startDate = convertDateIntoYYYYMMDD(sDate);
        endDate = convertDateIntoYYYYMMDD(eDate);
      } else {
        startDate = convertDateIntoYYYYMMDD(new Date());
        endDate = convertDateIntoYYYYMMDD(new Date());
      }
      params = {
        approved: valueTab === 0 ? "no" : "yes",
        startDate,
        endDate,
        orderBy: sortValue,
        timezone: getLocalTimeZone(),
      };
      if (params && selectedAssignee && selectedAssignee.value !== "") {
        params.user_id = selectedAssignee.value;
      }
      if (params && selectedProject && selectedProject.value !== "") {
        params.project_id = selectedProject.value;
      }

      if (tag === "PDF") params = { ...params, download_pdf: 1 };
      else if (tag === "CSV") params = { ...params, download_csv: 1 };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${FETCH_TIME_SHEET_API}`,
        { params, responseType: "blob" }
      );
      if (response.status !== 200) {
        throw new Error("No Record Found!");
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `timesheet_${selectedAssignee.label.replaceAll(
          " ",
          "_"
        )}_${startDate.replaceAll("-", "")}_${endDate.replaceAll("-", "")}.${
          tag === "PDF" ? "pdf" : "xlsx"
        }`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message:
          error.response.status === 404
            ? "No data found"
            : error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      {/* top page action with text */}
      <Box className="content-header timesheets-header">
        <Box direction="row" className="top-filter-field">
          <Tooltip
            title="Sort Timesheet Date Wise"
            className="reset-btn desktop-sorting-btn"
          >
            <Button
              className="btn btn-primary only-icon-btn"
              onClick={changeSortOrderHandler}
            >
              <BiSortAlt2 />
            </Button>
          </Tooltip>
          <Box className="calendar-ui">
            <DateRangePicker
              value={dateRangeValue}
              onChange={changeDateRangeHandler}
              ranges={ranges}
              shouldDisableDate={afterToday()}
              format="dd-MM-yyyy"
              ref={datePickerRef}
            />
          </Box>
        </Box>
        <Box className="content-header-right top-filter-btn">
          {(userDetails.is_assistant_manager ||
            userDetails?.user_role?.id !== ROLES.employee) && (
            <FormControl variant="standard" className="form-control">
              <Select
                className="timesheet-select-options select-dot-option"
                placeholder="Select Member"
                value={selectedAssignee}
                onChange={setSelectedAssignee}
                options={assigneeOption}
                styles={getDotColorStyle("SINGLE")}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "rgba(140, 199, 20, 0.4);",
                    primary: "#8CC714",
                  },
                })}
              />
            </FormControl>
          )}
          <FormControl variant="standard" className="form-control">
            <Select
              className="timesheet-select-options"
              placeholder="Select Projects"
              onChange={(selectedOption) => {
                setSelectedProject(selectedOption);
              }}
              value={selectedProject}
              options={projectOptions}
              classNamePrefix="select"
              // styles={colorStyle}
              isClearable={false}
              style={{
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "#cccccc",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary25: "rgba(140, 199, 20, 0.4);",
                  primary: "#8CC714",
                },
              })}
            />
          </FormControl>
          {permissionsAccess.add && (
            <Button className="btn btn-primary" onClick={addModalOpen}>
              <FiPlus /> Add Time
            </Button>
          )}
          <Button
            className="btn btn-primary"
            onClick={() => downloadTimeSheetPDFCSV("CSV")}
          >
            <FiDownloadCloud /> To CSV
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => downloadTimeSheetPDFCSV("PDF")}
          >
            <FiDownloadCloud /> To PDF
          </Button>
          <Tooltip
            title="Sort Timesheet Date Wise"
            className="reset-btn mobile-sorting-btn"
          >
            <Button
              className="btn btn-primary only-icon-btn"
              onClick={changeSortOrderHandler}
            >
              <BiSortAlt2 />
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box className="timesheet-tab-wrapper">
          <CustomTabs value={valueTab} onChange={handleChangeTabs}>
            <CustomTab label={`Un-Approved`} {...a11yProps(0)} />
            <CustomTab label={`Approved`} {...a11yProps(1)} />
          </CustomTabs>
        </Box>
      </Box>

      <Box
        className="card mb-0"
        sx={{ marginTop: "10px", border: "0px solid red" }}
      >
        <Box className="timesheet-table-listing">
          <TableContainer className="table-wrapper">
            <Table
              aria-label="collapsible table"
              className="view-edit-table mb-0"
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">User</TableCell>
                  <TableCell align="left">Manual Vs. Tracked</TableCell>
                  <TableCell align="left">Billed Vs. Unbilled</TableCell>
                  <TableCell align="left">Project Vs. Day Total Hrs.</TableCell>
                  <TableCell align="left">Activity</TableCell>
                  <TableCell align="left">
                    {(userDetails?.user_role?.id === ROLES.admin ||
                      userDetails?.user_role?.id === ROLES.manager ||
                      userDetails?.user_role?.id === ROLES.employee) &&
                      permissionsAccess.edit &&
                      "Actions"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timeSheetData?.data?.userTimes?.map((row, index) => (
                  <TimeSheetTableRow
                    key={index + "_timesheet"}
                    row={row}
                    valueTab={valueTab}
                    selectedProject={selectedProject}
                    projectOptions={projectOptions}
                    fetchTimeSheetData={fetchTimeSheetData}
                    open={expandCollapseIndex[index]}
                    setOpen={setOpen}
                    index={index}
                    permissionsAccess={permissionsAccess}
                    all={all}
                    row_wise={row_wise}
                    flag={flag}
                    setflag={setflag}
                  />
                ))}
                {!timeSheetData?.data?.userTimes?.length && (
                  <TableRow>
                    <TableCell align="center" colSpan={8}>
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomTablePaginationToolbar
            count={timeSheetData?.data?.totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>

      {addTimeModalOpen && (
        <AddTime
          addTimeModalOpen={addTimeModalOpen}
          addModalClose={addModalClose}
          addTimeSheetHandler={addTimeSheetHandler}
          assignee={assignee}
        />
      )}
    </>
  );
};

export default TimesheetViewEditPage;
