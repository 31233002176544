import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FeedbackModal from "./components/common/FeedbackModal";
import Loader from "./components/common/Loader";
import routes from "./routes";
import { ToastContainer } from "react-toastify";

import { useEffect, useLayoutEffect, useState } from "react";
import { setUserData } from "./store/redux/actions/user.action";
import { setFeedback, setLoading } from "./store/redux/actions/feedback.action";
import { getAuthToken, getLoggedInUserId } from "./utils/auth";

// CSS FILES
import "./styles/global.scss";
import "./styles/authentication.scss";
import "./styles/header.scss";
import "./styles/dashboard.scss";
import "./styles/myAccount.scss";
import "./styles/screenshots.scss";
import "./styles/timesheetviewedit.scss";
import "./styles/reports.scss";
import "./styles/projects.scss";
import "./styles/projecttodos.scss";
import "./styles/loader.scss";
import "./styles/employees.scss";
import "./styles/inviteEmployee.scss";
import "./styles/download.scss";
import "./styles/incompleteTimesheets.scss";
import "./styles/billablenonbillable.scss";
import "./styles/releasenote.scss";
import "./styles/settings.scss";

import Login from "./pages/login";
import Page404 from "./pages/404/Page404";
import ProtectedRoute from "./components/common/ProtectedRoute";
import { fetchLoggedInUserData } from "./apis/user";
import PublicRouteWrapper from "./components/common/PublicRouteWrapper";
import { storeTimeSheetFilter } from "./store/redux/actions/timesheet.action";
import { fetchApplicationPermissions } from "./store/redux/actions/applicationPermission.action";

// import ForgotPassword from './pages/ForgotPassword';
// import SetNewPassword from './pages/SetNewPassword';
// import EmployeeInvite from './pages/EmployeeInvite';

function App() {
  const token = getAuthToken();
  const dispatch = useDispatch();
  const [isUiRender, setIsUiRender] = useState();
  const { message: errorMessage, isLoading } = useSelector(
    (state) => state.feedback
  );
  useEffect(() => {
    const fetchApplicationsPermissions = () => {
      dispatch(fetchApplicationPermissions());
    };
    if (token) fetchApplicationsPermissions();
  }, [token, dispatch]);
  useLayoutEffect(() => {
    const fetchUserDataHandler = async () => {
      try {
        setIsUiRender(false);

        const userId = getLoggedInUserId();
        const response = await fetchLoggedInUserData(userId);

        if (response.data.status === 200) {
          const payload = response.data.data;
          dispatch(storeTimeSheetFilter(payload.timesheet_filter));
          dispatch(setUserData(payload));
        } else {
          const errPayload = {
            code: response.data.status,
            status: "fail",
            message:
              response.data.message || "Something went wrong. Try again later!",
          };
          dispatch(setFeedback(errPayload));
        }
      } catch (error) {
        dispatch(setLoading(false));
        const errPayload = {
          code: error.status,
          status: "fail",
          message: error.message || "Something went wrong. Try again later!",
        };
        dispatch(setFeedback(errPayload));
      } finally {
        setIsUiRender(true);
        // dispatch(setLoading(false));
      }
    };
    if (token) {
      fetchUserDataHandler();
    } else {
      setIsUiRender(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      {errorMessage && <FeedbackModal />}
      {isLoading && <Loader />}
      {isUiRender === false && <Loader />}

      <ToastContainer />
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRouteWrapper>
              <Login />
            </PublicRouteWrapper>
          }
        />
        {/* <Route path="/forgot-password" element={!token ? <ForgotPassword /> : <Navigate to={"/"} replace={true} />} /> */}
        {/* <Route path="/set-new-password" element={!token ? <SetNewPassword /> : <Navigate to={"/"} replace={true} />} /> */}
        {/* <Route path="/invite-user" element={!token ? <EmployeeInvite /> : <Navigate to={"/"} replace={true} />} /> */}
        {isUiRender === true && <Route path="*" element={<Page404 />} />}
        {isUiRender === true &&
          routes.map(({ path, element: Component, module }, index) => (
            <Route
              key={index}
              path={path}
              element={
                <ProtectedRoute module={module} path={path}>
                  {Component}
                </ProtectedRoute>
              }
            />
          ))}
      </Routes>

      {/* <RouterProvider router={routes}/> */}
    </>
  );
}

export default App;
