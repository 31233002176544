import React, { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { FiEye, FiX, FiTrash2 } from 'react-icons/fi';
import NoImage from '../../assets/dashboard/no_screenshot.png';
import ManualEntryImage from '../../assets/dashboard/manual_entry.png';
import { convertTimeInto12HoursFormat, truncateString } from '../../utils/helper';

const BASE_PATH = process.env.REACT_APP_IMAGE_PATH1;
// const BASE_PATH2 = process.env.REACT_APP_IMAGE_PATH2;

// const DRIVE_PATH = "https://drive.google.com/uc?id=<img-id>&export=view";
const DRIVE_PATH = "https://drive.google.com/thumbnail?id=<img-id>&sz=w100"; // thumbnail image path

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();

    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    return `${day}-${month}-${year} ${hours > 12 ? hours - 12 : hours}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
}

const getFullImagePath = (images) => {
    let image = null;
    const imgObj = images[0].image ? images[0] : images[1];
    
    if(imgObj.image){
        if(imgObj.isDriveImage){
            image = DRIVE_PATH.replace("<img-id>", imgObj.image);
        } else {
            image = BASE_PATH + imgObj.image;
        }
    }
    return image;
}

const ScreenCastCard = ({ 
        flag = 0, 
        ProjectTitle, 
        ScreenCastImage, 
        ScreenCastDate = "", 
        setIsScrollable, 
        Slot = {}, 
        checkBoxHandler, 
        deleteScreenShotHandler, 
        MODE = "", 
        moveSlotArray = [], 
        blockTime="", 
        activity, 
        openImageModalHandler, 
        tempIndex, 
        billable,
        isProjectBillable,
        changeBillableSlotArrayHandler,
        primaryIndex,
        secondaryIndex,
        isManual
    }) => {

    // const [screenCastModalOpen, setOpen] = useState(false);
    // let image = ScreenCastImage[0].image || ScreenCastImage[1].image;
    let image = getFullImagePath(ScreenCastImage);

    // const isFindImage = moveSlotArray.find((row) => {
    //     return row.start_date !== Slot.start_date && row.end_date !== Slot.end_date
    // });

    let isFindImage = "";
    if(MODE){
        isFindImage = moveSlotArray.find((row) => row.start_date === Slot.start_date);
    }

    let screenCount = 0;
    if(ScreenCastImage[0].image){
        screenCount += 1; 
    }
    if(ScreenCastImage[1].image){
        screenCount += 1; 
    }

    const date = ScreenCastDate === "" ? "" : formatDate(ScreenCastDate);

    const imageModalOpen = () => {
        openImageModalHandler(tempIndex);
    }

    const [isError, setIsError] = useState(false);
        
    return (
        <>
            <Box>
                <Box className='screencast-card'>
                    <Box className="screen-count">
                        {screenCount} screen{screenCount > 1 && "s"}
                    </Box>
                    {
                        isManual === "yes" ? 
                        <img 
                            src={ManualEntryImage} 
                            className={`screencast-img ${(MODE === "EDIT" && isFindImage) && 'screencast-img-blur'}`}
                            alt='screenCast' 
                            loading="lazy"
                        />
                        :
                        <img 
                            // src={image ? BASE_PATH + image : NoImage} 
                            src={image || NoImage} 
                            className={`screencast-img ${(MODE === "EDIT" && isFindImage) && 'screencast-img-blur'}`}
                            alt='screenCast' 
                            onError={(e) => {
                                if(e.target.alt === 'Screenshot'){
                                    return;
                                }
                                e.target.src = NoImage;
                                e.target.alt = 'Screenshot';
                                setIsError(true);
                            }}
                            loading="lazy"
                        />               
                    }
                    <Box className='screencast-overlay'>                                
                        <Box className="screencast-icon-container">
                            {
                                isError === false && image &&
                                <Box className='screencast-view' onClick={imageModalOpen}>
                                    <FiEye/>
                                </Box>
                            }
                            {
                                (MODE === "EDIT" && !isFindImage) && 
                                <Box className='screencast-view' onClick={() => deleteScreenShotHandler(true, Slot)}>
                                    <FiTrash2/>
                                </Box>
                            }
                            {
                                (MODE === "EDIT" && isFindImage) && 
                                <Box className='screencast-view' onClick={() => deleteScreenShotHandler(false, Slot)}>
                                    <FiX/>
                                </Box>
                            }
                        </Box>
                        {
                            flag === 0 &&
                            <Typography paragraph className='text-white'>
                                {date}
                            </Typography>
                        }
                    </Box>
                </Box>

                {
                    flag === 0 && 
                    <Box className='project-title'>
                        <Tooltip title={ProjectTitle}>
                            <Typography paragraph className='text-black project'>
                                {truncateString(ProjectTitle, 16)}
                            </Typography>
                        </Tooltip>
                        <Typography paragraph className="text-grey time">
                            <Box className="time-slot" style={MODE === "SPLIT" ? {justifyContent:'space-between'} : {justifyContent:'center'}}>
                                <Typography className={`text-grey text-center`}>
                                    {
                                        blockTime !== "" ? 
                                        blockTime
                                        :
                                        `${convertTimeInto12HoursFormat(Slot.start_date, 1)} to 
                                        ${convertTimeInto12HoursFormat(Slot.end_date, 1)}`
                                    }
                                </Typography> 
                            </Box>
                        </Typography> 
                        <Typography paragraph className="text-grey activity">
                            {activity}% Activity
                        </Typography> 
                        {
                                    MODE === "SPLIT" && 
                                    <Box className="billable-slot">

                                    {/* // <Checkbox  
                                    //     // checked={formData.disableActivity}
                                    //     onChange={(e) => checkBoxHandler(e, Slot)}
                                    //     size="small"
                                    //     className='time-slot-checkbox' 
                                    // /> */}
                                    <FormControlLabel
                                        control={<Checkbox className='time-slot-checkbox billable-slot-checkbox' size="small" />} 
                                        className='checkbox-field split-checkbox' 
                                        // label="Billable" 
                                        // classes={{ label: 'custom-label' }}
                                        onChange={(e) => checkBoxHandler(e, Slot)}

                                    />
                                    </Box>
                                }
                        {
                            MODE === "EDIT" && 
                            <Box className="billable-slot">
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            className='billable-slot-checkbox' 
                                            disabled={isProjectBillable === 'no' ? true : false}
                                            checked={billable === "yes" && isProjectBillable === "yes" ? true : false} 
                                            // onChange={(e) => changeBillableSlotArrayHandler(primaryIndex, secondaryIndex, e.target.checked)}
                                        />
                                        } 
                                    label="Billable" 
                                    className='checkbox-field billable-text' 
                                    // classes={{ label: 'custom-label' }}
                                    onChange={(e) => changeBillableSlotArrayHandler(primaryIndex, secondaryIndex, e.target.checked)}
                                />
                                {/* <Checkbox  
                                    checked={billable === "yes" ? true : false}
                                    onChange={(e) => changeBillableSlotArrayHandler(primaryIndex, secondaryIndex, e.target.checked)}
                                    size="medium"
                                    className='billable-slot-checkbox' 
                                />
                                <Typography className={`text-grey`}>Billable</Typography>  */}
                            </Box>
                        }
                    </Box>
                }
            </Box>
        </>
    );
}

export default memo(ScreenCastCard);