import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  fetchGDriveListing,
  fetchGlobalSettings,
  updateGlobalSettings,
} from "../../apis/settings";
import {
  setFeedback,
  setLoading,
} from "../../store/redux/actions/feedback.action";
import { showSuccessToast } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { TbInfoCircle } from "react-icons/tb";
import { CustomTablePaginationToolbar } from "../../components/common/Pagination";

const options = [
  { label: "60 Days", value: 60 },
  { label: "90 Days", value: 90 },
  { label: "120 Days", value: 120 },
  { label: "150 Days", value: 150 },
  { label: "180 Days", value: 180 },
];

const formatDate = (dateValue) => {
  const date = new Date(dateValue);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// const dummy_data = [
//     {id: 1, date: "2024-10-02", status: "pending", count: 2},
//     {id: 1, date: "2024-10-02", status: "pending", count: 2},
//     {id: 1, date: "2024-10-02", status: "pending", count: 2},
//     {id: 1, date: "2024-10-02", status: "pending", count: 2},
//     {id: 1, date: "2024-10-02", status: "pending", count: 2},
//     {id: 1, date: "2024-10-02", status: "pending", count: 2},
//     {id: 1, date: "2024-10-02", status: "pending", count: 2},
//     {id: 1, date: "2024-10-02", status: "pending", count: 2},
//     {id: 1, date: "2024-10-02", status: "pending", count: 2},
//     {id: 1, date: "2024-10-02", status: "pending", count: 2},
//     {id: 1, date: "2024-10-02", status: "pending", count: 2},
//     {id: 1, date: "2024-10-02", status: "pending", count: 2},
// ]

const GDriveSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedDays, setSelectedDays] = useState(null);
  const [listingData, setListingData] = useState([]);
  const [settingData, setSettingData] = useState([]);

  // pagination code start
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchGDriveSettingData = useCallback(async () => {
    try {
      const response = await fetchGlobalSettings();
      if (response.data.status === 200) {
        const result = response.data.data;
        setSettingData(result);
        setSelectedDays(
          result.find((row) => row.name.includes("gdrive")).value
        );
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch]);

  const fetchGDriveListingData = useCallback(async () => {
    try {
      const params = {
        page: page + 1,
        rows: rowsPerPage,
      };
      const response = await fetchGDriveListing(params);
      if (response.data.status === 200) {
        const result = response.data.data;
        setListingData(result);
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    // fetchGDriveSettingData();
    // fetchGDriveListingData()
    const fetchData = async () => {
      dispatch(setLoading(true));
      await Promise.all([fetchGDriveSettingData(), fetchGDriveListingData()]);
      dispatch(setLoading(false));
    };
    fetchData();
  }, [fetchGDriveSettingData, fetchGDriveListingData, dispatch]);

  const handleSubmit = async () => {
    try {
      let settingId = settingData.find((row) => row.name.includes("gdrive")).id;
      let settingValue = selectedDays;

      const payload = {
        value: settingValue,
      };

      const response = await updateGlobalSettings(payload, settingId);
      if (response.data.status === 200) {
        showSuccessToast(response.data.message);
      } else {
        const feedbackpayload = {
          code: response.data.status,
          status: "fail",
          message: response.data.message || "Something went wrong.",
        };
        dispatch(setFeedback(feedbackpayload));
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };

  return (
    <>
      <KeyboardBackspaceIcon
        style={{
          fontSize: "36px",
          cursor: "pointer",
          fill: "#777",
          marginBottom: "15px",
        }}
        onClick={() => navigate("/settings")}className='setting-back-btn'
      />

      <Grid container spacing={2}>
        <Grid item lg={5} md={6} sm={12} xs={12}>
          {/* setting form */}
          <Box className="card mb-0">
            <FormGroup className="form-field" style={{ width: "100%" }}>
              <FormControl
                variant="standard"
                className="form-control add-form-space"
              >
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={"5px"}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    className="text-black input-label"
                  >
                    Delete Screenshots from GDrive after{" "}
                    <TbInfoCircle
                      style={{
                        color: "#777",
                        width: "15px",
                        height: "15px",
                        cursor: "pointer",
                      }}
                    />
                  </Typography>
                </Stack>
                <Select
                  className="select-days"
                  placeholder="Select Days"
                  value={options.find(
                    (option) => option.value === selectedDays
                  )}
                  onChange={(newValue) => {
                    setSelectedDays(newValue.value);
                  }}
                  options={options}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      maxWidth: "1000px",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "rgba(140, 199, 20, 0.4);",
                      primary: "#8CC714",
                    },
                  })}
                />
              </FormControl>
            </FormGroup>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <Button
                  className="btn btn-primary"
                  sx={{ maxWidth: "100%" }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Grid>
              <Grid item md={6} xs={6}>
                <Button
                  className="btn btn-secondary"
                  sx={{ maxWidth: "100%" }}
                  onClick={() => navigate("/settings")}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={7} md={6} sm={12} xs={12}>
          {/* table listing */}
          <Box className="card mb-0">
            <TableContainer className="table-wrapper screenshot-setting-table">
              <Table className="">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    {/* <TableCell>Status</TableCell> */}
                    <TableCell>Pending Screenshots</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listingData?.data?.length ? (
                    listingData?.data?.map((row, index) => {
                      return (
                        <TableRow key={index + "g-drive-listing"}>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {formatDate(row.date)}
                          </TableCell>
                          {/* <TableCell style={{textTransform:'capitalize'}}>{row.status}</TableCell> */}
                          <TableCell>{row.count}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No Record Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={listingData.totalCount || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        /> */}
            <CustomTablePaginationToolbar
              count={listingData.totalCount || 0}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default GDriveSettings;
