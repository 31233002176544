import { FETCH_TIME_SHEET, REMOVE_TIME_SHEET, STORE_TIME_SHEET_FILTER } from "../actionTypes"

export const fetchTimeSheet = (payload) => {
    // console.log('payload in actiosn', payload)
    return { type: FETCH_TIME_SHEET, payload };
}

export const removeTimeSheet = (payload) => {
    return { type: REMOVE_TIME_SHEET, payload };
}

export const storeTimeSheetFilter = (payload) => {
    return { type: STORE_TIME_SHEET_FILTER, payload };
}

export const updateTimeSheetFilter = (payload) => {
    return { type: STORE_TIME_SHEET_FILTER, payload };
}