import { getAPICall, patchAPICall } from "../lib/callApi";
import {
  CHANGE_PASSWORD_API,
  FETCH_EMPLOYEES_BY_ID_API,
  UPDATE_ACCOUNT_DETAILS_API,
  UPDATE_EMAIL_API,
} from "../lib/constants";

export const changePassword = async (payload) => {
  const data = await patchAPICall(CHANGE_PASSWORD_API, true, payload);
  return data;
};

export const fetchLoggedInUserData = async (id) => {
  // const data = await getAPICall(FETCH_LOGGED_IN_USER_DATA, true);
  const data = await getAPICall(FETCH_EMPLOYEES_BY_ID_API, true, undefined, id);

  return data;
};

export const updateAccountDetails = async (payload, id) => {
  const data = await patchAPICall(
    UPDATE_ACCOUNT_DETAILS_API,
    true,
    payload,
    id
  );
  return data;
};

export const updateUserEmail = async (payload) => {
  const data = await patchAPICall(UPDATE_EMAIL_API, true, payload);
  return data;
};
