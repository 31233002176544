import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { FiPlus } from "react-icons/fi";
import {
  convertDateIntoDDMMYYYY,
  convertSecondsIntoHHMMSS,
} from "../../utils/helper";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#323232",
    color: theme.palette.common.white,
    padding: "10px 20px",
    borderBottom: "3px solid rgba(224, 224, 224, 1)",
    borderRight: "1px solid #323232",
    // fontWeight:500,    // textAlign: "center",
    // fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#777",
    padding: "10px 20px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // backgroundColor: '#F9F9F9',
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ViewApplicationActivity = ({
  openModal,
  closeModal,
  windowDetails,
  user,
}) => {
  const modalBodyRef = useRef();
  useEffect(() => {
    if (openModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [openModal]);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={closeModal}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={styled} className="modal-wrapper modal-bg">
            <Typography
              variant="h6"
              component="h6"
              className="text-black modal-title"
            >
              Window Activity
            </Typography>
            <Box className="modal-close" onClick={closeModal}>
              <FiPlus />
            </Box>
            <Box className="modal-body" ref={modalBodyRef}>
              <Box className="confirmation-text">
                <Typography
                  variant="subtitle4"
                  component="span"
                  className="text-black input-label"
                  sx={{ fontWeight: "600" }}
                >
                  Window Usage for {convertDateIntoDDMMYYYY(user?.date)}
                </Typography>
                <Grid container>
                  <Grid item lg={8} md={8} sm={8} xs={12}>
                    <Box mt={1}>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        className="text-black input-label"
                        sx={{ fontWeight: "600" }}
                      >
                        Employee Name :{" "}
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ display: "inline" }}
                        >
                          {user["user.first_name"]} {user["user.last_name"]}
                        </Typography>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Box mt={1}>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        className="text-black input-label"
                        sx={{ fontWeight: "600" }}
                      >
                        Total Time :{" "}
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ display: "inline" }}
                        >
                          {convertSecondsIntoHHMMSS(
                            windowDetails?.total_window_tracked_time
                          )}{" "}
                          hours
                        </Typography>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {windowDetails?.apps_used !== null ? (
                <TableContainer component={Paper} sx={{ mt: "3vh" }}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          sx={{ borderRight: "1px solid black" }}
                        >
                          Application/Tool Name
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          sx={{ textAlign: "center" }}
                        >
                          Time Spent (hours)
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          sx={{ textAlign: "center" }}
                        >
                          Time Spent (%)
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {windowDetails?.apps_used?.map((app) => (
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {app.appName}
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={{ textAlign: "center" }}
                          >
                            {convertSecondsIntoHHMMSS(app.timeSpent)}
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={{ textAlign: "center" }}
                          >
                            {app.percentage
                              ? app.percentage?.toFixed(2) + "%"
                              : "-"}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <center>
                  <Typography sx={{ marginTop: "50px", marginBottom: "30px" }}>
                    Window activity is not available
                  </Typography>
                </center>
              )}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ViewApplicationActivity;
