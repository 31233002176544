import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import ProjectName from "../../components/common/ProjectName";
import { useSelector } from "react-redux";
import { ROLES } from "../../lib/constants";
import {
  convertDateIntoDDMMYYYY,
  // convertSecondsIntoHHMM,
  convertSecondsIntoHHMMSS,
} from "../../utils/helper";
import WindowActivityTable from "./WindowActivityTable";


const activityPercentage = (activity, count) => {
  return count ? Math.round(activity / count) + "%" : "0%";
};

const ReportWindowActivityCard = ({ selectedOption }) => {
  const userRole = useSelector((state) => state.user.data?.user_role?.id);
  const reportsData = useSelector((state) => state.reports.reports);
  const windowReportsData = useSelector(
    (state) => state.reports?.windowReports
  );
  // console.log("windowReportsData =======================", windowReportsData);
  const convertData = (input) => {
    const output = [];
    for (const date in input) {
      const userData = [];
      for (const user in input[date]) {
        const { user_id, first_name, last_name, records } = input[date][user];
        const userDataItem = {
          user_id,
          first_name,
          last_name,
          records,
        };
        userData.push(userDataItem);
      }
      output.push({ date, userData });
    }
    return output;
  };


  // const ActivityCardArray = [
  //   {
  //     ActivityTitle: "Average Activity",
  //     ActivityNumber: isNaN(reportsData?.activity)
  //       ? "0%"
  //       : Math.round(reportsData?.activity) + "%",
  //     ActivityIcon: <FiActivity />,
  //   },
  //   {
  //     ActivityTitle: "Time",
  //     ActivityNumber: reportsData?.data
  //       ? convertSecondsToTime(reportsData?.worked_time)
  //       : "00:00:00",
  //     ActivityIcon: <FiClock />,
  //   },
  // ];
  // console.log("reportsData", reportsData);
  const dateData = (
    <>
      <TableBody>
        {
          

          windowReportsData?.length ? (
            windowReportsData?.map((data, index) => (
              <>
                <TableRow
                  className="report-table-row"
                  key={index + "timeActivity"}
                >
                  <TableCell
                    align="left"
                    colSpan={userRole !== ROLES.employee ? 5 : 4}
                    className="report-table-date"
                  >
                    {/* {new Date(ReportTableItem['start_date']).toLocaleString('en-US', dateFormatOptions)} */}
                    {data?.date}
                  </TableCell>
                </TableRow>
                
                {data?.data?.map((user) => (
                  <>
                    <TableRow>
                      <TableCell className="report-table-avatar" colSpan={2} >
                        <ProjectName
                          ProjectInitial={
                            user?.first_name
                              ? user?.first_name.charAt(0).toUpperCase()
                              : ""
                          }
                          ProjectTitle={
                            user?.first_name + " " + user?.last_name
                          }
                        />
                      </TableCell>
                      <TableCell  ></TableCell> 
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"  ></TableCell> 
                    </TableRow>
                    {user?.apps?.map((app) => (
                      <>
                        <TableRow>
                          <TableCell className="td-border-none"></TableCell>
                          {/* {userRole !== ROLES.employee && ( */}
                          <TableCell  colSpan={2}>
                            <ProjectName
                              ProjectInitial={
                                app?.appName
                                  ? app?.appName.charAt(0).toUpperCase()
                                  : ""
                              }
                              ProjectTitle={app?.appName}
                            />
                          </TableCell>
                          {/* )} */}

                         
                          <TableCell
                            sx={{ border: "0px solid red" }}
                            align="center"
                          >
                            {convertSecondsIntoHHMMSS(app?.timeSpent)}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ border: "0px solid red" }}
                            // className="activity-left-space"
                          >
                            {/* {Math.round(items.activity)+"%"} */}
                            {app?.percentage?.toFixed(2) + "%"}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </>
                ))}
                <TableRow className="report-table-hidden">
                  <TableCell></TableCell>
                </TableRow>
              </>
            ))
          ) : (
            <TableRow>
              <TableCell
                sx={{ textAlign: "center", backgroundColor: "#fff" }}
                colSpan={6}
              >
                No Records Found
              </TableCell>
            </TableRow>
          )
        }
      </TableBody>
    </>
  );

  
  return (
    <>
      {/* <Box className="activity-card-wrapper mb-24">
        <Grid container spacing={3}>
          {ActivityCardArray?.map((ActivityCardList, index) => (
            <ActivityCard
              key={index}
              ActivityTitle={ActivityCardList.ActivityTitle}
              ActivityNumber={ActivityCardList.ActivityNumber}
              ActivityIcon={ActivityCardList.ActivityIcon}
            />
          ))}
        </Grid>
      </Box> */}

      {/* {(selectedOption?.value === "date" || selectedOption === null) && ( */}
      <>
        <WindowActivityTable
          userRole={userRole}
          headers={["Member", "Application/Tool"]}
          tableData={dateData}
        />
      </>
      {/* )} */}

      
    </>
  );
};

export default ReportWindowActivityCard;
