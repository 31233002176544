import { deleteAPICall, getAPICall, patchAPICall, postAPICall, setIdInUrl } from "../lib/callApi"
import { CREATE_TODO_API, DELETE_TODO_API, FETCH_TODOS_BY_PROJECT_ID_API, FETCH_TODO_BY_ID_API, FETCH_USERS_BY_PROJECTS_API, UPDATE_TODO_API } from "../lib/constants"

export const fetchToDosByProjectId = async (params) => {
    const data = await getAPICall(FETCH_TODOS_BY_PROJECT_ID_API, true, params);
    return data;
}

export const fetchUsersByProject = async (params, id) => {
    const newUrl = setIdInUrl(FETCH_USERS_BY_PROJECTS_API, id)
    const data = await getAPICall(newUrl, true, params);
    return data;
}

export const addTodos = async (payload) => {
    const data = await postAPICall(CREATE_TODO_API, true, payload);
    return data;
}

export const fetchTodoById = async (todoId, payload) => {
    const data = await getAPICall(FETCH_TODO_BY_ID_API, true, payload , todoId);
    return data;
}

export const updateTodo = async (payload, todoId) => {
    const data = await patchAPICall(UPDATE_TODO_API, true, payload, todoId);
    return data;
}

export const deleteTodo = async (todoId, payload) => {
    const data = await deleteAPICall(DELETE_TODO_API, true, payload, todoId);
    return data;
}