import { REMOVE_TIME_SHEET, STORE_TIME_SHEET, STORE_TIME_SHEET_FILTER, UPDATE_TIME_SHEET_FILTER } from "../actionTypes";

const initialState = {
    totalTime:"",
    data: {
        totalCount: 0,
        userTimes: []
    }
}

const TimeSheetReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_TIME_SHEET:
            return { 
                ...state, 
                data: {
                    totalCount: action.payload.count,
                    userTimes: action.payload.data 
                }
            };
    
        case REMOVE_TIME_SHEET: 
            const newData = state.data.userTimes.filter(row => row.date !== action.payload.date || row.user_id !== action.payload.userId)
            return { ...state, data : { totalCount: state.data.totalCount - 1, userTimes: newData }}

        default:
            return state;
    }
}

export const TimeSheetFilterReducer = (state = {}, action) => {
    switch (action.type) {
        case STORE_TIME_SHEET_FILTER:
            return { 
                ...action.payload
            };

        case UPDATE_TIME_SHEET_FILTER:
            return {
                ...action.payload
            };

        default:
            return state;
    }
}

export default TimeSheetReducer