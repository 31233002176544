import { CHECK_USER_LOGIN, FAILED_USER_LOGIN, FETCH_USER_DATA, SET_USER_DATA, SUCCESS_USER_LOGIN, UPDATE_USER_DATA, UPDATE_USER_EMAIL } from "../actionTypes"

export const checkUserLogin = (payload)=> {
    return { type: CHECK_USER_LOGIN, payload }
}

export const successUserLogin = (payload) => {
    return { type: SUCCESS_USER_LOGIN, payload }
}

export const failedUserLogin = () => {
    return { type: FAILED_USER_LOGIN  }
}

export const fetchUserData = () => {
    return { type: FETCH_USER_DATA }
}

export const setUserData = (payload) => {
    return { type: SET_USER_DATA, payload }
}

export const updateUserData = (payload) => {
    return { type: UPDATE_USER_DATA, payload };
}

export const updateUserEmail = (payload) => {
    return { type: UPDATE_USER_EMAIL, payload };
}