import React, { useCallback, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import ListItemIcon from "@mui/material/ListItemIcon";
import { FiChevronRight, FiUser, FiLogOut } from "react-icons/fi";
import { RiMenu3Fill } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SiteLogo1 from "../../assets/layout/sitelogo1.png";
import SiteLogo from "../../assets/layout/sitelogo.png";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import {
  setFeedback,
  setLoading,
} from "../../store/redux/actions/feedback.action";
import { logoutAction, userLogout } from "../../apis/login";
import { getUserProfileDataFromCookies } from "../../utils/helper";
import {
  Backdrop,
  ClickAwayListener,
  Grid,
  useMediaQuery,
} from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import { IoApps } from "react-icons/io5";
import { MdPublishedWithChanges } from "react-icons/md";
const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Header = ({ handleDrawerOpen, handleDrawerClose, open }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:991.98px)");

  const userProfileData = getUserProfileDataFromCookies();
  const userApplicationPermissions = useSelector(
    (state) => state.applicationPermissions.applicationPermissions
  );

  // account dropdown
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const openAccount = Boolean(anchorEl);
  const openAccount2 = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const renderMenuItems = () => {
    return userApplicationPermissions?.application_details?.map(
      (item, index) => {
        if (item.app_id === 1) return null;
        return (
          <Grid
            item
            xs={4}
            // p={1}
            sx={{
              "& a": {
                textDecoration: "none", // Ensure no underline for links
              },
            }}
          >
            <MenuItem
              key={index}
              onClick={handleClose}
              component={Link}
              to={item.app_url}
              target="_blank"
              sx={{
                padding: "5px", // remove for 3 menuitem in 1 grid imp
                // padding: "4px",
                "&:hover": {
                  borderRadius: "12px",
                  backgroundColor: "#f8f9fa",

                  "& .MuiTypography-root": {
                    color: "black !important",
                  },
                },
              }}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <img
                    src={item.img_link ? item.img_link : SiteLogo}
                    alt="logo-main"
                    loading="lazy"
                    height="32px"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      color: "white !important",
                      fontSize: "14px",
                    }}
                  >
                    {item.app_name}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          </Grid>
        );
      }
    );
  };

  // sidebar
  const theme = useTheme();

  // fetch dynamic title
  const [title, setTitle] = useState("");

  const headerTitleHandler = useCallback(() => {
    if (location.pathname === "/") {
      setTitle("Dashboard");
    } else {
      let path = location.pathname.replace(/\d/g, "");

      if (path === "/timesheets//edit") {
        setTitle("Edit Timesheet");
        return;
      } else if (path === "/timesheets//split") {
        setTitle("Split Timesheet");
        return;
      } else if (path === "/users//edit") {
        setTitle("Edit User");
        return;
      }
      path = path.slice(1);
      path = path.replaceAll("/", "");

      if (path.includes("settings")) {
        if (path.includes("time-activity")) {
          setTitle("Time & Activity Settings");
          return;
        } else if (path.includes("project-tasks")) {
          setTitle("Project & Tasks Settings");
          return;
        } else if (path.includes("screenshots")) {
          setTitle("Screenshots Settings");
          return;
        }
      }
      if (path === "billable-nonbillable") {
        setTitle("Billable Vs. Non-Billable Members");
        return;
      }
      if (path === "billable-nonbillable-projects") {
        setTitle("Billable Vs. Non-Billable Projects");
        return;
      }
      if (path === "time-activity") {
        setTitle("Time & Activity");
        return;
      }
      if (path.includes("-")) {
        path = path.replaceAll("-", " ");
        const finalPath = path.charAt(0).toUpperCase() + path.slice(1);
        setTitle(finalPath);
      } else {
        const finalPath = path.charAt(0).toUpperCase() + path.slice(1);
        setTitle(finalPath);
      }
    }
  }, [location]);

  const logoutHandler = async () => {
    try {
      dispatch(setLoading(true));
      const response = await userLogout();
      if (response.status === 200) {
        logoutAction();
      } else {
        const payload = {
          code: response.data.status,
          status: "fail",
          message:
            response.data.message || "Something went wrong. Try again later!",
        };
        dispatch(setFeedback(payload));
      }
      dispatch(setLoading(false));
    } catch (error) {
      const payload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong. Try again later!",
      };
      dispatch(setFeedback(payload));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    headerTitleHandler();
  }, [headerTitleHandler]);

  useEffect(() => {
    if (isMobile && open) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isMobile, open]);
  return (
    <>
      <Helmet>
        <title>Time Tracker</title>
      </Helmet>
      <AppBar position="fixed" open={open} className="header">
        <Toolbar className="toolbar">
          <IconButton
            className="drawer-open-icon"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <FiChevronRight />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            className="page-title"
          >
            {title}
          </Typography>
          <Box className="app-button">
            <IoApps
              size={30}
              // fontSize="large"
              fillColor="black !important"
              style={{ marginRight: "8px" }}
              id="basic-button"
              aria-controls={openAccount2 ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openAccount2 ? "true" : undefined}
              onClick={handleClick2}
            />
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl2}
            open={openAccount2}
            onClose={handleClose2}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiPaper-root": {
                // Target the root Paper component within the Menu
                backgroundColor: "var(--color-black)", // Change the background color
                borderRadius: "12px", // Change the border radius
                border: "2px solid white",
                marginTop: "15px", // Add some gap from the top
                marginLeft: "-30px",
                // minWidth: "270px",
                width: "280px !important",
              },
            }}
          >
            <Grid container p={1}>
              {renderMenuItems()}
              {/* <Grid
                item
                xs={6}
                p={1}
                sx={{
                  "& a": {
                    textDecoration: "none", // Ensure no underline for links
                  },
                }}
              >
                <MenuItem
                  sx={{
                    padding: "5px", // remove for 3 menuitem in 1 grid imp
                    // padding: "4px",
                    "&:hover": {
                      borderRadius: "12px",
                      backgroundColor: "#f8f9fa",

                      "& .MuiTypography-root": {
                        color: "black !important",
                      },
                    },
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item></Grid>
                    <Grid item></Grid>
                  </Grid>
                </MenuItem>
              </Grid> */}
            </Grid>
          </Menu>
          <Box
            className="release-button"
            onClick={() => navigate("/release-notes")}
          >
            <Tooltip title="Release Notes">
              <NewReleasesIcon size={28} className="release-icon" />
            </Tooltip>
          </Box>

          <Box className="account-dropdown">
            <Tooltip title="">
              <IconButton
                onClick={handleClick}
                aria-controls={openAccount ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openAccount ? "true" : undefined}
              >
                <Avatar
                  className="avatar"
                  sx={{ padding: `${userProfileData?.imgUrl ? "0px" : "6px"}` }}
                >
                  <img
                    src={userProfileData?.imgUrl || SiteLogo}
                    alt="account img"
                  />
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            className="account-dropdown-menu"
            anchorEl={anchorEl}
            id="account-menu"
            open={openAccount}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.15))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Box className="user-details">
              <Box
                className="user-img"
                sx={{ padding: `${userProfileData?.imgUrl ? "0px" : "8px"}` }}
              >
                <img
                  src={userProfileData?.imgUrl || SiteLogo}
                  alt="account img"
                />
              </Box>
              <Typography
                variant="h5"
                component="h5"
                className="text-green user-name"
                align="center"
              >
                {user?.first_name} {user?.last_name}
              </Typography>
              <Typography
                variant="span"
                component="span"
                className="text-grey user-position"
                align="center"
                // sx={{border:'2px solid red'}}
              >
                {user?.user_role?.title?.charAt(0).toUpperCase() +
                  user?.user_role?.title?.slice(1)}
              </Typography>
            </Box>
            <hr className="divider-border" />
            <Box className="organization-details">
              <Box className="org-img">
                <img src={SiteLogo} alt="account img" />
              </Box>
              <Box className="org-text">
                <Typography
                  variant="h5"
                  component="h5"
                  className="text-green user-name"
                  align="center"
                >
                  {userProfileData?.companyId === 1
                    ? "Crest Infosystems Pvt. Ltd."
                    : "Crest Skillserve Pvt. Ltd."}
                </Typography>
                <Typography
                  variant="span"
                  component="span"
                  className="text-grey user-position"
                  align="center"
                >
                  Organization
                </Typography>
              </Box>
            </Box>
            <Box className="links">
              <MenuItem
                className="menu-link"
                onClick={() => navigate("/my-account")}
              >
                <ListItemIcon className="link-icon">
                  <FiUser />
                </ListItemIcon>
                My Account
              </MenuItem>

              <a
                href={`${process.env.REACT_APP_CHANGE_PASSWORD_URL}`}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#232323",
                  textDecoration: "none",
                }}
                onclick={handleClose}
              >
                <MenuItem className="menu-link">
                  <ListItemIcon className="link-icon">
                    <MdPublishedWithChanges />
                  </ListItemIcon>
                  <span class="link-text">Change Password</span>
                </MenuItem>
              </a>
              <MenuItem className="menu-link" onClick={logoutHandler}>
                <ListItemIcon className="link-icon">
                  <FiLogOut />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Box>
          </Menu>
        </Toolbar>
      </AppBar>
      {/* <ClickAwayListener onClickAway={handleClickAway}> */}

      <Drawer
        className="sidebar"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            // transform: "none !important",
          },
        }}
        variant="persistent"
        ModalProps={{
          keepMounted: true, // Better performance on mobile.
          disableEnforceFocus: true, // Allow interaction outside drawer.
        }}
        anchor="left"
        open={open}
        onClose={isMobile && handleDrawerClose}
      >
        <DrawerHeader className="site-logo">
          <img src={SiteLogo1} alt="Sitelogo" onClick={() => navigate("/")} />
          <IconButton onClick={handleDrawerClose} className="close-sidebar-btn">
            {theme.direction === "ltr" ? <RiMenu3Fill /> : <FiChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <Sidebar handleDrawerClose={handleDrawerClose} />
      </Drawer>
      {isMobile && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={open}
          onClick={handleDrawerClose}
        />
      )}
    </>
  );
};

export default Header;
