import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ProjectName = ({ProjectInitial, ProjectTitle}) => {
    return (
        <Box className='project-name'>
            <Box className='initial'>
                <Typography variant="body1" component="span" className='text-white'>
                    {ProjectInitial}
                </Typography>
            </Box>
            <Typography variant="h3" component="h3" className='text-grey'>
                {ProjectTitle}
            </Typography>
        </Box>
    );
}

export default ProjectName;
   