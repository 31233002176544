import { FETCH_APPLICATIONS_PERMISSIONS } from "../actionTypes";

const initialState = {
  applicationPermissions: {},
};

const ApplicationPermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APPLICATIONS_PERMISSIONS:
      return {
        ...state,
        applicationPermissions: action.payload,
      };
    default:
      return state;
  }
};

export default ApplicationPermissionReducer;
