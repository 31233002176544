import { FETCH_RECENT_SCREEN_SORT, FETCH_TODO_PROJECT, FETCH_WORK_TODAY_ACTIVITY } from "../actionTypes";

const initialState = {
    dashboards: [],
    todosprojects: [],
    screenSort: []
};

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WORK_TODAY_ACTIVITY:
            return {
                ...state,
                dashboards: { ...state.dashboards, ...action.payload }
            };
        case FETCH_TODO_PROJECT:
            return {
                ...state,
                todosprojects: action.payload
            };
        case FETCH_RECENT_SCREEN_SORT:
            return {
                ...state,
                screenSort: action.payload
            };
        default:
            return state;
    }
}

export default DashboardReducer;