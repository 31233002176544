import {
  deleteAPICall,
  getAPICall,
  patchAPICall,
  postAPICall,
} from "../lib/callApi";
import {
  CREATE_TIME_SHEET_API,
  DELETE_TIME_SHEET_API,
  EDIT_TIME_SHEET_API,
  FETCH_TIME_SHEET_API,
  FETCH_WINDOW_ACTIVITY,
  GET_TIME_LINE_API,
  IS_USER_UNDER_ASSOCIATE_MANAGER,
  STORE_TIME_SHEET_FILTER_API,
} from "../lib/constants";

export const fetchTimeSheetByDate = async (params) => {
  // console.log('params in apis', params)
  const data = await getAPICall(FETCH_TIME_SHEET_API, true, params);
  // console.log('data in apis', data)
  return data;
};

export const fetchWindowActivity = async (params) => {
  const data = await getAPICall(FETCH_WINDOW_ACTIVITY, true, params);
  // console.log("data in apis", data);
  return data;
};

export const createTimeSheet = async (payload) => {
  const data = await postAPICall(CREATE_TIME_SHEET_API, true, payload);
  return data;
};

export const moveTimeSheet = async (payload, id) => {
  const data = await patchAPICall(EDIT_TIME_SHEET_API, true, payload, id);
  return data;
};

export const deleteTimeSheet = async (id, payload) => {
  const data = await deleteAPICall(DELETE_TIME_SHEET_API, true, id, payload);
  return data;
};

export const storeTimeSheetFilter = async (payload) => {
  const data = await postAPICall(STORE_TIME_SHEET_FILTER_API, true, payload);

  return data;
};

export const getTimeLineChartData = async (params, id) => {
  const url = GET_TIME_LINE_API.replace("id", id);
  const data = await getAPICall(url, true, params);
  return data;
};
export const isUserUnderAssociateManager = async (payload, id) => {
  const data = await getAPICall(
    IS_USER_UNDER_ASSOCIATE_MANAGER,
    true,
    "",
    payload
  );
  return data;
};
