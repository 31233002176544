import React, { useState, useEffect, useCallback } from "react";
import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Select from "react-select";
import { FiFilter, FiPlus, FiSearch } from "react-icons/fi";
import PropTypes from "prop-types";
import ProjecttoDosTableRow from "../../components/common/ProjectToDosTableRow";
import AddProjectToDo from "./AddProjectToDo";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjects } from "../../store/redux/actions/project.action";
import { setFeedback } from "../../store/redux/actions/feedback.action";
import { fetchTodos } from "../../store/redux/actions/todos.action";
import debounce from "lodash.debounce";
import { fetchUsersByProject } from "../../apis/todos";
import { ROLES, storageName } from "../../lib/constants";
import {
  convertDateIntoDDMMYYYY,
  getFilterStorage,
  getPermissionAccess,
  handleLabel,
  setFilterStorage,
  getDotColorStyle,
} from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { CustomTablePaginationToolbar } from "../../components/common/Pagination";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
}));

const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#8CC714",
  },
  "& .MuiTab-root": {
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
    textTransform: "capitalize",
  },
  "& .MuiTab-textColorInherit": {
    color: "#777777",
  },
});

const CustomTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    color: "#8CC714",
    borderBottom: `2px solid #8CC714`,
  },
  "&:hover": {
    // color: '#8CC714',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProjectToDos = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.user.data);
  const permissions = userData.role_permissions.permissions;
  const userRole = userData?.user_role?.id;
  const permissionsAccess = getPermissionAccess(permissions, "Todos");
  if (
    !permissionsAccess.view &&
    !permissionsAccess.add &&
    !permissionsAccess.edit &&
    !permissionsAccess.delete
  ) {
    navigate("/", { replace: true });
  }

  const projects = useSelector((state) => state.project.projects_list);
  const { todos, totalCount } = useSelector((state) => state.todos.todos);

  const filterData = getFilterStorage(storageName.todo);

  // tab panel code
  const [valueTab, setValueTab] = useState(0);
  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };

  // pagination code start
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    // setPage((prev) => prev);
  };

  const projectOptions = projects?.map((proj) => ({
    value: proj.id,
    label: proj.title,
  }));

  let checkedValue = "completed";
  if (valueTab === 0) {
    checkedValue = "pending";
  } else if (valueTab === 1) {
    checkedValue = "completed";
  }

  //search code
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      // dispatch(fetchTodos(params));
      setSearch(value);
    }, 800),
    [fetchTodos]
  );

  const changeSearchValueHandler = async (e) => {
    setSearchValue(e.target.value);
    setPage(0);
    debouncedSearch(e.target.value);
  };

  let [userOptions, setUserOption] = useState([]);
  const [selectedProjectOption, setSelectedProjectOption] = useState(
    filterData?.project || ""
  );
  const [selectedUserOption, setSelectedUserOption] = useState(
    filterData?.user || []
  );

  const handleSelectedProject = (selectedOption) => {
    if (selectedOption) {
      setSelectedProjectOption(selectedOption);
      setFilterStorage(storageName.todo, {
        project: selectedOption,
        user: [],
        users: filterData.users,
      });
      setPage(0);
      if (selectedOption.label.toLowerCase() === "all") {
        setSelectedUserOption([]);
        setUserOption([]);
      } else {
        setSelectedUserOption([]);
      }
    }
  };

  // add Todos account modal
  const [addToDosModalOpen, addSetOpen] = React.useState(false);
  const addTodosModalOpen = () => addSetOpen(true);
  const addTodosModalClose = () => addSetOpen(false);

  //pabbing panel
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //right side drawer for filter
  const [state, setState] = useState(false);
  const handleDrawerOpen = () => {
    setState(true);
  };
  const handleDrawerClose = () => {
    setState(false);
  };

  const fetchProjectsData = useCallback(async () => {
    try {
      const params = { listing: true, isListing: true };
      dispatch(fetchProjects(params));
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  }, [dispatch]);

  useEffect(() => {
    setPage(0);
  }, [valueTab]);

  const fetchUsersByProjectData = useCallback(async () => {
    try {
      if (selectedProjectOption.value !== "") {
        const params = { role: [2, 3], paranoid: false };
        const response = await fetchUsersByProject(
          params,
          selectedProjectOption.value
        );
        const userData = response.data.status === 200 ? response.data.data : [];

        // let flag = 0; // 0 = G            1 = B
        let options = [];
        // if(userRole === ROLES.admin){
        //   // userData.forEach((row) => {
        //   //   if(row.deletedAt === null){
        //   //     if(row.user.deletedAt === null){
        //   //       options.push(handleLabel(true, row.user.id, `${row.user.first_name} ${row.user.last_name}`));
        //   //     } else {
        //   //       options.push(handleLabel(false, row.user.id, `${row.user.first_name} ${row.user.last_name}`));
        //   //     }
        //   //   } else {
        //   //     options.push(handleLabel(false, row.user.id, `${row.user.first_name} ${row.user.last_name}`))
        //   //   }
        //   // })
        //   userData.forEach((row) => {
        //     if(row.user.status === "active"){
        //       options.push(handleLabel(true, row.user.id, `${row.user.first_name} ${row.user.last_name}`));
        //     } else{
        //       options.push(handleLabel(false, row.user.id, `${row.user.first_name} ${row.user.last_name}`))
        //     }
        //   })
        // }
        // else if (userRole === ROLES.manager || userRole === ROLES.viewer || userRole === ROLES.employee) {
        //   userData.forEach((row) => {
        //     let flag = 0;
        //     // if(row.deletedAt !== null){
        //     //   flag = 1;
        //     // } else {
        //     //   if(row.user.deletedAt === null){
        //     //     flag = 0;
        //     //   } else {
        //     //     flag = 1;
        //     //   }
        //     // }
        //     if(row.user.status === "active"){
        //       flag = 0;
        //     } else{
        //       flag = 1;
        //     }

        //     if(flag === 0){
        //       options.push(handleLabel(true, row.user.id, `${row.user.first_name} ${row.user.last_name}`))
        //     } else {
        //       options.push(handleLabel(false, row.user.id, `${row.user.first_name} ${row.user.last_name}`))
        //     }
        //   })
        // } else {
        //   options = [];
        // }

        userData.forEach((row) => {
          if (row.deletedAt === null) {
            options.push(
              handleLabel(
                true,
                row.user.id,
                `${row.user.first_name} ${row.user.last_name}`
              )
            );
          } else {
            options.push(
              handleLabel(
                false,
                row.user.id,
                `${row.user.first_name} ${row.user.last_name}`
              )
            );
          }
        });
        setUserOption(options);

        setFilterStorage(storageName.todo, {
          project: filterData?.project,
          user: filterData?.user,
          users: options,
        });
      } else {
        setUserOption([]);
        setSelectedUserOption([]);
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedProjectOption, userRole]);

  const handleSelectedUser = (selectedOption) => {
    if (selectedOption) {
      setSelectedUserOption(selectedOption);
      setPage(0);
      if (selectedProjectOption.value === "") {
        setSelectedUserOption([]);
        setUserOption([]);
        selectedOption.value = "";
      }
    }
  };

  const fetchTodosByProject = useCallback(async () => {
    try {
      let assignees = [];
      if (selectedUserOption && selectedUserOption.length > 0) {
        assignees = selectedUserOption.map((user) => user.value);
      }

      const params = {
        title: search.trim().replace(/\s+/g, " "),
        project: selectedProjectOption.value,
        assignee: assignees,
        status: checkedValue,
        page: page + 1,
        rows: rowsPerPage,
      };
      setFilterStorage(storageName.todo, {
        project: selectedProjectOption,
        user: selectedUserOption,
        users: filterData?.users,
      });

      dispatch(fetchTodos(params));
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkedValue,
    dispatch,
    page,
    rowsPerPage,
    search,
    selectedProjectOption,
    selectedUserOption,
  ]);

  useEffect(() => {
    fetchProjectsData();
  }, [fetchProjectsData]);

  useEffect(() => {
    fetchUsersByProjectData();
  }, [fetchUsersByProjectData]);

  useEffect(() => {
    fetchTodosByProject();
  }, [fetchTodosByProject]);

  const clearAllFilterData = () => {
    setSelectedProjectOption("");
    setSelectedUserOption([]);
    fetchTodosByProject();
    setState(false);

    setFilterStorage(storageName.todo, { project: "", user: [], users: [] });
  };

  return (
    <>
      <Box className="content-header project-todo-module-wrapper mb-4">
        <Box>
          <CustomTabs
            value={valueTab}
            onChange={handleChangeTabs}
            className="mb-0"
          >
            <CustomTab label="In-Progress" {...a11yProps(0)} />
            <CustomTab label="Completed" {...a11yProps(1)} />
          </CustomTabs>
        </Box>
        <Box item className="content-header-right top-filter-btn top-bar mb-0">
          <Box className="searchbox bg-white">
            <BootstrapInput
              placeholder="Search to-dos"
              value={searchValue}
              onChange={changeSearchValueHandler}
              endAdornment={
                <InputAdornment
                  position="end"
                  className="end-input-icon text-grey"
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <FiSearch />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          {userRole !== ROLES.viewer && permissionsAccess.add && (
            <Button
              className="btn btn-primary add-btn-to-do"
              onClick={addTodosModalOpen}
            >
              <FiPlus /> Add To Dos
            </Button>
          )}
          <Tooltip title="Filter To-dos">
            <Button
              className="btn btn-primary only-icon-btn"
              onClick={handleDrawerOpen}
            >
              <FiFilter />
            </Button>
          </Tooltip>
        </Box>
      </Box>

      {/* table listing */}
      <Box className="card mb-0">
        {checkedValue !== "pending" ? (
          <TableContainer className="table-wrapper">
            <Table className="todo-table">
              <TableHead>
                <TableRow>
                  <TableCell>Project</TableCell>
                  <TableCell>To Dos</TableCell>
                  <TableCell>Member</TableCell>
                  <TableCell>Created</TableCell>
                  {userRole !== ROLES.viewer ? (
                    <TableCell>Action</TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {todos?.length ? (
                  todos?.map((ProjecttoDosTableRowList, index) => {
                    let aTitle = "";
                    if (
                      ProjecttoDosTableRowList?.createdBy?.first_name !==
                        null ||
                      ProjecttoDosTableRowList?.createdBy?.last_name !== null
                    ) {
                      aTitle =
                        ProjecttoDosTableRowList?.createdBy?.first_name +
                        " " +
                        ProjecttoDosTableRowList?.createdBy?.last_name;
                    }

                    return (
                      <ProjecttoDosTableRow
                        key={index}
                        projectInitial={
                          ProjecttoDosTableRowList?.project?.title === null
                            ? ""
                            : ProjecttoDosTableRowList?.project?.title
                                .charAt(0)
                                .toUpperCase()
                        }
                        projectTitle={ProjecttoDosTableRowList?.project?.title}
                        todoId={ProjecttoDosTableRowList?.id}
                        ToDos={ProjecttoDosTableRowList?.description}
                        AssigneeInitial={
                          ProjecttoDosTableRowList?.createdBy?.first_name ===
                          null
                            ? ""
                            : ProjecttoDosTableRowList?.createdBy?.first_name
                                .charAt(0)
                                .toUpperCase()
                        }
                        AssigneeTitle={aTitle}
                        member={ProjecttoDosTableRowList?.todo_assignments}
                        Created={convertDateIntoDDMMYYYY(
                          ProjecttoDosTableRowList?.createdAt
                        )}
                        Completed={checkedValue}
                        permissionsAccess={permissionsAccess}
                        valueTab={valueTab}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Record Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer className="table-wrapper">
            <Table className="todo-table">
              <TableHead>
                <TableRow>
                  <TableCell>Project</TableCell>
                  <TableCell>To Dos</TableCell>
                  <TableCell>Member</TableCell>
                  <TableCell>Created</TableCell>
                  {userRole !== ROLES.viewer ? (
                    <TableCell>Action</TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {todos?.length ? (
                  todos?.map((ProjecttoDosTableRowList, index) => (
                    <ProjecttoDosTableRow
                      key={index}
                      projectInitial={
                        ProjecttoDosTableRowList?.project?.title === null
                          ? ""
                          : ProjecttoDosTableRowList?.project?.title
                              .charAt(0)
                              .toUpperCase()
                      }
                      projectTitle={ProjecttoDosTableRowList?.project?.title}
                      todoId={ProjecttoDosTableRowList?.id}
                      ToDos={ProjecttoDosTableRowList?.description}
                      AssigneeInitial={ProjecttoDosTableRowList?.createdBy?.first_name
                        .charAt(0)
                        .toUpperCase()}
                      AssigneeTitle={
                        ProjecttoDosTableRowList?.createdBy?.first_name +
                        " " +
                        ProjecttoDosTableRowList?.user?.last_name
                      }
                      member={ProjecttoDosTableRowList?.todo_assignments}
                      Created={convertDateIntoDDMMYYYY(
                        ProjecttoDosTableRowList?.createdAt
                      )}
                      Completed={checkedValue}
                      permissionsAccess={permissionsAccess}
                      valueTab={valueTab}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} className="not-found" align="center">
                      No Record Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <CustomTablePaginationToolbar
          count={totalCount || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      {addToDosModalOpen && (
        <AddProjectToDo
          addToDosModalOpen={addToDosModalOpen}
          addTodosModalClose={addTodosModalClose}
          fetchTodosByProject={fetchTodosByProject}
        />
      )}

      {/* Filter modal */}
      <Drawer
        anchor="right"
        open={state}
        onClose={handleDrawerClose}
        className="filter-drawer to-do todo-wrawer"
      >
        <Box className="close-filter" onClick={handleDrawerClose}>
          <FiPlus />
        </Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="filter-tab"
        >
          <Tab className="filter-btn" label="Filter" {...a11yProps(0)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Box className="filter-content">
            <FormGroup className="form-field">
              <FormControl variant="standard" className="form-control mb-3">
                <Typography
                  variant="body2"
                  component="span"
                  className="text-black input-label"
                >
                  Projects
                </Typography>
                <Select
                  className="select-filter"
                  placeholder="Select Project"
                  value={selectedProjectOption}
                  onChange={handleSelectedProject}
                  options={projectOptions}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "rgba(140, 199, 20, 0.4);",
                      primary: "#8CC714",
                    },
                  })}
                />
              </FormControl>
              <FormControl variant="standard" className="form-control mb-3">
                <Typography
                  variant="body2"
                  component="span"
                  className="text-black input-label"
                >
                  Members
                </Typography>
                <Select
                  className="select-filter"
                  placeholder="Select Members"
                  value={selectedUserOption}
                  onChange={handleSelectedUser}
                  options={userOptions}
                  isMulti
                  isClearable={false}
                  classNamePrefix="select"
                  styles={getDotColorStyle("MULTI")}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "rgba(140, 199, 20, 0.4);",
                      primary: "#8CC714",
                    },
                  })}
                />
              </FormControl>
            </FormGroup>
            <FormGroup className="form-field">
              <FormControl variant="standard" className="form-control mb-3">
                <Button
                  className="btn btn-primary"
                  onClick={() => clearAllFilterData()}
                >
                  Clear All
                </Button>
              </FormControl>
            </FormGroup>
          </Box>
        </TabPanel>
      </Drawer>
    </>
  );
};

export default ProjectToDos;
