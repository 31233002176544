import React from 'react'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Step1 from '../../assets/steps/step-1.png'
import Step2 from '../../assets/steps/step-2.png'
import Step3 from '../../assets/steps/step-3.png'
import Step4 from '../../assets/steps/step-4.png'
import Step5 from '../../assets/steps/step-5.png'
import Step6 from '../../assets/steps/step-6.png'
import Step7 from '../../assets/steps/step-7.png'
import Step8 from '../../assets/steps/step-8.png'

const stepsContent = [
  {
    stepId: 1,
    title: "Project Selection and Task Selection",
    description: "Initiate the process by carefully choosing the project, and then select a to-do from your to-do list.",
    image: Step1,
  },
  {
    stepId: 2,
    title: "Starting the Tracker",
    description: "To initiate the tracker, click on the icon as illustrated in the figure.",
    image: Step2,
  },
  {
    stepId: 3,
    title: "Note Review and Memo Entry",
    description: "Before entering your memo in the designated field, carefully review the note provided. Once you've read the note, proceed to add your memo with all necessary details.",
    image: Step3,
  },
  {
    stepId: 4,
    title: "Adjusting Billable Status and Tracking Hours According to Task Requirements",
    description: "You can modify the billable status by simply checking the checkbox and then track your hours accordingly, based on whether the task is billable or non-billable, in alignment with your specific project requirements.",
    image: Step4,
  },
  {
    stepId: 5,
    title: "Editing Memo",
    description: "To edit your memo, click on the icon shown in the figure, where you can also change the billable or non-billable status.",
    image: Step5,
  },
  {
    stepId: 6,
    title: "Pause Timer",
    description: "You can now pause the timer for multiple tasks to efficiently manage and organize each task along with its corresponding memo and billable value.",
    image: Step6,
  },
  {
    stepId: 7,
    title: "Resume Timer",
    description: "You can now seamlessly resume a specific task with the previous memo and billable value.",
    image: Step7,
  },
  {
    stepId: 8,
    title: "Stop Timer",
    description: "To stop the tracker, click on the icon as illustrated in the figure.",
    image: Step8,
  },
]

const GuideSteps = ({ setIsUsageClick }) => {
  return (
    <>
      <Box className="download-wrapper">
        <Stack>
          <KeyboardBackspaceIcon style={{ fontSize : '36px', cursor:'pointer', fill: "#777" }} onClick={() => setIsUsageClick(false)} />
          <Typography variant='h5' component='h5' className='text-black download-title'>
            Step-by-Step Guide for how to use CrestTracker
          </Typography>
        </Stack>
        {stepsContent.map((step, stepNumber) => (
          <Box className='step-container' key={stepNumber}>
            <Stack className='step-text'>
              <h4>{step.stepId}. {step.title}</h4>
              <Typography variant='p' component={'p'} className="text-grey" m={0}>
                {step.description}
              </Typography>
            </Stack>
            <img src={step.image} alt={`step ${stepNumber + 1}`} />
          </Box>
        ))}
      </Box>
    </>
  )
}

export default GuideSteps