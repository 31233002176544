import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFeedback } from "../../store/redux/actions/feedback.action";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material";

const FeedbackModal = () => {
  const feedback = useSelector((state) => state.feedback);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(true);

  const modalCloseHandler = () => {
    setIsModalOpen(false);
    dispatch(clearFeedback());
    if (feedback.code === 403) {
      window.location.href = "/login";
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsModalOpen(false);
      dispatch(clearFeedback());
      if (feedback.code === 403) {
        window.location.href = "/login";
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, feedback.code]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen}
        onClose={modalCloseHandler}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen}>
          <Box sx={styled} className="modal-wrapper modal-bg">
            <Typography variant="h6" component="h6" className="status-icon">
              {feedback.status === "fail" && (
                <ErrorIcon className="fail-icon" />
              )}
              {feedback.status === "success" && (
                <CheckCircleIcon className="success-icon" />
              )}
              {feedback.status === "warning" && (
                <WarningIcon className="warning-icon" />
              )}
            </Typography>
            <Box className="modal-body">
              <Box className={`feedback-text ${feedback.status}-text`}>
                <Typography paragraph>
                  {feedback.message.charAt(0).toUpperCase() +
                    feedback.message.slice(1)}
                </Typography>
              </Box>
            </Box>

            <Box className="modal-footer">
              <div className="feedback-footer" style={{ margin: "auto" }}>
                <Button
                  className="btn btn-secondary"
                  onClick={modalCloseHandler}
                >
                  {feedback.code === 403 ? `Login` : "OK"}
                </Button>
              </div>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default FeedbackModal;
