// Auth Actions
export const CHECK_USER_LOGIN = "CHECK_USER_LOGIN";

export const SUCCESS_USER_LOGIN = "SUCCESS_USER_LOGIN";
export const FAILED_USER_LOGIN = "FAILED_USER_LOGIN";
export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const SET_USER_DATA = "SET_USER_DATA";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_USER_EMAIL = "UPDATE_USER_EMAIL";
export const UPDATE_USER_EMAIL_SUCCESS = "UPDATE_USER_EMAIL_SUCCESS";

// Feedback Actions
export const SET_FEEDBACK = "SET_FEEDBACK";
export const CLEAR_FEEDBACK = "CLEAR_FEEDBACK";
export const SET_LOADING = "SET_LOADING";

// Employee Actions
export const SET_EMPLOYEES = "SET_EMPLOYEES";
export const FETCH_EMPLOYEE = "FETCH_EMPLOYEE";
export const UPDATE_EMPLOYEE_STATUS = "UPDATE_EMPLOYEE_STATUS";
export const UPDATE_EMPLOYEE_TRACKING_STATUS =
  "UPDATE_EMPLOYEE_TRACKING_STATUS";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_SAGA = "UPDATE_EMPLOYEE_SAGA";
export const USER_ROLES = "USER_ROLES";
export const FETCH_USER_ROLES = "FETCH_USER_ROLES";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const DELETE_EMPLOYEE_SAGA = "DELETE_EMPLOYEE_SAGA";
export const FETCH_EMPLOYEE_BY_ID = "FETCH_EMPLOYEE_BY_ID";
export const FILTER_EMPLOYEE = "FILTER_EMPLOYEE";
export const SEARCH_EMPLOYEE = "SEARCH_EMPLOYEE";
export const SEARCH_EMPLOYEE_INVITE = "SEARCH_EMPLOYEE_INVITE";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const FETCH_PROJECT_BY_USER_ID = "FETCH_PROJECTBY_USER_ID";
export const UPDATE_PROJECT_BY_USER_ID = "UPDATE_PROJECT_BY_USER_ID";
export const FETCH_EMPLOYEE_INVITE_BY_MAIL = "FETCH_EMPLOYEE_INVITE_BY_MAIL";
export const SET_EMPLOYEE_INVITE_BY_MAIL = "SET_EMPLOYEE_INVITE_BY_MAIL";
export const RESEND_INVITE_ACTION = "RESEND_INVITE_ACTION";
export const DELETE_EMPLOYEE_INVITE = "DELELTE_EMPLOYEE_INVITE";

// Project Actions

export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const STORE_PROJECTS = "STORE_PROJECTS";
export const LEAVE_PROJECT = "LEAVE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const ADD_PROJECT = "ADD_PROJECT";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const FETCH_MEMBERS = "FETCH_MEMBERS";
export const STORE_MEMBERS = "STORE_MEMBERS";
export const CHANGE_PROJECT_STATUS = "CHANGE_PROJECT_STATUS";
export const CHANGE_PROJECT_STATUS_SUCCESS = "CHANGE_PROJECT_STATUS_SUCCESS";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";

// to-dos Actions
export const FETCH_TODOS = "FETCH_TODOS";
export const STORE_TODOS = "STORE_TODOS";
export const ADD_TODO = "ADD_TODO";
export const ADD_TODO_SUCCESS = "ADD_TODO_SUCCESS";
export const UPDATE_TODO = "UPDATE_TODO";
export const UPDATE_TODO_SUCCESS = "UPDATE_TODO_SUCCESS";
export const DELETE_TODO = "DELETE_TODO";
export const DELETE_TODO_SUCCESS = "DELETE_TODO_SUCCESS";
export const UPDATE_COMPLETE_TODO = "UPDATE_COMPLETE_TODO";
export const COMPLETE_TODO_SUCCESS = "COMPLETE_TODO_SUCCESS";

// Time Sheet view-edit Actions
export const FETCH_TIME_SHEET = "FETCH_TIME_SHEET";
export const STORE_TIME_SHEET = "STORE_TIME_SHEET";
export const REMOVE_TIME_SHEET = "REMOVE_TIME_SHEET";

export const STORE_TIME_SHEET_FILTER = "STORE_TIME_SHEET_FILTER";
export const UPDATE_TIME_SHEET_FILTER = "UPDATE_TIME_SHEET_FILTER";

// reports Actions
export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_WINDOW_REPORTS = "FETCH_WINDOW_REPORTS";
export const STORE_REPORTS = "STORE_REPORTS";
export const STORE_WINDOWREPORTS = "STORE_WINDOWREPORTS";
export const FETCH_FILTERS = "FETCH_FILTERS";
export const STORE_FILTERS = "STORE_FILTERS";
export const ADD_FILTER = "ADD_FILTER";
export const ADD_FILTER_SUCCESS = "ADD_FILTER_SUCCESS";
export const UPDATE_FILTER = "UPDATE_FILTER";
export const UPDATE_FILTER_SUCCESS = "UPDATE_FILTER_SUCCESS";
export const DELETE_FILTER = "DELETE_FILTER";
export const DELETE_FILTER_SUCCESS = "DELETE_FILTER_SUCCESS";
export const FETCH_SCREENSHOT_REPORTS = "FETCH_SCREENSHOT_REPORTS";
export const STORE_SCREENSHOT_REPORTS = "STORE_SCREENSHOT_REPORTS";
export const FETCH_BILLABLE_NONBILLABLE_REPORTS =
  "FETCH_BILLABLE_NONBILLABLE_REPORTS";
export const FETCH_BILLABLE_NONBILLABLE_TYPEWISE_REPORTS =
  "FETCH_BILLABLE_NONBILLABLE_TYPEWISE_REPORTS";

export const FETCH_BILLABLE_NONBILLABLE_PROJECTWISE_USERS =
  "FETCH_BILLABLE_NONBILLABLE_PROJECTWISE_USERS";

export const STORE_BILLABLE_NONBILLABLE_REPORTS =
  "STORE_BILLABLE_NONBILLABLE_REPORTS";
export const STORE_BILLABLE_TYPEWISE_NONBILLABLE_REPORTS =
  "STORE_BILLABLE_NONBILLABLE_TYPEWISE_REPORTS";

export const STORE_BILLABLE_NONBILLABLE_PROJECTWISE_USERS =
  "STORE_BILLABLE_NONBILLABLE_PROJECTWISE_USERS";

// dashboard Actions
export const FETCH_WORK_TODAY_ACTIVITY = "FETCH_WORK_TODAY_ACTIVITY";
export const FETCH_WORK_TODAY_ACTIVITY_STORE =
  "FETCH_WORK_TODAY_ACTIVITY_STORE";
export const FETCH_TODO_PROJECT = "FETCH_TODO_PROJECT";
export const FETCH_TODO_PROJECT_STORE = "FETCH_TODO_PROJECT_STORE";
export const FETCH_RECENT_SCREEN_SORT = "FETCH_RECENT_SCREEN_SORT";
export const GET_SINGLE_IMAGE = "GET_SINGLE_IMAGE";

// Incomplete TimeSheet Actions
export const FETCH_INCOMPLETE_TIME_SHEET = "FETCH_INCOMPLETE_TIME_SHEET";
export const STORE_INCOMPLETE_TIME_SHEET = "STORE_INCOMPLETE_TIME_SHEET";

//APPLICATION-PERMISSIONS
export const FETCH_APPLICATIONS_PERMISSIONS = "FETCH_APPLICATIONS_PERMISSIONS";
export const FETCH_APPLICATIONS_PERMISSIONS_STORE =
  "FETCH_APPLICATIONS_PERMISSIONS_STORE";
