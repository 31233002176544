import { all } from "redux-saga/effects";
import watchProjectAction from "./project.saga";
import watchTodoAction from "./todo.saga";
import watchEmployeeAction from "./employee.saga";
import watchUserAction from "./user.saga";
import watchTimeSheetAction from "./timesheet.saga";
import watchReportAction from "./report.saga";
import watchDashboardAction from "./dashboard.saga";
import watchAppllicationPermissionsAction from "./applicationPermission.saga";
function* rootSaga() {
  yield all([
    watchProjectAction(),
    watchTodoAction(),
    watchEmployeeAction(),
    watchUserAction(),
    watchTimeSheetAction(),
    watchReportAction(),
    watchDashboardAction(),
    watchAppllicationPermissionsAction(),
  ]);
}

export default rootSaga;
