import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import Select from 'react-select';
import { setFeedback, setLoading } from '../../store/redux/actions/feedback.action';
import { updateUserData } from '../../store/redux/actions/user.action';
import { useNavigate } from 'react-router-dom';
// import { getNormalColorStyle } from '../../utils/helper';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        // marginTop: theme.spacing(3),
    }
}));

// const options = [
//     { value: 'India :(GMT+05:30) Asia/Kolkata', label: '(GMT+05:30) Asia/Kolkata' },
//     { value: 'Australia :(GMT+10:00) Australia/Sydney', label: '(GMT+10:00) Australia/Sydney' },
//     { value: 'America :(GMT-5:00) America/New_York', label: '(GMT-5:00) America/New_York' },
//     { value: 'America :(GMT-7:00) America/Denver', label: '(GMT-7:00) America/Denver' },
//     { value: 'America: (GMT-7:00) America/Phoenix', label: '(GMT-7:00) America/Phoenix' },
//     { value: 'America :(GMT-8:00) America/Los_Angeles', label: '(GMT-8:00) America/Los_Angeles' },
// ];
  
const AccountDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user.data);
    
    const [userData, setUserData] = useState({
        full_name: `${user.first_name} ${user.last_name}`,
        phone:user.phone,
        Time_Zone: user.Time_Zone,
        skype_Id: user.skype_Id
    });
    // const [selectedOption, setSelectedOption] = useState('');

    const [errors, setErrors] = useState({
        full_name:"",
        phone:"",
        Time_Zone:"",
        skype_Id:""
    })

    const changeAccountDetailsHandler = (e) => {
        const name = e.target.name ;
        const value = e.target.value;

        if(value === ""){
            if(name === "full_name")
                setErrors(prev => ({...prev, full_name: "Please enter your full name." }));
            if(name === "phone")
                setErrors(prev => ({...prev, phone: "Please enter phone number." }));  
            if(name === "skype_Id")
                setErrors(prev => ({...prev, skype_Id: "Please enter your skype id." }));  
        } else {
            if(name === "full_name")
                setErrors(prev => ({...prev, full_name: "" })); 
            if(name === "phone")
                setErrors(prev => ({...prev, phone: "" }));
            if(name === "skype_Id")
                setErrors(prev => ({...prev, skype_Id: "" }));
        }

        setUserData(prevState => {
            return { ...prevState, [name]: value } 
        });
    }

    const blurAccountDetailsHandler = (e) => {
        const name = e.target.name ;
        const value = e.target.value;
        if(value === ""){
            if(name === "full_name")
                setErrors(prev => ({...prev, full_name: "Please enter your full name." }));
            if(name === "phone")
                setErrors(prev => ({...prev, phone: "Please enter phone number." }));  
            if(name === "skype_Id")
                setErrors(prev => ({...prev, skype_Id: "Please enter your skype id." }));  
        } else {
            if(name === "full_name")
                setErrors(prev => ({...prev, full_name: "" })); 
            if(name === "phone")
                setErrors(prev => ({...prev, phone: "" }));
            if(name === "skype_Id")
                setErrors(prev => ({...prev, skype_Id: "" }));
        }
    }

    useEffect(() => {
        // const selectedOption = options.find(option => option.value === userData.Time_Zone);
        // setSelectedOption(selectedOption);
      
        setUserData({
          full_name: `${user.first_name} ${user.last_name}`,
          phone: user.phone,
          Time_Zone: user.Time_Zone,
          skype_Id: user.skype_Id
        });
    }, [user, userData.Time_Zone]);
      
    // const handleSelectedOption = (selectedOption) => {
    //     setSelectedOption(selectedOption);
    // };      

    const submitHandler = async (e) => {
        let errPayload;
        try {
            setErrors({ full_name:"", phone:"", Time_Zone:"", skype_Id:"" });
            const tempName = userData.full_name.trim()
            const [first_name, last_name] = tempName.replace(/\s+/g, ' ').split(' ');
            if(!userData.full_name || userData.full_name.trim() === ""){
                setErrors(prev => ({ ...prev, full_name:"Please enter your full name." }));
                return;
            }
            if(!first_name || !last_name){
                setErrors(prev => ({ ...prev, full_name:"Please enter valid full name (first and last name)." }));
                return;
            }
            // if((userData.full_name.split(' ').length - 1) > 1 || (userData.full_name.split(' ').length - 1) < 1){
            //     setErrors(prev => ({ ...prev, full_name:"Please enter valid full name (first and last name)." }));
            //     return;
            // }
            if(!userData.phone || userData.phone.trim() === ""){
                setErrors(prev => ({ ...prev, phone:"Please enter phone number." }));
                return;
            }
            if(userData.phone.length !== 10){
                setErrors(prev => ({ ...prev, phone:"Please enter 10 digits." }));
                return;
            }
            if(!userData.skype_Id || userData.skype_Id.trim() === ""){
                setErrors(prev => ({ ...prev, skype_Id:"Please enter your skype id." }));
                return;
            }
            
            const payload = {
                data:{
                    first_name:first_name,
                    last_name:last_name,
                    phone: userData.phone,
                    // Time_Zone: selectedOption ? selectedOption.value : '',
                    skype_Id: userData.skype_Id
                },
                id:{
                    userId: user.id
                }
            }
            dispatch(updateUserData(payload));            
        } catch (error) {
            dispatch(setLoading(false));
            errPayload = { code: error.status, status: 'fail', 
            message:error.message || "Something went wrong." }
            dispatch(setFeedback(errPayload));       
        }
    }

    const cancelHandler = () => {
        navigate('/');
    }

  return (
    <>
        <Typography variant="h6" component="h6" className='text-black card-title' >
            Account Details
        </Typography>

        <Grid container>
            <Grid item md={7} xs={12}>
                <FormGroup className='form-field'>
                    <FormControl variant="standard" className='form-control mb-3'>
                        <Typography variant="body2" component="span" className='text-black input-label'>
                            Full Name<Typography variant="body2" component="span" className='text-black input-label asterisk'>
                              * 
                            </Typography>
                        </Typography>
                        <BootstrapInput 
                            name="full_name"
                            placeholder="Enter full name" 
                            onBlur={blurAccountDetailsHandler}
                            className={!errors.full_name ? 'input-field' : "border-error"} 
                            value={userData.full_name}
                            onChange={changeAccountDetailsHandler}
                            disabled
                        />
                        {errors.full_name && <p className="error-text">{errors.full_name}</p>}
                    </FormControl>

                    <FormControl variant="standard" className='form-control mb-3'>
                        <Typography variant="body2" component="span" className='text-black input-label'>
                            Email<Typography variant="body2" component="span" className='text-black input-label asterisk'>
                              * 
                            </Typography>
                        </Typography>
                        <BootstrapInput 
                            name="email"
                            placeholder="Enter email address" 
                            value={user.email} 
                            className='input-field' 
                            disabled
                        />
                    </FormControl>
                    
                    <FormControl variant="standard" className='form-control mb-3'>
                        <Typography variant="body2" component="span" className='text-black input-label'>
                            Phone<Typography variant="body2" component="span" className='text-black input-label asterisk'>
                              * 
                            </Typography>
                        </Typography>
                        <BootstrapInput 
                            name="phone"
                            type='number'
                            onBlur={blurAccountDetailsHandler}
                            placeholder="Enter phone number" 
                            className={!errors.phone ? 'input-field' : 'border-error'}
                            value={userData.phone}
                            onChange={changeAccountDetailsHandler}
                            disabled
                        />
                        {errors.phone && 
                            <p className="error-text">{errors.phone}</p>}
                    </FormControl>
                    
                    <FormControl variant="standard" className='form-control'>
                        <Typography variant="body2" component="span" className='text-black input-label'>
                            Skype ID
                        </Typography>
                        <BootstrapInput 
                            name="skype_Id"
                            onBlur={blurAccountDetailsHandler}
                            placeholder="Enter skype id" 
                            className={!errors.skype_Id ? "input-field" : "border-error"}
                            value={userData.skype_Id}
                            onChange={changeAccountDetailsHandler}
                        />
                        {errors.skype_Id && <p className="error-text">{errors.skype_Id}</p>}
                    </FormControl>

                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button className='btn btn-primary' onClick={submitHandler}> Save</Button>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button className='btn btn-secondary' onClick={cancelHandler}>Cancel</Button>
                        </Grid>
                    </Grid>
                </FormGroup>
            </Grid>
        </Grid>
    </>
  )
}

export default AccountDetails