import React, { useState, useCallback, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "react-slick";
import {
  FiActivity,
  FiChevronLeft,
  FiChevronRight,
  FiClock,
  FiEye,
  FiFolderPlus,
} from "react-icons/fi";
import { DateRangePicker } from "rsuite";
import { GoEye } from "react-icons/go";
import { MdRemoveRedEye } from "react-icons/md";
import ScreenCastCard from "../../components/common/ScreenCastCard";
import ActivityCard from "../../components/common/ActivityCard";
import ProjectName from "../../components/common/ProjectName";
import {
  setFeedback,
  setLoading,
} from "../../store/redux/actions/feedback.action";
import { useDispatch, useSelector } from "react-redux";
import { fetchScreenCasts } from "../../apis/dashboard";
import {
  fetchRecentScreenSort,
  fetchWorkActivity,
} from "../../store/redux/actions/dashboard.action";
import { ROLES, storageName } from "../../lib/constants";
import {
  convertDateIntoDDMMYYYY,
  convertDateIntoYYYYMMDD,
  convertSecondsIntoTime,
  getDateRangeOptions,
  getFilterStorage,
  getPermissionAccess,
  setFilterStorage,
} from "../../utils/helper";
import ImageModal from "../../components/common/ImageModal";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Tooltip } from "@mui/material";
import { fetcherflag } from "../../App";

const { afterToday } = DateRangePicker;

const WIDGETS = {
  activity: "Activity",
  time: "Time",
  projects: "Projects",
  managers: "Managers",
};

const tabLabel = (role, modules) => {
  return (
    <Box>
      <Typography variant="body1" component="span" className="user-name">
        {role}
      </Typography>{" "}
    </Box>
  );
};

const ranges = getDateRangeOptions();

const dateFormat = {
  weekday: "short",
  month: "short",
  day: "numeric",
  year: "numeric",
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const convertSecondsToHours = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${hours.toString().padStart(2, "0")}h ${minutes
    .toString()
    .padStart(2, "0")}m`;
};

const Dashboard = () => {
  let tempIndex = -1;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboards.dashboards);
  const screenShot = useSelector((state) => state.dashboards.screenSort);

  const WorkedTime = dashboardData?.workedTime?.[0];
  const ActivityData = dashboardData?.activityData;
  const TimeData = dashboardData?.timeData;
  const ProjectData = dashboardData?.projectData;
  const ManagerData = dashboardData?.managerData;
  const UserData = dashboardData?.userData;

  const filterData = getFilterStorage(storageName.dashboard);

  const userData = useSelector((state) => state.user.data);
  const permissions = userData.role_permissions.permissions;
  const permissionsAccess = getPermissionAccess(permissions, "Dashboard");
  if (
    !permissionsAccess.view &&
    !permissionsAccess.add &&
    !permissionsAccess.edit &&
    !permissionsAccess.delete
  ) {
    navigate("/", { replace: true });
  }

  const today = new Date();
  const initialSelectedDates = [today, today];

  const [selectedDates, setSelectedDates] = useState(
    filterData?.date
      ? [new Date(filterData?.date[0]), new Date(filterData?.date[1])]
      : initialSelectedDates
  );

  const [ActivityBoxTabValue, setActivityBoxTabValue] = useState(0);
  const handleActivityTabChange = (event, newValue) => {
    // if (
    //   (isShowActivities && user?.user_role?.id === ROLES.manager) ||
    //   user?.user_role?.id === ROLES.admin
    // ) {
    setActivityBoxTabValue(newValue);
    fetchDashboardDataWidgetWise(WIDGETS.activity, newValue);
    // }
  };

  const [ProjectBoxTabValue, setProjectBoxTabValue] = useState(0);
  const handleProjectTabChange = (event, newValue) => {
    // if (
    //   (isShowProjects && user?.user_role?.id === ROLES.manager) ||
    //   user?.user_role?.id === ROLES.admin
    // ) {
    setProjectBoxTabValue(newValue);
    fetchDashboardDataWidgetWise(WIDGETS.projects, newValue);
    // }
  };

  const [TimeBoxTabValue, setTimeBoxTabValue] = useState(0);
  const handleTimeTabChange = (event, newValue) => {
    setTimeBoxTabValue(newValue);
    fetchDashboardDataWidgetWise(WIDGETS.time, newValue);
  };

  const [ManagerBoxTabValue, setManagerBoxTabValue] = useState(0);
  const handleManagerTabChange = (event, newValue) => {
    setManagerBoxTabValue(newValue);
    fetchDashboardDataWidgetWise(WIDGETS.managers, newValue);
  };

  const user = useSelector((state) => state.user.data);
  const userName = `${user.first_name} ${user.last_name}`;

  const [isScrollable, setIsScrollable] = useState(true);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [isShowActivities, setIsShowActivities] = useState(false);
  const [isShowProjects, setIsShowProjects] = useState(false);
  const [isShowUsers, setIsShowUsers] = useState(false);
  const openImageModalHandler = (index) => {
    setImageIndex(index);
    setImageModalOpen(true);
  };

  const closeImageModalHandler = () => {
    setImageIndex(0);
    setImageModalOpen(false);
  };

  const handleDateRangeChange = (value) => {
    if (value !== null) {
      setSelectedDates(value);
      setFilterStorage(storageName.dashboard, { date: value });
    } else {
      setSelectedDates(initialSelectedDates);
      setFilterStorage(storageName.dashboard, { date: initialSelectedDates });
    }
  };

  const formattedStartDate =
    selectedDates[0] &&
    selectedDates[0].toLocaleDateString("en-US", dateFormat);
  const formattedEndDate =
    selectedDates[1] &&
    selectedDates[1].toLocaleDateString("en-US", dateFormat);

  const formattedStartDateDisplay = convertDateIntoDDMMYYYY(formattedStartDate);
  const formattedEndDateDisplay = convertDateIntoDDMMYYYY(formattedEndDate);

  const shouldDisplayDates =
    (formattedStartDate || formattedEndDate) &&
    (formattedStartDateDisplay !== "Invalid Date" ||
      formattedEndDateDisplay !== "Invalid Date");

  const dateDisplayText = shouldDisplayDates
    ? formattedStartDateDisplay === formattedEndDateDisplay
      ? formattedStartDateDisplay
      : `${formattedStartDateDisplay} - ${formattedEndDateDisplay}`
    : "";

  // screenshort slider
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 11,
    slidesToScroll: 1,
    nextArrow: <FiChevronRight />,
    prevArrow: <FiChevronLeft />,

    draggable: isScrollable,
    swipeToSlide: isScrollable,
    touchMove: isScrollable,

    responsive: [
      {
        breakpoint: 1930,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 1570,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 412,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const ActivityCardArray = [
    {
      ActivityTitle: "Activity",
      ActivityNumber: WorkedTime?.["user_activities.user_overall_activity"]
        ? `${Math.round(
            WorkedTime?.["user_activities.user_overall_activity"]
          )}%`
        : "0%",
      ActivityIcon: <FiActivity />,
    },
    {
      ActivityTitle: "Total Worked",
      ActivityNumber: WorkedTime?.["user_activities.worked_time"]
        ? convertSecondsIntoTime(WorkedTime?.["user_activities.worked_time"])
        : "00:00:00",
      ActivityIcon: <FiClock />,
    },
    {
      ActivityTitle: "Project worked",
      ActivityNumber:
        WorkedTime?.project_worked !== undefined &&
        WorkedTime?.project_worked !== null &&
        WorkedTime?.project_worked < 10
          ? `0${WorkedTime.project_worked}`
          : `${WorkedTime?.project_worked ?? "00"}`,
      ActivityIcon: <FiFolderPlus />,
    },
  ];

  const ActivityBox = (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xxl={12}>
        <Box className="widget-card-container">
          <Typography className="dashboard-widget-title">Activity</Typography>
          {
            <Box className="widget-card">
              <Box
                variant="div"
                component="div"
                className="tab-wrapper widget-listing"
              >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={ActivityBoxTabValue}
                    onChange={handleActivityTabChange}
                    aria-label="basic tabs example"
                    className="tab-area"
                    variant="scrollable"
                  >
                    <Tab
                      label={tabLabel("Best Performance")}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={tabLabel("Worst Performance")}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <TableContainer
                  sx={{
                    //   border: "2px solid green",
                    maxHeight: "375px",
                    minHeight: "375px",
                    height: "100%",
                  }}
                >
                  <Table
                    // sx={{ minWidth: 650 }}
                    stickyHeader
                    aria-label="table"
                    className="widget-listing-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">User Name</TableCell>
                        <TableCell align="left">Activity</TableCell>
                      </TableRow>
                    </TableHead>
                    {
                      <TableBody>
                        {
                          // !isShowActivities &&
                          // user?.user_role?.id === ROLES.manager ? (
                          //   <Tooltip
                          //     title={
                          //       <span style={{ fontSize: "13px" }}>
                          //         View Activities
                          //       </span>
                          //     }
                          //     arrow
                          //   >
                          //     <Box className="eye-icon-container">
                          //       <IconButton
                          //         onClick={() => setIsShowActivities(true)}
                          //         className="eye-icon"
                          //       >
                          //         <MdRemoveRedEye fill="#8cc714" size={30} />
                          //       </IconButton>
                          //     </Box>
                          //   </Tooltip>
                          // ) :
                          // ActivityData &&
                          ActivityData?.length ? (
                            ActivityData?.map((row, index) => {
                              return (
                                <TableRow key={index + "_activity-box"}>
                                  <TableCell>
                                    <ProjectName
                                      ProjectInitial={
                                        row?.[
                                          "user_time_tracking.user.first_name"
                                        ] === null
                                          ? ""
                                          : row?.[
                                              "user_time_tracking.user.first_name"
                                            ]
                                              .charAt(0)
                                              .toUpperCase()
                                      }
                                      ProjectTitle={
                                        row?.[
                                          "user_time_tracking.user.first_name"
                                        ] +
                                        " " +
                                        row?.[
                                          "user_time_tracking.user.last_name"
                                        ]
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {Math.round(row?.user_overall_activity)}%
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow>
                              <TableCell colSpan={2} align="center">
                                No Record Found
                              </TableCell>
                            </TableRow>
                          )
                        }
                      </TableBody>
                    }
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          }
        </Box>
      </Grid>
    </>
  );

  const TimeBox = (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xxl={6}>
        <Box className="widget-card-container">
          <Typography className="dashboard-widget-title">Time</Typography>
          <Box className="widget-card">
            <Box
              variant="div"
              component="div"
              className="tab-wrapper widget-listing"
            >
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={TimeBoxTabValue}
                  onChange={handleTimeTabChange}
                  aria-label="basic tabs example"
                  className="tab-area"
                  variant="scrollable"
                >
                  <Tab label={tabLabel("Most Hrs Worked")} {...a11yProps(0)} />
                  <Tab label={tabLabel("Least Hrs Worked")} {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TableContainer
                sx={{
                  //   border: "2px solid green",
                  maxHeight: "375px",
                  minHeight: "375px",
                  height: "100%",
                }}
              >
                <Table
                  // sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="widget-listing-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">User Name</TableCell>
                      <TableCell align="left">Hours</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {TimeData?.length ? (
                      TimeData?.map((row, index) => {
                        return (
                          <TableRow key={index + "_time-box"}>
                            <TableCell>
                              <ProjectName
                                ProjectInitial={
                                  row?.[
                                    "user_time_tracking.user.first_name"
                                  ] === null
                                    ? ""
                                    : row?.[
                                        "user_time_tracking.user.first_name"
                                      ]
                                        .charAt(0)
                                        .toUpperCase()
                                }
                                ProjectTitle={
                                  row?.["user_time_tracking.user.first_name"] +
                                  " " +
                                  row?.["user_time_tracking.user.last_name"]
                                }
                              />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              {convertSecondsToHours(row?.worked_time)}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          No Record Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
  const ProjectBox = (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xxl={12}>
        <Box
          className="widget-card-container"
          //   sx={{ border: "2px solid blue" }}
        >
          <Typography className="dashboard-widget-title">Projects</Typography>
          {
            <Box className="widget-card">
              <Box
                variant="div"
                component="div"
                className="tab-wrapper widget-listing"
              >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={ProjectBoxTabValue}
                    onChange={handleProjectTabChange}
                    aria-label="basic tabs example"
                    className="tab-area"
                    variant="scrollable"
                  >
                    <Tab label={tabLabel("Most Hrs")} {...a11yProps(0)} />
                    <Tab
                      label={tabLabel("Highest Activity")}
                      {...a11yProps(1)}
                    />
                    <Tab label={tabLabel("Worst Activity")} {...a11yProps(2)} />
                    <Tab label={tabLabel("Most Billable")} {...a11yProps(3)} />
                    <Tab
                      label={tabLabel("Most Non-Billable")}
                      {...a11yProps(4)}
                    />
                  </Tabs>
                </Box>
                <TableContainer
                  style={{
                    maxHeight: "375px",
                    minHeight: "375px",
                    height: "100%",
                  }}
                >
                  <Table
                    // sx={{ minWidth: 650 }}
                    aria-label="table"
                    className="widget-listing-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Project Name</TableCell>
                        {ProjectBoxTabValue === 0 ||
                        ProjectBoxTabValue === 3 ||
                        ProjectBoxTabValue === 4 ? (
                          <TableCell align="left">Hours</TableCell>
                        ) : (
                          <TableCell align="left">Activity</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        // !isShowProjects &&
                        // user?.user_role?.id === ROLES.manager ?
                        // (
                        //   <Tooltip
                        //     title={
                        //       <span style={{ fontSize: "13px" }}>
                        //         View Projects
                        //       </span>
                        //     }
                        //     arrow
                        //   >
                        //     <Box className="eye-icon-container">
                        //       <IconButton
                        //         onClick={() => {
                        //           setIsShowProjects(true);
                        //         }}
                        //         className="eye-icon"
                        //       >
                        //         <MdRemoveRedEye fill="#8cc714" size={30} />
                        //       </IconButton>
                        //     </Box>
                        //   </Tooltip>
                        // ) :
                        ProjectData?.length ? (
                          ProjectData?.map((row, index) => {
                            return (
                              <TableRow key={index + "_project-box"}>
                                <TableCell>
                                  <ProjectName
                                    ProjectInitial={
                                      row?.[
                                        "user_time_tracking.project.title"
                                      ] === null
                                        ? ""
                                        : row?.[
                                            "user_time_tracking.project.title"
                                          ]
                                            .charAt(0)
                                            .toUpperCase()
                                    }
                                    ProjectTitle={
                                      row?.["user_time_tracking.project.title"]
                                    }
                                  />
                                </TableCell>
                                {ProjectBoxTabValue === 0 ? (
                                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                                    {convertSecondsToHours(row?.worked_time)}
                                  </TableCell>
                                ) : ProjectBoxTabValue === 3 ? (
                                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                                    {convertSecondsToHours(
                                      row?.total_billable_time
                                    )}
                                  </TableCell>
                                ) : ProjectBoxTabValue === 4 ? (
                                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                                    {convertSecondsToHours(
                                      row?.total_unbillable_time
                                    )}
                                  </TableCell>
                                ) : (
                                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                                    {Math.round(row?.user_overall_activity)}%
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={3} align="center">
                              No Record Found
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          }
        </Box>
      </Grid>
    </>
  );

  const UserBox = (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xxl={12}>
        <Box
          className="widget-card-container"
          //   sx={{ border: "2px solid green" }}
        >
          <Typography className="dashboard-widget-title">Users</Typography>
          <Box className="widget-card">
            <Box
              variant="div"
              component="div"
              className="tab-wrapper widget-listing"
            >
              <TableContainer className="user-table-container">
                <Table // sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="widget-listing-table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Project Name</TableCell>
                      <TableCell align="left">User Name</TableCell>
                      <TableCell scope="row">To-do</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {UserData?.length ? (
                      UserData?.map((row, index) => {
                        return (
                          <>
                            <TableRow key={index + "user-box"}>
                              <TableCell colSpan={3}>
                                <ProjectName
                                  ProjectInitial={
                                    row?.["project.title"] === null
                                      ? ""
                                      : row?.["project.title"]
                                          .charAt(0)
                                          .toUpperCase()
                                  }
                                  ProjectTitle={row?.["project.title"]}
                                />
                              </TableCell>
                            </TableRow>
                            {row?.data?.map((items, index2) => {
                              return (
                                <TableRow key={index2 + "_inside-user-box"}>
                                  <TableCell
                                    sx={{ border: "none" }}
                                  ></TableCell>
                                  <TableCell>
                                    <ProjectName
                                      ProjectInitial={
                                        items["user.first_name"]
                                          ? items["user.first_name"]
                                              .charAt(0)
                                              .toUpperCase()
                                          : ""
                                      }
                                      ProjectTitle={
                                        items["user.first_name"] +
                                        " " +
                                        items["user.last_name"]
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {items["todo.description"]?.length > 35
                                      ? items["todo.description"]?.substr(
                                          0,
                                          40
                                        ) + "..."
                                      : items["todo.description"]}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          No Record Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );

  const ManagerBox = (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xxl={12}>
        <Box className="widget-card-container">
          <Typography className="dashboard-widget-title">Managers</Typography>
          <Box className="widget-card">
            <Box
              variant="div"
              component="div"
              className="tab-wrapper widget-listing"
            >
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={ManagerBoxTabValue}
                  onChange={handleManagerTabChange}
                  aria-label="basic tabs example"
                  className="tab-area"
                  variant="scrollable"
                >
                  <Tab label={tabLabel("Highest Activity")} {...a11yProps(0)} />
                  <Tab label={tabLabel("Most Billable")} {...a11yProps(1)} />
                  <Tab
                    label={tabLabel("Most Non-Billable")}
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <TableContainer
                sx={{
                  //   border: "2px solid green",
                  maxHeight: "375px",
                  minHeight: "375px",
                  height: "100%",
                }}
              >
                <Table
                  // sx={{ minWidth: 470 }}
                  aria-label="table"
                  className="widget-listing-table-manager"
                  // sx={{marginBottom: '42px'}}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Manager Name</TableCell>
                      <TableCell align="left">Activity</TableCell>
                      <TableCell align="left">Hours</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ManagerData?.length ? (
                      ManagerData?.map((row, index) => {
                        return (
                          <TableRow key={index + "_manager-box"}>
                            <TableCell>
                              <ProjectName
                                ProjectInitial={
                                  row?.first_name === null
                                    ? ""
                                    : row?.first_name?.charAt(0).toUpperCase()
                                }
                                ProjectTitle={
                                  row?.first_name + " " + row?.last_name
                                }
                              />
                            </TableCell>
                            <TableCell>
                              {Math.round(
                                row?.mangerData[0].user_overall_activity
                              )}
                              %
                            </TableCell>
                            {ManagerBoxTabValue === 0 ? (
                              <TableCell sx={{ whiteSpace: "nowrap" }}>
                                {convertSecondsToHours(
                                  row?.mangerData[0][
                                    "user_activities.worked_time"
                                  ]
                                )}
                              </TableCell>
                            ) : ManagerBoxTabValue === 1 ? (
                              <TableCell sx={{ whiteSpace: "nowrap" }}>
                                {convertSecondsToHours(
                                  row?.mangerData[0][
                                    "user_activities.total_billable_time"
                                  ]
                                )}
                              </TableCell>
                            ) : (
                              <TableCell sx={{ whiteSpace: "nowrap" }}>
                                {convertSecondsToHours(
                                  row?.mangerData[0][
                                    "user_activities.total_unbillable_time"
                                  ]
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          No Record Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );

  const fetchDashboardData = useCallback(
    async (startDate, endDate) => {
      try {
        const StartDate = formattedStartDate
          ? convertDateIntoYYYYMMDD(formattedStartDate)
          : convertDateIntoYYYYMMDD(new Date());
        const EndDate = formattedEndDate
          ? convertDateIntoYYYYMMDD(formattedEndDate)
          : convertDateIntoYYYYMMDD(new Date());
        let params = {
          startDate: StartDate,
          endDate: EndDate,
          mainData: 1,
        };
        if (user?.user_role?.id === ROLES.admin) {
          params.activity = ActivityBoxTabValue === 0 ? "Best" : "Worst";

          params.time = TimeBoxTabValue === 0 ? "Most" : "Least";

          params.project =
            ProjectBoxTabValue === 0
              ? "mostHrs"
              : ProjectBoxTabValue === 1
              ? "highActivity"
              : ProjectBoxTabValue === 2
              ? "worstActivity"
              : ProjectBoxTabValue === 3
              ? "billable"
              : "non-billable";

          params.manager =
            ManagerBoxTabValue === 0
              ? "highActivity"
              : ManagerBoxTabValue === 1
              ? "billable"
              : "non-billable";
        } else if (user?.user_role?.id === ROLES.manager) {
          // if (isShowActivities) {
          //   params = {
          //     startDate: StartDate,
          //     endDate: EndDate,
          //     activity: ActivityBoxTabValue === 0 ? "Best" : "Worst",
          //   };
          // }
          // else if (isShowProjects) {
          //   params = {
          //     startDate: StartDate,
          //     endDate: EndDate,
          //     project:
          //       ProjectBoxTabValue === 0
          //         ? "mostHrs"
          //         : ProjectBoxTabValue === 1
          //         ? "highActivity"
          //         : ProjectBoxTabValue === 2
          //         ? "worstActivity"
          //         : ProjectBoxTabValue === 3
          //         ? "billable"
          //         : "non-billable",
          //   };
          // }
          params.activity = ActivityBoxTabValue === 0 ? "Best" : "Worst";
          params.project =
            ProjectBoxTabValue === 0
              ? "mostHrs"
              : ProjectBoxTabValue === 1
              ? "highActivity"
              : ProjectBoxTabValue === 2
              ? "worstActivity"
              : ProjectBoxTabValue === 3
              ? "billable"
              : "non-billable";
        }

        dispatch(fetchWorkActivity(params));
      } catch (error) {
        const payload = {
          code: error.status,
          status: "fail",
          message: error.message || "Something went wrong. Try again later!",
        };
        if (payload.code !== 502) {
          dispatch(setFeedback(payload));
        }
      }
    },
    [
      dispatch,
      formattedEndDate,
      formattedStartDate,
      // isShowActivities,
      // isShowProjects,
    ]
  );

  const fetchDashboardDataWidgetWise = async (widget, widgetValue) => {
    try {
      const StartDate = formattedStartDate
        ? convertDateIntoYYYYMMDD(formattedStartDate)
        : convertDateIntoYYYYMMDD(new Date());
      const EndDate = formattedEndDate
        ? convertDateIntoYYYYMMDD(formattedEndDate)
        : convertDateIntoYYYYMMDD(new Date());

      const params = {
        startDate: StartDate,
        endDate: EndDate,
      };

      if (widget === WIDGETS.activity) {
        params.activity = widgetValue === 0 ? "Best" : "Worst";
      } else if (widget === WIDGETS.time) {
        params.time = widgetValue === 0 ? "Most" : "Least";
      } else if (widget === WIDGETS.projects) {
        params.project =
          widgetValue === 0
            ? "mostHrs"
            : widgetValue === 1
            ? "highActivity"
            : widgetValue === 2
            ? "worstActivity"
            : widgetValue === 3
            ? "billable"
            : "non-billable";
      } else if (widget === WIDGETS.managers) {
        params.manager =
          widgetValue === 0
            ? "highActivity"
            : widgetValue === 1
            ? "billable"
            : "non-billable";
      }

      dispatch(fetchWorkActivity(params));
    } catch (error) {
      const payload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong. Try again later!",
      };
      if (payload.code !== 502) {
        dispatch(setFeedback(payload));
      }
    }
  };

  const fetchScreenCastsHandler = useCallback(
    async (startDate, endDate) => {
      try {
        const formattedStartDate = startDate
          ? convertDateIntoYYYYMMDD(startDate)
          : convertDateIntoYYYYMMDD(new Date());
        const formattedEndDate = endDate
          ? convertDateIntoYYYYMMDD(endDate)
          : convertDateIntoYYYYMMDD(new Date());

        const params = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
        const response = await fetchScreenCasts(params);
        if (response.data.status === 200) {
          const data = response.data.data;
          dispatch(fetchRecentScreenSort(data));
        } else if (response.data.status === 404) {
          dispatch(fetchRecentScreenSort([]));
        } else {
          const errPayload = {
            code: response.data.status,
            status: "fail",
            message: response.data.message,
          };
          if (errPayload.code !== 502) {
            dispatch(setFeedback(errPayload));
          }
        }
      } catch (error) {
        dispatch(setLoading(false));
        const errPayload = {
          code: error.status,
          status: "fail",
          message: error.message || "Something went wrong.",
        };
        if (errPayload.code !== 502) {
          dispatch(setFeedback(errPayload));
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (user?.user_role?.id === ROLES.employee) {
      fetchScreenCastsHandler(formattedStartDate, formattedEndDate);
    }
  }, [formattedEndDate, formattedStartDate, user, fetchScreenCastsHandler]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);
  return (
    <>
      {/* top page action with text */}

      <Box className="content-header dashboard-header">
        <Box className="calendar-ui">
          <DateRangePicker
            ranges={ranges}
            onChange={handleDateRangeChange}
            value={selectedDates}
            shouldDisableDate={afterToday()}
            format="dd-MM-yyyy"
          />
        </Box>
        <Box className="content-header-right">
          <Typography paragraph className="text-grey mb-0 date-visibility">
            {dateDisplayText}
          </Typography>
        </Box>
      </Box>

      {/* activity card */}
      <Box className="activity-card-wrapper mb-24">
        <Grid container spacing={3}>
          {ActivityCardArray?.map((ActivityCardList, index) => (
            <ActivityCard
              key={index + "_activity-card"}
              ActivityTitle={ActivityCardList.ActivityTitle}
              ActivityNumber={ActivityCardList.ActivityNumber}
              ActivityIcon={ActivityCardList.ActivityIcon}
            />
          ))}
        </Grid>
      </Box>

      {/* Screen shot slider for user */}
      {user?.user_role?.id === ROLES.employee && (
        <Box className="card screencast-slider mb-24">
          <Grid
            container
            spacing={2}
            className="card-title-bar"
            alignItems={"center"}
          >
            <Grid item xs={6}>
              <Typography
                variant="h6"
                component="h6"
                className="text-black card-title"
              >
                Recent Screencasts
              </Typography>
            </Grid>
          </Grid>
          {!screenShot?.length ? (
            <Box sx={{ textAlign: "center" }}>
              <Typography className="text-grey">
                No Recent Screenshots Found
              </Typography>
            </Box>
          ) : (
            <Slider {...settings}>
              {screenShot[0]?.map((ScreenCastCardList, index) => {
                if (
                  ScreenCastCardList?.drive_primary ||
                  ScreenCastCardList?.screenshot_primary ||
                  ScreenCastCardList?.drive_secondary ||
                  ScreenCastCardList?.screenshot_secondary
                ) {
                  let currentCard = [];
                  if (
                    ScreenCastCardList?.drive_primary ||
                    ScreenCastCardList?.screenshot_primary
                  ) {
                    tempIndex = tempIndex + 1;
                    currentCard.push(tempIndex);
                  }
                  if (
                    ScreenCastCardList?.drive_secondary ||
                    ScreenCastCardList?.screenshot_secondary
                  ) {
                    tempIndex = tempIndex + 1;
                    currentCard.push(tempIndex);
                  }
                  //}
                  return (
                    <ScreenCastCard
                      key={index + "dashboard_slider"}
                      flag={0}
                      ScreenCastImage={[
                        {
                          screen: "Screen 1",
                          image:
                            ScreenCastCardList?.drive_primary ||
                            ScreenCastCardList?.screenshot_primary,
                          isDriveImage: ScreenCastCardList?.drive_primary
                            ? true
                            : false,
                        },
                        {
                          screen: "Screen 2",
                          image:
                            ScreenCastCardList?.drive_secondary ||
                            ScreenCastCardList?.screenshot_secondary,
                          isDriveImage: ScreenCastCardList?.drive_secondary
                            ? true
                            : false,
                        },
                      ]}
                      ScreenCastDate={ScreenCastCardList?.end_date}
                      setIsScrollable={setIsScrollable}
                      Slot={{
                        start_date: ScreenCastCardList.start_date,
                        end_date: ScreenCastCardList.end_date,
                      }}
                      activity={ScreenCastCardList?.overall_activity}
                      ProjectTitle={
                        ScreenCastCardList["user_time_tracking.project.title"]
                      }
                      openImageModalHandler={openImageModalHandler}
                      tempIndex={currentCard[0]}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </Slider>
          )}
        </Box>
      )}

      {imageModalOpen && (
        <ImageModal
          imageModalOpen={imageModalOpen}
          closeImageModalHandler={closeImageModalHandler}
          finalModalArray={screenShot[0]}
          imageIndex={imageIndex}
          userName={userName}
        />
      )}

      <Grid container spacing={3}>
        {user?.user_role?.id === ROLES.viewer && UserBox}
        {user?.user_role?.id === ROLES.admin && (
          <>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={3} style={{ flex: "1" }}>
                <Grid item lg={6} xs={12}>
                  {ActivityBox}
                </Grid>
                <Grid item lg={6} xs={12}>
                  {TimeBox}
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={3} style={{ flex: "1" }}>
                <Grid item lg={6} xs={12}>
                  {ProjectBox}
                </Grid>
                <Grid item lg={6} xs={12}>
                  {ManagerBox}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {user?.user_role?.id === ROLES.manager && (
          <>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              {UserBox}
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Grid container spacing={3} style={{ flex: "1" }}>
                <Grid item xs={12}>
                  {ActivityBox}
                </Grid>
                <Grid item xs={12}>
                  {ProjectBox}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Dashboard;
