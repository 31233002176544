// auth APIs

export const USER_LOGIN_API = "/auth/login";
export const USER_LOGOUT_API = "/auth/logout";
export const FORGOT_PASSWORD_API = "/auth/forgot-password";
// export const VERIFY_TOKEN_API = "/auth/forgot-password/verify";
export const VERIFY_TOKEN_API = "/user-verify";
export const FORGOT_PASSWORD_RECOVERY_API = "/auth/reset-password";
export const CHANGE_PASSWORD_API = "/users/update-password";
export const FETCH_LOGGED_IN_USER_DATA = "/users/profile";
export const UPDATE_ACCOUNT_DETAILS_API = "/users";
export const UPDATE_EMAIL_API = "/users/update-email";

//employee APIs
export const FETCH_ALL_EMPLOYEES_API = "/users";
export const FETCH_EMPLOYEES_BY_ID_API = "/users";
export const FETCH_USER_ROLES_API = "/userRole/roles";
export const FILTER_EMPLOYEE_API = "/users/filter";
export const DELETE_EMPLOYEE_API = "/users";
export const UPDATE_EMPLOYEE_API = "/users";
export const SEARCH_EMPLOYEES_API = "/users";
export const CREATE_EMPLOYEE_BY_EMAIL_API = "/users/send-email";
export const RESET_PASSWORD_API = "/users/reset-password";
export const PROJECT_BY_USER_FOR_UPDATE_API = "/users/id/projects";
export const UPDATE_PROJECT_BY_USER_API = "/users/id/projects";
export const FETCH_ALL_EMPLOYEES_INVITE_BY_MAIL_API = "/users/invite-by-mail";
export const SEARCH_EMPLOYEES_INVITE_API = "/users/invite-by-mail";
export const RESEND_INVITE = "/users/resend-mail/invite-by-mail";
export const INVITE_USER_UPDATE_API = "/users/update-user/invite-by-mail";
// export const VERIFY_INVITE_TOKEN_API = '/user-verify/verify';

// project APIs

export const FETCH_PROJECT_LIST_API = "/projects";
export const LEAVE_PROJECT_API = "/users/id/projects";
export const DELETE_PROJECT_API = "/projects";
export const CREATE_PROJECT_API = "/projects";
export const FETCH_SINGLE_PROJECT_API = "/projects";
export const UPDATE_PROJECT_API = "/projects";
export const FETCH_MEMBERS_API = "/users/roles";

//to-dos APIs

export const FETCH_TODOS_BY_PROJECT_ID_API = "/todos";
export const FETCH_USERS_BY_PROJECTS_API = "/projects/id/users";
export const CREATE_TODO_API = "/todos";
export const FETCH_TODO_BY_ID_API = "/todos";
export const UPDATE_TODO_API = "/todos";
export const DELETE_TODO_API = "/todos";

// time sheet APIs

export const FETCH_TIME_SHEET_API = "/timesheets";
export const FETCH_WINDOW_ACTIVITY = "/timesheets/window-acitivity-details";
export const CREATE_TIME_SHEET_API = "/timesheets";
export const EDIT_TIME_SHEET_API = "/timesheets";
export const DELETE_TIME_SHEET_API = "/timesheets";
export const GENERATE_TIME_SHEET_PDF_API = "/timesheets/generate-pdf";

export const STORE_TIME_SHEET_FILTER_API = "/timesheets/filter";
export const GET_TIME_LINE_API = "/users/id/timesheets";
export const IS_USER_UNDER_ASSOCIATE_MANAGER = "/users/associate";

//reports APIs

export const FETCH_REPORTS_API = "/reports/activity";
export const FETCH_WINDOWREPORTS_API = "/reports/window-activity";
export const FETCH_FILTERS_API = "/filters";
export const CREATE_FILTER_API = "/filters";
export const FETCH_FILTER_BY_ID_API = "/filters";
export const UPDATE_FILTER_API = "/filters";
export const DELETE_FILTER_API = "/filters";
export const FETCH_SCREENSHOT_REPORT_API = "/reports/screenshot";
export const FETCH_BILLABLE_NONBILLABLE_REPORT_API = "/reports/billable";
export const FETCH_BILLABLE_NONBILLABLE_TYPEWISE_REPORT_API =
  "/reports/project-billable";
export const FETCH_BILLABLE_NONBILLABLE_PROJECTWISE_USERS_API =
  "/reports/project-billable/users";
export const FETCH_INCOMPLETE_TIME_SHEET_API = "/reports/incomplete-timesheets";

//dashboard APIs

export const FETCH_WORK_TODAY_ACTIVITY_API = "/user-activity";
export const FETCH_TODO_PROJECT_API = "/dashboard/projects-todos";
export const FETCH_SCREEN_CASTS_API = "/dashboard/screenshots";
export const GET_SINGLE_IMAGE_API = "/screen-shot/image/:";

// Release note APIs

export const CREATE_RELEASE_NOTE_API = "/release-notes";
export const FETCH_RELEASE_NOTE_API = "/release-notes";
export const DELETE_RELEASE_NOTE_API = "/release-notes";
export const UPDATE_RELEASE_NOTE_API = "/release-notes";

// settings APIs

export const FETCH_GLOBAL_SETTINGS_API = "/settings";
export const UPDATE_GLOBAL_SETTINGS_API = "/settings";
export const FETCH_G_DRIVE_LISTING = "/g-drive";

//APPLICATION PERMISSIONS
export const FETCH_APPLICATION_PERMISSION = "/users/applications";
//const roles
// export const ROLES = {
//     admin: 'admin',
//     manager: 'manager',
//     employee: 'user',
//     viewer: 'viewer'
// }

export const ROLES = {
  admin: 1,
  manager: 3,
  employee: 2,
  viewer: 4,
};

export const storageName = {
  timeTrackerCredential: "timeTrackerCredential",
  dashboard: "dashboardFilter",
  timeSheet: "timeSheetFilter",
  todo: "todoFilter",
  timeActivity: "timeActivityFilter",
  windowActivity: "windowActivityFilter",
  screenShot: "screenshotFilter",
  user: "userFilter",
  incompleteTimesheets: "incompleteTimesheets",
  billableNonBillable: "billableNonBillableFilter",
  billableNonBillableTypewise: "billableNonBillableTypewiseFilter",
};

export const DATE_RANGE_TYPE = {
  today: "Today",
  yesterday: "Yesterday",
  thisWeek: "This week",
  lastWeek: "Last week",
  last7Days: "Last 7 Days",
  thisMonth: "This Month",
  lastMonth: "Last Month",
  last30Days: "Last 30 Days",
  custom: "Custom",
};
