import React, { useLayoutEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import { FiPlus } from 'react-icons/fi';
import {  styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { fetchTimeSheetByDate } from '../../apis/timeSheet';
import { setFeedback } from '../../store/redux/actions/feedback.action';
import { convertTimeInto12HoursFormat } from '../../utils/helper';
import Assignee from '../../components/common/Assignee';
import ProjectName from '../../components/common/ProjectName';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3), 
    }
}));

const convertDDMMYYYY = (dateValue) => {
    const utcDate = new Date(dateValue);
    const date = utcDate.getUTCDate();
    const month = utcDate.getUTCMonth();
    const year = utcDate.getUTCFullYear();
    const convertedDate = `${date}-${month + 1}-${year}`;
    return convertedDate;
}

const DeleteTime = ({ deleteTimeModalOpen, deleteModalClose, deleteTimeSheetHandler, timeSheetId }) => {
    const dispatch = useDispatch();
    const [reason, setReason] = useState("");
    const [validationErrors, setValidationErrors] = useState("");
    const [timeSheetData, setTimeSheetData] = useState([]);
    
    useLayoutEffect(() => {
        const getSingleTimeSheetData = async() =>{
            try {
                const params = { timesheet_id: timeSheetId };
                const response = await fetchTimeSheetByDate(params);
                if(response.data.status === 200){
                    setTimeSheetData(response.data.data[0]);
                } else if(response.data.status === 404){
                    setTimeSheetData([]);
                } else {
                    const errPayload = { code: response.data.status, status: 'fail', 
                    message:response.data.message || "Something went wrong." }
                    dispatch(setFeedback(errPayload));  
                }
            } catch (error) {
                const errPayload = { 
                    code: error.status, 
                    status: 'fail', 
                    message:error.message || "Something went wrong." 
                };
                dispatch(setFeedback(errPayload));  
            }
        }
        getSingleTimeSheetData();
    }, [dispatch, timeSheetId])

    const cancelHandler = () => {
        setReason("")
        deleteModalClose()
    }
    const changeReasonHandler = (event) => {
        setReason(event.target.value);
        if(event.target.value === ""){
            setValidationErrors('Please enter a Reason.');
            return;
        }
        setValidationErrors('');
    };

    const onSubmitHandler = () => {
        if(reason.trim() === ""){
            setValidationErrors('Please enter a Reason.');
            return;
        }
        deleteTimeSheetHandler(reason);
    }

  return (
    <>
    {/* Delete time modal */}
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={deleteTimeModalOpen}
            onClose={deleteModalClose}
            closeAfterTransition
            slotProps={{
            backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={deleteTimeModalOpen}>
                <Box sx={styled} className='modal-wrapper modal-bg'>
                    <Typography variant="h6" component="h6" className='text-black modal-title' >
                    Delete Time
                    </Typography>
                    <Box className='modal-close' onClick={deleteModalClose}>
                        <FiPlus/>
                    </Box>
                    <Box className='modal-body'>
                    <Box className='confirmation-text' sx={{marginBottom:'20px'}}>
                        <Grid container spacing={2}>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                    User
                                </Typography>
                                <Assignee 
                                    AssigneeInitial={timeSheetData?.user?.first_name?.charAt(0) || ""} 
                                    AssigneeTitle={`${timeSheetData?.user?.first_name || ""} ${timeSheetData?.user?.last_name || "-"}`} 
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                    Project
                                </Typography>
                                <ProjectName
                                    ProjectInitial={timeSheetData?.project?.title[0].toUpperCase() || ""}
                                    ProjectTitle={timeSheetData?.project?.title || "-"}
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                    Date
                                </Typography>
                                <Typography variant="body2" component="span" className='text-grey'>
                                    {timeSheetData.start_date ? convertDDMMYYYY(timeSheetData.start_date) : "-"}
                                </Typography>                                
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                    Time Span
                                </Typography>
                                <Typography variant="body2" component="span" className='text-grey'>
                                    {
                                    timeSheetData.start_date ? convertTimeInto12HoursFormat(timeSheetData.start_date) : "-" } 
                                    <b className='text-black'>To</b> {" "}
                                    {timeSheetData.end_date ? convertTimeInto12HoursFormat(timeSheetData.end_date) : "-" }
                                </Typography>                                
                            </Grid>
                            
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                    To-Do
                                </Typography>
                                <Typography variant="body2" component="span" className='text-grey'>
                                {timeSheetData?.todo?.description}
                                </Typography>                                
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="body2" component="span" className='text-black confirmation-title'>
                                    Memo
                                </Typography>
                                <Typography variant="body2" component="span" className='text-grey'>
                                    {timeSheetData?.memo}
                                </Typography>                                
                            </Grid>
                        </Grid>
                    </Box>
                    <FormGroup className='form-field'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl variant="standard" className='form-control'>
                                    <Typography variant="body2" component="span" className='text-black input-label'>
                                    Reason *
                                    </Typography>
                                    <BootstrapInput
                                        multiline
                                        value={reason}
                                        onChange={changeReasonHandler}
                                        className={`input-field ${validationErrors && 'border-error'}`}
                                        placeholder="Why are you deleting this time entry? ( ex: Forgot to stop timer )"
                                    />
                                    {validationErrors && (
                                        <span className="error-text">
                                            {validationErrors}
                                        </span>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </FormGroup>
                    </Box>
                    <Box className='modal-footer'>
                        <Grid container spacing={3}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button className='btn btn-tertiary' onClick={onSubmitHandler}>Delete</Button>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button className='btn btn-secondary' onClick={cancelHandler}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Fade>
      </Modal>
    </>
  )
}

export default DeleteTime