import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputBase from '@mui/material/InputBase';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { FiEyeOff, FiEye } from "react-icons/fi";
import authenticationImage from '../../assets/authentication/loginImg.png';
import SiteLogo from '../../assets/authentication/siteLogo.png';
import { useNavigate } from "react-router-dom";
import {checkUserLoginCredentials} from '../../apis/login'
import { useDispatch } from 'react-redux';
import { failedUserLogin, successUserLogin } from '../../store/redux/actions/user.action';
import { setFeedback, setLoading } from '../../store/redux/actions/feedback.action';
import { getFilterStorage, setFilterStorage, removeFilterStorage } from '../../utils/helper';
import { storageName } from '../../lib/constants';
import { encrypt, decrypt } from 'n-krypta';
import { storeTimeSheetFilter } from '../../store/redux/actions/timesheet.action';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3), 
  }
}));

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [EmailErrorMessage, setEmailErrorMessage] = useState("");
  const [PasswordErrorMessage, setPasswordErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const [isRememberMe, setIsRememberMe] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changedHandler = (e) => {
    const name = e.target.name.trim();
    const value = e.target.value.trimLeft();
    if(value === ""){
      if(name === "email")
        setEmailErrorMessage("Please enter your email.");
      if(name === "password")
        setPasswordErrorMessage("Please enter your password.") 
    } else {
      if(name === "email")
        setEmailErrorMessage("");
      if(name === "password")
        setPasswordErrorMessage("");  
    }

    // if(!emailRegex.test(email)){
    //   setEmailErrorMessage("Please enter valid email address.");
    // }

    if(name === "email")
      setEmail(value);
    if(name === "password")
      setPassword(value);
  }

  const blurHandler = (e) => {
    const name = e.target.name.trim();
    const value = e.target.value.trim();
    if(value === ""){
      if(name === "email")
        setEmailErrorMessage("Please enter your email.");
      if(name === "password")
        setPasswordErrorMessage("Please enter your password.") 
    } else {
      if(name === "email")
        setEmailErrorMessage("");
      if(name === "password")
        setPasswordErrorMessage("");  
    }
    if(!emailRegex.test(email)){
      setEmailErrorMessage("Please enter valid email address.");
    }
  }

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setEmailErrorMessage("");
    setPasswordErrorMessage("");

    if(!email.trim()){
      setEmailErrorMessage("Please enter your email.");
      return;
    } 
    else if(!emailRegex.test(email.trim())){
      setEmailErrorMessage("Please enter valid email address.");
      return;
    }
    else if(!password.trim()){
      setPasswordErrorMessage("Please enter your password.")
      return;
    }

    try {
      dispatch(setLoading(true));
      const payload = { email, password };
      const response = await checkUserLoginCredentials(payload); 
      dispatch(setLoading(false));
      
      if(response.data.status === 200){
        const userData = { ...response.data.data.user, role_permissions: response.data.data.role_permissions };
        dispatch(successUserLogin(userData));
        dispatch(storeTimeSheetFilter(response.data.data.timesheet_filter));
        // setAuthToken(response.data.data.access_token);
        // setLoggedInUserId(response.data.data.user.id);

        if(isRememberMe){
          const encryptedPassword = encrypt(password, SECRET_KEY);
          setFilterStorage(storageName.timeTrackerCredential, { email, password: encryptedPassword });
        } else {
          removeFilterStorage(storageName.timeTrackerCredential);
        }

        navigate('/', { replace:true });
      } else {
        const payload = { code: response.data.status, status:"fail", 
        message: response.data.message || "Something went wrong. Try again later!" };
        dispatch(failedUserLogin());
        dispatch(setFeedback(payload));
      }
    } catch (error) {
      dispatch(setLoading(false));
      const payload = { code: error.status, status:"fail", 
      message: error.message || "Something went wrong. Try again later!" };
      dispatch(failedUserLogin());
      dispatch(setFeedback(payload));
    }
  }

  useEffect(() => {
    const userCredential = getFilterStorage(storageName.timeTrackerCredential);
    if(userCredential){
      const decryptedPassword = decrypt(userCredential.password, SECRET_KEY);
  
      setEmail(userCredential?.email);
      setPassword(decryptedPassword);
      setIsRememberMe(true);
    }
  }, [])
  

  return (
    <Box className='authentication'>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box className='authentication-img'>
            <img src={authenticationImage} alt='authentication-img' />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className='auth'>

          <Box className='authenication-form'>
            <form onSubmit={onSubmitHandler}>  
              <Grid container>
                <Grid item xs={12}>
                  <Box className='site-logo'>
                    <img src={SiteLogo} alt='Site logo' />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h1" component="h1" className='text-black auth-title'>
                    Sign In
                  </Typography>
                  <Typography variant="body1" component="span" className='text-grey auth-para'>
                    Time Management refers to managing time effectively so that the right time is allocated to the right activity.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  
                  <FormGroup className='form-field'>
                    <FormControl variant="standard" className='form-control bg-transparent'>
                      <Typography variant="body2" component="span" className='text-black input-label'>
                        Email
                      </Typography>
                      <BootstrapInput
                        autoFocus
                        name="email" 
                        placeholder="Enter email address" 
                        className={!EmailErrorMessage ? 'input-field' : 'border-error'}
                        value={email} 
                        onBlur={blurHandler}
                        onChange={changedHandler}
                      />
                      {EmailErrorMessage && <p className="error-text">&nbsp;{EmailErrorMessage}</p>}
                    </FormControl>
                    <FormControl variant="standard" className='form-control bg-transparent'>
                      <Typography variant="body2" component="span" className='text-black input-label'>
                        Password
                      </Typography>
                      <BootstrapInput 
                        name="password"
                        placeholder="********" 
                        className={!PasswordErrorMessage ? 'input-field' : 'border-error'}
                        value={password} 
                        onBlur={blurHandler}
                        onChange={changedHandler}
                        type={showPassword ? 'text' : 'password'}
                        autoComplete='off'
                        endAdornment={
                          <InputAdornment position="end" className='end-input-icon text-grey'>
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <FiEyeOff /> : <FiEye />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {PasswordErrorMessage && <p className="error-text">&nbsp;{PasswordErrorMessage}</p>}
                    </FormControl>   
                    <FormControl className='form-control remember-forgot  bg-transparent'>
                      <FormControlLabel 
                        control={
                          <Checkbox checked={isRememberMe} onClick={(e) => {setIsRememberMe(e.target.checked)}} />
                        } 
                        label="Remember Me" 
                        className='checkbox-field'
                      />
                      {/* <Box onClick={()=>navigate("/forgot-password")} className='forgot-password'>Forgot password?</Box> */}
                      <a href={process.env.REACT_APP_FORGOT_PASSWORD_URL} target='_blank' rel="noreferrer"
                      className='forgot-password'>Forgot password?</a>
                    </FormControl>
                  </FormGroup>              
                </Grid>

                <Grid item xs={12}>
                  <Button type='submit' variant="contained" 
                    className='btn btn-primary'>
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </form> 
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Login;
