import React from "react";
import { getAuthToken } from "../../utils/auth";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROLES } from "../../lib/constants";
import LayoutProvider from "../layout";

const ProtectedRoute = ({ children, module, path }) => {
  const token = getAuthToken();
  const userDetails = useSelector((state) => state.user.data);
  if (!token) {
    return <Navigate to="/login" />;
  }
  const AllPermission = userDetails?.role_permissions?.permissions;
  const Permissions = AllPermission?.find((row) => row.module_name === module);

  if (Permissions?.permissions === "none") {
    return <Navigate to={"/"} replace={true} />;
  }
  if (
    path === "/incomplete-timesheets" ||
    path === "/billable-nonbillable" ||
    path === "/project-yields"
  ) {
    if (
      (userDetails.user_role.id === ROLES.employee &&
        !userDetails.is_assistant_manager) ||
      userDetails.user_role.id === ROLES.viewer
    ) {
      return <Navigate to={"/"} replace={true} />;
    }
  }

  return <LayoutProvider>{children}</LayoutProvider>;
};

export default ProtectedRoute;
