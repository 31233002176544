import {
  ADD_FILTER_SUCCESS,
  DELETE_FILTER_SUCCESS,
  STORE_BILLABLE_NONBILLABLE_PROJECTWISE_USERS,
  STORE_BILLABLE_NONBILLABLE_REPORTS,
  STORE_BILLABLE_TYPEWISE_NONBILLABLE_REPORTS,
  STORE_FILTERS,
  STORE_INCOMPLETE_TIME_SHEET,
  STORE_REPORTS,
  STORE_SCREENSHOT_REPORTS,
  STORE_WINDOWREPORTS,
  UPDATE_FILTER_SUCCESS,
} from "../actionTypes";

const initialState = {
  reports: [],
  windowReports: [],
  filters: [],
  screenShotReport: [],
  incompleteTimesheetReport: {
    totalCount: 0,
    data: [],
  },
  billableNonbillableReport: [],
  billableNonbillableTypewiseReport: [],
  billableNonbillableProjectwiseUsers: [],
};

const ReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
    case STORE_WINDOWREPORTS:
      return {
        ...state,
        windowReports: action.payload,
      };

    case STORE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case ADD_FILTER_SUCCESS:
      return {
        ...state,
        filters: [action.payload, ...state.filters],
      };

    case UPDATE_FILTER_SUCCESS:
      const updatedFilter = state.filters.map((row) => {
        if (row.id === action.payload.id) {
          return {
            ...action.payload,
          };
        }
        return row;
      });
      return {
        ...state,
        filters: updatedFilter,
      };

    case DELETE_FILTER_SUCCESS:
      const filterId = action.payload;
      return {
        ...state,
        filters: state.filters.filter((row) => row.id !== filterId),
      };

    case STORE_SCREENSHOT_REPORTS:
      return {
        ...state,
        screenShotReport: action.payload,
      };

    case STORE_INCOMPLETE_TIME_SHEET:
      return {
        ...state,
        incompleteTimesheetReport: {
          totalCount: action.payload.totalCount,
          data: action.payload.data,
        },
      };

    case STORE_BILLABLE_NONBILLABLE_REPORTS:
      return {
        ...state,
        billableNonbillableReport: action.payload,
      };
    case STORE_BILLABLE_TYPEWISE_NONBILLABLE_REPORTS:
      return {
        ...state,
        billableNonbillableTypewiseReport: action.payload,
      };
    case STORE_BILLABLE_NONBILLABLE_PROJECTWISE_USERS:
      return {
        ...state,
        billableNonbillableProjectwiseUsers: action.payload,
      };
    default:
      return state;
  }
};

export default ReportsReducer;
