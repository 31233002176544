import {
  deleteAPICall,
  getAPICall,
  patchAPICall,
  postAPICall,
  setIdInUrl,
} from "../lib/callApi";
import {
  CREATE_PROJECT_API,
  DELETE_PROJECT_API,
  FETCH_MEMBERS_API,
  FETCH_PROJECT_LIST_API,
  FETCH_SINGLE_PROJECT_API,
  LEAVE_PROJECT_API,
  UPDATE_PROJECT_API,
} from "../lib/constants";

export const fetchProjectsByUserId = async (params) => {
  const data = await getAPICall(FETCH_PROJECT_LIST_API, true, params);
  return data;
};

export const leaveProjectById = async (userId, projectId) => {
  const newUrl = setIdInUrl(LEAVE_PROJECT_API, userId);
  const data = await deleteAPICall(newUrl, true, projectId);
  return data;
};

export const deleteProjectById = async (projectId) => {
  const data = await deleteAPICall(DELETE_PROJECT_API, true, projectId);
  return data;
};

export const createProject = async (payload) => {
  const data = await postAPICall(CREATE_PROJECT_API, true, payload);
  return data;
};

export const fetchAllMembers = async () => {
  const data = await getAPICall(FETCH_MEMBERS_API, true);

  return data;
};

export const fetchSingleProject = async (projectId) => {
  const data = await getAPICall(
    FETCH_SINGLE_PROJECT_API,
    true,
    undefined,
    projectId
  );

  return data;
};

export const updateProject = async (payload, ProjectId) => {
  const data = await patchAPICall(UPDATE_PROJECT_API, true, payload, ProjectId);

  return data;
};
