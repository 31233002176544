import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container'
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';

export default function Page404() {
  const navigate = useNavigate();
  const buttonHandler = (e) => {
    e.preventDefault();
    navigate('/', { replace:true });
  }

  return (
    <>
      <Helmet>
        <title> 404 | Page Not Found </title>
      </Helmet>

      <Container>
        <Box className='not-found-container'>
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>
          <Typography className="content">
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? <br/> Be sure to check your
            spelling.
          </Typography>
          <Box
            component="img"
            alt="404 image"
            src="/assets/illustrations/not_found.png"
          />
          <Button size="large" variant="contained" onClick={buttonHandler}>
            Go to Home
          </Button>
        </Box>
      </Container>
    </>
  );
}
