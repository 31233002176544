import { CLEAR_FEEDBACK, SET_FEEDBACK, SET_LOADING } from "../actionTypes";

export const setFeedback = (payload) => {
    return { type: SET_FEEDBACK, payload };
}

export const clearFeedback = () => {
    return { type: CLEAR_FEEDBACK };
}

export const setLoading = (payload) => {
    return { type: SET_LOADING, payload };
}