import { getAPICall, patchAPICall } from "../lib/callApi";
import {
  FETCH_GLOBAL_SETTINGS_API,
  FETCH_G_DRIVE_LISTING,
  UPDATE_GLOBAL_SETTINGS_API,
} from "../lib/constants";

export const fetchGlobalSettings = async () => {
  const data = await getAPICall(FETCH_GLOBAL_SETTINGS_API, true);

  return data;
};

export const updateGlobalSettings = async (payload, id) => {
  const data = await patchAPICall(
    UPDATE_GLOBAL_SETTINGS_API,
    true,
    payload,
    id
  );

  return data;
};

export const fetchGDriveListing = async (params) => {
  const data = await getAPICall(FETCH_G_DRIVE_LISTING, true, params);

  return data;
};
