import { Box } from "@mui/material";
import React from "react";
import { Chart } from "react-google-charts";

const formatTime = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  // const ampm = hours >= 12 ? 'PM' : 'AM';
  // const formattedHours = hours % 12 || 12; // Convert 0 to 12 for midnight

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

const compareTwoBlocks = (end_date, start_date) => {
  return new Date(end_date).getTime() === new Date(start_date).getTime();
};

const calculateDuration = (startDate, endDate) => {
  const durationInMilliseconds = endDate - startDate;
  const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor(
    (durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  );
  return `${hours}h ${minutes}m`;
};

// const timeLineChartData2 = [
//   {
//     start_date: "2024-01-01T02:30:00.000Z",
//     end_date: "2024-01-01T05:00:00.000Z",
//   },
//   {
//     start_date: "2024-01-01T05:01:00.000Z",
//     end_date: "2024-01-01T05:30:00.000Z",
//   },
//   {
//     start_date: "2024-01-01T06:30:00.000Z",
//     end_date: "2024-01-01T08:30:00.000Z",
//   },
// ]

const TimeLineChart = ({ timeLineChartData, selectedDate }) => {
  let DATA = [];
  let colorCount = 0;

  if (!timeLineChartData.length) {
    DATA.push([
      "Available Time",
      "",
      new Date(0, 0, 0, 0, 0, 0),
      new Date(0, 0, 0, 23, 59, 59),
    ]);
  } else {
    const tempDate = new Date(timeLineChartData[0].start_date);
    const first_block_first_date = new Date(0, 0, 0, 0, 0, 0);
    const first_block_end_date = new Date(
      0,
      0,
      0,
      tempDate.getHours(),
      tempDate.getMinutes(),
      tempDate.getSeconds()
    );

    const tempDate2 = new Date(
      timeLineChartData[timeLineChartData.length - 1].end_date
    );
    const last_block_start_date = new Date(
      0,
      0,
      0,
      tempDate2.getHours(),
      tempDate2.getMinutes(),
      tempDate2.getSeconds()
    );
    const last_block_end_date = new Date(0, 0, 0, 23, 59, 59);

    DATA.push([
      "Available Time",
      "",
      first_block_first_date,
      first_block_end_date,
    ]); // start block

    for (let index = 0; index < timeLineChartData.length; index++) {
      const { start_date, end_date } = timeLineChartData[index];

      const START_DATE = new Date(start_date);
      const END_DATE = new Date(end_date);

      let title = `${formatTime(START_DATE)} - ${formatTime(
        END_DATE
      )} [${calculateDuration(START_DATE, END_DATE)}]`;

      colorCount = colorCount + 1;
      DATA.push([
        "Available Time",
        title,
        new Date(
          0,
          0,
          0,
          START_DATE.getHours(),
          START_DATE.getMinutes(),
          START_DATE.getSeconds()
        ),
        new Date(
          0,
          0,
          0,
          END_DATE.getHours(),
          END_DATE.getMinutes(),
          END_DATE.getSeconds()
        ),
      ]);

      // compare code
      if (index < timeLineChartData.length - 1) {
        const nextIndex = index + 1;
        const currentBlock = timeLineChartData[index];
        const nextBlock = timeLineChartData[nextIndex];

        if (!compareTwoBlocks(currentBlock.end_date, nextBlock.start_date)) {
          const tempDate1 = new Date(currentBlock.end_date);
          const start = new Date(
            0,
            0,
            0,
            tempDate1.getHours(),
            tempDate1.getMinutes(),
            tempDate1.getSeconds()
          );

          const tempDate2 = new Date(nextBlock.start_date);
          const end = new Date(
            0,
            0,
            0,
            tempDate2.getHours(),
            tempDate2.getMinutes(),
            tempDate2.getSeconds()
          );

          DATA.push(["Available Time", "", start, end]);
        }
      }
    }

    DATA.push([
      "Available Time",
      "",
      last_block_start_date,
      last_block_end_date,
    ]); // end block
  }

  let colorArray = [];
  for (let index = 0; index < colorCount; index++) {
    colorArray.push("#8CC714");
  }

  const data = [
    [
      { type: "string", id: "Time" },
      { type: "string", id: "Name" },
      { type: "date", id: "Start" },
      { type: "date", id: "End" },
    ],

    ...DATA,

    // [
    //   "Available Time",
    //   "",
    //   new Date(0, 0, 0, 0, 0, 0),
    //   new Date(0, 0, 0, 12, 0, 0),
    // ],
    // [
    //   "Available Time",
    //   "",
    //   new Date(0, 0, 0, 12, 0, 0),
    //   new Date(0, 0, 0, 15, 0, 0),
    // ],
    // [
    //   "Available Time",
    //   "03:00:12 Pm - 08:00:40 PM / 5h 30m",
    //   new Date(0, 0, 0, 15, 0, 0),
    //   new Date(0, 0, 0, 20, 24, 0),
    // ],
    // [
    //   "Available Time",
    //   "",
    //   new Date(0, 0, 0, 20, 24, 0),
    //   new Date(0, 0, 0, 23, 59, 59),
    // ],
  ];

  const options = {
    timeline: {
      showRowLabels: false,
      colorByRowLabel: false,
    },
    avoidOverlappingGridLines: false,
    backgroundColor: "transparent",
    colors: ["lightgrey", ...colorArray],
    hAxis: {
      format: "HH:mm",
    },
  };
  return (
    <>
      <Box stye={{ width: "100%", height: "100%" }} className="chart-container">
        <Chart
          chartType="Timeline"
          data={data}
          width="100%"
          height="100px"
          options={options}
          loader={<div>Loading...</div>}
        />
      </Box>
    </>
  );
};

export default TimeLineChart;
