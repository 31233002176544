import { put, takeEvery } from "redux-saga/effects";
import {
  FETCH_APPLICATIONS_PERMISSIONS,
  FETCH_APPLICATIONS_PERMISSIONS_STORE,
  SET_FEEDBACK,
  SET_LOADING,
} from "../redux/actionTypes";

import { getApplicationPermissions } from "../../apis/applicationPermission";

function* fetchApplicationPermissionHandler(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield getApplicationPermissions(action.payload);

    yield put({ type: SET_LOADING, payload: false });

    if (response.data.status === 200) {
      const payload = response.data.data;

      yield put({ type: FETCH_APPLICATIONS_PERMISSIONS, payload });
    } else if (response.data.status === 404) {
      yield put({ type: FETCH_APPLICATIONS_PERMISSIONS, payload: [] });
    } else {
      const payload = {
        code: response.data.status,
        status: "fail",
        message: response.data.message || "Something went wrong.",
      };
      if (payload.code !== 502) {
        yield put({ type: SET_FEEDBACK, payload });
      }
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const payload = {
      code: error.status,
      status: "fail",
      message: error.message || "Something went wrong.",
    };
    if (payload.code !== 502) {
      yield put({ type: SET_FEEDBACK, payload });
    }
  }
}

function* watchApplicationPermissionAction() {
  yield takeEvery(
    FETCH_APPLICATIONS_PERMISSIONS_STORE,
    fetchApplicationPermissionHandler
  );
}

export default watchApplicationPermissionAction;
