import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import React, { useLayoutEffect, useState } from "react";
import { BiChevronDown } from 'react-icons/bi';
import { RiAddCircleLine } from 'react-icons/ri'
import { useDispatch } from "react-redux";
import ReactQuill from 'react-quill';
import { setFeedback, setLoading } from '../../store/redux/actions/feedback.action';
import { convertDateIntoDDMMYYYY, getLocalTimeZone } from '../../utils/helper';
import axios from 'axios';
import 'react-quill/dist/quill.bubble.css';

const APP_ID = process.env.REACT_APP_APP_ID;
const CENTRAL_URL = process.env.REACT_APP_CENTRAL_API_URL;

const ReleaseBox = ({ index, id, version, date, dispatch }) => {
    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState("");

    const getReleaseNoteDescription = async () => {
        try {
            if(!open){
                dispatch(setLoading(true));
                const paramsData = {
                    id: id,
                    timezone: getLocalTimeZone()
                }
                const response = await axios.get(`${CENTRAL_URL}/release-note`, 
                    { params: paramsData },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            // Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (response.data.status) {
                    setDescription(response.data.data.description);
                    
                } else {
                    setDescription("");
                    const payload = { 
                        code: response.status, 
                        status:"fail", 
                        message: response.data.message || "Something went wrong." 
                    };
                    dispatch(setFeedback(payload));
                }
                // const response = await getAllReleaseNotes(params);
                setOpen(true);
            } else {
                setOpen(false);
            }
        } catch (error) {
            const errPayload = { 
                code: error.status, 
                status: 'fail', 
                message:error.message || "Something went wrong." 
            };
            dispatch(setFeedback(errPayload));  
        } finally {
            dispatch(setLoading(false));
        }
    }

    return <>
        <Box className="release-note">  
            <Box className="heading" onClick={getReleaseNoteDescription}>
                <Box className="release-note-title">
                    <Typography variant="p" component={'p'} className="version-name">Version {version.replace("v", "")}</Typography>
                    <Typography variant="p" component={'p'} className="date">
                        {index === 0 && "Last update:"} { convertDateIntoDDMMYYYY(date) }
                    </Typography>
                </Box>

                <Box className="table-action-btn release-action-container">
                    <BiChevronDown 
                        
                        className={'down-arrow rotate-transition'} 
                        style={{ transform: `rotate(${open ? '180deg' : '0deg'})` }} 
                    />
                </Box>
            </Box>

            <Collapse in={open} timeout="auto" unmountOnExit={true}>
                 <Box className="content">
                    <ReactQuill 
                        value={description || "<h4>NO NOTES AVAILABLE</h4>"}
                        theme='bubble'
                        readOnly={true}
                    />
                </Box> 
            </Collapse>
        </Box>
    </>
}

const ReleaseNotes = () => {
    const dispatch = useDispatch();
    const [rows, setRows] = useState(5);
    const loadMoreHandler = () => {
        setRows(prev => prev + 5);
    }
    const [releaseNotes, setReleaseNotes] = useState({
        totalCount: 0,
        data: [],
    });

    useLayoutEffect(() => {
        const fetchReleaseNotes = async () => {
            try {
                dispatch(setLoading(true));
                const params = { 
                    allNotes: 1, 
                    app_id: APP_ID, 
                    page: 1,
                    rows: rows,
                    timezone: getLocalTimeZone() 
                };

                const response = await axios.get(`${CENTRAL_URL}/release-note`, { params: params },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            // Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (response.data.status) {
                    setReleaseNotes({ totalCount:response.data.data.totalCount, data: response.data.data.findAll});
                } else {
                    const payload = { 
                        code: response.status, 
                        status:"fail", 
                        message: response.data.message || "Something went wrong." 
                    };
                    dispatch(setFeedback(payload));   
                }
                dispatch(setLoading(false));
            } catch (error) {
                const errPayload = { 
                    code: error.status, 
                    status: 'fail', 
                    message:error.message || "Something went wrong." 
                };
                dispatch(setFeedback(errPayload));  
            } finally {
                dispatch(setLoading(false));
            }
        }
        fetchReleaseNotes();
    }, [dispatch, rows]);

    return (
        <>
            {
                releaseNotes?.data?.map(({ id, version_name, date }, index) => {
                    return <Box className="card p-0" key={index + "_releaseBox"}>
                        <ReleaseBox 
                            index={index} 
                            id={id} 
                            version={version_name} 
                            date={date} 
                            dispatch={dispatch}
                        />
                    </Box>
                })
            }
            {
                !releaseNotes.data.length && 
                <Box className="card">
                <p style={{textAlign: 'center'}}>No Release Notes</p>
                </Box>
            }

            {
                releaseNotes.totalCount > rows && 
                <Box className="load-more-section">
                    <Button component={'button'} className='btn btn-primary' onClick={loadMoreHandler}>
                    Load more &nbsp; <RiAddCircleLine />
                    </Button>
                </Box>
            }
        </>
    )
}

export default ReleaseNotes;